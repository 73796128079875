import React, { Component } from "react";
import "./Editrow.css";
import moment from "moment";
import axios from "axios";
import { Row, Col, Input, CardHeader } from "reactstrap";
import { ToggleSwitch } from "react-dragswitch";
import config from "react-global-configuration";
import "../../../config";
import consoleFunction from "../../../Helpers";
import { AllClientDetails } from "../../../components/loginRedux/loginReducers";
import store from "../../../components/loginRedux/Store";
import { connect } from "react-redux";
import getDateTimeField from "../../../Utils";

let taxyearstartdate = "",
  taxyearenddate = "";
const authApi = config.get("apiDomain");

class Addtask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      ChangeDate: "",
      ChangeTask: "",
      ChangeStart: moment(new Date()).format("YYYY-MM-DD"),
      ChangeEnd: moment(new Date()).format("YYYY-MM-DD"),
      ChangeAssign: this.props.username,
      ChangePriority: "Medium",
      ChangeStatus: "ToDo",
      ChangeTime: "",
      ChangeIsdeleted: 0,
      ChangePlanType: "",
      subclient: "",
      file_path: "",
      advanceamount: 0,
      phonenumber: "",
      completed_date: null,
      Clientname: "",
      clientid: [],
      id_value: "",
      plan: [],
      currentuser: "",
      items: [],
      nickname: [],
      Client_id: "",
      client_alldetails: this.props.clientdetails,
      subclient_details: [],
      current_client_id: [],
      select_clientName: [],
      select_client_id: [],
      companydetails: [],
      planId: "",
      Phonenum: "",
      pgcaddTaskNames: [],
      todaydate: moment(new Date()).format("YYYYMMDD"),
      error: null,
      Maintaskid: "",
      enableprinting: 0,
      border: "1px solid #dee2e6",
      checked: false,
      PrinterName: "",
      checkclient: 0,
      userid: 0,
      drawingTitle: "",
      checkClientid: 0,
      clientidborder: "1px solid #dee2e6",
      palntypeborder: "1px solid #dee2e6",
      subclientborder: "1px solid #dee2e6",
      companyborder: "1px solid #dee2e6",
      Unpaiddetails: [],
      isButtonDisabled: false,
      companyid: "",
      company_name: "",
      Projectname: "",
      ProjectId: 0,
      ProjectnameDetails: [],
      showclientid: true,
      showclientname: true,
    };
  }

  async componentDidMount() {
    //await this.Projectdetails();
    let maxdata = 0,
      max = 0;
    const responseyear = await this.checkTaxYear();
    responseyear.forEach((currentyear) => {
      if (currentyear.id > max) {
        max = currentyear.id;
        maxdata = currentyear;
      }
    });
    taxyearstartdate = maxdata.startdate;
    taxyearenddate = maxdata.enddate;
    this.checkprinting();
    let advanceamount = parseInt(this.state.advanceamount);
    this.setState({
      advanceamount: advanceamount,
    });
    let currentuser = this.props.username;
    let currentuserdetails = this.props.allusers.filter(function (item) {
      return item.name === currentuser;
    });
    this.setState(
      {
        items: this.props.allusers,
        userid: currentuserdetails[0].id,
      },
      function () {
        this.PlanTypeFunction();
        this.CompanyDetails();
        this.filtereditclient();
        this.Storeprintername();
      }
    );
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.Client_id !== this.state.Client_id) {
      this.setState({ Client_id: this.state.Client_id });
    }
  }

  Projectdetails = async () => {
    const response = await fetch(
      `${authApi}projectfilter/?status__in=Initiate%2CPending%2CInProgress`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    );
    let body = await response.json();
    this.setState({
      ProjectnameDetails: body,
    });
  };

  checkTaxYear = () => {
    return fetch(`${authApi}taxyear/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  Storeprintername = () => {
    this.state.items.forEach((i) => {
      if (i.userprofile === "Printer") {
        this.setState({
          PrinterName: i.name,
        });
      }
    });
  };

  checkprinting = () => {
    if (
      this.state.Clientname === "General-Printing" ||
      this.state.Clientname === "General"
    ) {
      this.setState({
        enableprinting: 1,
      });
    }
  };

  CompanyDetails = () => {
    fetch(`${authApi}companyfilter/?isactive=1`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        console.log(json);
        this.setState({
          companydetails: json,
          companyid: json[0].id,
          company_name: json[0].companyname,
        });
      });
  };

  filtereditclient = async () => {
    if (this.state.id_value !== "" && this.state.id_value !== undefined) {
      console.log(this.state.id_value);
      let currentclient = this.state.client_alldetails.filter(function (item) {
        return item.id === this.state.id_value;
      });
      this.setState(
        {
          current_client_id: currentclient,
        },
        function () {
          this.updatenickname();
        }
      );
    }
  };

  PlanTypeFunction = () => {
    fetch(`${authApi}Plans/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState(
          {
            plan: json,
          },
          function () {}
        );
      });
  };
  HandleChangeTask = (event) => {
    const b = event.target.value;
    this.setState({
      ChangeTask: b,
    });
  };

  HandleChangeTime = (event) => {
    this.setState({
      ChangeTime: event.target.value,
    });
  };

  HandleChangeAssign = (event) => {
    const c = event.target.value;
    this.setState({
      ChangeAssign: c,
    });
  };

  HandleChangePriority = (event) => {
    const d = event.target.value;
    this.setState({
      ChangePriority: d,
    });
  };

  HandleChangeStart = (event) => {
    const e = event.target.value;
    this.setState({
      ChangeStart: e,
    });
  };

  HandleChangeEnd = (event) => {
    const f = event.target.value;
    this.setState({
      ChangeEnd: f,
    });
  };

  HandleChangeStatus = (event) => {
    const g = event.target.value;
    this.setState({
      ChangeStatus: g,
    });
  };

  Formvalidation = () => {
    if (this.state.id_value !== "") {
      if (this.state.ChangePlanType !== "") {
        if (this.state.subclient !== "") {
          if (this.state.company_name !== "") {
            this.setState({
              isButtonDisabled: true,
            });
            this.UpdationRow();
          } else {
            this.setState({
              companyborder: "1px solid #fb6340",
              subclientborder: "1px solid #dee2e6",
              clientidborder: "1px solid #dee2e6",
              palntypeborder: "1px solid #dee2e6",
            });
          }
        } else {
          this.setState({
            subclientborder: "1px solid #fb6340",
            clientidborder: "1px solid #dee2e6",
            palntypeborder: "1px solid #dee2e6",
          });
        }
      } else {
        this.setState({
          palntypeborder: "1px solid #fb6340",
          clientidborder: "1px solid #dee2e6",
        });
      }
    } else {
      this.setState({
        clientidborder: "1px solid #fb6340",
      });
    }
  };

  UpdationRow = () => {
    let datetime_field = getDateTimeField();
    this.subclientUpdation();
    const date = this.state.ChangeStart;
    const task = this.state.ChangeTask;
    const assignto = this.state.ChangeAssign;
    const periority = this.state.ChangePriority;
    const startdate = this.state.ChangeStart;
    const enddate = this.state.ChangeEnd;
    const status = this.state.ChangeStatus;
    const time = this.state.ChangeTime;
    const isdeleted = this.state.ChangeIsdeleted;
    axios({
      method: "post",
      url: `${authApi}Tasklist/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        date: date,
        task: task,
        assignto: assignto,
        priority: periority,
        startdate: startdate,
        enddate: enddate,
        status: status,
        time: time,
        isdeleted: isdeleted,
        plantype: this.state.ChangePlanType,
        subclient: this.state.subclient,
        task_starttime: "",
        client: this.state.id_value,
        file_path: this.state.file_path,
        completed_date: this.state.completed_date,
        advanceamount: this.state.advanceamount,
        phonenumber: this.state.phonenumber,
        clientname: this.state.Clientname,
        drawingtitle: this.state.drawingTitle,
        parenttaskid: "",
        parenttaskbalance: 0,
        company: this.state.companyid,
        companyname: this.state.company_name,
        projectname: this.state.Projectname,
        taskproject: this.state.ProjectId === 0 ? null : this.state.ProjectId,
        createdby: this.props.username,
        createdat: datetime_field,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          console.log("task created successfully");
          this.setState(
            {
              Maintaskid: res.data.id,
            },
            function () {
              this.DefaultSubtask();
              if (
                this.state.Clientname !== "General-Printing" &&
                this.state.Clientname !== "General"
              ) {
                this.StoreTransaction();
              } else {
                this.props.calbackfunction("AddTask");
              }
            }
          );
        }
      })
      .catch((err) => {
        console.log(err);
        consoleFunction("Task updation error", err.response);
        this.props.calbackfunction("AddTasks");
        this.setState({ error: err.response.request.response });
      });
  };

  subclientUpdation = () => {
    let SubName = this.state.subclient;
    let currentsubclient = this.state.subclient_details.filter(function (item) {
      return item.name === SubName;
    });
    if (currentsubclient.length === 0) {
      let datetime_field = getDateTimeField();
      axios({
        method: "post",
        url: `${authApi}subclients/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          name: this.state.subclient,
          phoneno: this.state.Phonenum,
          clients: this.state.id_value,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      });
      this.updateclientdetails();
    }
  };

  updateclientdetails = () => {
    fetch(`${authApi}clientfilter_redux/?isdeleted=False`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        localStorage.setItem(
          "clientdetails",
          JSON.stringify(json.sort((a, b) => a.clientid - b.clientid))
        );
        store.dispatch({
          type: AllClientDetails,
          data: json.sort((a, b) => a.clientid - b.clientid),
        });
      });
  };

  StoreTransaction = () => {
    const id = this.state.Maintaskid;
    const today = new Date();
    const dateformat = moment(today).format("YYYY-MM-DD");
    let advanceamountvalue = parseInt(this.state.advanceamount) || 0;
    const currentTime = moment(new Date())
      .utcOffset("+05:30")
      .format("hh:mm:ss A");
    if (advanceamountvalue !== 0) {
      let datetime_field = getDateTimeField();
      console.log("clientid" + this.state.id_value);
      axios({
        method: "post",
        url: `${authApi}transactions/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          transactiontaskid: id,
          date: dateformat,
          time: currentTime,
          clientidnum: this.state.id_value,
          transaction_clientid: this.state.id_value,
          userid_id: this.state.userid,
          amount: this.state.advanceamount,
          deliverynoteid: "",
          originaldeliverynoteid: 0,
          originalcompanyid: 0,
          description: "",
          transactiontype: "received",
          companyid: this.state.companyid,
          companyidnum: this.state.companyid,
          pmtmode: "Cash",
          pmtreference: "",
          voucherid: "",
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          if (res.status === 201) {
            if (
              this.state.Clientname !== "General-Printing" &&
              this.state.Clientname !== "General"
            ) {
              this.PayforUnpaidTask();
            }
          }
        })
        .catch((err) => {
          consoleFunction(
            "Transaction received error",
            err.response.request.response
          );
          this.props.calbackfunction("AddTasks");
        });
    } else {
      this.props.calbackfunction("AddTask");
    }
  };

  PayforUnpaidTask = async () => {
    axios({
      method: "post",
      url: `${authApi}sp_payforunpaidinvoice/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        client_id: this.state.id_value,
        company_id_value: this.state.companyid,
        startdate: taxyearstartdate,
        enddate: taxyearenddate,
        userid: this.state.userid,
        clientname: this.state.Clientname,
        received_amount: this.state.advanceamount,
        description: "Advance",
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          this.props.calbackfunction("AddTask");
        }
      })
      .catch((err) => {
        consoleFunction(
          "Pay for unpaid task error",
          err.response.request.response
        );
        this.props.calbackfunction("AddTasks");
      });
  };

  DefaultSubtask = () => {
    let datetime_field = getDateTimeField();
    const id = this.state.Maintaskid;
    const date = moment(this.state.todaydate).format("YYYY-MM-DD");
    const subtask = "Default";
    const assignto = this.state.ChangeAssign;
    const periority = this.state.ChangePriority;
    const status = this.state.ChangeStatus;
    const time = this.state.ChangeTime;
    const isdeleted = this.state.ChangeIsdeleted;
    if (this.state.ChangeStatus === "Printing") {
      axios({
        method: "post",
        url: `${authApi}subtasks/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: date,
          subtask: "Printing",
          assignto: assignto,
          priority: periority,
          status: status,
          time: time,
          isdeleted: isdeleted,
          completed_date: null,
          task_starttime: this.state.completed_date,
          tasklist: id,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      }).catch((err) => {
        consoleFunction(
          "Printing default subtask error",
          err.response.request.response
        );
        this.props.calbackfunction("AddTasks");
      });
    } else {
      axios({
        method: "post",
        url: `${authApi}subtasks/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: date,
          subtask: subtask,
          assignto: assignto,
          priority: periority,
          status: status,
          time: time,
          isdeleted: isdeleted,
          completed_date: null,
          task_starttime: this.state.completed_date,
          tasklist: id,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          this.props.calbackfunction("AddTasks");
        })
        .catch((err) => {
          consoleFunction(
            "Printing default subtask error",
            err.response.request.response
          );
          this.props.calbackfunction("AddTasks");
        });
    }
  };

  selectClient_Name = async (event) => {
    let currentclient = await this.state.client_alldetails.filter((item) => {
      return item.company_name === event.target.value;
    });
    if (currentclient.length > 0) {
      this.setState(
        {
          clientidborder: "1px solid #dee2e6",
          Client_id: currentclient[0].clientid,
          subclient_details: currentclient[0].clients,
          id_value: currentclient[0].id,
          Clientname: event.target.value,
          subclient:
            currentclient[0].company_name === "General-Printing" ||
            currentclient[0].company_name === "General"
              ? "General"
              : "",
          Phonenum:
            currentclient[0].company_name === "General-Printing" ||
            currentclient[0].company_name === "General"
              ? "General"
              : "",
          phonenumber:
            currentclient[0].company_name === "General-Printing" ||
            currentclient[0].company_name === "General"
              ? "General"
              : "",
        },
        () => {
          this.TaskUpdate();
        }
      );
    } else {
      this.setState({
        clientidborder: "1px solid #fb6340",
        Client_id: "",
        subclient_details: [],
        id_value: "",
        Clientname: event.target.value,
      });
    }
  };

  selectplantype = (event) => {
    let plantype = this.state.ChangePlanType.substr(0, 2);
    this.state.plan.map((item) => {
      if (item.planname === this.state.ChangePlanType) {
        this.setState({
          planId: item.id,
          ChangeTask:
            this.state.Client_id.toString() +
            plantype +
            "_" +
            this.state.subclient +
            "_" +
            this.state.todaydate,
        });
      }
      return this.state.plan;
    });
    this.setState(
      {
        ChangePlanType: event.target.value,
      },
      function () {
        this.TaskUpdate();
      }
    );
  };

  selectid = async (event) => {
    let currentclient = await this.state.client_alldetails.filter(function (
      item
    ) {
      return item.clientid === event.target.value;
    });
    if (currentclient.length > 0) {
      this.setState(
        {
          clientidborder: "1px solid #dee2e6",
          Client_id: event.target.value,
          subclient_details: currentclient[0].clients,
          id_value: currentclient[0].id,
          Clientname: currentclient[0].company_name,
          subclient:
            currentclient[0].company_name === "General-Printing" ||
            currentclient[0].company_name === "General"
              ? "General"
              : "",
          Phonenum:
            currentclient[0].company_name === "General-Printing" ||
            currentclient[0].company_name === "General"
              ? "General"
              : "",
          phonenumber:
            currentclient[0].company_name === "General-Printing" ||
            currentclient[0].company_name === "General"
              ? "General"
              : "",
        },
        () => {
          this.TaskUpdate();
        }
      );
    } else {
      this.setState(
        {
          clientidborder: "1px solid #fb6340",
          Client_id: event.target.value,
          subclient_details: [],
          id_value: "",
          Clientname: "",
        },
        () => {
          this.TaskUpdate();
        }
      );
    }
  };
  updatenickname = () => {
    this.state.current_client_id.map((item) => {
      if (item.clientid === "00300") {
        this.setState({
          Clientname: item.company_name,
          Client_id: item.clientid,
          enableprinting: 1,
        });
      } else {
        this.setState({
          Clientname: item.company_name,
          Client_id: item.clientid,
        });
      }
      return this.state.Clientname;
    });
  };

  handlesubclient = (event) => {
    let currentsubclientdetails = this.state.subclient_details.filter(
      (subclientdata) => {
        return subclientdata.name === event.target.value;
      }
    );
    this.setState(
      {
        subclient: event.target.value,
        Phonenum:
          currentsubclientdetails.length > 0
            ? currentsubclientdetails[0].phoneno
            : "",
        phonenumber:
          currentsubclientdetails.length > 0
            ? currentsubclientdetails[0].phoneno
            : "",
      },
      function () {
        this.TaskUpdate();
      }
    );
  };

  TaskUpdate = () => {
    let plantype = this.state.ChangePlanType.substr(0, 2);
    let task =
      this.state.Client_id.toString() +
      plantype +
      "_" +
      this.state.subclient +
      "_" +
      this.state.drawingTitle +
      "_" +
      this.state.todaydate;
    this.setState({
      ChangeTask: task,
    });
  };

  HandleAdvanceAmount = (event) => {
    this.setState({
      advanceamount: event.target.value,
    });
  };

  HandlePhonenum = (event) => {
    this.setState({
      Phonenum: event.target.value,
      phonenumber: event.target.value,
    });
  };

  HandleDrawingTitle = (event) => {
    this.setState(
      {
        drawingTitle: event.target.value,
      },
      function () {
        this.TaskUpdate();
      }
    );
  };

  HandleCompany = (event) => {
    event.persist();
    this.setState({
      company_name: event.target.value,
    });
    fetch(`${authApi}companyfilter/?companyname=${event.target.value}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.length !== 0) {
          console.log(json);
          this.setState({
            companyid: json[0].id,
          });
        }
      });
  };

  Printingdeails = async (event) => {
    let currentclient = await this.state.client_alldetails.filter(function (
      item
    ) {
      return item.company_name === "General-Printing";
    });
    let startdate = moment(this.state.todaydate).format("YYYY-MM-DD");
    let enddate = moment(this.state.todaydate).format("YYYY-MM-DD");
    if (this.state.checked === false) {
      this.setState(
        {
          checked: true,
          Client_id: "00300",
          ChangePlanType: "18Printing",
          Clientname: "General-Printing",
          enableprinting: 1,
          ChangeAssign: this.state.PrinterName,
          ChangeStart: startdate,
          ChangeEnd: enddate,
          subclient_details: currentclient[0].clients,
          id_value: currentclient[0].id,
          ChangeDate: moment(this.state.todaydate).format("YYYY-MM-DD"),
          ChangeTime: "00:00",
          completed_date: null,
          file_path: "",
          advanceamount: "0",
          subclient: "General",
          Phonenum: "General",
          phonenumber: "General",
        },
        function () {
          this.updateprintingTaskname();
        }
      );
    } else {
      this.setState({
        checked: false,
        Client_id: "",
        ChangePlanType: "",
        Clientname: "",
        enableprinting: 0,
        ChangeStart: startdate,
        ChangeEnd: enddate,
        subclient_details: [],
        ChangeAssign: this.props.assigneeName,
        ChangePriority: "Medium",
        ChangeStatus: "ToDo",
        drawingTitle: "",
        subclient: "",
        Phonenum: "",
        ChangeTask: "",
      });
    }
  };

  updateprintingTaskname = () => {
    this.setState({
      ChangeTask:
        this.state.Client_id.toString() +
        this.state.ChangePlanType.substr(0, 2) +
        "_" +
        this.state.subclient +
        "_" +
        this.state.drawingTitle +
        "_" +
        this.state.todaydate,
    });
  };

  ChangeProjectname = (event) => {
    this.setState({
      Projectname: event.target.value,
    });
    this.state.ProjectnameDetails.forEach((element) => {
      if (element.projectname === event.target.value) {
        this.setState({
          ProjectId: element.id,
        });
      }
    });
  };

  backtoPosition = () => {
    this.props.calbackfunction("AddTask");
  };

  handleEnter = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    } else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  Showclientiddatalist = () => {
    this.setState({
      showclientid: false,
    });
  };

  hideclientiddatalist = () => {
    this.setState({
      showclientid: true,
    });
  };

  ShowClientnamedatalist = () => {
    this.setState({
      showclientname: false,
    });
  };

  hideclientnamedatalist = () => {
    this.setState({
      showclientname: true,
    });
  };

  render() {
    return (
      <React.Fragment>
        <br />
        <form>
          <Row>
            <Col md="11">
              <CardHeader
                style={{
                  backgroundColor: "transparent",
                  padding: 10,
                  marginTop: 20,
                }}
              >
                <h3 className="mb-0">Add New Task </h3>
              </CardHeader>
            </Col>
            <Col md="1">
              <button
                className="btn btn-primary btn-sm"
                style={{ marginTop: 25, width: 65, height: 40 }}
                data-tip
                data-for="completed-Tip"
                onClick={this.backtoPosition}
              >
                Back
              </button>
            </Col>
          </Row>
        </form>
        <div style={{ marginTop: 30 }}>
          <div className="Scroll-Content" style={{ marginTop: 10 }}>
            <Row style={{ marginTop: 10 }}>
              <form style={{ width: "100%" }}>
                <Col xl="12">
                  <Row>
                    <Col md="2">
                      <label className="form-control-label">PrintTask</label>
                      <br />
                      <label
                        style={{
                          textAlign: "center",
                          marginLeft: 30,
                          marginTop: 2,
                        }}
                      >
                        <ToggleSwitch
                          offColor="#ff1a1a"
                          onColor="#00cc00"
                          checked={this.state.checked}
                          onChange={this.Printingdeails}
                        />
                        {this.state.checked === true ? (
                          <h6>On</h6>
                        ) : (
                          <h6>Off</h6>
                        )}
                      </label>
                    </Col>
                    {this.state.showclientid === true ? (
                      <Col md="2">
                        <label className="form-control-label">Client Id</label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          style={{ border: "1px solid #dee2e6" }}
                          value={this.state.Client_id}
                          onFocus={this.Showclientiddatalist}
                          onKeyDown={this.handleEnter}
                        />
                        {this.state.clientidborder !== "1px solid #dee2e6" ? (
                          <h5 style={{ color: "#fb6340" }}>
                            Please select clientid
                          </h5>
                        ) : null}
                      </Col>
                    ) : (
                      <Col md="2">
                        <label className="form-control-label">Client Id</label>
                        <Input
                          type="option"
                          list="id_value-list"
                          className="form-control-alternative"
                          defaultValue={this.state.Client_id}
                          style={{ border: this.state.clientidborder }}
                          onChange={this.selectid}
                          onBlur={this.hideclientiddatalist}
                          onKeyDown={this.handleEnter}
                          autoFocus="autofocus"
                        />
                        <datalist id="id_value-list">
                          {this.state.client_alldetails.map((item, key) => (
                            <option key={key} value={item.clientid} />
                          ))}
                        </datalist>
                        {this.state.clientidborder !== "1px solid #dee2e6" ? (
                          <h5 style={{ color: "#fb6340" }}>
                            Please select clientid
                          </h5>
                        ) : null}
                      </Col>
                    )}
                    {this.state.showclientname ? (
                      <Col md="4">
                        <label className="form-control-label">
                          Client Name
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          style={{ border: "1px solid #dee2e6" }}
                          value={this.state.Clientname}
                          onFocus={this.ShowClientnamedatalist}
                          onKeyDown={this.handleEnter}
                        />
                        {this.state.clientidborder !== "1px solid #dee2e6" ? (
                          <h5 style={{ color: "#fb6340" }}>
                            Please select name
                          </h5>
                        ) : null}
                      </Col>
                    ) : (
                      <Col md="4">
                        <label className="form-control-label">
                          Client Name
                        </label>
                        <Input
                          type="option"
                          list="nickname-list"
                          className="form-control-alternative"
                          defaultValue={this.state.Clientname}
                          style={{ border: this.state.clientidborder }}
                          onChange={this.selectClient_Name}
                          onBlur={this.hideclientnamedatalist}
                          onKeyDown={this.handleEnter}
                        />
                        <datalist id="nickname-list">
                          {this.state.client_alldetails.map((item, name) => (
                            <option key={name} value={item.company_name} />
                          ))}
                        </datalist>
                        {this.state.clientidborder !== "1px solid #dee2e6" ? (
                          <h5 style={{ color: "#fb6340" }}>
                            Please select name
                          </h5>
                        ) : null}
                      </Col>
                    )}
                    <Col md="4">
                      <label className="form-control-label">AssignTo</label>
                      <Input
                        type="select"
                        className="form-control-alternative"
                        style={{ border: "1px solid #dee2e6" }}
                        value={this.state.ChangeAssign}
                        onChange={this.HandleChangeAssign}
                        onKeyDown={this.handleEnter}
                      >
                        <option>Select</option>
                        {this.state.items.map((obj, assign) => (
                          <option key={assign} value={obj.name}>
                            {obj.name}
                          </option>
                        ))}
                      </Input>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: -5 }}>
                    <Col md="4">
                      <label className="form-control-label">Plan type</label>
                      <Input
                        type="option"
                        list="models-list"
                        className="form-control-alternative"
                        value={this.state.ChangePlanType}
                        style={{ border: this.state.palntypeborder }}
                        onChange={this.selectplantype}
                        onKeyDown={this.handleEnter}
                      />
                      <datalist id="models-list">
                        {this.state.plan.map((item, plan) => (
                          <option
                            key={plan}
                            value={item.planname}
                            newvalue={item.id}
                          />
                        ))}
                      </datalist>
                      {this.state.palntypeborder !== "1px solid #dee2e6" ? (
                        <h5 style={{ color: "#fb6340" }}>
                          Please select plantype
                        </h5>
                      ) : null}
                    </Col>
                    <Col md="4">
                      <label className="form-control-label">Subclient</label>
                      <Input
                        type="option"
                        list="subclient-list"
                        className="form-control-alternative"
                        style={{ border: this.state.subclientborder }}
                        value={this.state.subclient}
                        onChange={this.handlesubclient}
                        onKeyDown={this.handleEnter}
                      ></Input>
                      <datalist id="subclient-list">
                        {this.state.subclient_details.map((item, name) => (
                          <option key={name} value={item.name} />
                        ))}
                      </datalist>
                      {this.state.subclientborder !== "1px solid #dee2e6" ? (
                        <h5 style={{ color: "#fb6340" }}>
                          Please enter subclient
                        </h5>
                      ) : null}
                    </Col>
                    <Col md="4">
                      <label className="form-control-label">Phonenumber</label>
                      <Input
                        className="form-control-alternative"
                        type="text"
                        style={{ border: "1px solid #dee2e6" }}
                        value={this.state.Phonenum}
                        onChange={this.HandlePhonenum}
                        onKeyDown={this.handleEnter}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="2">
                      <label className="form-control-label">Status</label>
                      <Input
                        type="select"
                        className="form-control-alternative"
                        style={{ border: "1px solid #dee2e6" }}
                        defaultValue={this.state.ChangeStatus}
                        onChange={this.HandleChangeStatus}
                        onKeyDown={this.handleEnter}
                      >
                        <option value="0">Select</option>
                        <option value="ToDo">ToDo</option>
                        <option value="Pending">Pending</option>
                        <option value="InProgress">InProgress </option>
                        <option value="Completed">Completed</option>
                      </Input>
                    </Col>
                    <Col md="2">
                      <label className="form-control-label">Priority</label>
                      <Input
                        type="select"
                        className="form-control-alternative"
                        style={{ border: "1px solid #dee2e6" }}
                        defaultValue={this.state.ChangePriority}
                        onChange={this.HandleChangePriority}
                        onKeyDown={this.handleEnter}
                      >
                        <option value="0">Select</option>
                        <option value="Low">Low</option>
                        <option value="Medium">Medium</option>
                        <option value="High">High</option>
                      </Input>
                    </Col>
                    <Col md="4">
                      <label className="form-control-label">
                        Drawing Title
                      </label>
                      <Input
                        className="form-control-alternative"
                        type="text"
                        style={{ border: "1px solid #dee2e6" }}
                        value={this.state.drawingTitle}
                        onChange={this.HandleDrawingTitle}
                        onKeyDown={this.handleEnter}
                      />
                    </Col>
                    <Col md="4">
                      <label className="form-control-label">End Date</label>
                      <Input
                        className="form-control-alternative"
                        type="date"
                        style={{ border: "1px solid #dee2e6" }}
                        defaultValue={this.state.ChangeEnd}
                        onChange={this.HandleChangeEnd}
                        onKeyDown={this.handleEnter}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      <label className="form-control-label">Company</label>
                      <Input
                        type="select"
                        className="form-control-alternative"
                        value={this.state.company_name}
                        style={{ border: this.state.companyborder }}
                        onChange={this.HandleCompany}
                        onKeyDown={this.handleEnter}
                      >
                        <option value="">Select Company</option>
                        {this.state.companydetails.map((item, key) => (
                          <option key={key} value={item.companyname}>
                            {item.companyname}
                          </option>
                        ))}
                      </Input>
                      {this.state.companyborder !== "1px solid #dee2e6" ? (
                        <h5 style={{ color: "#fb6340" }}>
                          Please select companyname
                        </h5>
                      ) : null}
                    </Col>
                    <Col md="4">
                      <label className="form-control-label">Task Name</label>
                      <Input
                        type="option"
                        list="id_value-lists"
                        style={{ border: "1px solid #dee2e6" }}
                        className="form-control-alternative"
                        value={this.state.ChangeTask}
                        onChange={this.HandleChangeTask}
                        onKeyDown={this.handleEnter}
                      />
                      <datalist id="id_value-lists">
                        {this.state.pgcaddTaskNames.map((item, key) => (
                          <option key={key} value={item.task} />
                        ))}
                      </datalist>
                    </Col>
                    <Col md="4">
                      <label className="form-control-label">Advance</label>
                      <Input
                        className="form-control-alternative"
                        type="text"
                        style={{ border: "1px solid #dee2e6" }}
                        defaultValue={this.state.advanceamount}
                        onChange={this.HandleAdvanceAmount}
                        onKeyDown={this.handleEnter}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="4">
                      {/* <label className="form-control-label">Project Name</label>
                      <Input
                        type="option"
                        list="id_value-projectlist"
                        className="form-control-alternative"
                        value={this.state.Projectname}
                        onChange={this.ChangeProjectname}
                        onKeyDown={this.handleEnter}
                      />
                      <datalist id="id_value-projectlist">
                        {this.state.ProjectnameDetails.map((item, key) => (
                          <option key={item.id} value={item.projectname} />
                        ))}
                      </datalist> */}
                    </Col>
                    <Col md="6"></Col>
                    <Col md="2" style={{ textAlign: "center" }}>
                      <label className="form-control-label">
                        &nbsp;&nbsp;
                        <button
                          id="buttonsave"
                          className="btn btn-dark"
                          onClick={this.Formvalidation}
                          disabled={this.state.isButtonDisabled}
                        >
                          Save
                        </button>
                      </label>
                    </Col>
                  </Row>
                </Col>
              </form>
            </Row>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails: state.clientdetails,
    allusers: state.allusers,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(Addtask);
