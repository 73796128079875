import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
  },
  rows: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold"
  },
  dates: {
    width: "20%",
    textAlign: "left",
    paddingTop: 4,
  },
  invoicenos: {
    width: "25%",
    textAlign: "center",
    paddingTop: 4,
  },
  types: {
    width: "20%",
    textAlign: "left",
    paddingLeft: 24,
    paddingTop: 4,
  },
  clientBalances: {
    width: "17.5%",
    textAlign: "center",
    paddingTop: 4,
  },
  invoiceamounts: {
    width: "17.5%",
    textAlign: "center",
    paddingTop: 4,
  }
});

const TransactionRow = ({ taskData , cd }) => 
{
   const finaldata = taskData.filter((i) => { return i.Amount !== "0" });

   const reportTasks = finaldata.map((t) => (
    <React.Fragment>
      <View style={styles.rows} key={t.id} wrap={false}>
        <Text style={styles.dates}>{t.date}</Text>
        { cd !== undefined ?
          <Text style={styles.invoicenos}>{t.Invoice}</Text> :
          <Text style={styles.invoicenos}>{t.ClientName}</Text>
        }
        <Text style={styles.types}>{t.transactiontype}</Text>
        <Text style={styles.invoiceamounts}>{t.Credit}</Text>
        { t.transactiontype === 'spend' || t.transactiontype === 'adjustmentspend' ?
          <Text style={styles.clientBalances}> {t.Debit} </Text>
        : 
          <Text style={styles.clientBalances}> {t.InvoiceAmount} </Text>
        }
      </View>
    </React.Fragment>
   ));

  return( 
    <React.Fragment>
      {reportTasks}
    </React.Fragment>
  )
};

export default TransactionRow;
