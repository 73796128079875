import React, { Component } from "react";
import config from "react-global-configuration";
import axios from "axios";
import { Row, Col, Input } from "reactstrap";
import { connect } from "react-redux";
import "../../../config";
import moment from "moment";
import ReactBSAlert from "react-bootstrap-sweetalert";
import getDateTimeField from "../../../Utils";

let TransactionClient_balance = 0,
  invoicebalance = 0,
  taxyearstartdate = "",
  taxyearenddate = "";
const authApi = config.get("apiDomain");

class InvoiceEditRowpopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Invoiceno: this.props.EditRowSend.invoiceno,
      date: this.props.EditRowSend.invoicedate,
      client: this.props.EditRowSend.client,
      clientname: this.props.EditRowSend.clientname,
      Subtotal: this.props.EditRowSend.subtotal,
      duedate: this.props.EditRowSend.duedate,
      Discount: this.props.EditRowSend.discount,
      invoice_amount: this.props.EditRowSend.invoice_amount,
      amount_received: this.props.EditRowSend.amount_received,
      balancedue: this.props.EditRowSend.balancedue,
      invoicestatus: this.props.EditRowSend.invoicestatus,
      paymentmode: "Cash",
      paymentmode_option: "",
      referenceno: "",
      newamount: 0,
      Amountcheck: "",
      transaction: [],
      TransactionClientBalance: 0,
      TransactionInvoiceBalance: 0,
      transactionbalance: 0,
      transactiondate: new Date(),
      userid: "",
      newamountborder: "none",
      isButtonDisabled: true,
      maintaskid: "",
      companyid: "",
      paycurrentinvoiceCheckbox: true,
      bank: [],
      alert: false,
      CompanyName: "",
    };
  }

  async componentDidMount() {
    let maxdata = 0,
      max = 0;
    const responseyear = await this.checkTaxYear();
    responseyear.forEach((currentyear) => {
      if (currentyear.id > max) {
        max = currentyear.id;
        maxdata = currentyear;
      }
    });
    taxyearstartdate = maxdata.startdate;
    taxyearenddate = maxdata.enddate;
    this.companyUpdation();
    this.clientbalancedetails();
    this.getUserid(this.props.username);
    this.setState({
      maintaskid: this.props.invoicepropsdata.tbltasklist_id,
      companyid: this.props.invoicepropsdata.companyid,
    });
    this.getBankDetailsForCurrenttaskCompany();
  }

  getBankDetailsForCurrenttaskCompany = () => {
    fetch(
      `${authApi}bankfilter/?companydetails=${this.props.invoicepropsdata.companyid}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          bank: json,
        });
      });
  };

  companyUpdation = () => {
    this.props.CompanyData.map((cmp) => {
      if (parseInt(cmp.id) === parseInt(this.props.company_id)) {
        this.setState({
          CompanyName: cmp.companyname,
        });
      }
    });
  };

  checkTaxYear = () => {
    return fetch(`${authApi}taxyear/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  clientbalancedetails = () => {
    axios({
      method: "post",
      url: `${authApi}sp_calculateclosingbalance/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        client_id: this.state.client,
        company_id: this.props.invoicepropsdata.companyid,
        startdate: taxyearstartdate,
        enddate: taxyearenddate,
      },
    })
      .then((res) => {
        if (res.data.balance > 0) {
          this.setState({
            TransactionClientBalance: 0,
            TransactionInvoiceBalance: res.data.balance,
            isButtonDisabled: false,
          });
        } else {
          this.setState({
            TransactionClientBalance: parseInt(res.data.balance) * -1,
            TransactionInvoiceBalance: 0,
            isButtonDisabled: false,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getUserid = (user) => {
    fetch(`${authApi}userfilter/?name=${user}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          userid: json[0].id,
        });
      });
  };

  HandleChangepaymentmode = (event) => {
    this.setState({
      paymentmode: event.target.value,
    });
  };

  HandleChangereferenceno = (event) => {
    this.setState({
      referenceno: event.target.value,
    });
  };

  newamountvalue = (event) => {
    let newamoutvalue = 0,
      balanced = 0;
    if (event.target.value === "") {
      newamoutvalue = 0;
    } else {
      newamoutvalue = parseInt(event.target.value);
      balanced = this.state.balancedue;
    }
    if (newamoutvalue <= balanced) {
      if (event.target.value !== "") {
        this.setState({
          Amountcheck: "",
          newamount: event.target.value,
        });
      } else {
        this.setState({
          newamount: event.target.value,
        });
      }
    } else {
      this.setState({
        Amountcheck: (
          <h6 style={{ color: "red" }}>
            Amount Entered is greater than Balance Amount. To Pay More use Multi
            Payment option..!
          </h6>
        ),
      });
    }
  };

  Formvalidation = async () => {
    let receivedamount = parseInt(this.state.newamount) || 0;
    let duebalancetotal = parseInt(this.state.balancedue) || 0;
    if (receivedamount !== 0) {
      if (this.state.newamount !== "") {
        this.setState({
          isButtonDisabled: true,
        });
        if (
          this.props.EditRowSend.client === 110 ||
          this.props.EditRowSend.client === 182
        ) {
          invoicebalance = parseInt(this.state.TransactionInvoiceBalance) || 0;
          console.log(invoicebalance);
          TransactionClient_balance =
            parseInt(this.state.TransactionClientBalance) || 0;
          await this.GeneralReceiveTransaction(receivedamount);
          if (duebalancetotal <= receivedamount) {
            TransactionClient_balance =
              TransactionClient_balance - duebalancetotal;
            let Totalreceivedamount =
              parseInt(this.state.amount_received) + duebalancetotal;
            let status = "Paid";
            let duebalance = "0";
            await this.UpdateBalanceInvoice(
              this.props.EditRowSend.invoiceno,
              Totalreceivedamount,
              status,
              duebalance
            );
            await this.writeCurrecntTransaction(
              receivedamount,
              this.props.EditRowSend.invoiceno,
              status
            );
          } else {
            if (receivedamount !== 0) {
              let received_amountvalue =
                receivedamount + parseInt(this.state.amount_received);
              let status = "Partially Paid";
              let duebalance =
                parseInt(this.props.EditRowSend.invoice_amount) -
                received_amountvalue;
              await this.UpdateBalanceInvoice(
                this.props.EditRowSend.invoiceno,
                received_amountvalue,
                status,
                duebalance
              );
              await this.writeCurrecntTransaction(
                receivedamount,
                this.props.EditRowSend.invoiceno,
                status
              );
            }
          }
        } else {
          this.OnSubmitInvoicedata();
        }
      } else {
        this.setState({
          newamountborder: "1px solid red",
        });
      }
    } else {
      this.setState({
        newamountborder: "1px solid red",
      });
    }
  };

  GeneralReceiveTransaction = async (receive) => {
    let date = moment(new Date()).format("YYYY-MM-DD");
    const currentTime = moment(new Date())
      .utcOffset("+05:30")
      .format("hh:mm:ss A");
    let datetime_field = getDateTimeField();
    try {
      await axios({
        method: "post",
        url: `${authApi}transactions/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          transactiontaskid: "",
          date: date,
          time: currentTime,
          clientidnum: this.state.client,
          transaction_clientid: this.state.client,
          userid_id: this.state.userid,
          amount: receive,
          deliverynoteid: this.props.invoicepropsdata.deliverynoteid,
          originaldeliverynoteid: 0,
          originalcompanyid: 0,
          description: "",
          transactiontype: "received",
          companyid: this.state.companyid,
          companyidnum: this.state.companyid,
          pmtmode: this.state.paymentmode,
          pmtreference: this.state.referenceno,
          voucherid: "",
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      });
    } catch (err) {
      this.warningAlert("General received transaction error");
    }
  };

  UpdateBalanceInvoice = async (
    invoiceid,
    receivedamount,
    status,
    duebalance
  ) => {
    await axios({
      method: "put",
      url: `${authApi}sp_updatecurrentinvoice/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        amount_received: receivedamount,
        invoicestatus: status,
        balancedue: duebalance,
        invoiceidno: invoiceid,
        clientname: this.props.EditRowSend.clientname,
        client_id: this.props.EditRowSend.client,
        deliverynote_id: this.props.EditRowSend.companyinvoice,
        company_id: this.state.companyid,
        user_id: this.state.userid,
        received_amount: this.state.newamount,
        project_id: this.props.EditRowSend.project_id,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  writeCurrecntTransaction = async (amount, id, status) => {
    const today = new Date();
    const dateformat = moment(today).format("YYYY-MM-DD");
    const currentTime = moment(new Date())
      .utcOffset("+05:30")
      .format("hh:mm:ss A");
    let datetime_field = getDateTimeField();
    try {
      await axios({
        method: "post",
        url: `${authApi}transactions/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          transactiontaskid: "",
          date: dateformat,
          time: currentTime,
          clientidnum: this.props.EditRowSend.client,
          transaction_clientid: this.props.EditRowSend.client,
          userid_id: this.state.userid,
          amount: amount,
          deliverynoteid: this.props.invoicepropsdata.deliverynoteid,
          originaldeliverynoteid: 0,
          originalcompanyid: 0,
          description: "",
          transactiontype: "spend",
          companyid: this.state.companyid,
          companyidnum: this.state.companyid,
          pmtmode: "",
          pmtreference: "",
          voucherid: "",
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      });

      this.successAlert("Successfully write current transaction ");
    } catch (err) {
      this.warningAlert("Current transaction error");
    }
  };

  OnSubmitInvoicedata = async () => {
    let receivedamount = parseInt(this.state.newamount) || 0,
      balancedue = parseInt(this.state.balancedue) || 0,
      existingreceived = parseInt(this.state.amount_received) || 0;
    let totalinvocie = parseInt(this.state.invoice_amount) || 0;
    invoicebalance = parseInt(this.state.TransactionInvoiceBalance);
    TransactionClient_balance = parseInt(this.state.TransactionClientBalance);
    if (receivedamount !== 0) {
      await this.StoreReceiveTransaction(receivedamount);
      if (this.state.paycurrentinvoiceCheckbox === false) {
        this.updatetransactionunpaid();
      } else {
        await this.storeupdatereceivebalance(
          this.props.EditRowSend.invoiceno,
          receivedamount
        );
        let status = balancedue > receivedamount ? "Partially Paid" : "Paid";
        let Totalreceivedamount = existingreceived + receivedamount;
        let UpdateBalancedue = totalinvocie - Totalreceivedamount;
        await this.StoreSummarydata(
          Totalreceivedamount,
          UpdateBalancedue,
          status
        );
      }
      this.successAlert("Successfully Received");
    }
  };

  StoreSummarydata = async (Totalreceivedamount, UpdateBalancedue, Status) => {
    await axios({
      method: "put",
      url: `${authApi}sp_updatecurrentinvoice/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        amount_received: Totalreceivedamount,
        invoicestatus: Status,
        balancedue: UpdateBalancedue,
        invoiceidno: this.state.Invoiceno,
        clientname: this.props.EditRowSend.clientname,
        client_id: this.props.EditRowSend.client,
        deliverynote_id: this.props.EditRowSend.companyinvoice,
        company_id: this.state.companyid,
        user_id: this.state.userid,
        received_amount: this.state.newamount,
        project_id: this.props.EditRowSend.project_id,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  storeupdatereceivebalance = async (invoiceid, receivedamount) => {
    let date = moment(new Date()).format("YYYY-MM-DD");
    const currentTime = moment(new Date())
      .utcOffset("+05:30")
      .format("hh:mm:ss A");
    let datetime_field = getDateTimeField();
    try {
      const resp = await axios({
        method: "post",
        url: `${authApi}transactions/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          transactiontaskid: "",
          date: date,
          time: currentTime,
          clientidnum: this.state.client,
          transaction_clientid: this.state.client,
          userid_id: this.state.userid,
          amount: receivedamount,
          deliverynoteid: this.props.invoicepropsdata.deliverynoteid,
          originaldeliverynoteid: 0,
          originalcompanyid: 0,
          description: "",
          transactiontype: "spend",
          companyid: this.state.companyid,
          companyidnum: this.state.companyid,
          pmtmode: "",
          pmtreference: "",
          voucherid: "",
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      });
      console.log(resp);
    } catch (err) {
      this.warningAlert("Update spend transaction error");
    }
  };

  updatetransactionunpaid = () => {
    axios({
      method: "post",
      url: `${authApi}sp_payforunpaidinvoice/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        client_id: this.state.client,
        company_id_value: this.state.companyid,
        startdate: taxyearstartdate,
        enddate: taxyearenddate,
        userid: this.state.userid,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          this.successAlert("Successfully Transaction Updated");
        }
      })
      .catch((err) => {
        this.warningAlert("Update transaction unpaid error");
      });
  };

  StoreReceiveTransaction = async (receive) => {
    let date = moment(new Date()).format("YYYY-MM-DD");
    const currentTime = moment(new Date())
      .utcOffset("+05:30")
      .format("hh:mm:ss A");
    let datetime_field = getDateTimeField();
    try {
      const resp = await axios({
        method: "post",
        url: `${authApi}transactions/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          transactiontaskid: "",
          date: date,
          time: currentTime,
          clientidnum: this.state.client,
          transaction_clientid: this.state.client,
          userid_id: this.state.userid,
          amount: receive,
          deliverynoteid: this.props.invoicepropsdata.deliverynoteid,
          originaldeliverynoteid: 0,
          originalcompanyid: 0,
          description: "",
          transactiontype: "received",
          companyid: this.state.companyid,
          companyidnum: this.state.companyid,
          pmtmode: this.state.paymentmode,
          pmtreference: this.state.referenceno,
          voucherid: "",
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      });
      console.log(resp);
    } catch (err) {
      this.warningAlert("Received transaction error");
    }
  };

  payCurrentTaskCheckBox = () => {
    if (this.state.paycurrentinvoiceCheckbox === true) {
      this.setState({
        paycurrentinvoiceCheckbox: true,
      });
    }
  };

  successAlert = (message) => {
    const getAlert = () => (
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success!"
        onConfirm={() => this.hideAlert()}
        confirmBtnBsStyle="success"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );

    this.setState({
      alert: getAlert(),
    });
  };

  warningAlert = (message) => {
    const getwarningAlert = () => (
      <ReactBSAlert
        warning
        style={{ display: "block", marginTop: "-100px" }}
        title="Warning"
        onConfirm={() => this.hideAlert()}
        confirmBtnBsStyle="warning"
        btnSize=""
      >
        {message}
      </ReactBSAlert>
    );

    this.setState({
      alert: getwarningAlert(),
    });
  };

  hideAlert() {
    this.setState({
      alert: false,
    });
    this.props.closebtn();
    this.props.callbackfunction();
  }

  clear = (event) => {
    event.target.value = "";
  };

  render() {
    let develiverynoteid =
      this.props.invoicepropsdata.vehiclenumber !== null
        ? this.props.invoicepropsdata.companycode +
          1 +
          "-" +
          this.props.invoicepropsdata.deliverynoteid
        : this.props.invoicepropsdata.companycode +
          "-" +
          this.props.invoicepropsdata.deliverynoteid;
    let develiverynoteiddata =
      this.props.invoicepropsdata.project_id !== null
        ? this.props.invoicepropsdata.companycode +
          2 +
          "-" +
          this.props.invoicepropsdata.deliverynoteid
        : develiverynoteid;

    return (
      <React.Fragment>
        {this.state.alert}
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Edit Invoice Details</div>
        <div className="Scroll-Content">
          <Row>
            <Col md="4">
              <label className="form-control-label">Invoice</label>
              <Input
                type="text"
                list="id_value-list"
                className="form-control-alternative"
                value={develiverynoteiddata}
              />
            </Col>
            <Col md="4">
              <label className="form-control-label">Client Name :</label>
              <Input
                type="text"
                className="form-control-alternative"
                value={this.state.clientname}
              />
            </Col>
            <Col md="4">
              <label className="form-control-label">Invoice Amount :</label>
              <Input
                className="form-control-alternative"
                type="text"
                value={this.state.invoice_amount}
              />
            </Col>
          </Row>
          <Row>
            <Col md="2">
              <label className="form-control-label">Received</label>
              <Input
                type="text"
                className="form-control-alternative"
                value={this.state.amount_received}
              />
            </Col>
            <Col md="2">
              <label className="form-control-label">Balance</label>
              <Input
                type="text"
                className="form-control-alternative"
                value={this.state.balancedue}
              />
            </Col>
            <Col md="4">
              <label className="form-control-label">Companyname</label>
              <Input
                type="text"
                className="form-control-alternative"
                value={this.state.CompanyName}
              />
            </Col>
            <Col md="4">
              <label className="form-control-label">Invoice Status</label>
              <Input
                type="text"
                className="form-control-alternative"
                value={this.state.invoicestatus}
              />
            </Col>
          </Row>
          <Row>
            <Col md="4">
              <label className="form-control-label">Payment Mode:</label>
              <Input
                type="select"
                list="company_value-list"
                className="form-control-alternative"
                Value={this.state.paymentmode}
                onChange={this.HandleChangepaymentmode}
              >
                {this.state.bank
                  .sort((a, b) => (a.bankname > b.bankname ? 1 : -1))
                  .map((item, key) => (
                    <option key={key} value={item.bankname}>
                      {item.bankname}
                    </option>
                  ))}
              </Input>
            </Col>
            <Col md="4">
              <label className="form-control-label">Amount</label>
              <Input
                type="number"
                className="form-control-alternative"
                value={this.state.newamount}
                syle={{ border: this.state.newamountborder }}
                onChange={this.newamountvalue}
              />
              <div style={{ fontSize: 16, color: "red" }}>
                {this.state.Amountcheck}
              </div>
              {this.state.newamountborder !== "none" ? (
                <h6 style={{ color: "red" }}>Please Enter Amount</h6>
              ) : null}
            </Col>
            <Col md="4">
              <label className="form-control-label">Reference No</label>
              <Input
                type="text"
                list="id_value-lists"
                className="form-control-alternative"
                value={this.state.referenceno}
                onChange={this.HandleChangereferenceno}
              />
            </Col>
          </Row>
          <Row>
            <Col md="3">
              {this.props.EditRowSend.clientname === "General" ||
              this.props.EditRowSend.clientname ===
                "General-Printing" ? null : (
                <>
                  <label className="form-control-label">Client Balance</label>
                  <Input
                    type="text"
                    className="form-control-alternative"
                    value={this.state.TransactionClientBalance}
                  />
                </>
              )}
            </Col>
            <Col md="3">
              {this.props.EditRowSend.clientname === "General" ||
              this.props.EditRowSend.clientname ===
                "General-Printing" ? null : (
                <>
                  <label className="form-control-label">Invoice Balance</label>
                  <Input
                    type="text"
                    className="form-control-alternative"
                    value={this.state.TransactionInvoiceBalance}
                  />
                </>
              )}
            </Col>
            <Col md="2">
              {this.props.EditRowSend.clientname === "General" ||
              this.props.EditRowSend.clientname === "General-Printing" ||
              this.state.paycurrentinvoiceCheckbox === true ? null : (
                <label className="form-control-label" style={{ marginTop: 30 }}>
                  <label>Pay For CurrentInvoice</label>
                </label>
              )}
            </Col>
            <Col md="2">
              {this.props.EditRowSend.clientname === "General" ||
              this.props.EditRowSend.clientname === "General-Printing" ||
              this.state.paycurrentinvoiceCheckbox === true ? null : (
                <label className="form-control-label" style={{ marginTop: 40 }}>
                  <input
                    type="checkbox"
                    style={{ width: "20px", height: "20px" }}
                    checked={this.state.paycurrentinvoiceCheckbox}
                    onChange={this.payCurrentTaskCheckBox}
                  />
                </label>
              )}
            </Col>
            <Col md="2" style={{ textAlign: "center" }}>
              <label className="form-control-label">
                &nbsp;
                <button
                  id="buttonsave"
                  className="btn btn-dark"
                  onClick={this.Formvalidation}
                  disabled={this.state.isButtonDisabled}
                >
                  Save
                </button>
              </label>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(InvoiceEditRowpopup);
