import React from "react";
import { Page, Document, StyleSheet, Text, View } from "@react-pdf/renderer";
import InvoiceTableFooter from "./InvoiceTableFooter";
import InvoiceTable from "./InvoiceTable";
import InvoiceTitle from "./InvoiceTitle";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 40,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 50,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 16,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },
  titleContainer: {
    marginTop: 0,
    width: 300,
    height: 66,
    marginLeft: "auto",
    marginRight: -25,
    marginBottom: 5,
  },

  reportTitle: {
    marginTop: -20,
    marginRight: -5,
    color: "#11cdef",
    letterSpacing: 1,
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase",
  },

  titlelocation: {
    marginTop: -60,
    width: 140,
    height: 66,
    marginLeft: "auto",
    marginRight: 15,
    marginBottom: 5,
    fontSize: 12,
    textAlign: "right",
  },
  titlelocation1: {
    marginTop: -55,
    width: 140,
    height: 66,
    marginLeft: "auto",
    marginRight: 15,
    marginBottom: 5,
    fontSize: 12,
    textAlign: "right",
  },
});

const Invoice = ({ GenerateData, group, sD, eD }) => {
  const d = GenerateData.filter((item) => {
    return item.invoicestatus !== "Cancelled";
  });
  const ascendingReport = d.sort((a, b) => {
    return a.id - b.id;
  });

  return (
    <Document>
      <Page size="A4" style={styles.page}>


        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}> PG Cadd Printers </Text>
        </View>

        <Text style={styles.titlelocation}>334, Mettur road,</Text>
        <Text style={styles.titlelocation1}>Erode - 638001.</Text>

        <InvoiceTitle stDa={sD} edDa={eD} />

        <InvoiceTable groupedData={group} />

        <InvoiceTableFooter itemsTotal={ascendingReport} />

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} / ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default Invoice;
