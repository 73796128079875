import config from "react-global-configuration";
import "./config";

const process_Env = config.get("NODE_ENV");

const Debug = true;
export default function consoleFunction(message, errorInfo) {
  if (process_Env !== "Production") {
  }
  if (Debug === true) {
    let msg = `${message} \nInfo: \n${errorInfo} `;
    alert(msg);
  } else {
    alert(message);
  }
}
