import React, { useEffect, useState, useMemo } from "react";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import TableContainerExpand from "../../Filters/TableContainerExpand";
import { SelectColumnFilter } from "../../Filters/filters";
import "reactjs-popup/dist/index.css";
import { connect } from "react-redux";
import config from "react-global-configuration";
import Popup from "reactjs-popup";
import LowStockPurchaseRequest from "./LowStockPurchaseRequest";
import "../../../../config";
import { Row, Container, NavLink, Nav, NavItem } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { CurrentLocation } from "../../../../components/loginRedux/loginReducers";	
import store from "../../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");
let group_data = "";
let currentActiveTab = "1";

function Lowstock(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);

  useEffect(
    () => {
      const doFetch = async () => {
        store.dispatch({ type: CurrentLocation, data: props.location.pathname })
        const response = await fetch(`${authApi}inventorytransactionfilter/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        });
        const myTaskData = await response.json();
        if (myTaskData.length === 0) {
          alert("There is no Lowstocks.!");
          setLoading(false);
        } else {
          const details = myTaskData.sort((a, b) => a.id - b.id);
          group_data = multipleGroupByArray(
            details,
            (property) => property.stockname
          );
          getLowstockDetails(group_data);
          filterlowstockdata(group_data);
          setData(group_data);
          setLoading(false);
          console.log(setChecked(true));
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function multipleGroupByArray(dataArray, groupPropertyArray) {
    const groups = {};
    dataArray.forEach((item) => {
      const group = JSON.stringify(groupPropertyArray(item));
      groups[group] = groups[group] || [];
      groups[group].push(item);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    });
  }

  function getLowstockDetails(body) {
    body.forEach((e) => {
      e.quantity = calculateStockQuantity(e);
    });
    body.forEach((e) => {
      e.stockname = setStockname(e);
    });
    body.forEach((e) => {
      e.productcode = setProductcode(e);
    });
  }

  function calculateStockQuantity(e) {
    let total = 0;
    total = e.reduce((i, i_bal) => i + parseInt(i_bal.quantity, 0), 0);
    return total;
  }

  function setStockname(e) {
    let sn = "";
    sn = e[0].stockname;
    return sn;
  }

  function setProductcode(e) {
    let pc = "";
    pc = e[0].productcode;
    return pc;
  }

  function filterlowstockdata(e) {
    group_data = e.filter(function (d) {
      return parseInt(d.quantity) <= 50;
    });
    return group_data;
  }

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: (row) => {
          const row_data = row.row.original;
          return (
            <React.Fragment>
              <Popup
                modal
                position="center"
                trigger={
                  <button
                    className="btn btn-primary btn-sm text-center"
                    style={{ marginLeft: 100 }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                }
              >
                {(close) => (
                  <LowStockPurchaseRequest
                    closebtn={close}
                    editabledata={row_data}
                  />
                )}
              </Popup>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Stockname",
        accessor: "stockname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Productcode",
        accessor: "productcode",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Low Stocks",
        accessor: "quantity",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div style={{ marginTop: 0 }}>
        <Nav tabs style={{ padding: "25px 20px 0", fontSize: 20, backgroundColor: '#11cdef' }}>
          <NavItem>
            <NavLink style={{ color: 'white' }}
              className={classnames({
                active: currentActiveTab === "2",
              })}
              to="/admin/management/inventory"
              tag={Link}
            >
              Inventory
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ color: 'white' }}
              className={classnames({
                active: currentActiveTab === "4",
              })}
              to="/admin/inventory/inventorydetails"
              tag={Link}
            >
              Inventory Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ color: 'white' }}
              className={classnames({
                active: currentActiveTab === "3",
              })}
              to="/admin/inventory/inventorymonthly"
              tag={Link}
            >
              Monthly Data
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "1",
              })}
              to="/admin/inventory/lowstock"
              tag={Link}
            >
              Low Stock
            </NavLink>
          </NavItem>
        </Nav>
        <Container className="mt--6" fluid>
          <br />
          <Row>
            <div
              className="addRow"
              style={{ position: "absolute", right: "65px" }}
            >
              <NavLink to="/admin/inventory/conversiontable" tag={Link}>
                <button className="btn btn-primary btn-sm"
                    style={{ width: "135%", height: 40 }} >
                  <i className="ni ni-single-02"></i>
                </button>
              </NavLink>
            </div>
          </Row>
          <br />
          <br />
          <Row style={{ marginTop: 5 }}>
            {loading === true ? (
              <div>
                <ConPreLoader />
              </div>
            ) : (
              <div className=" col">
                <TableContainerExpand
                  columns={columns}
                  data={data}
                  checkedToggle={checked}
                />
              </div>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(Lowstock);
