import classnames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  Collapse,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
  Navbar,
  NavItem,
  Nav,
  Container,
  Form,FormGroup
} from "reactstrap";
import * as firebase from 'firebase';
import {useSelector} from "react-redux";

function AdminNavbar({ propss, theme, sidenavOpen, toggleSidenav, brandText}) {
  const username = useSelector(state => state.username);
  const profilepic = useSelector(state => state.profilepic);

  const handleLogout = () => {
    firebase
      .auth()
      .signOut()
      .then((user) => {
        propss.history.push('/auth/login');
      });
  };

  return (
    <>
      <Navbar
        className={classnames(
          "navbar-top navbar-expand border-bottom",
          { "navbar-dark bg-info": theme === "dark" },
          { "navbar-light bg-secondary": theme === "light" }
        )}
      >
        <Container fluid>
          <Collapse navbar isOpen={true}>
            <Form
              className={classnames(
                "navbar-search form-inline mr-sm-3",
                { "navbar-search-light": theme === "dark" },
                { "navbar-search-dark": theme === "light" }
              )}
            >
              <FormGroup className="mb-0">
                  <h1 className="h4 mb-0 text-white d-none d-lg-inline-block">
                    {brandText}
                  </h1>
              </FormGroup>
            </Form>

            <Nav className="align-items-center ml-md-auto" navbar>
              <NavItem className="d-xl-none">
                <div
                  className={classnames(
                    "pr-3 sidenav-toggler",
                    { active: sidenavOpen },
                    { "sidenav-toggler-dark": theme === "dark" }
                  )}
                  onClick={toggleSidenav}
                >
                  <div className="sidenav-toggler-inner">
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                    <i className="sidenav-toggler-line" />
                  </div>
                </div>
              </NavItem>
            </Nav>
            <Nav className="align-items-center ml-auto ml-md-0" navbar>
              <UncontrolledDropdown nav>
                <DropdownToggle className="nav-link pr-0" color="" tag="a">
                  <Media className="align-items-center">
                    <span className="avatar avatar-sm rounded-circle">
                      <img
                        alt=""
                        src={profilepic}
                      />
                    </span>
                    <Media className="ml-2 d-none d-lg-block">
                      <span className="mb-0 text-sm font-weight-bold">
                        {username}
                      </span>
                    </Media>
                  </Media>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem className="noti-title" header tag="div">
                    <h6 className="text-overflow m-0">Welcome..!</h6>
                  </DropdownItem>
                  <DropdownItem
                    to="/admin/user-profile" tag={Link}
                  >
                    <i className="ni ni-single-02" />
                    <span>My profile</span>
                  </DropdownItem>
                  <DropdownItem
                    to="/admin/myday" tag={Link}
                  >
                    <i className="ni ni-calendar-grid-58" />
                    <span>My Day</span>
                  </DropdownItem>
                  <DropdownItem
                    to="/admin/teamtask" tag={Link}
                  >
                    <i className="ni ni-user-run" />
                    <span>Team Tasks</span>
                  </DropdownItem>
                  <DropdownItem divider />
                  <DropdownItem
                    onClick={handleLogout}
                  >
                    <i className="ni ni-button-power" />
                    <span>Logout</span>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

AdminNavbar.defaultProps = {
  toggleSidenav: () => {},
  sidenavOpen: false,
  theme: "dark",
};
AdminNavbar.propTypes = {
  toggleSidenav: PropTypes.func,
  sidenavOpen: PropTypes.bool,
  theme: PropTypes.oneOf(["dark", "light"]),
};

export default AdminNavbar;