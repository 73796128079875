import React, { Component } from "react";
import {Row, Card, Container, NavLink} from "reactstrap";
import { Link } from 'react-router-dom';
import Popup from "reactjs-popup";
import "config"
import { connect } from "react-redux";
import DispatchBillapp from "./DispatchPrint/DispatchBillapp";
import ViewIndividualdispatch from "./ViewIndividualDispatch";
import ConPreLoader from "components/Loader/Con_PreLoader";
import Header from "components/Headers/SimpleHeader";

class IndividualDispatchPreview extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonObject:"",
      loading : true
    };
  }
  
  componentDidMount() {
    if(this.props.location.state !== undefined){
      this.setState({
        jsonObject: this.props.location.state.dispatchData,
        loading: false
      })
    }
    else{
      alert("Please select your dispatch bill")
      this.setState({
        loading: false,
        jsonObject: ""
      })
    }
  }

  render() {
    if(this.state.loading)
    {
      return <ConPreLoader />
    }
    else
    {
      return (
        <React.Fragment>
          <Header />
           <Container className="mt--7" fluid>
           { this.state.jsonObject === "" ? null :
            <div className="invoicegenerate">
              <Popup
                modal
                data-backdrop="static"
                trigger =
                {
                  <button className="btn btn-dark btn-sm" style = {{ width:'135%', height:40, marginTop: 31, right: 15 }}>
                    Print
                  </button>
                }
              >
                {
                  (close) => (
                    <DispatchBillapp
                      data = {this.state.jsonObject}
                      closebtn={close}
                    />
                )}
              </Popup>
            </div>
          }
          <div className="invoicegenerate">
              <NavLink
                  to={{
                    pathname:"/admin/management/dispatch",  
                  }}
                  tag={Link}
                >
                  <button className = "btn btn-primary btn-sm" style = {{ width:'135%', height:40, marginTop: 27 }} >
                    Back 
                  </button>
              </NavLink>
          </div>
          <br /><br /><br />
          <Row className="Viewpage">
            <div className="col">
              <div className="Scrolls-Contents" style={{ height: '100%', backgroundColor: 'transparent'}}>
                <Card>
                  { this.state.jsonObject === "" ? null :
                    <ViewIndividualdispatch 
                      dispatchViewdata = {this.state.jsonObject}
                    />
                  }
                </Card>
              </div>
            </div>
          </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    token:state.token, 
  }
}
export default connect(mapStateToProps, null)(IndividualDispatchPreview);