import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import BalanceDetails from "./BalanceDetails";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  rows: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold",
  },
  clients: {
    width: "40%",
    textAlign: "left",
    paddingRight: 4,
    paddingLeft: 2,
    paddingTop: 4,
  },
  details: {
    width: "60%",
    textAlign: "left",
    paddingRight: 8,
  },
});

const InvoiceDetailsRow = ({ grp_invoice }) => {
  const invoice = grp_invoice.map((i) => (
    <React.Fragment>
      <View style={styles.rows} wrap={false}>
        {i[0].client === " " || i[0].client === undefined ? (
          <Text style={styles.clients}> </Text>
        ) : (
          <Text style={styles.clients}> {i[0].client}</Text>
        )}
        <View style={styles.details}>
          <View>
            <BalanceDetails grp_invoiceBal={i} />
          </View>
        </View>
      </View>
    </React.Fragment>
  ));

  return <React.Fragment>{invoice}</React.Fragment>;
};

export default InvoiceDetailsRow;
