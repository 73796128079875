import React from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Table,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
import PayVoucherRow from "./AddPayVoucherRow";
import config from "react-global-configuration";
import "../../../../config";
import ReactBSAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import Header from "components/Headers/SimpleHeader";
import getDateTimeField from "../../../../Utils";

const authApi = config.get("apiDomain");
let pay_voucherStringData = "";

class AddPayVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pay_voucherId: 1,
      pay_voucherType: "Payment",
      pay_voucherDate: moment(new Date()).format("YYYY-MM-DD"),
      pay_voucherClientId: "",
      pay_voucherClientName: "",
      pay_voucherCompanyId: "",
      pay_voucherCompanyName: "",
      pay_voucherLedgerType: "",
      pay_voucherPaymentMode: "",
      pay_voucherReceivedAmount: 0,
      pay_voucherAmount: 0,
      pay_voucherRowList: [],
      companyDetails: [],
      clientDetails: this.props.clientdetails,
      bankDetails: [],
      selectedCompanyBanks: [],
      purchaseBillDetails: [],
      purchaseOrderDetails: [],
      ledgerTypeDetails: [],
      alert: false,
      currentUser: "",
      currentUserId: "",
    };
  }
  componentDidMount() {
    this.getCompanyDetails();
    this.getBankDetails();
    this.getCurrentUser();
    this.getLedgertypeDetails();
  }

  getCurrentUser = () => {
    this.setState(
      {
        currentUser: this.props.username,
      },
      function callback() {
        this.getUserid();
      }
    );
  };

  getUserid = () => {
    fetch(`${authApi}userfilter/?name=${this.state.currentUser}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          currentUserId: json[0].id,
        });
      });
  };

  getCompanyDetails = () => {
    fetch(`${authApi}companyfilter/?isactive=1`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          companyDetails: json,
        });
      });
  };

  getLedgertypeDetails = () => {
    fetch(`${authApi}ledgertypefilter/?isvisible=true`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          ledgerTypeDetails: json,
        });
      });
  };

  getBankDetails = () => {
    fetch(`${authApi}bankfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          bankDetails: json,
        });
      });
  };

  handleVoucherDate = (event) => {
    this.setState({
      pay_voucherDate: event.target.value,
    });
  };

  handleVoucherClientname = (event) => {
    this.state.clientDetails.forEach((d) => {
      if (d.company_name === event.target.value) {
        this.setState({
          pay_voucherClientId: d.id,
          pay_voucherClientName: d.company_name,
          pay_voucherLedgerType: "",
          pay_voucherAmount: 0,
          pay_voucherRowList: [],
        });
      } else {
        this.setState({
          pay_voucherClientName: event.target.value,
          pay_voucherLedgerType: "",
          pay_voucherAmount: 0,
          pay_voucherRowList: [],
        });
      }
    });
  };

  handleVoucherCompany = (event) => {
    fetch(
      `${authApi}companyfilter/?isactive=1&companyname=${event.target.value}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState(
          {
            pay_voucherCompanyName: json[0].companyname,
            pay_voucherCompanyId: json[0].id,
            pay_voucherLedgerType: "",
            pay_voucherAmount: 0,
            pay_voucherRowList: [],
            pay_voucherReceivedAmount: 0,
          },
          function () {
            this.updateVoucherBankDetails(json[0].id);
          }
        );
      });
  };

  updateVoucherBankDetails = (id) => {
    fetch(`${authApi}bankfilter/?companydetails=${id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          selectedCompanyBanks: json,
        });
      });
  };

  handleVoucherLedgerType = (event) => {
    if (
      this.state.pay_voucherLedgerType === "" ||
      (this.state.pay_voucherLedgerType !== event.target.value &&
        this.state.pay_voucherRowList.length === 0)
    ) {
      this.setState(
        {
          pay_voucherLedgerType: event.target.value,
          pay_voucherRowList: [],
          pay_voucherAmount: 0,
        },
        function callback() {
          this.getPaymentDetails(event.target.value);
        }
      );
    } else if (
      this.state.pay_voucherLedgerType !== event.target.value &&
      this.state.pay_voucherRowList.length !== 0
    ) {
      this.checkVoucherLedgerType(event.target.value);
    }
  };

  handleVoucherReceivedAmount = (event) => {
    this.setState({
      pay_voucherReceivedAmount: event.target.value,
    });
  };

  getPaymentDetails = (e) => {
    if (this.state.pay_voucherClientId !== "") {
      if (e === "PurchaseBills" || e === "PurchaseAdvance") {
        fetch(
          `${authApi}purchaseorderfilter/?supplier=${this.state.pay_voucherClientId}&status=Issued&companyid=${this.state.pay_voucherCompanyId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${this.props.token}`,
            },
          }
        )
          .then((res) => res.json())
          .then((json) => {
            const purchaseOrders = json;
            this.setState({
              purchaseOrderDetails: purchaseOrders,
            });
          });
        fetch(
          `${authApi}purchaseinvoicebillfilter/?supplier=${this.state.pay_voucherClientId}&company=${this.state.pay_voucherCompanyId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${this.props.token}`,
            },
          }
        )
          .then((res) => res.json())
          .then((json) => {
            const purchaseBills = json.filter(function (pb) {
              return pb.status !== "Paid";
            });
            this.setState({
              purchaseBillDetails: purchaseBills,
            });
          });
      }
    }
  };

  handleVoucherPaymentMode = (event) => {
    this.setState({
      pay_voucherPaymentMode: event.target.value,
    });
  };

  handlePayVoucherAddRow = (e) => {
    e.preventDefault();
    const payVocList = {
      id: this.state.pay_voucherId + 1,
      purchase_Id: "",
      paysubType: "",
      purchase_Total: "",
      payamtReceived: "",
      paysubReferType: "",
      purchase_Balance: "",
    };
    this.setState({
      pay_voucherId: payVocList.id,
      pay_voucherRowList: [...this.state.pay_voucherRowList, payVocList],
    });
  };

  handlePayVoucherRemoveRow = (i) => {
    const DelData = this.state.pay_voucherRowList.filter(
      (item) => item.id !== i.id
    );
    this.setState({
      pay_voucherRowList: [...DelData],
    });
  };

  updationPayVoucherlist = (x) => {
    this.setState(
      (prevState) => {
        const updatedPvData = prevState.pay_voucherRowList.map((pv) => {
          if (pv.id === x.id) {
            pv.id = x.id;
            pv.purchase_Id = x.pay_purchaseId;
            pv.paysubType = x.pay_subType;
            pv.purchase_Total = x.pay_purchaseTotal;
            pv.payamtReceived = x.pay_amtReceived;
            pv.paysubReferType = x.pay_subReferType;
            pv.purchase_Balance = x.pay_purchaseBalance;
          }
          return pv;
        });
        return {
          pay_voucherRowList: updatedPvData,
        };
      },
      function callback() {
        const totalReceived = this.state.pay_voucherRowList
          .filter((item) => item.payamtReceived !== "")
          .reduce((i, i_rec) => i + parseFloat(i_rec.payamtReceived, 0), 0);
        this.setState({
          pay_voucherAmount: totalReceived,
        });
      }
    );
  };

  handleEnter = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    } else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  checkVoucherLedgerType = (ev) => {
    const getwarningAlert = () => (
      <ReactBSAlert
        warning
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        confirmBtnText="Yes"
        confirmBtnBsStyle="success"
        title="Delete payment details ?"
        cancelBtnText="No"
        cancelBtnBsStyle="warning"
        onConfirm={() => this.changedLedgerType(ev)}
        onCancel={() => this.setState({ alert: false })}
      >
        Are you sure you want to delete all your payment detail changes. This
        will erase all your entries
      </ReactBSAlert>
    );

    this.setState({
      alert: getwarningAlert(),
    });
  };

  backtoPaymentVoucherPage = () => {
    const getwarningAlert = () => (
      <ReactBSAlert
        warning
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        confirmBtnText="Yes"
        confirmBtnBsStyle="success"
        title="Delete payment details ?"
        cancelBtnText="No"
        cancelBtnBsStyle="warning"
        onConfirm={() => this.goBackPage()}
        onCancel={() => this.setState({ alert: false })}
      >
        Are you sure you want to delete all your payment detail changes. This
        will erase all your entries
      </ReactBSAlert>
    );

    this.setState({
      alert: getwarningAlert(),
    });
  };

  changedLedgerType(ev) {
    this.setState({
      alert: false,
      pay_voucherLedgerType: ev,
      pay_voucherRowList: [],
      pay_voucherAmount: 0,
    });
    this.getPaymentDetails(ev);
  }

  goBackPage() {
    this.setState({
      alert: false,
    });
    this.props.history.push("/admin/voucher/paymentvoucher");
  }

  onSubmitVoucher = async () => {
    if (
      this.state.pay_voucherClientId !== "" &&
      this.state.pay_voucherClientName !== "" &&
      this.state.pay_voucherCompanyName !== "" &&
      this.state.pay_voucherPaymentMode !== "" &&
      this.state.pay_voucherLedgerType !== ""
    ) {
      if (this.state.pay_voucherRowList.length !== 0) {
        if (
          parseInt(this.state.pay_voucherAmount) ===
          parseInt(this.state.pay_voucherReceivedAmount)
        ) {
          if (
            this.state.pay_voucherLedgerType === "PurchaseAdvance" ||
            this.state.pay_voucherLedgerType === "PurchaseBills"
          ) {
            const voucherRows = this.state.pay_voucherRowList.filter(
              (item) => item.purchase_Id !== "" && item.payamtReceived !== ""
            );
            if (parseInt(voucherRows.length) !== 0) {
              if (this.state.pay_voucherLedgerType === "PurchaseAdvance") {
                const unique = new Set();
                const showError = voucherRows.some(
                  (element) =>
                    unique.size === unique.add(element.purchase_Id).size
                );
                if (!showError) {
                  await this.makeVoucherStringData(voucherRows);
                  await this.AddPaymentVoucher(pay_voucherStringData);
                } else {
                  alert("Please remove duplicate entries");
                }
              } else {
                const unique = new Set();
                const showError = voucherRows.some(
                  (element) =>
                    unique.size === unique.add(element.purchase_Id).size
                );
                if (!showError) {
                  await this.makeVoucherStringData(voucherRows);
                  await this.AddPaymentVoucher(pay_voucherStringData);
                } else {
                  alert("Please remove duplicate entries");
                }
              }
            } else {
              alert("Please enter valid voucher record");
            }
          } else {
            const voucherRows = this.state.pay_voucherRowList.filter(
              (item) =>
                item.paysubType !== "" &&
                item.paysubReferType !== "" &&
                item.payamtReceived !== ""
            );
            if (parseInt(voucherRows.length) !== 0) {
              await this.makeVoucherStringData(voucherRows);
              await this.AddPaymentVoucher(pay_voucherStringData);
            } else {
              alert("Please enter valid voucher record");
            }
          }
        } else {
          alert("Please enter received amount and total amount equally");
        }
      } else {
        alert("Please add atleast 1 voucher record");
      }
    } else {
      alert("Please enter valid details");
    }
  };

  makeVoucherStringData = (voucData) => {
    pay_voucherStringData = "";
    if (
      this.state.pay_voucherLedgerType === "PurchaseBills" ||
      this.state.pay_voucherLedgerType === "PurchaseAdvance"
    ) {
      voucData.forEach((voucher) => {
        let newvoucher = {
          vouchertype: voucher.purchase_Id,
          voucherreferencetype: this.state.pay_voucherLedgerType,
          amount: voucher.payamtReceived,
        };
        pay_voucherStringData = [...pay_voucherStringData, newvoucher];
      });
    } else {
      voucData.forEach((voucher) => {
        let newvoucher = {
          vouchertype: voucher.paysubType,
          voucherreferencetype: voucher.paysubReferType,
          amount: voucher.payamtReceived,
        };
        pay_voucherStringData = [...pay_voucherStringData, newvoucher];
      });
    }
  };

  AddPaymentVoucher = async (stringData) => {
    if (this.state.pay_voucherReceivedAmount > 0) {
      let datetime_field = getDateTimeField();
      axios({
        method: "post",
        url: `${authApi}vouchermanagement/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: this.state.pay_voucherDate,
          time: moment().format("hh:mm a"),
          username: this.state.currentUser,
          clientname: this.state.pay_voucherClientName,
          vouchertype: this.state.pay_voucherType,
          vouchersubtype: this.state.pay_voucherLedgerType,
          vouchersubreferencetype: "",
          amount: this.state.pay_voucherAmount,
          paymentmode: this.state.pay_voucherPaymentMode,
          companyname: this.state.pay_voucherCompanyName,
          client: this.state.pay_voucherClientId,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            this.storeVoucherDetails(res.data.id, stringData);
          }
        })
        .catch((err) => {
          this.setState({ error: err.message });
        });
    } else {
      alert("Please Check your Payment Amount. Payment Amount Can't be Zero");
    }
  };

  storeVoucherDetails = async (id, data) => {
    axios({
      method: "post",
      url: `${authApi}sp_vouchermanagement/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        vouchermanagement_id: id,
        date: this.state.pay_voucherDate,
        vouchertype: this.state.pay_voucherType,
        detailtype: this.state.pay_voucherLedgerType,
        client_id: this.state.pay_voucherClientId,
        user_id: this.state.currentUserId,
        company_id: this.state.pay_voucherCompanyId,
        paymentmode: this.state.pay_voucherPaymentMode,
        requesteddata: data,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 201) {
          this.restoreDefaultData();
        }
      })
      .catch((err) => {
        console.log(err);
        alert("voucher detais store error", err.response.request.response);
      });
  };

  restoreDefaultData = async () => {
    this.setState({
      pay_voucherId: 1,
      pay_voucherType: "Payment",
      pay_voucherDate: this.state.pay_voucherDate,
      pay_voucherLedgerType: "",
      pay_voucherAmount: 0,
      pay_voucherRowList: [],
      pay_voucherReceivedAmount: 0,
    });
  };

  render() {
    const payVoucherRowList = [...this.state.pay_voucherRowList].map((pv) => (
      <PayVoucherRow
        key={pv.id}
        id={pv.id}
        purchase_Id={pv.purchase_Id}
        paysubType={pv.paysubType}
        purchase_Total={pv.purchase_Total}
        payamtReceived={pv.payamtReceived}
        paysubReferType={pv.paysubReferType}
        purchase_Balance={pv.purchase_Balance}
        voucherLedgerType={this.state.pay_voucherLedgerType}
        purchaseOrderData={this.state.purchaseOrderDetails}
        purchaseBillData={this.state.purchaseBillDetails}
        deleteRow={this.handlePayVoucherRemoveRow}
        updateAddRow={this.updationPayVoucherlist}
      />
    ));

    return (
      <>
        <Header />
        {this.state.alert}
        <Container className="mt--7" fluid>
          <br />
          <Row>
            <div
              className="addRow"
              style={{ position: "absolute", right: "65px", marginTop: 20 }}
            >
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                onClick={this.backtoPaymentVoucherPage}
              >
                Back
              </button>
            </div>
          </Row>
          <br />
          <br />
          <br />
          <Row style={{ marginTop: 10 }}>
            <form style={{ width: "100%" }}>
              <Col xl="12">
                <Card className="bg-secondary shadow">
                  <CardBody>
                    <Row style={{ marginTop: -20 }}>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Company</label>
                          <Input
                            type="select"
                            className="form-control-alternative"
                            style={{ height: "100%" }}
                            defaultValue={this.state.pay_voucherCompanyName}
                            value={this.state.pay_voucherCompanyName}
                            onChange={this.handleVoucherCompany}
                            onKeyDown={this.handleEnter}
                            autoFocus="autofocus"
                          >
                            <option value="">Select Company</option>
                            {this.state.companyDetails.map((item, key) => (
                              <option key={key} value={item.companyname}>
                                {item.companyname}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">
                            ClientName
                          </label>
                          <Input
                            type="option"
                            list="nickname-list"
                            className="form-control-alternative"
                            style={{ height: "100%" }}
                            defaultValue={this.state.pay_voucherClientName}
                            value={this.state.pay_voucherClientName}
                            onChange={this.handleVoucherClientname}
                            placeholder="Select Clientname"
                            onKeyDown={this.handleEnter}
                          />
                          <datalist id="nickname-list">
                            {this.state.clientDetails.map((item, name) => (
                              <option key={name} value={item.company_name} />
                            ))}
                          </datalist>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Date</label>
                          <Input
                            type="date"
                            className="form-control-alternative"
                            style={{ height: 40 }}
                            defaultValue={this.state.pay_voucherDate}
                            value={this.state.pay_voucherDate}
                            onChange={this.handleVoucherDate}
                            onKeyDown={this.handleEnter}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: -20 }}>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">
                            PaymentThrough
                          </label>
                          <Input
                            className="form-control-alternative"
                            style={{ height: "100%" }}
                            placeholder="paymentmode"
                            type="select"
                            defaultValue={this.state.pay_voucherPaymentMode}
                            value={this.state.pay_voucherPaymentMode}
                            onChange={this.handleVoucherPaymentMode}
                            onKeyDown={this.handleEnter}
                          >
                            <option value="">Select Mode</option>
                            {this.state.selectedCompanyBanks.map(
                              (item, key) => (
                                <option key={key} value={item.bankname}>
                                  {item.bankname}
                                </option>
                              )
                            )}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">
                            LedgerType
                          </label>
                          <Input
                            type="select"
                            className="form-control-alternative"
                            style={{ height: "100%" }}
                            defaultValue={this.state.pay_voucherLedgerType}
                            value={this.state.pay_voucherLedgerType}
                            onChange={this.handleVoucherLedgerType}
                            onKeyDown={this.handleEnter}
                          >
                            <option value="">Select Ledgertype</option>
                            {this.state.ledgerTypeDetails
                              .sort(
                                (a, b) => a.ledgertypename - b.ledgertypename
                              )
                              .map((item, key) => (
                                <option key={key} value={item.ledgertypename}>
                                  {item.ledgertypename}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">
                            Payment Amount
                          </label>
                          <Input
                            type="text"
                            className="form-control-alternative"
                            style={{ height: "100%" }}
                            defaultValue={this.state.pay_voucherReceivedAmount}
                            value={this.state.pay_voucherReceivedAmount}
                            onChange={this.handleVoucherReceivedAmount}
                            onKeyDown={this.handleEnter}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: -5, marginBottom: 5 }}>
                      <Col md="12">
                        <Table className="table-flush table-hover" responsive>
                          <thead className="thead-light">
                            <tr>
                              {this.state.pay_voucherLedgerType ===
                                "PurchaseAdvance" ||
                              this.state.pay_voucherLedgerType ===
                                "PurchaseBills" ? (
                                <>
                                  <th className="text-center">Reference</th>
                                  <th className="text-center">Total</th>
                                  <th className="text-center">Balance</th>
                                  <th className="text-center">Amount</th>
                                  <th className="text-center">Action</th>
                                </>
                              ) : (
                                <>
                                  <th className="text-center">Payment Type</th>
                                  <th className="text-center">Reference</th>
                                  <th className="text-center">Amount</th>
                                  <th className="text-center">Action</th>
                                </>
                              )}
                            </tr>
                          </thead>
                          <tbody>{payVoucherRowList}</tbody>
                        </Table>
                        <div>
                          <button
                            className="btn btn-primary btn-sm"
                            style={{ width: 45, height: 40 }}
                            onClick={this.handlePayVoucherAddRow}
                          >
                            <i className="fas fa-plus-circle fa-1x"></i>
                          </button>
                        </div>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: -20 }}>
                      <Col lg="6"></Col>
                      <Col md="3">
                        <ul class="list-group list-group-flush">
                          <li
                            class="list-group-item"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <h3>Amount</h3>
                          </li>
                        </ul>
                      </Col>
                      <Col md="3" style={{ backgroundColor: "transparent" }}>
                        <ul class="list-group list-group-flush align-center">
                          <li
                            class="list-group-item"
                            style={{ backgroundColor: "transparent" }}
                          >
                            <h3>{this.state.pay_voucherAmount}</h3>
                          </li>
                        </ul>
                      </Col>
                    </Row>
                    <button
                      className="btn btn-dark btn-sm"
                      style={{
                        width: 150,
                        height: 40,
                        float: "right",
                        right: "-5px",
                        marginTop: 5,
                      }}
                      onClick={this.onSubmitVoucher}
                    >
                      Create Voucher
                    </button>
                  </CardBody>
                </Card>
              </Col>
            </form>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails: state.clientdetails,
    username: state.username,
  };
};

export default connect(mapStateToProps, null)(AddPayVouchers);
