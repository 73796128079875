import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#bff0fd";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold"
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingRight: 8,
    paddingTop: 4,
  },
  total: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  }
});

const InvoiceTableFooter = ({ itemsTotal, sales_Orderid }) => {
 

  return (
    <React.Fragment>
      { sales_Orderid === "" ?
      <>
      <View style={styles.row}>
        <Text style={styles.description}>Total</Text>
        <Text style={styles.total}>{Number.parseFloat(itemsTotal.subtotal).toFixed(2)}</Text>
      </View>
      { itemsTotal.discount !== "0" ?
        <View style={styles.row}>
          { 
            itemsTotal.discount > "0" ?
            <React.Fragment>
              <Text style={styles.description}>Additional Drafting Charges</Text> 
              <Text style={styles.total}>{Number.parseFloat(itemsTotal.discount).toFixed(2)}</Text>
            </React.Fragment>
            : 
            <React.Fragment>
              <Text style={styles.description}>Discount</Text>
              <Text style={styles.total}>{Number.parseFloat(itemsTotal.discount).toFixed(2)}</Text>
            </React.Fragment>
          }
        </View>
      : null }
      <View style={styles.row}>
        <Text style={styles.description}>Invoice Amount</Text>
        <Text style={styles.total}>{Number.parseFloat(itemsTotal.invoice_amount).toFixed(2)}</Text>
      </View>
      </>
      :
      <>
        <View style={styles.row}>
          <Text style={styles.description}>Total</Text>
          <Text style={styles.total}>{Number.parseFloat(itemsTotal.subtotal).toFixed(2)}</Text>
        </View>
        { itemsTotal.discount !== "0" ?
          <View style={styles.row}>
            { 
              itemsTotal.discount > "0" ?
              <React.Fragment>
                <Text style={styles.description}>Additional Drafting Charges</Text> 
                <Text style={styles.total}>{Number.parseFloat(itemsTotal.discount).toFixed(2)}</Text>
              </React.Fragment>
              : 
              <React.Fragment>
                <Text style={styles.description}>Discount</Text>
                <Text style={styles.total}>{Number.parseFloat(itemsTotal.discount).toFixed(2)}</Text>
              </React.Fragment>
            }
          </View>
        : null }
        <View style={styles.row}>
          <Text style={styles.description}>Invoice Amount</Text>
          <Text style={styles.total}>{Number.parseFloat(itemsTotal.invoice_amount).toFixed(2)}</Text>
        </View>
      </> 
    }
    </React.Fragment>
  );
};

export default InvoiceTableFooter;
