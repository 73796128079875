import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import "../../../../config";
import axios from "axios";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import {
  Row,
  Container,
  Form,
  FormGroup,
  NavLink,
} from "reactstrap";
import TableContainerExpand from "../../Filters/TableContainerExpand";
import TableContainerExpandTeam from "../../Filters/TableContainerExpandTeam";
import { SelectColumnFilter } from "../../Filters/filters";
import Header from "../../../../components/Headers/SimpleHeader";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import config from "react-global-configuration";
import EditSales from "./EditSales";
import Popup from "reactjs-popup";
import CancelSales from "./CancelSales";
import DispatchDetails from "./MoveToDispatch/DispatchDetails";
import { CurrentLocation } from "../../../../components/loginRedux/loginReducers";	
import store from "../../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");
function SalesDetails(props) {
  const [q, setQ] = useState("");
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [EditSalesdetails, updateEditorNot] = useState(false);
  const [Editdata, updateEditdata] = useState();
  const [Showdispatch, updateShowdispatch] = useState(false);
  useEffect(
    () => {
      const getSalesdetails = async () => {
        store.dispatch({ type: CurrentLocation, data: props.location.pathname })
        let response = await fetch(`${authApi}salesfilter/?status__in=Draft%2CIssued%2CPartially+Dispatched%2CDispatched`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        });
        let salesdetails = await response.json();
        const companyfulldetails = await getCompanydetails();
        salesdetails.forEach((oreder) => {
          companyfulldetails.forEach((company) => {
            if (parseInt(oreder.company) === company.id) {
              oreder.company = company.companyname;
            }
          });
        });

        salesdetails.forEach((order) => {
          props.clientdetails.forEach((client) => {
            if (parseInt(order.supplier) === client.id) {
              order.supplier = client.company_name;
            }
          });
        });
        setdata(salesdetails.reverse());
        setLoading(false);
      };
      getSalesdetails();
    },
    // eslint-disable-next-line
    []
  );


  const getCompanydetails = async () => {
    const response = await fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
    });
    const body = await response.json();
    return body;
  };

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : null
      )
    );
  }

  const callBackFunction = async (sales_or_dispatch) => {
    if (sales_or_dispatch === 'Sales') {
      updateEditorNot(false);
    }
    else {
      updateShowdispatch(false);
    }
    updateEditdata("");
    setLoading(true);
    let response = await fetch(`${authApi}salesfilter/?status__in=Draft%2CIssued%2CPartially+Dispatched%2CDispatched`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
    });
    let salesdetails = await response.json();
    const companyfulldetails = await getCompanydetails();
    salesdetails.forEach((oreder) => {
      companyfulldetails.forEach((company) => {
        if (parseInt(oreder.company) === company.id) {
          oreder.company = company.companyname;
        }
      });
    });

    salesdetails.forEach((order) => {
      props.clientdetails.forEach((client) => {
        if (parseInt(order.supplier) === client.id) {
          order.supplier = client.company_name;
        }
      });
    });
    setdata(salesdetails.reverse());
    setLoading(false);
  };

  const editDetails = (editdata, dispatch_or_editsales) => {
    if (dispatch_or_editsales === 'Sales') {
      updateEditdata(editdata);
      updateEditorNot(true);
    }
    else {
      updateEditdata(editdata);
      updateShowdispatch(true);
    }
  };

  const cancelsalesorder = async (id) => {
    let response = await axios({
      method: "post",
      url: `${authApi}sp_deletesalesorder/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${props.token}`,
      },
      data: {
        sales_id: id
      }
    })
    if (response.status === 200) {
      callBackFunction();
    }
  }


  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Actions",
        Cell: (row) => {
          const row_data = row.row.original;
          return (
            <React.Fragment>
              <button
                className="btn btn-primary btn-sm"
                style={{ marginRight: "5px" }}
                onClick={() => editDetails(row_data, "Sales")}
                disabled={row_data.status === "Draft" ? false : true}
              >
                <i className="fa fa-edit" />
              </button>
              <Popup
                modal
                trigger={
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ marginRight: "5px" }}
                    disabled={row_data.status === "Draft" ? false : true}
                  >
                    <i className="fa fa-trash" />
                  </button>
                }
              >
                {(close) => (
                  <CancelSales
                    closebtn={close}
                    purchaseorder={true}
                    Editdata={row_data}
                    cancelSalesorder={cancelsalesorder}
                  />
                )}
              </Popup>
              <button className="btn btn-primary btn-sm" style={{ width: 30, height: 28 }}>
                <NavLink
                  style={{ marginTop: -5 }}
                  to={{
                    pathname: "/admin/management/view-salesorder",
                    state: { salesorderData: row_data }
                  }}
                  tag={Link}
                >
                  <i style={{ marginLeft: -13, marginBottom: 500 }} className="fa fa-eye text-white" />
                </NavLink>
              </button>

              <button className="btn btn-primary btn-sm" onClick={() => editDetails(row_data, "Dispatch")} disabled={row_data.status === "Draft" || row_data.status === "Issued" ? false : true} >
                <i className="fas fa-plus-circle fa-1x text-white" />
              </button>

             
            </React.Fragment>
          );
        },
      },
      {
        Header: "SalesID",
        accessor: "id",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Client Name",
        accessor: "supplier",
        Filter: SelectColumnFilter,
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Company",
        accessor: "company",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Adjustment",
        accessor: "roundoff",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ],
    // eslint-disable-next-line
    []
  );

  const columnSecondLayer = useMemo(
    () => [
      {
        Header: "Stockname",
        accessor: "stockname",
      },
      {
        Header: "Productcode",
        accessor: "productcode",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Rate",
        accessor: "rate",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "CGST(%)",
        accessor: "cgstpercentage",
      },
      {
        Header: "SGST(%)",
        accessor: "sgstpercentage",
      },
      {
        Header: "Total",
        accessor: "total",
      },
    ],
    // eslint-disable-next-line
    []
  );

  const renderRowSubComponent = (row) => {
    const rowdata = row.original.sales.filter(function (item) {
      return item.isdeleted !== true;
    });
    let stocks = [];
    stocks = rowdata.filter(function (item) {
      return item.stockname !== "Total";
    });
    rowdata.forEach((item) => {
      if (item.stockname === "Total") {
        stocks = [...stocks, item];
      }
    });
    return (
      <React.Fragment>
        <br />
        <h5 className="thead-light">Subtask Details :</h5>
        <TableContainerExpandTeam
          columns={columnSecondLayer}
          data={stocks}
        />
        <br />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Header />
      {EditSalesdetails === false && Showdispatch === false ? (
        <Container className="mt--7" fluid>
          <br />
          <Row>
            <Form
              className="searchForm"
              style={{ marginTop: 18, right: "100px" }}
            >
              <FormGroup className="mb-0">
                <input
                  className="searchBox "
                  type="search"
                  placeholder="Search"
                  value={q}
                  onChange={(e) => setQ(e.target.value)}
                />
              </FormGroup>
            </Form>
            <div
              className="addRow"
              style={{ position: "absolute", right: "50px", marginTop: 17 }}
            >
              <NavLink
                to="/admin/sales/addsalesdetails"
                tag={Link}
              >
                <button className="btn btn-primary btn-sm"
                  style={{ width: "135%", height: 40 }}
                  data-tip data-for="completed-Tip"
                >
             
                  <i className="fas fa-plus-circle fa-1x"></i>
                </button>
              </NavLink>
            </div>
          </Row>
          {loading === true ? (
            <div>
         
              <ConPreLoader />
            </div>
          ) : (
            <Row style={{ marginTop: 80 }}>
              <div className=" col">
                <ReactTooltip place="top" type="dark" effect="float" />
                <TableContainerExpand
                  columns={columns}
                  data={search(data)}
                  renderRowSubComponent={renderRowSubComponent}
                />
              </div>
            </Row>
          )}
          <br /><br />
        </Container>
      ) : null}
      {EditSalesdetails === true ? (
        <>
          <Container className="mt--7" fluid>
            <EditSales
              Editdata={Editdata}
              checked={EditSalesdetails}
              callBack={callBackFunction}
            />
          </Container>
        </>
      ) : null}
      {Showdispatch === true ?
        <DispatchDetails
          Editdata={Editdata}
          checked={EditSalesdetails}
          callBack={callBackFunction}
        /> : null}
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails:state.clientdetails
  };
};
export default connect(mapStateToProps, null)(SalesDetails);
