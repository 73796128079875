import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import "../../../config";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import { Row, Container, Form, FormGroup, NavLink } from "reactstrap";
import TableContainerExpand from "../Filters/TableContainerExpand";
import TableContainerExpandTeam from "../Filters/TableContainerExpandTeam";
import { SelectColumnFilter } from "../Filters/filters";
import Header from "../../../components/Headers/SimpleHeader";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import config from "react-global-configuration";
import { CurrentLocation } from "../../../components/loginRedux/loginReducers";	
import store from "../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");
function SalesRequest(props) {
  const [q, setQ] = useState("");
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const getSalesdetails = async () => {
      store.dispatch({ type: CurrentLocation, data: props.location.pathname })
      let response = await fetch(`${authApi}quotationfilter/`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${props.token}`,
        },
      });
      let salesdetails = await response.json();
      const companyfulldetails = await getCompanydetails();
      salesdetails.forEach((oreder) => {
        companyfulldetails.forEach((company) => {
          if (parseInt(oreder.company) === company.id) {
            oreder.company = company.companyname;
          }
        });
      });

      salesdetails.forEach((order) => {
        props.clientdetails.forEach((client) => {
          if (parseInt(order.supplier) === client.id) {
            order.supplier = client.company_name;
          }
        });
      });
      setdata(salesdetails.reverse());
      setLoading(false);
    };
    getSalesdetails();
  },
    // eslint-disable-next-line
  []
  );


  const getCompanydetails = async () => {
    const response = await fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
    });
    const body = await response.json();
    return body;
  };

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : null
      )
    );
  }

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "QuotationId",
        accessor: "id",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Client Name",
        accessor: "supplier",
        Filter: SelectColumnFilter,
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Company",
        accessor: "company",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Adjustment",
        accessor: "roundoff",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
    ],

    []
  );

  const columnSecondLayer = useMemo(
    () => [
      {
        Header: "Stockname",
        accessor: "stockname",
      },
      {
        Header: "Productcode",
        accessor: "productcode",
      },
      {
        Header: "Quantity",
        accessor: "quantity",
      },
      {
        Header: "Rate",
        accessor: "rate",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "CGST(%)",
        accessor: "cgstpercentage",
      },
      {
        Header: "SGST(%)",
        accessor: "sgstpercentage",
      },
      {
        Header: "Total",
        accessor: "total",
      },
    ],

    []
  );

  const renderRowSubComponent = (row) => {
    const rowdata = row.original.salesquotation.filter(function (item) {
      return item.isdeleted !== true;
    });
    let stocks = [];
    stocks = rowdata.filter(function (item) {
      return item.stockname !== "Total";
    });
    rowdata.forEach((item) => {
      if (item.stockname === "Total") {
        stocks = [...stocks, item];
      }
    });
    return (
      <React.Fragment>
        <br />
        <h5 className="thead-light">Subtask Details :</h5>
        <TableContainerExpandTeam columns={columnSecondLayer} data={stocks} />
        <br />
      </React.Fragment>
    );
  };

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--7" fluid>
        
        <br />
        <Row>
          <Form
            className="searchForm"
            style={{ marginTop: 14, right: "145px" }}
          >
            <FormGroup className="mb-0">
              <input
                className="searchBox "
                type="search"
                placeholder="Search"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </FormGroup>
          </Form>
          <div
            className="addRow"
            style={{ position: "absolute", right: "98px", marginTop: 2 }}
          >
            <NavLink
              style={{ marginTop: 10 }}
              to="/admin/sales/salesquotation"
              tag={Link}
            >
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                data-tip
                data-for="completed-Tip"
              >
                
                <i className="fas fa-plus-circle fa-1x"></i>
              </button>
            </NavLink>
            <ReactTooltip
              id="completed-Tip"
              place="left"
              type="dark"
              effect="solid"
            >
              Create Quotation
            </ReactTooltip>
          </div>
          <div
            className="invoicegenerate"
            style={{ position: "absolute", right: "0px", marginTop: 3 }}
          >
            <NavLink style={{ marginTop: 10 }} to="/admin/myday" tag={Link}>
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                data-tip
                data-for="Back-Tip"
              >
                Back
              </button>
            </NavLink>
          </div>
        </Row>
        <br />
        <br />
        <br />
        {loading === true ? (
          <div>
            
            <ConPreLoader />
          </div>
        ) : (
          <Row style={{ marginTop: 10 }}>
            <div className=" col">
              <ReactTooltip place="top" type="dark" effect="float" />
              <TableContainerExpand
                columns={columns}
                data={search(data)}
                renderRowSubComponent={renderRowSubComponent}
              />
            </div>
          </Row>
        )}
        <br />
        <br />
      </Container>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails:state.clientdetails
  };
};
export default connect(mapStateToProps, null)(SalesRequest);
