import React from "react";
import { Input } from "reactstrap";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";

const authApi = config.get("apiDomain");

class RecVoucherRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.id,
      rec_salesId: this.props.sales_Id,
      rec_subType: this.props.recsubType,
      rec_salesTotal: this.props.sales_Total,
      rec_amtReceived: this.props.recamtReceived,
      rec_subReferType: this.props.recsubReferType,
      rec_salesBalance: this.props.sales_Balance,
      rec_ledgerType: this.props.voucherLedgerType,
      salesOrderDetails: this.props.salesOrderData,
      salesDispatchDetails: this.props.salesDispatchData,
      sales_invclientCompanyid: this.props.clientCompanyid,
      sales_invclientId: this.props.clientId,
      amountborder: "none",
    };
  }

  deleteRecVoucherRow = () => {
    this.props.deleteRow(this.state);
  };

  handleVoucherReceiptId = (event) => {
    if (this.state.rec_ledgerType === "Invoice") {
      fetch(
        `${authApi}summaryfilter/?deliverynoteid=${event.target.value}&client=${this.state.sales_invclientId}&invoice_company=${this.state.sales_invclientCompanyid}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((json) => {
          const filterJson = json.filter(function (db) {
            return db.invoicestatus !== "Paid";
          });
          if (filterJson.length !== 0 && filterJson.length !== undefined) {
            let balance = 0;
            let amountreceived = filterJson[0].amount_received;
            if (amountreceived === null || amountreceived === 0) {
              amountreceived = 0;
            }
            balance =
              parseFloat(filterJson[0].invoice_amount) -
              parseFloat(amountreceived);
            this.setState(
              {
                rec_salesId: filterJson[0].deliverynoteid,
                rec_salesTotal: filterJson[0].invoice_amount,
                rec_amtReceived: "",
                rec_salesBalance: parseFloat(balance),
              },
              function () {
                this.props.updateAddRow(this.state);
              }
            );
          } else {
            alert("Selected Invoice Has been Paid Already");
          }
        });
    } else if (this.state.rec_ledgerType === "Projects") {
      fetch(
        `${authApi}summaryfilter/?deliverynoteid=${event.target.value}&client=${this.state.sales_invclientId}&invoice_company=${this.state.sales_invclientCompanyid}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((json) => {
          console.log(json);
          const filterJson = json.filter(function (db) {
            return db.invoicestatus !== "Paid";
          });
          if (filterJson.length !== 0 && filterJson.length !== undefined) {
            let balance = 0;
            let amountreceived = filterJson[0].amount_received;
            if (amountreceived === null || amountreceived === 0) {
              amountreceived = 0;
            }
            balance =
              parseFloat(filterJson[0].invoice_amount) -
              parseFloat(amountreceived);
            this.setState(
              {
                rec_salesId: filterJson[0].deliverynoteid,
                rec_salesTotal: filterJson[0].invoice_amount,
                rec_amtReceived: "",
                rec_salesBalance: parseFloat(balance),
              },
              function () {
                this.props.updateAddRow(this.state);
              }
            );
          } else {
            alert("Selected Project invoice has been paid already.!");
          }
        });
    } else if (this.state.rec_ledgerType === "SalesInvoice") {
      fetch(
        `${authApi}summaryfilter/?deliverynoteid=${event.target.value}&client=${this.state.sales_invclientId}&invoice_company=${this.state.sales_invclientCompanyid}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((json) => {
          const filterJson = json.filter(function (db) {
            return db.salesorder !== null;
          });
          if (filterJson.length !== 0 && filterJson.length !== undefined) {
            let balance = 0;
            let amountreceived = filterJson[0].amount_received;
            if (amountreceived === null || amountreceived === 0) {
              amountreceived = 0;
            }
            balance =
              parseFloat(filterJson[0].invoice_amount) -
              parseFloat(amountreceived);
            this.setState(
              {
                rec_salesId: filterJson[0].deliverynoteid,
                rec_salesTotal: filterJson[0].invoice_amount,
                rec_amtReceived: "",
                rec_salesBalance: parseFloat(balance),
              },
              function () {
                this.props.updateAddRow(this.state);
              }
            );
          } else {
            alert("Sales invoice bill amount has zero");
          }
        });
    } else if (this.state.rec_ledgerType === "SalesAdvance") {
      fetch(`${authApi}salesfilter/?id=${event.target.value}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json.length !== 0 && json.length !== undefined) {
            let balance = 0;
            let amount_received = json[0].amountreceived;
            if (amount_received === null || amount_received === 0) {
              amount_received = 0;
            }
            balance =
              parseFloat(json[0].amount) - parseFloat(json[0].advancereceived);
            this.setState(
              {
                rec_salesId: json[0].id,
                rec_salesTotal: json[0].amount,
                rec_amtReceived: "",
                rec_salesBalance: parseFloat(balance).toFixed(2),
              },
              function () {
                this.props.updateAddRow(this.state);
              }
            );
          } else {
            alert("Sales dispatch bill amount has zero");
          }
        });
    }
  };

  handleReceiptName = (event) => {
    this.setState(
      {
        rec_subType: event.target.value,
      },
      function () {
        this.props.updateAddRow(this.state);
      }
    );
  };

  handleRecVoucherAmtRecevd = (event) => {
    if (
      this.state.rec_ledgerType === "SalesInvoice" ||
      this.state.rec_ledgerType === "SalesAdvance" ||
      this.state.rec_ledgerType === "Invoice" ||
      this.state.rec_ledgerType === "Projects"
    ) {
      if (
        parseFloat(this.state.rec_salesBalance) < parseFloat(event.target.value)
      ) {
        this.setState({
          amountborder: "1px solid red",
          rec_amtReceived: event.target.value,
        });
      } else {
        this.setState(
          {
            rec_amtReceived: event.target.value,
            amountborder: "none",
          },
          function () {
            this.props.updateAddRow(this.state);
          }
        );
      }
    }
  };

  handleVoucherAmountRecevd = (event) => {
    if (this.state.rec_subReferType === "") {
      this.setState({
        amountborder: "1px solid red",
        rec_amtReceived: event.target.value,
      });
    } else {
      this.setState(
        {
          rec_amtReceived: event.target.value,
          amountborder: "none",
        },
        function () {
          this.props.updateAddRow(this.state);
        }
      );
    }
  };

  handleVoucherReceiptReference = (event) => {
    this.setState(
      {
        rec_subReferType: event.target.value,
      },
      function () {
        this.props.updateAddRow(this.state);
      }
    );
  };

  render() {
    return (
      <React.Fragment>
        <tr>
          {this.state.rec_ledgerType === "SalesInvoice" ||
          this.state.rec_ledgerType === "SalesAdvance" ||
          this.state.rec_ledgerType === "Invoice" ||
          this.state.rec_ledgerType === "Projects" ? (
            <>
              <td>
                <Input
                  type="select"
                  className="form-control-alternative"
                  style={{ height: "100%", width: 220, marginLeft: -20 }}
                  defaultValue={this.state.rec_salesId}
                  placeholder="enter sales"
                  onChange={this.handleVoucherReceiptId}
                >
                  {this.state.rec_ledgerType === "Invoice" ? (
                    <>
                      <option value="">Invoice</option>
                      {this.state.salesDispatchDetails
                        .sort((a, b) => a.deliverynoteid - b.deliverynoteid)
                        .map((item, key) => (
                          <option key={key.id} value={item.deliverynoteid}>
                            
                            {item.deliverynoteid} -{item.invoice_amount}
                          </option>
                        ))}
                    </>
                  ) : null}
                  {this.state.rec_ledgerType === "Projects" ? (
                    <>
                      <option value="">Projects</option>
                      {this.state.salesDispatchDetails
                        .sort((a, b) => a.deliverynoteid - b.deliverynoteid)
                        .map((item, key) => (
                          <option key={key.id} value={item.deliverynoteid}>
                            
                            {item.deliverynoteid} - {item.invoice_amount}
                          </option>
                        ))}
                    </>
                  ) : null}
                  {this.state.rec_ledgerType === "SalesInvoice" ? (
                    <>
                      <option value="">Dispatch</option>
                      {this.state.salesDispatchDetails
                        .sort((a, b) => a.deliverynoteid - b.deliverynoteid)
                        .map((item, key) => (
                          <option key={key.id} value={item.deliverynoteid}>
                            
                            {item.deliverynoteid}
                          </option>
                        ))}
                    </>
                  ) : null}
                  {this.state.rec_ledgerType === "SalesAdvance" ? (
                    <>
                      <option value="">Orders</option>
                      {this.state.salesOrderDetails
                        .sort((a, b) => a.id - b.id)
                        .map((item, key) => (
                          <option key={key.id} value={item.id}>
                            
                            {item.id}
                          </option>
                        ))}
                    </>
                  ) : null}
                </Input>
              </td>
              <td>
                <Input
                  type="number"
                  className="form-control-alternative"
                  style={{ height: "100%" }}
                  placeholder="Total"
                  defaultValue={this.state.rec_salesTotal}
                  value={this.state.rec_salesTotal}
                  disabled
                />
              </td>
              <td>
                <Input
                  type="number"
                  className="form-control-alternative"
                  style={{ height: "100%" }}
                  placeholder="Balance"
                  defaultValue={this.state.rec_salesBalance}
                  value={this.state.rec_salesBalance}
                  disabled
                />
              </td>
              <td>
                <Input
                  type="text"
                  className="form-control-alternative"
                  style={{ height: "100%", border: this.state.amountborder }}
                  placeholder="enter amount"
                  defaultValue={this.state.rec_amtReceived}
                  value={this.state.rec_amtReceived}
                  onChange={this.handleRecVoucherAmtRecevd}
                />
                {this.state.amountborder !== "none" ? (
                  <h6 style={{ color: "red" }}>
                    Received amount is greater than due amount
                  </h6>
                ) : null}
              </td>
            </>
          ) : (
            <>
              <td>
                <Input
                  type="text"
                  className="form-control-alternative"
                  style={{ height: "100%", width: 300, marginLeft: -20 }}
                  placeholder="Enter type"
                  defaultValue={this.state.rec_subType}
                  onChange={this.handleReceiptName}
                />
              </td>
              <td>
                <Input
                  type="text"
                  className="form-control-alternative"
                  style={{
                    height: "100%",
                    width: 250,
                    border: this.state.amountborder,
                  }}
                  placeholder="Enter reference"
                  defaultValue={this.state.rec_subReferType}
                  onChange={this.handleVoucherReceiptReference}
                />
                {this.state.amountborder !== "none" ? (
                  <h6 style={{ color: "red" }}>
                    Please enter valuable reference
                  </h6>
                ) : null}
              </td>
              <td>
                <Input
                  type="text"
                  className="form-control-alternative"
                  style={{ height: "100%", width: 250 }}
                  placeholder="Enter amount"
                  defaultValue={this.state.rec_amtReceived}
                  onChange={this.handleVoucherAmountRecevd}
                />
              </td>
            </>
          )}
          <td className="text-center">
            <button
              className="btn btn-primary btn-sm"
              style={{ marginTop: 8 }}
              onClick={this.deleteRecVoucherRow}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(RecVoucherRow);
