import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  date: {
    width: "30%",
  },
  task: {
    width: "40%",
  },
  time: {
    width: "30%",
  }
});

const SummarizedHeader = () => (
  <View style={styles.container}>
    <Text style={styles.date}> Date </Text>
    <Text style={styles.task}> Assignee </Text>
    <Text style={styles.time}>Time</Text>
  </View>
);

export default SummarizedHeader;
