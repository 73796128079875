import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Salesorder from "./Salesorder";
import {Row, Card} from "reactstrap";
import "config";
import { connect } from "react-redux";

class Salesorderapp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      JsonObject: this.props.data,
      loading : true
    };
  }
  
  componentDidMount() {
    this.setState({
      loading: false
    })
  }

  render() {
    if(this.state.loading)
    {
      return null;
    }
    else
    {
      return (
        <React.Fragment>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">
            SalesOrder details
          </div>
          <Row>
            <div className="col">
              <div className="Scrolls-Contents">
                <Card className="shadow">
                  <PDFViewer width="100%" height="600" className="app">
                    <Salesorder 
                      salesorderData = {this.state.JsonObject}
                    />
                  </PDFViewer>
                </Card>
              </div>
            </div>
          </Row>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = state => {
  return {
    token:state.token, 
  }
}
export default connect(mapStateToProps, null)(Salesorderapp);