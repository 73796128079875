import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Invoice from "./Invoice";
import { Row, Card } from "reactstrap";
import "../../../../config";
import config from "react-global-configuration";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";

const authApi = config.get("apiDomain");

class InvoiceAppOF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      JsonObject: "",
      startDate: "",
      endDate: "",
      loading: true,
      PresentDay: new Date(),
    };
  }

  componentDidMount() {
    this.datefunction();
    this.downloadFunction();
  }

  datefunction = () => {
    const a = moment(this.props.dateStart).format("YYYY-MM-DD");
    const b = moment(this.props.dateEnd).format("YYYY-MM-DD");
    this.setState({
      startDate: a,
      endDate: b,
    });
  };

  downloadFunction = () => {
    axios({
      method: "get",
      url: `${authApi}summarizedbalance/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => {
        this.setState({
          JsonObject: res.data,
          loading: false,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <React.Fragment>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">Outstanding Balance Details</div>
          <Row>
            <div className=" col">
              <div className="Scrolls-Contents">
                <Card className="shadow">
                  <PDFViewer width="100%" height="600" className="app">
                    <Invoice
                      GenerateData={this.state.JsonObject}
                      sD={this.state.startDate}
                      eD={this.state.endDate}
                    />
                  </PDFViewer>
                </Card>
              </div>
            </div>
          </Row>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(InvoiceAppOF);
