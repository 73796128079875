import React from "react";
import { Input, Row, Col } from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import config from "react-global-configuration";
import consoleFunction from "../../../../Helpers";
import getDateTimeField from "../../../../Utils";
import "../../../../config";

const authApi = config.get("apiDomain");

class EditClientgrp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.EditRowSend.id,
      clientgroupname: this.props.EditRowSend.name,
      clientgroupnameborder: "1px solid #dee2e6",
      isButtonDisabled: false,
    };
  }

  FormValidation = () => {
    if (this.state.clientgroupname !== "") {
      this.setState({
        isButtonDisabled: true,
        clientgroupnameborder: "1px solid #dee2e6",
      });
      this.editclientgrpname();
    } else {
      this.setState({
        clientgroupnameborder: "1px solid #fb6340",
      });
    }
  };

  editclientgrpname = () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "put",
      url: `${authApi}clientgroup/${this.state.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        name: this.state.clientgroupname,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.props.closebtn();
          this.props.callbackfunction();
        }
      })
      .catch((err) => {
        consoleFunction(
          "Clientgroup name updation error",
          err.response.request.response
        );
        this.setState({ error: err.message });
      });
  };

  handleClientgroupName = (event) => {
    this.setState({
      clientgroupname: event.target.value.replace(/[^\w\s]/gi, ""),
    });
  };

  render() {
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Edit Clientgroup name</div>
        <div className="Scroll-Content">
          <Row>
            <Col md="6">
              <label className="form-control-label">Name</label>
              <Input
                type="text"
                className="form-control-alternative"
                defaultValue={this.state.clientgroupname}
                style={{ border: this.state.clientgroupnameborder }}
                onChange={this.handleClientgroupName}
              />
              {this.state.clientgroupnameborder !== "1px solid #dee2e6" ? (
                <h5 style={{ color: "#fb6340" }}>Please enter groupname</h5>
              ) : null}
            </Col>
            <Col md="6">
              <div className="button">
                <button
                  id="buttonsave"
                  className="btn btn-dark"
                  style={{ float: "right", marginRight: 15 }}
                  onClick={this.FormValidation}
                  disabled={this.state.isButtonDisabled}
                >
                  Done
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(EditClientgrp);
