import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  NavLink,
  NavItem,
  Nav,
  Form,
  FormGroup,
  Col,
} from "reactstrap";
import { Link } from "react-router-dom";
import TableContainer from "../../Filters/TableContainer";
import { SelectColumnFilter } from "../../Filters/filters";
import classnames from "classnames";
import moment from "moment";
import ConPreLoader from "../../../../components/Loader/Con_PreLoader";
import "../../Teamtask/Todotask.css";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";
import ReactTooltip from "react-tooltip";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { CurrentLocation } from "../../../../components/loginRedux/loginReducers";	
import store from "../../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");
let currentActiveTab = "1";
let sdToday = new Date();
let Date1 = new Date(sdToday.getFullYear(), sdToday.getMonth(), 1);
let Date2 = new Date(sdToday.getFullYear(), sdToday.getMonth() + 1, 0);

function TransactionDetails(props) {
  const [data, setData] = useState([]);
  const [Filterdata, setFilterData] = useState([]);
  const [searchPhrase, setSearchPhrase] = useState("");
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [btnClass, setBtnClass] = useState("btn btn-danger btn-sm");

  useEffect(
    () => {
      const doFetch = async () => {
        store.dispatch({ type: CurrentLocation, data: props.location.pathname })
        const response = await fetch(`${authApi}jointransactions/`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        });
        const body = await response.json();
        defaultrangepicker(body);
        setData(body);
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function getFilterButton(event) {
    if (event === true) {
      setChecked(false);
      setBtnClass("btn btn-success btn-sm");
    } else {
      setChecked(true);
      setBtnClass("btn btn-danger btn-sm");
    }
  }

  function defaultrangepicker(body) {
    let PresentDay = new Date();
    let firstDay = new Date(PresentDay.getFullYear(), PresentDay.getMonth(), 1);
    let lastDay = new Date(
      PresentDay.getFullYear(),
      PresentDay.getMonth() + 1,
      0
    );
    let D1 = moment(firstDay).format("YYYY-MM-DD");
    let D2 = moment(lastDay).format("YYYY-MM-DD");
    let defaultVal = body.filter(function (d) {
      return d.date >= D1 && d.date <= D2;
    });
    if (defaultVal.length === 0) {
      setFilterData(defaultVal);
      setLoading(false);
    } else {
      CalculateDateTime(defaultVal);
      callback(defaultVal);
    }
  }

  function CalculateDateTime(body) {
    body.forEach((e) => {
      e.time = calculateTime(e);
    });
  }

  function calculateTime(t) {
    let num = moment(t.time, "h:mm:ss A").format("HH:mm:ss A");
    return num;
  }

  function callback(body) {
    setFilterData(body.reverse());
    setLoading(false);
  }

  function handleDateRangeCallback(event, picker) {
    Date1 = picker.startDate._d.toISOString();
    Date2 = picker.endDate._d.toISOString();
    setLoading(true);
    const sd = picker.startDate;
    const ed = picker.endDate;
    let a_sd = moment(sd).format("YYYY-MM-DD");
    let b_ed = moment(ed).format("YYYY-MM-DD");
    let filter_val = data.filter(function (d) {
      return d.date >= a_sd && d.date <= b_ed;
    });
    if (filter_val.length === 0) {
      alert("No Task available for the Selected Filter.!");
      setFilterData([]);
      setLoading(false);
    } else {
      CalculateDateTime(filter_val);
      callback(filter_val);
    }
  }

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c].toString().toLowerCase().indexOf(searchPhrase.toLowerCase()) >
            -1
          : null
      )
    );
  }

  function DownloadData(json) {
    const items = json;
    const replacer = (key, value) => (value === null ? "" : value);
    const header = Object.keys(items[0]).filter(
      (field) =>
        field !== "id" &&
        field !== "subtask" &&
        field !== "isdeleted" &&
        field !== "task_starttime" &&
        field !== "time"
    );
    let csv = items.map((row) =>
      header
        .map((fieldName) => JSON.stringify(row[fieldName], replacer))
        .join(",")
    );
    csv.unshift(header.join(","));
    csv = csv.join("\r\n");
    const ConvertData = csv;
    const element = document.createElement("a");
    const file = new Blob([ConvertData], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download = "TeamTask.csv";
    document.body.appendChild(element);
    element.click();
  }

  const columns = useMemo(
    () => [
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Time",
        accessor: "time",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "User",
        accessor: "name",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Paymentmode",
        accessor: "pmtmode",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Client",
        accessor: "company_name",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Company",
        accessor: "companyid",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Task",
        accessor: "task",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Type",
        accessor: "transactiontype",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Amount",
        accessor: "amount",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "SalesorderId",
        accessor: "deliverynoteid",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Reference No.",
        accessor: "pmtreference",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "VoucherId",
        accessor: "voucher_id",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "VoucherType",
        accessor: "vouchertype",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "Referencetype",
        accessor: "voucherreferencetype",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <div style={{ marginTop: 0 }}>
        <Nav
          tabs
          style={{
            padding: "10px 10px 0",
            fontSize: 20,
            backgroundColor: "#11cdef",
          }}
        >
          <NavItem>
            <NavLink
              style={{ color: "white" }}
              className={classnames({
                active: currentActiveTab === "2",
              })}
              to="/admin/filter/transaction-report"
              tag={Link}
            >
              Overview
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "1",
              })}
              to="/admin/filter/transactions"
              tag={Link}
            >
              Transactions
            </NavLink>
          </NavItem>
        </Nav>
        <Container className="mt--7" fluid>
          <br />
          <br />
          <br />
          <br />
          <Row>
            <Col md="0.5">
              <ReactTooltip
                id="transactions-Tip"
                place="right"
                type="dark"
                effect="solid"
              >
                Go to Invoice Page
              </ReactTooltip>
              <NavLink
                style={{ marginTop: 10 }}
                to="/admin/invoice/invoice-management"
                tag={Link}
              >
                <button
                  className="btn btn-primary btn-sm"
                  style={{ width: "135%", height: 40 }}
                  data-tip
                  data-for="transactions-Tip"
                >
                  <i className="fa fa-arrow-left"></i>
                </button>
              </NavLink>
            </Col>
            <Col md="3">
              <NavLink style={{ marginTop: 13, marginLeft: -10 }}>
                <DateRangePicker
                  autoUpdateInput={false}
                  onApply={handleDateRangeCallback}
                  initialSettings={{
                    locale: {
                      format: 'DD/MM/YYYY'
                    }
                  }}
                >
                  <button
                    className="form-control-alternative"
                    style={{
                      height: "35px",
                      width: "120%",
                      overflow: "hidden",
                      padding: "2px 2px 2px",
                      backgroundColor: "transparent",
                      border: "none",
                      opacity: "0.7",
                    }}
                  >
                    <i
                      className="fa fa-calendar-o"
                      style={{ paddingRight: 15 }}
                      aria-hidden="true"
                    ></i>
                    {moment(Date1).format("DD/MM/YYYY")} -
                    {moment(Date2).format("DD/MM/YYYY")}
                  </button>
                </DateRangePicker>
              </NavLink>
            </Col>
            <Col md="0.5"></Col>
            <Col md="6.5">
              <div
                className="invoicegenerate"
                style={{ position: "absolute", right: "30px", marginTop: 11 }}
              >
                <button
                  className={btnClass}
                  style={{ width: "135%", height: 40 }}
                  onClick={(e) => getFilterButton(checked)}
                >
                  <i className="fa fa-filter" aria-hidden="true"></i>
                </button>
              </div>
              <Form
                className="searchForm"
                style={{ marginTop: 10, right: "105px" }}
              >
                <FormGroup className="mb-0">
                  <input
                    className="searchBox "
                    type="search"
                    placeholder="Search"
                    value={searchPhrase}
                    onChange={(e) => setSearchPhrase(e.target.value)}
                  />
                </FormGroup>
              </Form>
            </Col>
          </Row>
          {loading === true ? (
            <div>
              
              <ConPreLoader />
            </div>
          ) : (
            <Row style={{ marginTop: 5 }}>
              <div className=" col">
                <ReactTooltip place="top" type="dark" effect="float" />
                <TableContainer
                  columns={columns}
                  data={search(Filterdata)}
                  checkedToggle={checked}
                />
              </div>
            </Row>
          )}
          <br />
          <button
            className="Export btn-info"
            id="download"
            style={{ marginRight: "5px" }}
            onClick={() => DownloadData(Filterdata)}
          >
            Export
          </button>
          <br />
          <br />
        </Container>
      </div>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    //   detail:state.filterData
  };
};
export default connect(mapStateToProps, null)(TransactionDetails);
