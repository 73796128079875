import React, { Component } from "react";
import config from "react-global-configuration";
import { Row, Card, Container, NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import Popup from "reactjs-popup";
import "../../../config";
import { connect } from "react-redux";
import InvoiceApp from "../PdfRenderer/InvoiceApp";
import ViewIndividualinvoice from "./ViewIndividualinvoice";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import "./ViewInvoice.css";

const authApi = config.get("apiDomain");
let company_Code = "";
let company_Invoiceid = "";

class IndividualInvoice extends Component {
  constructor(props) {
    super(props);
    this.state = {
      jsonObject: "",
      sales_orderid: "",
      loading: true,
    };
  }

  componentDidMount() {
    if (this.props.location.state !== undefined) {
      company_Code = this.props.location.state.invoicepropsdata.companycode;
      company_Invoiceid =
        this.props.location.state.invoicepropsdata.companyinvoiceid;
      if (
        this.props.location.state.invoicepropsdata.salesorder_id === null &&
        this.props.location.state.invoicepropsdata.project_id === null
      ) {
        this.getTaskData(this.props.location.state.invoiceRowData);
      } else if (
        this.props.location.state.invoicepropsdata.project_id !== null
      ) {
        this.getProjectData(this.props.location.state.invoiceRowData);
      } else {
        let d_id = this.props.location.state.invoicepropsdata.deliverynoteid;
        let inv_data = this.props.location.state.invoicepropsdata;
        this.setState({
          sales_orderid: d_id,
        });
        this.updateClientname(inv_data.client);
        fetch(`${authApi}summaryfilter/?deliverynoteid=${d_id}`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
        })
          .then((res) => res.json())
          .then((json) => {
            const body = json.filter((i) => i.salesorder !== null);
            if (body.length !== 0) {
              this.updateJsonSalesObject(body, inv_data);
            }
          });
      }
    } else {
      alert("Please select your invoice");
      this.setState({
        loading: false,
        jsonObject: "",
      });
    }
  }

  getTaskData = (data) => {
    this.updateClientname(data.client);
    fetch(`${authApi}tasklistfilter/?id=${data.taskid}&isdeleted=false`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => this.updateJsonObject(json, data));
  };

  getProjectData = (projectdata) => {
    this.updateClientname(projectdata.client);
    fetch(
      `${authApi}projectfilter/?id=${projectdata.project_id}&isdeleted=false`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((projectjson) =>
        this.updateProjectJsonObject(projectjson, projectdata)
      );
  };

  updateClientname = (c) => {
    this.props.location.state.clientdata.forEach((d) => {
      if (d.id === parseInt(c)) {
        this.setState({
          jsonclientname: d.engineer_name,
          jsonclientCompany: d.company_name,
          jsonclientphoneno: d.phoneno,
          jsonclientemail: d.emailid,
        });
      }
    });
  };

  updateJsonObject = (json, data) => {
    let taskList = [];
    const taskPromises = json.map((element) => this.getTaskDetail(element));
    Promise.all(taskPromises)
      .then((result) => {
        result.forEach((element) => {
          taskList = [...taskList, element];
        });
        this.setState({
          jsonObject: {
            invoiceno: company_Code + "-" + data.companyinvoice,
            companyinvoiceid: company_Invoiceid,
            invoicedate: moment(data.invoicedate).format("DD-MM-YYYY"),
            client: data.client,
            clientCompany: this.state.jsonclientCompany,
            clientname: this.state.jsonclientname,
            emailId: this.state.jsonclientemail,
            Mobilenumber: this.state.jsonclientphoneno,
            amount_received: data.amount_received,
            balancedue: data.balancedue,
            discount: data.discount,
            duedate: data.duedate,
            invoice_amount: data.invoice_amount,
            invoicestatus: data.invoicestatus,
            paymentmode: data.paymentmode,
            referenceno: data.referenceno,
            subtotal: data.subtotal,
            TaskDetails: taskList,
            companyname: json[0].companyname,
            subclient: json[0].subclient,
          },
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  updateProjectJsonObject = (projectjson, projectdata) => {
    let taskList = [];

    const taskPromises = projectjson[0].taskproject
      .filter(
        (i) =>
          i.invoiceidno === String(projectdata.companyinvoice) &&
          i.status === "Completed"
      )
      .map((element) => this.getProjectDetail(element));
    Promise.all(taskPromises)
      .then((result) => {
        result.forEach((element) => {
          taskList = [...taskList, element];
        });
        this.setState({
          jsonObject: {
            invoiceno: company_Code + "-" + projectdata.companyinvoice,
            companyinvoiceid: company_Invoiceid,
            invoicedate: moment(projectdata.invoicedate).format("DD-MM-YYYY"),
            client: projectdata.client,
            clientCompany: this.state.jsonclientCompany,
            clientname: this.state.jsonclientname,
            emailId: this.state.jsonclientemail,
            Mobilenumber: this.state.jsonclientphoneno,
            amount_received: projectdata.amount_received,
            balancedue: projectdata.balancedue,
            projectid: projectjson[0].id,
            discount: projectdata.discount,
            duedate: projectdata.duedate,
            invoice_amount: projectdata.invoice_amount,
            invoicestatus: projectdata.invoicestatus,
            paymentmode: projectdata.paymentmode,
            referenceno: projectdata.referenceno,
            subtotal: projectdata.subtotal,
            TaskDetails: taskList,
            companyname: projectjson[0].project[0].invoice_company.companyname,
            subclient: projectjson[0].subclient,
          },
          loading: false,
        });
      })
      .catch((error) => {
        this.setState({ loading: false });
      });
  };

  updateJsonSalesObject = (json, data) => {
    this.setState({
      jsonObject: {
        invoiceno: parseInt(company_Code) + 1 + "-" + json[0].deliverynoteid,
        companyinvoiceid: company_Invoiceid,
        invoicedate: moment(data.Date).format("DD-MM-YYYY"),
        client: data.client,
        clientCompany: data.company_name,
        clientname: data.Engineer_name,
        emailId: "",
        Mobilenumber: "",
        amount_received: json[0].amount_received,
        balancedue: json[0].Balancedue,
        discount: json[0].discount,
        duedate: json[0].duedate,
        invoice_amount: json[0].invoice_amount,
        invoicestatus: json[0].invoicestatus,
        paymentmode: "",
        referenceno: "",
        subtotal: json[0].subtotal,
        TaskDetails: json[0].task_invoiceno,
        companyname: data.companyname,
        subclient: "",
      },
      loading: false,
    });
  };

  getProjectDetail = async (project_task) => {
    let charge = 0;

    const dc = await this.getDraftingCharge(project_task.id);
    const pc = await this.getPrintingCharge(project_task.id);

    dc.forEach((x) => {
      charge = charge + parseInt(x.amount);
    });

    const newtask = {
      ID: project_task.id,
      Name: project_task.task,
      DraftingCharge: charge,
      PrintingCharge: pc,
    };

    return newtask;
  };

  getTaskDetail = async (task) => {
    const dc = await this.getDraftingCharge(task.id);

    let charge = 0;
    dc.forEach((x) => {
      charge = charge + parseInt(x.amount);
    });

    const pc = await this.getPrintingCharge(task.id);

    const newtask = {
      ID: task.id,
      Name: task.task,
      DraftingCharge: charge,
      PrintingCharge: pc,
    };

    return newtask;
  };

  getDraftingCharge = (taskID) => {
    return fetch(
      `${authApi}invoicefilter/?tasklistrowid=${taskID}&isdeleted=false`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    ).then((res) => res.json());
  };

  getPrintingCharge = (taskID) => {
    return fetch(`${authApi}stockinvoicefilter/?tasklistrow=${taskID}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  render() {
    if (this.state.loading) {
      return <ConPreLoader />;
    } else {
      return (
        <React.Fragment>
          <Container className="mt--7" fluid>
            <br />
            <br />
            <br />
            <br />
            {this.state.jsonObject === "" ? null : (
              <div className="invoicegenerate" style={{ marginTop: 22 }}>
                <Popup
                  style={{ marginRight: 0 }}
                  modal
                  data-backdrop="static"
                  trigger={
                    <button
                      className="btn btn-dark btn-sm"
                      style={{ width: "135%", height: 40 }}
                    >
                      Print
                    </button>
                  }
                >
                  {(close) => (
                    <InvoiceApp
                      data={this.state.jsonObject}
                      salesorderid={this.state.sales_orderid}
                      closebtn={close}
                    />
                  )}
                </Popup>
              </div>
            )}
            <div className="invoicegenerate">
              <NavLink
                to={{
                  pathname: "/admin/invoice/invoice-management",
                }}
                tag={Link}
              >
                <button
                  className="btn btn-primary btn-sm"
                  style={{ width: "135%", height: 40 }}
                  disabled={this.state.isButtonDisabled}
                >
                  Back
                </button>
              </NavLink>
            </div>
            <br />
            <br />
            <br />
            <Row className="Viewpage">
              <div className=" col">
                <div
                  className="Scrolls-Contents"
                  style={{ height: "100%", backgroundColor: "transparent" }}
                >
                  <Card>
                    {this.state.jsonObject === "" ? null : (
                      <ViewIndividualinvoice
                        individualInvoicedata={this.state.jsonObject}
                        salesorderid={this.state.sales_orderid}
                      />
                    )}
                  </Card>
                </div>
              </div>
            </Row>
          </Container>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(IndividualInvoice);
