import React, { Component } from 'react';
import Header from "../../../../components/Headers/SimpleHeader";
import WorktimeRow from "./WorktimeRow";
import PrintingDetails from './PrintingDetails';
import { Container, Row, Table, NavLink, Card, Col, Form, Input, DropdownMenu, DropdownItem, UncontrolledDropdown, DropdownToggle, } from 'reactstrap';
import "./Worktime.css";
import config from "react-global-configuration";
import '../../../../config';
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import ReportAppDB from "./Daybookform/ReportApp";
import Popup from "reactjs-popup";

let details = 0, s = ["PG CONSTRUCTIONS"];
const authApi = config.get('apiDomain');

class DayWorktime extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      taskid: [],
      printing: [],
      date: "",
      username: this.props.username,
      User: this.props.allusers,
      sdToday: new Date(),
      TotalTime: "00:00",
      alldetails: [],
      id: 0,
      Companyname: "",
      clientfilter: 0,
      startdate: "",
      endate: "",
      dateRangeSt: new Date(),
      dateRangeEd: new Date(),
    }
  }

  componentDidMount() {
    this.defaultUser();
  }

  defaultUser = () => {
    let a_sd = moment(this.state.sdToday).format("YYYY-MM-DD");
    let b_ed = moment(this.state.sdToday).format("YYYY-MM-DD");
    if (this.state.username === "") {
      alert("Please Select Username");
    }
    else {
      const FilterNotCompleted = this.state.items.filter(function (item) {
        return (item.date <= a_sd && item.date <= b_ed);
      });
      this.setState({
        items: FilterNotCompleted,
      })
      fetch(`${authApi}subtaskTimeFilter/?id=&date__gte=${a_sd}&date__lte=${b_ed}&date=&username=${this.state.username}`, {
        method: 'GET',
        headers: {
          'content-type': "application/json",
          'Authorization': `token ${this.props.token}`,
        }
      })
        .then(res => res.json())
        .then(json => {
          if (json.length === 0) {
            alert("No Task available for the Selected Filter.!");
            this.setState({
              TotalTime: "00:00"
            })
          }
          else {
            this.setState({
              items: json,
            }, function () {
              this.HandleTotalTime();
            })
          }
        })
    }
  }

  getdate = (event, picker) => {
    this.setState({
      items: [],
      alldetails: [],
      clientfilter: 0,
      dateRangeSt: picker.startDate,
      dateRangeEd: picker.endDate,
      startdate: picker.startDate.format("DD-MM-YYYY"),
      endate: picker.endDate.format("DD-MM-YYYY"),
      minDate: picker.startDate._d.toISOString(),
      sdToday: picker.endDate._d.toISOString()
    })
    const sd = picker.startDate;
    const ed = picker.endDate;
    let a_sd = moment(sd).format("YYYY-MM-DD");
    let b_ed = moment(ed).format("YYYY-MM-DD");
    if (this.state.username === "") {
      alert("Please Select Username");
    }
    else {
      const FilterNotCompleted = this.state.items.filter(function (item) {
        return (item.date <= a_sd && item.date <= b_ed);
      });
      this.setState({
        items: FilterNotCompleted,
      })
      fetch(`${authApi}subtaskTimeFilter/?id=&date__gte=${a_sd}&date__lte=${b_ed}&date=&username=${this.state.username}`, {
        method: 'GET',
        headers: {
          'content-type': "application/json",
          'Authorization': `token ${this.props.token}`,
        }
      })
        .then(res => res.json())
        .then(json => {
          if (json.length === 0) {
            alert("No Task available for the Selected Filter.!");
            this.setState({
              TotalTime: "00:00"
            })
          }
          else {
            this.setState({
              items: json,
            }, function () {
              this.HandleTotalTime();
            })
          }
        })
    }
  }



  HandleTotalTime() {
    let total = 0, mins = 0, times = 0, stringtime = 0, h = 0, m = 0;
    this.state.items.forEach((d) => {
      stringtime = d.time;
      times = stringtime.split(':');
      mins = times[0] * 60 + +times[1];
      total += mins;
      h = (total / 60 | 0);
      m = total % 60;
      function z(n) { return (n < 10 ? '0' : '') + n; }
      this.setState({
        TotalTime: z(h) + ':' + z(m)
      })
    });
  }

  collectdata = (x) => {
    if (this.state.clientfilter === 0) {
      let data = {
        id: this.state.id + 1,
        date: x.date,
        time: x.time,
        subtask: x.subtask,
        subtaskname: x.subtaskname,
        maintaskname: x.maintaskname,
        clientName: x.clientName,
        username: x.username,
      }
      this.setState({
        alldetails: [...this.state.alldetails, data],
        id: this.state.id + 1,
      }, function () {
        details = this.state.alldetails.map(q => q.clientName);
        s = details.filter((q, idx) => details.indexOf(q) === idx);
      })
    }
  }

  selectTaskClientName = (event) => {
    this.setState({
      Companyname: event.target.value,
      clientfilter: 1,
    }, function () {
      this.FilterTimetasks();
    })
  }

  FilterTimetasks = () => {
    const filterdata = this.state.alldetails;
    const Companyname = this.state.Companyname;
    let Filtercompany = 0;
    if (Companyname === "") {
      Filtercompany = filterdata;
    }
    else {
      Filtercompany = filterdata.filter(function (item) {
        return (item.clientName === Companyname);
      });
    }
    this.setState({
      items: Filtercompany,
    }, function () {
      this.HandleTotalTime();
    })
  }

  taskdetails = (taskid) => {
    this.setState({ taskid: [...this.state.taskid, taskid] });
  }

  render() {
    const ChecklistData = this.state.items.map((item) => (
      <WorktimeRow
        key={item.id}
        id={item.id}
        date={item.date}
        time={item.time}
        username={item.username}
        subtask={item.subtask}
        Storealldata={this.collectdata}
      />
    ))
    const printingData = this.state.items.map((item) => (
      <PrintingDetails
        data={item}
        taskid={this.state.taskid}
        taskdetails={this.taskdetails}
      />
    )
    );
    return (
      <React.Fragment>
        <Header />
        <Container className="mt--7" fluid><br />
          <div className="invoicegenerate" >
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <button className="btn btn-dark btn-sm" style={{ width: '135%', height: 40 }}>
                  <i className="fa fa-download" aria-hidden="true"></i>
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem size="sm">
                  <Popup
                    modal
                    data-backdrop="static"
                    trigger=
                    {
                      <h5 className="text-center">Day-Book Reports</h5>
                    }
                  >
                    {
                      (close) => (
                        <ReportAppDB
                          generateData={this.state.alldetails}
                          totalTime={this.state.TotalTime}
                          dateStart={this.state.dateRangeSt}
                          dateEnd={this.state.dateRangeEd}
                          closebtn={close}
                        />
                      )}
                  </Popup>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <Row style={{ marginTop: 15 }}>
            <ReactTooltip id="backToMyday-Tip" place="right" type="dark" effect="solid">
              Back to My Day
            </ReactTooltip>
            <NavLink
              to="/admin/myday"
              tag={Link}
            >
              <button className="btn btn-primary btn-sm" style={{ width: '135%', height: 40 }} data-tip data-for="backToMyday-Tip"><i className="fa fa-arrow-left"></i></button>
            </NavLink>
          </Row>
          <Row>
            <Col md="4">
              <Form style={{ marginTop: -45, marginLeft: 45 }}>
                <div className="worktimeassign">
                  <Input
                    type="select"
                    className="form-control-alternative"
                    value={this.state.Companyname}
                    onChange={this.selectTaskClientName}
                    style={{
                      height: '35px',
                      width: '80%',
                      overflow: 'hidden',
                      padding: '2px 2px 2px',
                      backgroundColor: 'white',
                      border: 'none',
                    }}
                  >
                    <option value="">Select All</option>
                    {
                      s.map((item, key) =>
                        (<option key={key} value={item}> {item} </option>))
                    }
                  </Input>
                </div>
              </Form>
            </Col>
            <Col md="3" style={{ marginTop: -40, marginLeft: -80 }}>
              <DateRangePicker
                autoUpdateInput={false}
                onApply={this.getdate}
                initialSettings={{
                  locale: {
                    format: 'DD/MM/YYYY'
                  }
                }}
              >
                <button className="form-control-alternative" style={{
                  height: '35px',
                  width: '100%',
                  overflow: 'hidden',
                  padding: '2px 2px 2px',
                  backgroundColor: 'white',
                  border: 'none',
                }}>
                  <i className="fa fa-calendar-o" style={{ paddingRight: 15 }} aria-hidden="true"></i>
                  {moment(this.state.minDate).format("DD/MM/YYYY")} -{" "}{moment(this.state.sdToday).format("DD/MM/YYYY")}
                </button>
              </DateRangePicker>
            </Col>
          </Row>
          <br />
          <Row>
            <div className=" col">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">Date</th>
                      <th className="text-center">ClientName</th>
                      <th className="text-center">Task</th>
                      <th className="text-center">SubTask</th>
                      <th className="text-center">Time </th>
                    </tr>
                  </thead>
                  <tbody>{ChecklistData}</tbody>
                  <tfoot>
                    <tr>
                      <td></td>
                      <td></td>
                      <td></td>
                      <td className="text-info text-center"> Total : </td>
                      <td className="text-info text-center"> {this.state.TotalTime} </td>
                    </tr>
                  </tfoot>
                </Table>
              </Card>
            </div>
          </Row>
          <Row>
            <div className='col'>
              <Card className='shadow'>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">Task</th>
                      <th className="text-center">Printing Details</th>
                    </tr>
                  </thead>
                  <tbody>{printingData}
                  </tbody>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </React.Fragment>


    )
  }
}
const mapStateToProps = state => {
  return {
    token: state.token,
    allusers:state.allusers,
    username:state.username
  }
}
export default connect(mapStateToProps, null)(DayWorktime);