import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceTask from "./InvoiceFolder/InvoiceTaskName";
import InvoiceSales from "./InvoiceFolder/InvoiceSalesName";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 15,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});

const InvoiceTable = ({ manageData, sales_Orderid }) => {
  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader salesorderId={sales_Orderid} />
      {sales_Orderid === "" ? (
        <InvoiceTask task={manageData.TaskDetails} />
      ) : (
        <InvoiceSales task={manageData.TaskDetails} />
      )}
    </View>
  );
};

export default InvoiceTable;
