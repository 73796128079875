import React from "react";
import { Page, Document, StyleSheet, Text, View } from "@react-pdf/renderer";
import InvoiceTableFooter from "./InvoiceTableFooter";
import InvoiceThankYouMsg from "./InvoiceThankYouMsg";
import InvoiceTable from "./InvoiceTable";
import InvoiceTitle from "./InvoiceTitle";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 40,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  pageNumber: {
    position: "absolute",
    fontSize: 12,
    bottom: 16,
    left: 0,
    right: 0,
    textAlign: "center",
    color: "grey",
  },

  titleContainer: {
    marginTop: 0,
    width: 300,
    height: 66,
    marginLeft: "auto",
    marginRight: -25,
    marginBottom: 5,
  },

  reportTitle: {
    marginTop: -20,
    marginRight: -5,
    color: "#11cdef",
    letterSpacing: 1,
    fontSize: 20,
    marginLeft: 23,
    textAlign: "left",
    textTransform: "uppercase",
  },

  titlelocation: {
    marginTop: -60,
    width: 140,
    height: 66,
    marginLeft: "auto",
    marginRight: 15,
    marginBottom: 5,
    fontSize: 12,
    textAlign: "right",
  },
  titlelocation1: {
    marginTop: -55,
    width: 140,
    height: 66,
    marginLeft: "auto",
    marginRight: 15,
    marginBottom: 5,
    fontSize: 12,
    textAlign: "right",
  },

  clientTitle: {
    width: 100,
    height: 66,
    marginRight: "auto",
    marginLeft: -20,
    marginTop: -90,
  },

  invoiceClientContainer: {
    width: 200,
    height: 66,
    marginRight: "auto",
    marginLeft: 0,
    marginTop: -50,
  },
});

const Invoice = ({ GenerateData, sD, eD, OpeningBalance }) => {
  const d = GenerateData.filter((item) => {
    return item.invoicestatus !== "Cancelled";
  });
  const ascendingReport = d.sort((a, b) => a.id - b.id);

  return (
    <Document>
      {GenerateData.length > 0 ? (
        <>
          <Page size="A4" style={styles.page} orientation="landscape">
            <View style={styles.titleContainer}>
              <Text style={styles.reportTitle}>
                {GenerateData[0].companyname}
              </Text>
            </View>

            <Text style={styles.titlelocation}>334, Mettur road,</Text>
            <Text style={styles.titlelocation1}>Erode - 638001.</Text>

            <Text style={styles.clientTitle}> Client : </Text>
            <View style={styles.invoiceClientContainer}>
              <Text style={styles.clientname}>
                {" "}
                {GenerateData[0].clientname}{" "}
              </Text>
              <Text style={styles.clientcompany}>
                {GenerateData[0].clientCompany}
              </Text>
              <Text style={styles.clientemail}>
                {GenerateData[0].Mobilenumber}
              </Text>
              <Text style={styles.clientnumber}>
                {" "}
                {GenerateData[0].emailId}{" "}
              </Text>
            </View>

            <InvoiceTitle stDa={sD} edDa={eD} />

            <InvoiceTable manageData={ascendingReport} ob={OpeningBalance} />

            <InvoiceTableFooter
              itemsTotal={ascendingReport}
            />

            <InvoiceThankYouMsg />

            <Text
              style={styles.pageNumber}
              render={({ pageNumber, totalPages }) =>
                `${pageNumber} / ${totalPages}  ${GenerateData[0].clientname} `
              }
              fixed
            />
          </Page>
        </>
      ) : (
        <>
          <Page size="A4" style={styles.page} orientation="landscape"></Page>
        </>
      )}
    </Document>
  );
};

export default Invoice;
