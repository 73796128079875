import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontStyle: "bold"
  },
  date: {
    width: "10%",
    textAlign: "left",
    paddingLeft: 5,
  },
  task: {
    width: "40%",
    textAlign: "left",
    paddingLeft: 5,
    display:"flex",
    flexDirection:"row", 
    overflowWrap: "break-word"
  },
  client: {
    width: "10%",
    textAlign: "left",
    paddingLeft: 5,
    display:"flex",
    flexDirection:"row",
    overflowWrap: "break-word"
  },
  subtask: {
    width: "10%",
    textAlign: "center",
    paddingLeft: 5,
  },
  starttime: {
    width: "10%",
    textAlign: "left",
    paddingLeft: 5,
  },
  endtime: {
    width: "10%",
    textAlign: "left",
    paddingLeft: 5,
  },
  time: {
    width: "10%",
    textAlign: "left",
    paddingLeft: 20,
  }
});

const ReportTableRow = ({ reportData }) => {

  const reportRow = reportData.map((i) => (
    <View style={styles.row}>
      <Text style={styles.date} > {i.date} </Text>
      <Text style={styles.task}> {i.maintaskname} </Text>
      <Text style={styles.client}> {i.clientName} </Text>
      <Text style={styles.subtask} > {i.subtaskname} </Text>
      <Text style={styles.starttime} >{i.start_time}</Text>
      <Text style={styles.endtime} >{i.endtime}</Text>
      <Text style={styles.time} >{i.time}</Text>
    </View>
  ));

  return (
    <React.Fragment>
      {reportRow}
    </React.Fragment>
  );
};

export default ReportTableRow;
