import React from "react";
import { Input, Row, Col } from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import config from "react-global-configuration";
import consoleFunction from "Helpers";
import "../../../../../config";
import getDateTimeField from "../../../../../Utils";

const authApi = config.get("apiDomain");

class EditLedgergroup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: this.props.EditRowSend.id,
      ledger_groupName: this.props.EditRowSend.ledgergroupname,
      ledger_groupvisibletypeid: "",
      ledger_groupnameState: null,
      ledger_visibletypeChecked: this.props.EditRowSend.isvisible,
    };
  }

  componentDidMount() {
    fetch(
      `${authApi}ledgertypefilter/?ledgertypename=${this.props.EditRowSend.ledgergroupname}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (json.length === 0) {
          this.setState({
            ledger_groupvisibletypeid: "",
          });
        } else {
          this.setState({
            ledger_groupvisibletypeid: json[0].id,
          });
        }
      });
  }

  handleLedgergroupName = (event) => {
    this.setState({
      ledger_groupName: event.target.value,
    });
    if (event.target.value === "") {
      this.setState({
        ledger_groupnameState: "invalid",
      });
    } else {
      this.setState({
        ledger_groupnameState: "valid",
      });
    }
  };

  handleLedgerVisiblecheck = (event) => {
    this.setState({
      ledger_visibletypeChecked: event.target.checked,
    });
  };

  Formvalidation = () => {
    if (this.state.ledger_groupName !== "") {
      this.setState({
        ledger_groupnameState: "valid",
      });
      this.EditLedgergrp();
    } else {
      this.setState({
        ledger_groupnameState: "invalid",
      });
    }
  };

  EditLedgergrp = () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "put",
      url: `${authApi}ledgergroup/${this.state.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        ledgergroupname: this.state.ledger_groupName,
        isvisible: this.state.ledger_visibletypeChecked,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then(async (res) => {
        if (res.status) {
          this.EditLedgergrptype();
        }
      })
      .catch((err) => {
        consoleFunction(
          "ledgergroup name creation error",
          err.response.request.response
        );
        this.setState({ error: err.message });
      });
  };

  EditLedgergrptype = () => {
    let datetime_field = getDateTimeField();
    if (this.state.ledger_groupvisibletypeid !== "") {
      axios({
        method: "put",
        url: `${authApi}ledgertype/${this.state.ledger_groupvisibletypeid}/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          ledgertypename: this.state.ledger_groupName,
          isvisible: this.state.ledger_visibletypeChecked,
        },
      })
        .then((res) => {
          if (res.status) {
            this.props.closebtn();
            this.props.callbackfunction();
          }
        })
        .catch((err) => {
          consoleFunction("ledgergroup name creation error", err.message);
          this.setState({ error: err.message });
        });
    } else {
      axios({
        method: "post",
        url: `${authApi}ledgertype/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          ledgertypename: this.state.ledger_groupName,
          isvisible: this.state.ledger_visibletypeChecked,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          if (res.status) {
            this.props.closebtn();
            this.props.callbackfunction();
          }
        })
        .catch((err) => {
          consoleFunction(
            "ledgergroup name creation error",
            err.response.request.response
          );
          this.setState({ error: err.message });
        });
    }
  };

  render() {
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Add Ledgergroup</div>
        <div className="Scroll-Content">
          <Row style={{ marginBottom: 10 }}>
            <Col md="5">
              <label className="form-control-label">Name</label>
              <Input
                type="text"
                defaultValue={this.state.ledger_groupName}
                valid={this.state.ledger_groupnameState === "valid"}
                invalid={this.state.ledger_groupnameState === "invalid"}
                onChange={this.handleLedgergroupName}
                placeholder="enter ledgergroup name"
              />
              <div className="invalid-feedback">
                Please enter a ledgergroupname.
              </div>
            </Col>
            <Col md="5">
              <label className="form-control-label">Visible in type</label>
              <br />
              <label
                style={{ float: "left", marginLeft: 50, marginTop: 10 }}
                className="custom-toggle custom-toggle-default mr-1"
              >
                <input
                  defaultChecked={this.state.ledger_visibletypeChecked}
                  type="checkbox"
                  onChange={this.handleLedgerVisiblecheck}
                />
                <span
                  className="custom-toggle-slider rounded-circle"
                  data-label-off="No"
                  data-label-on="Yes"
                />
              </label>
            </Col>
            <Col md="2">
              <div className="button">
                <button
                  id="buttonsave"
                  className="btn btn-dark"
                  style={{ float: "right", marginRight: 15 }}
                  onClick={this.Formvalidation}
                >
                  Add
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(EditLedgergroup);
