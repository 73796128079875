import React from "react";
import { connect } from "react-redux";
import axios from "axios";
import Header from "../../../../components/Headers/SimpleHeader";
import config from "react-global-configuration";
import "../../../../config";
import {
  Container,
  Card,
  Row,
  Col,
  Input,
  DropdownItem,
  Table,
  CardHeader,
} from "reactstrap";
import CancelEdit from "./CancelEdit";
import Popup from "reactjs-popup";
import PurchaseStocks from "./PurchaseStocks";
import moment from "moment";
import RequestedStock from "./RequestedStock";
let StoreprocData = [];
let Totalstockdata = [];
const authApi = config.get("apiDomain");
let Stockid = 1,
  checkgst = true;
class AddPurchase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Clientdetails: this.props.clientdetails,
      SupplierName: "",
      Supplierid: "",
      Reference: "",
      Date: moment(new Date()).format("YYYY-MM-DD"),
      CompanyDetails: [],
      Company: "",
      Companyid: "",
      gstcheck: true,
      Status: "Draft",
      Expecteddeliverydate: moment(new Date())
        .add(7, "days")
        .format("YYYY-MM-DD"),
      Stockdetails: [],
      Stockname: [],
      TotalTaxableValue: 0,
      TotalCGSTAmount: 0,
      TotalSGSTAmount: 0,
      TotalAmount: 0,
      TotalQuantity: 0,
      Adjustamount: "0.00",
      GrandTotalAmount: 0,
      ValidCompany: "none",
      ValidSupplierName: "none",
      ValidAdjustamount: "none",
      nullvalueisthere: false,
      showRequestpage: false,
      Gsttype: "SGST",
      AvaibleStockDetails: [],
      updateSalesQty: false,
    };
  }

  async componentDidMount() {
    let stocknamedetails = await this.getTotalStockdetails();
    this.getAvailableStockdetails();
    Totalstockdata = stocknamedetails;
    this.setState({
      Stockname: stocknamedetails,
    });
    let stock = {
      id: Stockid,
      itemname: ``,
      productcode: "",
      quantity: 0,
      rate: "",
      amount: "",
      CGSTdiscount: 0,
      SGSTdiscount: 0,
      CGSTdiscountpercentage: "0%",
      SGSTdiscountpercentage: "0%",
      totalamount: 0,
      type: "",
    };
    this.setState({
      Stockdetails: [...this.state.Stockdetails, stock],
    });
    await this.getCompanydetails();
    let addStock = {
      id: 9999,
      itemname: "Total",
      productcode: "",
      quantity: "",
      rate: "",
      amount: "",
      CGSTdiscount: "",
      SGSTdiscount: "",
      CGSTdiscountpercentage: "",
      SGSTdiscountpercentage: "",
      totalamount: "",
      type: "",
    };
    this.setState({
      Stockdetails: [...this.state.Stockdetails, addStock],
    });
  }

  getAvailableStockdetails = () => {
    axios({
      method: "post",
      url: `${authApi}getavailableqtytemplate/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => {
        this.setState({
          AvaibleStockDetails: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  getTotalStockdetails = async () => {
    let response = await fetch(`${authApi}stocks/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    return response;
  };

  getCompanydetails = () => {
    fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          CompanyDetails: json,
        });
      });
  };

  selectClient = (event) => {
    if (event.target.value === "Select Supplier") {
      this.setState({
        SupplierName: "",
      });
    } else {
      this.setState(
        {
          SupplierName: event.target.value,
        },
        function () {
          this.getClientid(event.target.value);
        }
      );
    }
  };

  getClientid = (clientname) => {
    this.state.Clientdetails.forEach((client) => {
      if (client.company_name === clientname) {
        this.setState({
          Supplierid: client.id,
        });
      }
    });
  };

  getReference = (event) => {
    this.setState({
      Reference: event.target.value,
    });
  };
  changeDeliverydate = (event) => {
    this.setState({
      Expecteddeliverydate: event.target.value,
    });
  };
  changeDate = (event) => {
    console.log(event.target.value);
    this.setState({
      Date: event.target.value,
    });
  };

  selectCompany = (event) => {
    if (event.target.value === "Select Company") {
      this.setState({
        Company: "",
      });
    } else {
      this.state.CompanyDetails.forEach((company) => {
        if (company.companyname === event.target.value) {
          this.setState(
            {
              Company: event.target.value,
              Companyid: company.id,
              gstcheck: company.isgst,
              Stockdetails:
                checkgst === company.isgst
                  ? this.state.Stockdetails
                  : this.state.Stockdetails.filter(
                      (item) => item.itemname === "Total"
                    ),
            },
            function () {
              if (checkgst !== company.isgst) {
                checkgst = company.isgst;
                this.updategstcheck();
              }
            }
          );
        }
      });
    }
  };

  updategstcheck = () => {
    Stockid = 1;
    let stockdata = {
      id: Stockid,
      itemname: ``,
      productcode: "",
      quantity: 0,
      rate: "",
      amount: "",
      CGSTdiscount: 0,
      SGSTdiscount: 0,
      CGSTdiscountpercentage: "0%",
      SGSTdiscountpercentage: "0%",
      totalamount: 0,
      type: "",
    };
    this.setState(
      {
        Stockdetails: [...this.state.Stockdetails, stockdata],
      },
      function () {
        this.calculateTotalAmount();
      }
    );
  };
  changeStatus = (event) => {
    this.setState({
      Status: event.target.value,
    });
  };

  addAnotherStock = () => {
    Stockid = Stockid + 1;
    let Totalstock = this.state.Stockdetails;
    let stock = {
      id: Stockid,
      itemname: "",
      productcode: "",
      quantity: 0,
      rate: "",
      amount: "",
      CGSTdiscount: 0,
      SGSTdiscount: 0,
      CGSTdiscountpercentage: "0%",
      SGSTdiscountpercentage: "0%",
      totalamount: 0,
      type: "",
    };
    Totalstock = [...Totalstock, stock];
    this.setState({
      Stockdetails: [],
    });
    Totalstock = Totalstock.sort((a, b) => (a.id > b.id ? 1 : -1));
    this.setState({
      Stockdetails: Totalstock,
    });
  };

  removeStockDetails = (id) => {
    let remainingdata = this.state.Stockdetails.filter(
      (item) => item.id !== id
    );
    this.setState(
      {
        Stockdetails: remainingdata,
      },
      function () {
        this.calculateTotalAmount();
      }
    );
  };

  updateStockDetails = (
    id,
    stockname,
    productcode,
    quantity,
    rate,
    amount,
    cgstdiscount,
    sgstdiscount,
    cgstdiscountpercentage,
    sgstdiscountpercentage,
    totalamount
  ) => {
    this.state.Stockdetails.forEach((stock) => {
      if (stock.id === id) {
        stock.itemname = stockname;
        stock.productcode = productcode;
        stock.quantity = quantity;
        stock.rate = rate;
        stock.amount = amount;
        stock.CGSTdiscount = cgstdiscount;
        stock.SGSTdiscount = sgstdiscount;
        stock.CGSTdiscountpercentage =
          this.state.gstcheck === false
            ? "0%"
            : parseInt(cgstdiscountpercentage) || 0;
        stock.SGSTdiscountpercentage =
          this.state.gstcheck === false
            ? "0%"
            : parseInt(sgstdiscountpercentage) || 0;
        stock.totalamount = totalamount;
      }
    });
    this.calculateTotalAmount();
  };

  calculateTotalAmount = () => {
    let taxableamount = 0.0,
      totalcgstdiscount = 0.0,
      totalsgstdiscount = 0.0,
      granttotalamount = 0.0,
      totalquantity = 0.0;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.id !== 9999) {
        let CheckAmount = parseFloat(stock.amount) || 0;
        let CheckCgstdiscount = parseFloat(stock.CGSTdiscount) || 0;
        let CheckSgstdiscount = parseFloat(stock.SGSTdiscount) || 0;
        let CheckGrandtotal = parseFloat(stock.totalamount) || 0;
        let CheckQuantity = parseFloat(stock.quantity) || 0;
        taxableamount = parseFloat(taxableamount) + CheckAmount;
        totalcgstdiscount = parseFloat(totalcgstdiscount) + CheckCgstdiscount;
        totalsgstdiscount = parseFloat(totalsgstdiscount) + CheckSgstdiscount;
        granttotalamount = parseFloat(granttotalamount) + CheckGrandtotal;
        totalquantity = parseFloat(totalquantity) + CheckQuantity;
      }
    });

    this.state.Stockdetails.forEach((stock) => {
      if (stock.id === 9999) {
        stock.amount = taxableamount.toFixed(2);
        stock.CGSTdiscount = totalcgstdiscount.toFixed(2);
        stock.SGSTdiscount = totalsgstdiscount.toFixed(2);
        stock.totalamount = granttotalamount.toFixed(2);
        stock.quantity = totalquantity.toFixed(2);
        this.setState({
          TotalTaxableValue: taxableamount.toFixed(2),
          TotalCGSTAmount: totalcgstdiscount.toFixed(2),
          TotalSGSTAmount: totalsgstdiscount.toFixed(2),
          TotalAmount: granttotalamount.toFixed(2),
          GrandTotalAmount: granttotalamount.toFixed(2),
          TotalQuantity: totalquantity.toFixed(2),
        });
      }
    });
  };

  adjustAmount = (event) => {
    let total = parseFloat(this.state.TotalAmount) || 0;
    let totalamount = total - parseFloat(event.target.value);
    let totalwithtofixed = totalamount.toFixed(2);
    this.setState({
      GrandTotalAmount: totalwithtofixed,
      Adjustamount: event.target.value,
    });
  };

  isvalidEntryInForm = async () => {
    let isvalid = false,
      count = 0,
      ratecount = 0;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.itemname !== "Total") {
        if (parseInt(stock.quantity) === 0) {
          count = count + 1;
        }
        if (parseInt(stock.rate) === 0) {
          ratecount = ratecount + 1;
        }
      }
    });
    ratecount =
      this.state.SupplierName === "Manuel Stock Entry" ? 0 : ratecount;
    if (count < 1 && ratecount < 1) {
      this.setState({
        updateSalesQty: false,
      });
      if (this.state.Company !== "") {
        if (this.state.SupplierName !== "") {
          if (
            this.state.Adjustamount !== "" &&
            this.state.Adjustamount !== null &&
            this.state.Adjustamount !== "."
          ) {
            if (this.state.nullvalueisthere === false) {
              let remainingdata = this.state.Stockdetails.filter(
                (item) => item.itemname !== ""
              );
              this.setState(
                {
                  ValidCompany: "none",
                  ValidSupplierName: "none",
                  Stockdetails: remainingdata,
                  ValidAdjustamount: "none",
                },
                function () {
                  this.stockDataPreparationForStock();
                }
              );
              isvalid = true;
            } else {
              alert("Please specify value for the required fields");
            }
          } else {
            this.setState({
              ValidCompany: "none",
              ValidSupplierName: "none",
              ValidAdjustamount: "1px solid red",
            });
          }
        } else {
          this.setState({
            ValidCompany: "none",
            ValidSupplierName: "1px solid red",
          });
        }
      } else {
        this.setState({
          ValidCompany: "1px solid red",
          ValidSupplierName: "none",
        });
      }
    } else {
      this.setState({
        updateSalesQty: true,
      });
    }
    return isvalid;
  };

  onSubmitForm = async (createStatus) => {
    if (await this.isvalidEntryInForm()) {
      let status = createStatus === "Submit and Confirm" ? "Issued" : "Draft";
      axios({
        method: "post",
        url: `${authApi}sp_purchaseorder/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: this.state.Date,
          companyid: this.state.Companyid,
          total: this.state.GrandTotalAmount,
          supplier: this.state.Supplierid,
          payment: "Cash",
          status: status,
          isdeleted: false,
          reference: this.state.Reference,
          roundoff: this.state.Adjustamount,
          usergsttype: this.state.Gsttype,
          stockdetails: StoreprocData,
        },
      })
        .then((res) => {
          console.log(res.status);
          StoreprocData = [];
          if (res.status === 201) {
            StoreprocData = [];
            this.props.history.push("/admin/management/purchase-order");
          }
        })
        .catch((err) => {
          console.log(err);
          StoreprocData = [];

          alert("error purchase order", err.response.request.response);
        });
    }
  };

  stockDataPreparationForStock = () => {
    this.state.Stockdetails.forEach((stockdetails) => {
      let count = 0,
        cgstcount = 0;
      count = (stockdetails.SGSTdiscountpercentage.match(/%/g) || []).length;
      cgstcount = (stockdetails.CGSTdiscountpercentage.match(/%/g) || [])
        .length;
      if (count > 0) {
        stockdetails.SGSTdiscountpercentage =
          stockdetails.SGSTdiscountpercentage.substring(
            0,
            stockdetails.SGSTdiscountpercentage.length - 1
          );
      }
      if (cgstcount > 0) {
        stockdetails.CGSTdiscountpercentage =
          stockdetails.CGSTdiscountpercentage.substring(
            0,
            stockdetails.CGSTdiscountpercentage.length - 1
          );
      }
    });
    console.log(this.state.Stockdetails);
    this.state.Stockdetails.forEach((stockdetails) => {
      let newstockdetails = {
        name: stockdetails.itemname,
        productcode: stockdetails.productcode,
        quantity: stockdetails.quantity,
        rate: stockdetails.rate,
        amount: stockdetails.amount,
        cgstdiscount: stockdetails.CGSTdiscount,
        sgstdiscount: stockdetails.SGSTdiscount,
        cgstdiscountpercentage: stockdetails.CGSTdiscountpercentage,
        sgstdiscountpercentage: stockdetails.SGSTdiscountpercentage,
        type: stockdetails.type,
        total: stockdetails.totalamount,
        requestid: stockdetails.id,
      };
      StoreprocData = [...StoreprocData, newstockdetails];
    });
  };

  CancelEdit = () => {
    this.props.history.push("/admin/management/purchase-order");
  };

  checkanynullvalue = (check) => {
    this.setState({
      nullvalueisthere: check,
    });
  };

  changePanelStockRequest = () => {
    this.setState({
      showRequestpage: true,
    });
  };

  backToPurchase = () => {
    this.setState({
      showRequestpage: false,
    });
  };

  addPurchasestockdetails = (requestdata) => {
    let Totalstock = this.state.Stockdetails,
      alreadythere = false;
    let filterstockdetails = Totalstockdata.filter(
      (item) => item.stockname === requestdata.stockname
    );
    Totalstock.forEach((stock) => {
      if (stock.itemname === requestdata.stockname) {
        stock.quantity =
          parseInt(stock.quantity) + parseInt(requestdata.quantity);
        alreadythere = true;
      }
    });
    if (alreadythere === false) {
      let stock = {
        id: requestdata.id,
        itemname: requestdata.stockname,
        productcode: requestdata.productcode,
        quantity: requestdata.quantity,
        rate: 0,
        amount: 0,
        CGSTdiscount: 0,
        SGSTdiscount: 0,
        CGSTdiscountpercentage: filterstockdetails[0].cgstpercentage,
        SGSTdiscountpercentage: filterstockdetails[0].sgstpercentage,
        totalamount: 0,
        type: "Requested",
      };
      Totalstock = [...Totalstock, stock];
      this.setState({
        Stockdetails: [],
      });

      Totalstock = Totalstock.sort((a, b) => (a.id > b.id ? 1 : -1));
      this.setState({
        Stockdetails: Totalstock.filter((item) => item.itemname !== ""),
      });
      let max = 0;
      Totalstock.forEach((stock) => {
        if (stock.itemname !== "Total") {
          if (stock.id > max) {
            max = stock.id;
          }
        }
      });
      Stockid = max + 1;
    }
    this.calculateTotalAmount();
  };

  changeGst = (event) => {
    this.setState({
      Gsttype: event.target.value,
    });
  };

  keyEventOperation = (event) => {
    if (!event.shiftKey && event.key === "Enter") {
      let index = [...event.target.form].indexOf(event.target);

      if (event.target.form.elements[index + 1] !== undefined && index <= 6) {
        event.target.form.elements[index + 1].focus();
        event.preventDefault();
      }
    } else {
      if (event.shiftKey && event.key === "Enter") {
        let index = [...event.target.form].indexOf(event.target);

        if (event.target.form.elements[index - 1] !== undefined && index <= 6) {
          event.target.form.elements[index - 1].focus();
          event.preventDefault();
        }
      }
    }
  };

  render() {
    let stockdetailsdata = this.state.Stockdetails.sort((a, b) =>
      a.id > b.id ? 1 : -1
    );
    const stockdata = stockdetailsdata.map((items) => (
      // const stockdata = this.state.Stockdetails.map((items) => (
      <PurchaseStocks
        key={items.id}
        stock={items}
        gstCheck={this.state.gstcheck}
        companyId={this.state.Company}
        taxableamount={this.state.TotalTaxableValue}
        totalcgstdiscount={this.state.TotalCGSTAmount}
        totalsgstdiscount={this.state.TotalSGSTAmount}
        granttotalamount={this.state.TotalAmount}
        totalquantity={this.state.TotalQuantity}
        removestockdetails={this.removeStockDetails}
        stockname={this.state.Stockname}
        updatestock={this.updateStockDetails}
        addstock={this.addAnotherStock}
        checkifanynull={this.checkanynullvalue}
        nullvalueisthere={this.state.nullvalueisthere}
        totalstockdetails={this.state.Stockdetails}
        availablestockqty={this.state.AvaibleStockDetails}
      />
    ));
    let totalclientname = this.state.Clientdetails.map((q) => q.company_name);
    let clientname = totalclientname.filter(
      (q, idx) => totalclientname.indexOf(q) === idx
    );
    let totalcompany = this.state.CompanyDetails.map((q) => q.companyname);
    let companyorder = totalcompany.filter(
      (q, idx) => totalcompany.indexOf(q) === idx
    );
    return (
      <React.Fragment>
        <Header />
        <Container className="mt--7" fluid>
          <br />
          <form>
            {this.state.showRequestpage === false ? (
              <Row>
                <Col md="11">
                  <CardHeader
                    style={{
                      backgroundColor: "transparent",
                      padding: 10,
                      marginTop: 20,
                    }}
                  >
                    <h3 className="mb-0">
                      <i
                        style={{ marginRight: 5 }}
                        class="fas fa-cart-plus"
                      ></i>{" "}
                      New Purchase Order
                    </h3>
                  </CardHeader>
                </Col>
                <Col md="1">
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ marginTop: 25, width: 45, height: 40 }}
                    data-tip
                    data-for="completed-Tip"
                    onClick={this.changePanelStockRequest}
                  >
                    <i className="fas fa-plus-circle fa-1x"></i>
                  </button>
                </Col>
              </Row>
            ) : null}
            {this.state.showRequestpage === false ? <br /> : null}
            {this.state.showRequestpage === false ? (
              <Row>
                <Col md="1">
                  <label
                    className="form-control-label"
                    style={{ marginTop: 12 }}
                  >
                    Company
                  </label>
                </Col>
                <Col md="3">
                  <Input
                    type="select"
                    value={this.state.Company}
                    style={{ border: this.state.ValidCompany }}
                    onChange={this.selectCompany}
                    onKeyDown={this.keyEventOperation}
                  >
                    <option value="Select Company">Select Company</option>
                    {companyorder.map((item, key) => (
                      <option key={key} value={item}>
                        {item}
                      </option>
                    ))}
                  </Input>
                  {this.state.ValidCompany !== "none" ? (
                    <h6 style={{ color: "red" }}>Please Select Company</h6>
                  ) : null}
                </Col>
                <Col md="1">
                  <label
                    className="form-control-label"
                    style={{ marginTop: 12 }}
                  >
                    SupplierName
                  </label>
                </Col>
                <Col md="3">
                  <Input
                    type="select"
                    value={this.state.SupplierName}
                    style={{ border: this.state.ValidSupplierName }}
                    onChange={this.selectClient}
                    onKeyDown={this.keyEventOperation}
                  >
                    <option value="Select Supplier">Select Supplier</option>
                    {clientname
                      .sort((a, b) => a.localeCompare(b))
                      .map((item, key) => (
                        <option key={key} value={item}>
                          {item}
                        </option>
                      ))}
                  </Input>
                  {this.state.ValidSupplierName !== "none" ? (
                    <h6 style={{ color: "red" }}>Please Select SupplierName</h6>
                  ) : null}
                </Col>
                <Col md="1">
                  <label
                    className="form-control-label"
                    style={{ marginTop: 12 }}
                  >
                    Date
                  </label>
                </Col>
                <Col md="2">
                  <Input
                    className="form-control-alternative"
                    type="date"
                    Value={this.state.Date}
                    onChange={this.changeDate}
                    onKeyDown={this.keyEventOperation}
                  />
                </Col>
              </Row>
            ) : null}
            {this.state.showRequestpage === false ? <br /> : null}
            {this.state.showRequestpage === false ? (
              <Row>
                <Col md="1">
                  <label
                    className="form-control-label"
                    style={{ marginTop: 12 }}
                  >
                    Reference
                  </label>
                </Col>
                <Col md="3">
                  <Input
                    type="text"
                    value={this.state.Reference}
                    onChange={this.getReference}
                    onKeyDown={this.keyEventOperation}
                  />
                </Col>
                <Col md="1">
                  <label
                    className="form-control-label"
                    style={{ marginTop: 12 }}
                  >
                    SGST Type
                  </label>
                </Col>
                <Col md="3">
                  <Input
                    type="select"
                    value={this.state.Gsttype}
                    onChange={this.changeGst}
                    onKeyDown={this.keyEventOperation}
                  >
                    <option>SGST</option>
                    <option>IGST</option>
                  </Input>
                </Col>
                <Col md="2">
                  <label
                    className="form-control-label"
                    style={{ marginTop: 12 }}
                  >
                    Delivery Date
                  </label>
                </Col>
                <Col md="2" style={{ marginLeft: -110 }}>
                  <Input
                    className="form-control-alternative"
                    type="date"
                    defaultValue={this.state.Expecteddeliverydate}
                    onChange={this.changeDeliverydate}
                    onKeyDown={this.keyEventOperation}
                  />
                </Col>
              </Row>
            ) : null}
            {this.state.showRequestpage === false ? <br /> : null}
            {this.state.showRequestpage === false ? (
              <Row>
                <div className=" col">
                  <br />
                  <Card className="shadow">
                    <Table
                      className="align-items-center table-flush"
                      responsive-sm
                    >
                      {" "}
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center">ACTION</th>
                          <th className="text-center">GOODS DESCRIPTION</th>
                          <th className="text-center">PRO.CODE</th>
                          <th className="text-center">AVI.QTY</th>
                          <th className="text-center">QUANTITY</th>
                          <th className="text-center">RATE</th>
                          {this.state.gstcheck === true ? (
                            <th className="text-center">TAXABLE AMOUNT</th>
                          ) : null}
                          {this.state.gstcheck === true ? (
                            <th className="text-center">CGST</th>
                          ) : null}
                          {this.state.gstcheck === true ? (
                            <th className="text-center">
                              {this.state.Gsttype}{" "}
                            </th>
                          ) : null}
                          <th className="text-center">TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>{stockdata}</tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
            ) : null}
            {this.state.showRequestpage === false ? (
              <Row>
                <Col md="8"></Col>
                <Col md="2">
                  <ul
                    class="list-group list-group-flush"
                    style={{ paddingLeft: 90, width: 290 }}
                  >
                    {this.state.gstcheck === true ? (
                      <li class="list-group-item">TaxableValue</li>
                    ) : null}
                    {this.state.gstcheck === true ? (
                      <li class="list-group-item">TotalCGSTAmount</li>
                    ) : null}
                    {this.state.gstcheck === true ? (
                      <li class="list-group-item">TotalSGSTAmount</li>
                    ) : null}
                    <li class="list-group-item">Amount</li>
                    <li class="list-group-item" style={{ height: 62 }}>
                      Adjustment
                    </li>
                    <li class="list-group-item">GrandTotalAmount</li>
                  </ul>
                </Col>
                <Col md="2">
                  <ul
                    class="list-group list-group-flush align-center"
                    style={{ paddingLeft: 60 }}
                  >
                    {this.state.gstcheck === true ? (
                      <li class="list-group-item">
                        {this.state.TotalTaxableValue}
                      </li>
                    ) : null}
                    {this.state.gstcheck === true ? (
                      <li class="list-group-item">
                        {this.state.TotalCGSTAmount}
                      </li>
                    ) : null}
                    {this.state.gstcheck === true ? (
                      <li class="list-group-item">
                        {this.state.TotalSGSTAmount}
                      </li>
                    ) : null}
                    {this.state.gstcheck === true ? (
                      <li class="list-group-item">{this.state.TotalAmount}</li>
                    ) : (
                      <li class="list-group-item">
                        {this.state.TotalTaxableValue}
                      </li>
                    )}
                    <li class="list-group-item">
                      <Input
                        type="text"
                        style={{
                          border: this.state.ValidAdjustamount,
                          textAlign: "left",
                          position: "relative",
                          display: "block",
                          top: 0,
                          fontSize: "15px",
                          margin: "0",
                          left: -12,
                          height: 33,
                          width: "110%",
                        }}
                        Value={this.state.Adjustamount}
                        onChange={this.adjustAmount}
                      />
                    </li>
                    {this.state.gstcheck === true ? (
                      <li class="list-group-item">
                        {this.state.GrandTotalAmount}
                      </li>
                    ) : (
                      <li class="list-group-item">
                        {parseFloat(
                          parseFloat(this.state.TotalTaxableValue) -
                            parseFloat(this.state.Adjustamount)
                        ).toFixed(2)}
                      </li>
                    )}
                  </ul>
                </Col>
              </Row>
            ) : null}
            {this.state.nullvalueisthere === true ? (
              <Row>
                <Col md="5"></Col>
                <Col>
                  <h3 style={{ color: "red" }}>
                    Please fill the required fields marked in red
                  </h3>
                </Col>
              </Row>
            ) : null}
            {this.state.updateSalesQty === true ? (
              <Row>
                <Col md="5"></Col>
                <Col>
                  <h3 style={{ color: "red" }}>
                    Please check Received Quantity or Rate
                  </h3>
                </Col>
              </Row>
            ) : null}
            {this.state.showRequestpage === false ? (
              <Row>
                <Col md="2">
                  <DropdownItem size="sm">
                    <Popup
                      modal
                      data-backdrop="static"
                      trigger={
                        <button
                          id="buttonsave"
                          className="btn btn-dark btn-sm"
                          style={{ width: 100, height: 40, paddingTop: 5 }}
                        >
                          Cancel Edit
                        </button>
                      }
                    >
                      {(close) => (
                        <CancelEdit
                          clearpurchase={this.CancelEdit}
                          Editdata={true}
                          purchaseorder={false}
                          closebtn={close}
                        />
                      )}
                    </Popup>
                  </DropdownItem>
                </Col>
                <Col md="6"></Col>
                <Col md="2">
                  <button
                    id="buttonsave"
                    className="btn btn-dark btn-sm"
                    style={{
                      width: 100,
                      height: 40,
                      float: "right",
                      right: -65,
                    }}
                    onClick={() => this.onSubmitForm("Save as Draft")}
                  >
                    Save as Draft
                  </button>
                </Col>
                <Col md="2">
                  <button
                    id="buttonsave"
                    className="btn btn-dark btn-sm"
                    style={{ width: 100, height: 40, float: "right" }}
                    onClick={() => this.onSubmitForm("Submit and Confirm")}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            ) : null}
          </form>
          {this.state.showRequestpage === true ? (
            <>
              <RequestedStock
                Requestedstocks={this.state.Stockdetails.filter(
                  (item) => item.type === "Requested"
                )}
                backtoAddpurchase={this.backToPurchase}
                purchasestockdetails={this.addPurchasestockdetails}
              />
            </>
          ) : null}
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails: state.clientdetails,
  };
};
export default connect(mapStateToProps, null)(AddPurchase);
