import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import config from "react-global-configuration";
import TableContainerExpand from "../../../Filters/TableContainerExpand";
import "../../../../../config";
import { Container, Input } from "reactstrap";
import Header from "../../../../../components/Headers/SimpleHeader";
import { Row, Form, FormGroup, InputGroup } from "reactstrap";
import axios from "axios";
import { CurrentLocation } from "../../../../../components/loginRedux/loginReducers";
import store from "../../../../../components/loginRedux/Store";
import getDateTimeField from "../../../../../Utils";

const authApi = config.get("apiDomain");
let requesteddata = [],
  totalcompanies = [],
  totalclient = [],
  supplierid = 0,
  companyid = 0,
  RequestedTotalData = [],
  ParticularUserDataOnly = [],
  StoreprocData = [];
function PurchaseRequest(props) {
  const [Requestdata, getRequestData] = useState([]);
  const [userdata, getuserdataData] = useState([]);
  const [companyname, getcompanyname] = useState([]);
  const [username, getusername] = useState([]);
  const [statusdetails, getstatusdetails] = useState([]);
  let [selectedStatus, userselectedStatus] = useState("");
  let [selectedusername, userselectUsername] = useState("");
  const [clientdetails, updateclientdetails] = useState([]);
  const [suppliername, getSupplierName] = useState("");
  const [purchaeordercompanyname, getpurchaseordecompanyname] = useState("");
  const [selectdata, setseleted] = useState(false);
  const [suppliernameempty, setSupplierNameEmpty] = useState(false);
  const [companynameempty, setCompanyNameEmpty] = useState(false);

  useEffect(
    () => {
      const purchaserequestdata = async () => {
        store.dispatch({
          type: CurrentLocation,
          data: props.location.pathname,
        });
        updateclientdetails(props.clientdetails);
        let response = await fetch(
          `${authApi}purchaserequestfilter/?isdeleted=false`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        ).then((res) => res.json());
        await getUserDetais(response.reverse());
      };
      purchaserequestdata();
      getcompany();
      getClientDetails();
    },
    // eslint-disable-next-line
    []
  );

  const getcompany = async () => {
    fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        totalcompanies = json;
        let totalcompany = json.map((q) => q.companyname);
        let companyorder = totalcompany.filter(
          (q, idx) => totalcompany.indexOf(q) === idx
        );
        getcompanyname(companyorder);
      });
  };

  const getClientDetails = () => {
    let totalclientname = props.clientdetails.map((q) => q.company_name);
    let clientname = totalclientname.filter(
      (q, idx) => totalclientname.indexOf(q) === idx
    );
    updateclientdetails(clientname);
  };

  const getUserDetais = async (requestdetails) => {
    const response = await fetch(`${authApi}userfilter/?isapproved=1`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
    }).then((res) => res.json());
    requestdetails.forEach((request) => {
      response.forEach((user) => {
        if (user.id === parseInt(request.username)) {
          request.username = user.name;
        }
      });
    });
    getuserdataData(response);
    console.log(userdata);
    let totalusernames = requestdetails.map((q) => q.username);
    totalusernames = [...totalusernames, "All Name"];
    let users = totalusernames.filter(
      (q, idx) => totalusernames.indexOf(q) === idx
    );
    let totalstatus = requestdetails.map((q) => q.status);
    totalstatus = [...totalstatus, "All Status"];
    let Status = totalstatus.filter((q, idx) => totalstatus.indexOf(q) === idx);
    getusername(users.reverse());
    getstatusdetails(Status.reverse());
    let Requesteddata = requestdetails.filter(
      (data) => data.status === "Requested"
    );
    getRequestData(
      Requesteddata.sort((a, b) => a.stockname.localeCompare(b.stockname))
    );
    userselectedStatus("Requested");
    RequestedTotalData = requestdetails;
    ParticularUserDataOnly = requestdetails;
  };

  const onChangeChaeckbox = (requested) => {
    let alreadyplaced = false;
    if (requesteddata.length !== 0) {
      setseleted(true);
      requesteddata.forEach((request) => {
        if (request.id === requested.id) {
          alreadyplaced = true;
          let remainingrequest = requesteddata.filter(function (item) {
            return item.id !== requested.id;
          });
          requesteddata = remainingrequest;
        }
      });
    } else {
      setseleted(true);
      alreadyplaced = true;
      requesteddata = [...requesteddata, requested];
    }
    if (alreadyplaced === false) {
      let stocknamealreadythere = false;
      requesteddata.forEach((request) => {
        if (request.stockname === requested.stockname) {
          stocknamealreadythere = true;
          request.quantity =
            parseInt(request.quantity) + parseInt(requested.quantity);
        }
      });
      if (stocknamealreadythere === false) {
        requesteddata = [...requesteddata, requested];
      }
    }
  };

  const updateSupplierName = (event) => {
    getSupplierName(event.target.value);
    totalclient.forEach((supplier) => {
      if (event.target.value === supplier.company_name) {
        supplierid = supplier.id;
      }
    });
  };

  const updateCompanyName = (event) => {
    getpurchaseordecompanyname(event.target.value);
    totalcompanies.forEach((company) => {
      if (event.target.value === company.companyname) {
        companyid = company.id;
      }
    });
  };

  function createPurchaseOrderWithPurchaseRequeted() {
    if (purchaeordercompanyname !== "") {
      if (suppliername !== "") {
        datapreparation();
      } else {
        setSupplierNameEmpty(true);
        setCompanyNameEmpty(false);
      }
    } else {
      setCompanyNameEmpty(true);
      setSupplierNameEmpty(false);
    }
  }

  function datapreparation() {
    requesteddata.forEach((requestdetails) => {
      let newrequest = {
        requestedid: requestdetails.id,
        stockname: requestdetails.stockname,
        productcode: requestdetails.productcode,
        quantity: requestdetails.quantity,
      };
      StoreprocData = [...StoreprocData, newrequest];
    });

    axios({
      method: "post",
      url: `${authApi}sp_createpowithuserrequest/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
      data: {
        companyid: companyid,
        supplier: supplierid,
        stockdetails: StoreprocData,
      },
    })
      .then((res) => {
        StoreprocData = [];
        requesteddata = [];
        getpurchaseordecompanyname("");
        getSupplierName("");
        setCompanyNameEmpty(false);
        setSupplierNameEmpty(false);
        getRequestData([]);
        callbackfunction();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  const callbackfunction = async () => {
    requesteddata = [];
    userselectUsername("All Name");
    userselectedStatus("All Status");
    let response = await fetch(
      `${authApi}purchaserequestfilter/?isdeleted=false`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${props.token}`,
        },
      }
    ).then((res) => res.json());
    await getUserDetais(response.reverse());
  };

  const selectUserName = (event) => {
    ParticularUserDataOnly = [];
    userselectUsername(event.target.value);
    userselectedStatus("All Status");
    const rowdata = RequestedTotalData.filter(function (item) {
      return event.target.value === item.username;
    });
    if (event.target.value === "All Name") {
      getRequestData(RequestedTotalData);
      ParticularUserDataOnly = RequestedTotalData;
    } else {
      getRequestData(rowdata);
      ParticularUserDataOnly = rowdata;
    }
  };

  const selectStatus = (event) => {
    userselectedStatus(event.target.value);
    const rowdata = ParticularUserDataOnly.filter(function (item) {
      return event.target.value === item.status;
    });
    if (event.target.value === "All Status") {
      getRequestData(ParticularUserDataOnly);
    } else {
      getRequestData(rowdata);
    }
  };

  const deletePurchaseRequest = async (rowdata) => {
    let datetime_field = getDateTimeField();
    await axios({
      method: "put",
      url: `${authApi}purchaserequest/${rowdata.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${props.token}`,
      },
      data: {
        date: rowdata.date,
        time: rowdata.time,
        username: rowdata.username,
        stockname: rowdata.stockname,
        productcode: rowdata.productcode,
        quantity: rowdata.productcode,
        status: "Removed",
        description: rowdata.description,
        purchaseorderid: rowdata.purchaseorderid,
        isdeleted: true,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        console.log(res);
        if (res.status === 200) {
          callbackfunction();
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: (row) => {
          let row_data = row.row.original;
          return (
            <React.Fragment>
              <div>
                <input
                  type="checkbox"
                  style={{ width: "20px", height: "20px" }}
                  disabled={
                    row_data.status === "Requested" ||
                    row_data.status === "Returned"
                      ? false
                      : true
                  }
                  onChange={() => onChangeChaeckbox(row_data)}
                />
              </div>
              <div style={{ marginTop: -30, paddingLeft: 30 }}>
                <button
                  className="btn btn-primary btn-sm"
                  style={{ marginRight: "5px" }}
                  onClick={() => deletePurchaseRequest(row_data)}
                >
                  <i className="fa fa-trash" />
                </button>
              </div>
            </React.Fragment>
          );
        },
      },
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        filter: "equals",
        headerStyle: { textAlign: "left" },
      },
      {
        Header: "Username",
        headerStyle: { textAlign: "left" },
        accessor: "username",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "stockname",
        headerStyle: { textAlign: "left" },
        accessor: "stockname",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "ProductCode",
        headerStyle: { textAlign: "left" },
        accessor: "productcode",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Quantity",
        headerStyle: { textAlign: "left" },
        accessor: "quantity",
        disableSortBy: true,
        filter: "equals",
      },
      {
        Header: "Status",
        headerStyle: { textAlign: "left" },
        accessor: "status",
        disableSortBy: true,
        filter: "equals",
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Header />
      <Container className="md--7" fluid>
        <br />
        <Row style={{ marginBottom: -35 }}>
          <Form style={{ marginTop: -65, marginLeft: 15 }}>
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <Input
                  type="select"
                  value={selectedusername}
                  onChange={selectUserName}
                  style={{
                    height: "35px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "2px 2px 2px",
                    backgroundColor: "white",
                    border: "none",
                  }}
                >
                  {username.map((item, key) => (
                    <option key={key} value={item}>
                      {" "}
                      {item}{" "}
                    </option>
                  ))}
                </Input>
              </InputGroup>
            </FormGroup>
          </Form>
          &nbsp;
          <Form style={{ marginTop: -65, marginLeft: 5 }}>
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <Input
                  type="select"
                  value={selectedStatus}
                  onChange={selectStatus}
                  style={{
                    height: "35px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "2px 2px 2px",
                    backgroundColor: "white",
                    border: "none",
                  }}
                >
                  {statusdetails.map((item, key) => (
                    <option key={key} value={item}>
                      {" "}
                      {item}{" "}
                    </option>
                  ))}
                </Input>
              </InputGroup>
            </FormGroup>
          </Form>
          &nbsp;
          <Form style={{ marginTop: -65, marginLeft: 5 }}>
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <Input
                  type="select"
                  value={purchaeordercompanyname}
                  onChange={updateCompanyName}
                  style={{
                    height: "35px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "2px 2px 2px",
                    backgroundColor: "white",
                    border:
                      companynameempty === true
                        ? "2.5px solid #fb6340"
                        : "none",
                  }}
                >
                  <option value="">Select Company</option>
                  {companyname.map((item, key) => (
                    <option key={key} value={item}>
                      {" "}
                      {item}{" "}
                    </option>
                  ))}
                </Input>
              </InputGroup>
            </FormGroup>
          </Form>
          &nbsp;
          <Form style={{ marginTop: -65, marginLeft: 5 }}>
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <Input
                  type="select"
                  style={{
                    height: "35px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "2px 2px 2px",
                    backgroundColor: "white",
                    border:
                      suppliernameempty === true
                        ? "2.5px solid #fb6340"
                        : "none",
                  }}
                  value={suppliername}
                  onChange={updateSupplierName}
                >
                  <option value="">Select Supplier</option>
                  {clientdetails
                    .sort((a, b) => a.localeCompare(b))
                    .map((item, key) => (
                      <option key={key} value={item}>
                        {" "}
                        {item}{" "}
                      </option>
                    ))}
                </Input>
              </InputGroup>
            </FormGroup>
          </Form>
          &nbsp;
          {selectdata === true ? (
            <Form style={{ marginTop: -66, marginLeft: 5 }}>
              <FormGroup className="mb-0">
                <button
                  className="btn btn-dark btn-sm"
                  style={{ width: 75, height: 35, float: "right" }}
                  onClick={createPurchaseOrderWithPurchaseRequeted}
                >
                  CreatePO
                </button>
              </FormGroup>
            </Form>
          ) : null}
        </Row>
        <TableContainerExpand columns={columns} data={Requestdata} />
      </Container>
    </React.Fragment>
  );
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails: state.clientdetails,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(PurchaseRequest);
