import React, { Fragment } from 'react';
import {
  useTable,
  useExpanded,
  useSortBy,
  useFilters,
  usePagination
} from 'react-table';
import { Table, Card } from 'reactstrap';
import { Filter, DefaultColumnFilter } from './filters';

const TableContainerExpand = ({ columns, data, renderRowSubComponent, checkedToggle}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
    },
    useFilters,
    useSortBy,
    useExpanded,
    usePagination
  );

  const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? <i className="fa fa-arrow-up" aria-hidden="true"></i> : <i className="fa fa-arrow-down" aria-hidden="true"></i>) : '';
  };

  return (
    <React.Fragment>
      <Card className="shadow">
      <Table className="align-items-center table-flush" bordered hover {...getTableProps()} responsive>
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="text-left" {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                  </div>
                  { checkedToggle === false ? null : <Filter column={column} /> }
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>
                  {row.cells.map((cell) => {
                    let style = { maxWidth: 150, overflow: "hidden"};
                    return (
                      <td className="text-left" {...cell.getCellProps({ style })}>{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
                {row.isExpanded && (
                <tr>
                  <td colSpan={visibleColumns.length} className="text-left">
                    {renderRowSubComponent(row)}
                  </td>
                </tr>
                )}
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      </Card>
      <br />
    </React.Fragment>
  );
};

export default TableContainerExpand;
