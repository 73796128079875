import React, { Component } from "react";
import axios from "axios";
import { Row, Col, Input } from "reactstrap";
import "./Addsubtask.css";
import moment from "moment";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../config";
import consoleFunction from "../../../Helpers";
import getDateTimeField from "../../../Utils";

const authApi = config.get("apiDomain");

class Addsubtask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tasklist: this.props.EditRowSend,
      client: this.props.ClientId,
      date: new Date(),
      Subtask: "",
      AssignTo: this.props.username,
      Status: "ToDo",
      Priority: "Medium",
      Time: "00:00",
      items: this.props.allusers,
      maintask: [],
      subtaskborder: "1px solid #dee2e6",
      isButtonDisabled: false,
      alreadysubtask: false,
    };
  }

  componentDidMount() {
    this.fetchManitask();
  }

  fetchManitask = () => {
    fetch(`${authApi}Tasklist/${this.state.tasklist}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          maintask: json,
        });
      });
  };

  MaintaskBendingStatus = () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "put",
      url: `${authApi}Tasklist/${this.state.tasklist}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        date: this.state.maintask.date,
        task: this.state.maintask.task,
        assignto: this.state.maintask.assignto,
        priority: this.state.maintask.periority,
        startdate: this.state.maintask.startdate,
        enddate: this.state.maintask.enddate,
        status: "Pending",
        time: this.state.maintask.time,
        plantype: this.state.maintask.plantype,
        isdeleted: this.state.maintask.isdeleted,
        subclient: this.state.maintask.subclient,
        task_starttime: this.state.maintask.task_starttime,
        client: this.state.maintask.client,
        file_path: this.state.maintask.file_path,
        completed_date: this.state.maintask.completed_date,
        advanceamount: this.state.maintask.advanceamount,
        phonenumber: this.state.maintask.phonenumber,
        company: this.state.maintask.company,
        companyname: this.state.maintask.companyname,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    }).catch((err) => {
      consoleFunction(
        "Task pending updation error",
        err.response.request.response
      );
      this.props.functionreload(
        this.props.tt_client,
        this.props.tt_company,
        this.props.tt_assignto,
        this.props.tt_plantype,
        this.props.tt_status
      );
    });
  };

  FormValidation = () => {
    let subtaskassign = this.state.AssignTo;
    let sameassigneedata = this.props.Totaltask.subtask.filter(function (
      subtask
    ) {
      return subtask.assignto === subtaskassign;
    });
    if (sameassigneedata.length === 0) {
      if (this.state.Subtask === "") {
        this.setState({
          subtaskborder: "1px solid #fb6340",
          alreadysubtask: false,
          isButtonDisabled: false,
        });
      } else {
        this.setState({
          isButtonDisabled: true,
        });
        this.UpdateSubtask();
      }
    } else {
      this.setState({
        alreadysubtask: true,
        isButtonDisabled: true,
      });
    }
  };

  UpdateSubtask = () => {
    let datetime_field = getDateTimeField();
    this.MaintaskBendingStatus();
    const date = moment(this.state.date).format("YYYY-MM-DD");
    const subtask = this.state.Subtask;
    const assignto = this.state.AssignTo;
    const priority = this.state.Priority;
    const status = this.state.Status;
    const time = this.state.Time;
    const isdeleted = false;
    const completed_date = null;
    const tasklist = this.state.tasklist;
    axios({
      method: "post",
      url: `${authApi}subtasks/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        date: date,
        subtask: subtask,
        assignto: assignto,
        priority: priority,
        status: status,
        time: time,
        isdeleted: isdeleted,
        completed_date: completed_date,
        tasklist: tasklist,
        createdby: this.props.username,
        createdat: datetime_field,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          this.props.closebtn();
          this.props.functionreload(
            this.props.tt_client,
            this.props.tt_company,
            this.props.tt_assignto,
            this.props.tt_plantype,
            this.props.tt_status
          );
        }
      })
      .catch((err) => {
        consoleFunction(
          "Subtask updation error in teamtask",
          err.response.request.response
        );
        this.props.functionreload(
          this.props.tt_client,
          this.props.tt_company,
          this.props.tt_assignto,
          this.props.tt_plantype,
          this.props.tt_status
        );
        this.setState({ error: err.response.request.response });
      });
  };

  HandleChangesubtask = (event) => {
    this.setState({
      Subtask: event.target.value,
    });
  };

  HandleChangeAssignTo = (event) => {
    this.setState({
      AssignTo: event.target.value,
      isButtonDisabled: false,
    });
  };

  HandleChangeStatus = (event) => {
    this.setState({
      Status: event.target.value,
    });
  };

  HandleChangePriority = (event) => {
    this.setState({
      Priority: event.target.value,
    });
  };

  HandeleChangeTime = (event) => {
    this.setState({
      Time: event.target.value,
    });
  };

  render() {
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Add Subtask</div>
        <div className="Scroll-Content">
          <Row>
            <Col md="6">
              <label className="form-control-label">Subtask</label>
              <Input
                className="form-control-alternative"
                type="text"
                value={this.state.Subtask}
                style={{ border: this.state.subtaskborder }}
                onChange={this.HandleChangesubtask}
              />
              {this.state.subtaskborder !== "1px solid #dee2e6" ? (
                <h6 style={{ color: "#fb6340" }}>Please enter subtask</h6>
              ) : null}
            </Col>
            <Col md="6">
              <label className="form-control-label">AssignTo</label>
              <Input
                type="select"
                className="form-control-alternative"
                style={{ border: "1px solid #dee2e6" }}
                value={this.state.AssignTo}
                onChange={this.HandleChangeAssignTo}
              >
                <option>Select</option>
                {this.state.items.map((obj, assign) => (
                  <option key={assign} value={obj.name}>
                    {obj.name}
                  </option>
                ))}
              </Input>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <label className="form-control-label">Status</label>
              <Input
                type="select"
                className="form-control-alternative"
                style={{ border: "1px solid #dee2e6" }}
                defaultValue={this.state.Status}
                onChange={this.HandleChangeStatus}
              >
                <option value="0">Select</option>
                <option value="ToDo">ToDo</option>
                <option value="Pending">Pending</option>
                <option value="InProgress">InProgress </option>
                <option value="Completed">Completed</option>
              </Input>
            </Col>
            <Col md="6">
              <label className="form-control-label">Priority</label>
              <Input
                type="select"
                className="form-control-alternative"
                style={{ border: "1px solid #dee2e6" }}
                defaultValue={this.state.Priority}
                onChange={this.HandleChangePriority}
              >
                <option value="0">Select</option>
                <option value="Low">Low</option>
                <option value="Medium">Medium</option>
                <option value="High">High</option>
              </Input>
            </Col>
          </Row>
          {this.state.alreadysubtask === true ? (
            <h5 style={{ textAlign: "center", color: "#fb6340" }}>
              Instance with the same assignee already exists
            </h5>
          ) : null}
          <button
            id="buttonsave"
            className="btn btn-dark"
            style={{ float: "right", marginRight: 15 }}
            onClick={this.FormValidation}
            disabled={this.state.isButtonDisabled}
          >
            {" "}
            Add{" "}
          </button>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    tt_client: state.teamtask_client,
    tt_company: state.teamtask_company,
    tt_assignto: state.teamtask_assignto,
    tt_plantype: state.teamtask_plantype,
    tt_status: state.teamtask_status,
    allusers: state.allusers,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(Addsubtask);
