import React, { Component } from "react";
import { Container, Table, Row, Card, Input, Col } from "reactstrap";
import config from "react-global-configuration";
import "../../../config";
import { connect } from "react-redux";
import AccountPopupRow from "./AccountPopupRow";
import Subtask from "./Subtask";
import axios from "axios";
import moment from "moment";
import consoleFunction from "../../../Helpers";
import "./AccountPopup.css";
import getDateTimeField from "../../../Utils";

let PreTotalAmount = 0,
  Total = 0,
  parrentbalance = 0,
  taxyearstartdate = "",
  taxyearenddate = "";
let Draftingdetails = [],
  DraftingStringdata = "";
let StocksStringdata = "";
const authApi = config.get("apiDomain");

class AccountPopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: 0,
      items: [],
      subtask: this.props.EditRowSend,
      error: "",
      users: [],
      Rate: "",
      maintask: [],
      length: "",
      CheckData: [],
      allStockTotal: 0,
      DraftingAmount: 0,
      DraftingCharge: [],
      discount: 0,
      discountpercentage: 0,
      advanceamount: 0,
      Referenceno: 0,
      PaymentMode: "Cash",
      received_amount: "0",
      clientid: 0,
      plantype: "",
      deletedRows: [],
      transaction: [],
      transactionbalance: 0,
      transactionClientBalance: 0,
      transactioninvoicebalance: 0,
      date: new Date(),
      userid: "",
      username: this.props.username,
      discountpercentageborder: "1px solid #dee2e6",
      adjustmentborder: "1px solid #dee2e6",
      referencenoborder: "1px solid #dee2e6",
      receivedamountborder: "1px solid #dee2e6",
      Unpaiddetails: [],
      general: 0,
      Generaltask: 0,
      generaltaskborder: "1px solid #dee2e6",
      isButtonDisabled: false,
      ClonetaskCheckbox: false,
      cloneColor: "none",
      ZeroTotal: 0,
      Invocieisalreadycreated: 0,
      companyid: this.props.maintask.company,
      Companyinvoice: 0,
      bank: [],
      companyname: "",
    };
  }

  async componentDidMount() {
    let maxdata = 0,
      max = 0;
    const responseyear = await this.checkTaxYear();
    responseyear.forEach((currentyear) => {
      if (currentyear.id > max) {
        max = currentyear.id;
        maxdata = currentyear;
      }
    });
    taxyearstartdate = maxdata.startdate;
    taxyearenddate = maxdata.enddate;
    parrentbalance = this.props.maintask.parenttaskbalance;
    this.getMaxInvoiceidForParticularCompany();
    if (
      this.props.maintask.clientname === "General-Printing" ||
      this.props.maintask.clientname === "General"
    ) {
      this.setState({
        Generaltask: 1,
      });
    }
    if (this.props.maintask.status === "Pending") {
      this.setState({
        ClonetaskCheckbox: true,
      });
    }
    this.invoiceStock();
    this.Maintaskdetails();
    this.getBankDetailsForCurrenttaskCompany();
    let data = this.state.subtask.filter(function (item) {
      return item.isdeleted !== true;
    });
    this.setState({
      subtask: data,
      clientid: this.props.maintask.client,
    });
    this.finddefaultRate();
    this.StoreprocInvoiceClientbalance(this.props.maintask.client);
  }

  getBankDetailsForCurrenttaskCompany = () => {
    fetch(
      `${authApi}bankfilter/?companydetails=${this.props.maintask.company}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          bank: json,
        });
      });
  };

  getMaxInvoiceidForParticularCompany = () => {
    axios({
      method: "post",
      url: `${authApi}sp_getcompanygreaterinvoice/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        companyid: this.props.maintask.company,
      },
    })
      .then((res) => {
        console.log("sp_getcompanygreaterinvoice");
        console.log(res.data);
        this.setState({
          Companyinvoice: res.data + 1,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  checkTaxYear = () => {
    return fetch(`${authApi}taxyear/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  StoreprocInvoiceClientbalance = (clientid) => {
    axios({
      method: "post",
      url: `${authApi}sp_calculateclosingbalance/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        client_id: clientid,
        company_id: this.props.maintask.company,
        startdate: taxyearstartdate,
        enddate: taxyearenddate,
      },
    })
      .then((res) => {
        console.log("line111111192", res);
        let balance = res.data.balance > 0 ? res.data.balance : 0;
        if (balance > 0) {
          this.setState({
            transactionClientBalance: 0,
            transactioninvoicebalance: balance,
          });
        } else {
          this.setState({
            transactionClientBalance: parseInt(balance) * -1,
            transactioninvoicebalance: 0,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  payforcurrentTaskInvoice = (client) => {
    let Receivedamount = 0;
    let advance = parseInt(this.props.maintask.advanceamount) || 0;
    let receive = parseInt(this.state.received_amount) || 0;
    if (
      this.props.maintask.clientname === "General-Printing" ||
      this.props.maintask.clientname === "General"
    ) {
      Receivedamount = advance + receive;
    } else {
      Receivedamount = receive;
    }
    axios({
      method: "post",
      url: `${authApi}sp_payforcurrentinvoicetask/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        clientid: client,
        userid: this.state.userid,
        companyid: this.props.maintask.company,
        amount: Receivedamount,
        tbltasklist_id: this.props.maintask.id,
      },
    })
      .then((res) => {
        console.log(res);
        this.props.closebtn();
        this.props.functionreload(
          this.props.tt_client,
          this.props.tt_company,
          this.props.tt_assignto,
          this.props.tt_plantype,
          this.props.tt_status
        );
      })
      .catch((err) => {
        consoleFunction(
          "Pay for currenttask task error",
          err.response.request.response
        );
        this.props.functionreload(
          this.props.tt_client,
          this.props.tt_company,
          this.props.tt_assignto,
          this.props.tt_plantype,
          this.props.tt_status
        );
      });
  };

  invoiceStock = () => {
    fetch(
      `${authApi}stockinvoicefilter/?tasklistrow=${this.props.maintaskid}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        const body = json;
        const data = body.map((e) => {
          e.availablequantity = 0;
          return e;
        });
        this.setState({
          items: data,
        });
        this.updateAvailableQuantity(data);
      });
  };

  updateId = () => {
    let max = 0;
    this.state.items.forEach((ms) => {
      if (ms.id > max) {
        max = ms.id;
        this.setState({
          id: max,
        });
      }
    });
  };

  updateAvailableQuantity = (body) => {
    const promise1 = body.map((e) => this.handleAvailableQuantity(e));
    Promise.all(promise1).then((result) => {
      this.setState(
        {
          length: result.length,
          items: result,
          CheckData: result,
        },
        function () {
          this.updateId();
          if (this.state.items.length !== 0) {
            this.CalculateStockvalue(this.state.items);
          }
        }
      );
    });
  };

  handleAvailableQuantity = async (e) => {
    let a = await this.getAvailablequantity(e.types);
    e.availablequantity = a;
    return e;
  };

  getAvailablequantity = async (e) => {
    await axios({
      method: "post",
      url: `${authApi}sp_getavailablestockquantity/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        stockname: e,
      },
    }).then((res) => {
      console.log(res.data);
      if (res.data.length !== 0) {
        console.log(res.data[0].quantity);
        return res.data[0].quantity;
      } else {
        return 0;
      }
    });
  };

  Maintaskdetails = () => {
    fetch(`${authApi}Tasklist/${this.props.maintaskid}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState(
          {
            maintask: json,
          },
          function () {
            this.setState({
              advanceamount: parseInt(this.state.maintask.advanceamount),
              plantype: this.state.maintask.plantype,
            });
          }
        );
      });
  };

  finddefaultRate = () => {
    let currentuser = this.props.allusers.filter((users) => {
      return users.name === this.props.username;
    });
    this.setState({
      users: this.props.allusers,
      userid: currentuser[0].id,
    });
  };

  handleAddRow = () => {
    const item = {
      id: this.state.id + 1,
      types: "",
      unit: "",
      availablequantity: "",
      quantity: "",
      rate: "",
      amount: "",
      tasklistrow: "",
    };
    this.setState({
      id: item.id,
      items: [...this.state.items, item],
    });
  };

  ChangeTodoValue = (x) => {
    this.setState(
      (prevState) => {
        const updatedTodoData = prevState.items.map((todo) => {
          if (todo.id === x.id) {
            todo.id = x.id;
            todo.types = x.stockname;
            todo.rate = x.defaultRate;
            todo.availablequantity = x.availablequantitys;
            todo.unit = x.units;
            todo.quantity = x.quantitys;
            todo.amount = x.amount;
          }
          return todo;
        });
        return {
          items: updatedTodoData,
        };
      },
      function () {
        this.CalculateStockvalue(this.state.items);
      }
    );
  };

  CalculateStockvalue = (x) => {
    let StockTotal = 0;
    x.forEach((i) => {
      StockTotal = StockTotal + parseInt(i.amount);
    });
    this.setState({
      allStockTotal: StockTotal,
    });
  };

  calculateDraftingCharges = (amount, user) => {
    let data = {
      draftingamount: amount,
      draftinguser: user,
    };
    this.setState(
      {
        DraftingCharge: [...this.state.DraftingCharge, data],
      },
      function () {
        this.calculateDrafitingAmount();
      }
    );
  };

  isvalidEntryInForm = async () => {
    let isvalidform = false;
    this.setState({
      isButtonDisabled: true,
    });
    if (Total > 0) {
      if (this.state.discountpercentage !== "") {
        if (this.state.discount !== "") {
          if (this.state.Referenceno !== "") {
            if (this.state.received_amount !== "") {
              if (
                this.props.maintask.clientname === "General-Printing" ||
                this.props.maintask.clientname === "General"
              ) {
                let advance = parseInt(this.state.maintask.advanceamount) || 0;
                let receive = parseInt(this.state.received_amount) || 0;
                let TotalAmount = advance + receive;
                if (TotalAmount > Total) {
                  this.setState({
                    general: 1,
                    generaltaskborder: "1px solid #fb6340",
                    isButtonDisabled: false,
                  });
                } else {
                  isvalidform = true;
                }
              } else {
                isvalidform = true;
              }
            } else {
              this.setState({
                discountpercentageborder: "1px solid #dee2e6",
                adjustmentborder: "1px solid #dee2e6",
                referencenoborder: "1px solid #dee2e6",
                receivedamountborder: "1px solid #fb6340",
                isButtonDisabled: false,
              });
            }
          } else {
            this.setState({
              discountpercentageborder: "1px solid #dee2e6",
              adjustmentborder: "1px solid #dee2e6",
              referencenoborder: "1px solid #fb6340",
              receivedamountborder: "1px solid #dee2e6",
              isButtonDisabled: false,
            });
          }
        } else {
          this.setState({
            discountpercentageborder: "1px solid #dee2e6",
            adjustmentborder: "1px solid #fb6340",
            referencenoborder: "1px solid #dee2e6",
            receivedamountborder: "1px solid #dee2e6",
            isButtonDisabled: false,
          });
        }
      } else {
        this.setState({
          discountpercentageborder: "1px solid #fb6340",
          adjustmentborder: "1px solid #dee2e6",
          referencenoborder: "1px solid #dee2e6",
          receivedamountborder: "1px solid #dee2e6",
          isButtonDisabled: false,
        });
      }
    } else {
      this.setState({
        ZeroTotal: 1,
        isButtonDisabled: false,
      });
    }
    return isvalidform;
  };

  onSubmitForm = async () => {
    if (await this.isvalidEntryInForm()) {
      if (DraftingStringdata !== "") {
        await this.StoreDraftingcharges();
      }
      Draftingdetails = [];
      if (this.state.ClonetaskCheckbox === true) {
        let responedata = await this.createNewTask();
        await this.CreateNewSubtask(responedata.data.id);
      }
      if (this.props.maintask.status === "Pending") {
        await this.closeSubtasks(this.props.maintask.subtask);
      }
      await this.OnSubmitInvoice();
    }
  };

  StoreDraftingcharges = async () => {
    axios({
      method: "post",
      url: `${authApi}sp_storedraftingcharges/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        drafting_data: DraftingStringdata,
      },
    })
      .then((res) => {
        console.log(res.data);
      })
      .catch((err) => {
        consoleFunction(
          "Pay for unpaid task error",
          err.response.request.response
        );
      });
  };

  PrepareDraftingcharges = () => {
    DraftingStringdata = [];
    Draftingdetails.forEach((element) => {
      let data = {
        name: element.user,
        time: element.unit,
        rate: element.rate,
        amount: element.amount,
        taskid: element.tasklistrowid,
      };
      DraftingStringdata = [...DraftingStringdata, data];
    });
  };

  CreateNewSubtask = async (taskid) => {
    if (this.props.maintask.status === "Pending") {
      let CreateableSubtask = this.props.maintask.subtask.filter(function (
        subtask
      ) {
        return subtask.status !== "Completed" && subtask.isdeleted === false;
      });
      await this.createSubtaskforPending(CreateableSubtask, taskid);
    } else {
      await this.CreateTodosubtaskforReadyToBill(taskid);
    }
  };

  createNewTask = async () => {
    let datetime_field = getDateTimeField();
    parrentbalance = this.props.maintask.parenttaskbalance + Total;
    try {
      const resp = await axios({
        method: "post",
        url: `${authApi}Tasklist/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: this.props.maintask.date,
          task: this.props.maintask.task,
          assignto: this.props.maintask.assignto,
          priority: this.props.maintask.priority,
          startdate: this.props.maintask.startdate,
          enddate: this.props.maintask.enddate,
          status: "ToDo",
          time: "00:00",
          isdeleted: this.props.maintask.isdeleted,
          plantype: this.props.maintask.plantype,
          subclient: this.props.maintask.subclient,
          task_starttime: this.props.maintask.task_starttime,
          client: this.props.maintask.client,
          file_path: this.props.maintask.file_path,
          completed_date: null,
          advanceamount: "0",
          phonenumber: this.props.maintask.phonenumber,
          clientname: this.props.maintask.clientname,
          drawingtitle: this.props.maintask.drawingtitle,
          parenttaskid: this.props.maintask.id,
          parenttaskbalance: parrentbalance,
          company: this.props.maintask.company,
          companyname: this.props.maintask.companyname,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      });
      return resp;
    } catch (err) {
      consoleFunction(
        "Create new task via readytobill error",
        err.response.request.response
      );
      this.props.functionreload(
        this.props.tt_client,
        this.props.tt_company,
        this.props.tt_assignto,
        this.props.tt_plantype,
        this.props.tt_status
      );
    }
  };

  CreateTodosubtaskforReadyToBill = async (taskid) => {
    let date = moment(this.state.date).format("YYYY-MM-DD");
    let datetime_field = getDateTimeField();
    try {
      const resp = await axios({
        method: "post",
        url: `${authApi}subtasks/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: date,
          subtask: "Default",
          assignto: this.props.maintask.assignto,
          priority: this.props.maintask.priority,
          status: "ToDo",
          time: "00:00",
          isdeleted: false,
          completed_date: null,
          task_starttime: "",
          client_id: this.props.maintask.client,
          tasklist: taskid,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      });
      console.log(resp.data);
    } catch (err) {
      consoleFunction(
        "Create default subtask via readytobill error",
        err.response.request.response
      );
      this.props.functionreload(
        this.props.tt_client,
        this.props.tt_company,
        this.props.tt_assignto,
        this.props.tt_plantype,
        this.props.tt_status
      );
    }
  };

  createSubtaskforPending = async (NewSubtask, maintaskid) => {
    let resp = 0;
    let datetime_field = getDateTimeField();
    try {
      for (const subtask of NewSubtask) {
        resp = await axios({
          method: "post",
          url: `${authApi}subtasks/`,
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
          data: {
            date: subtask.date,
            subtask: subtask.subtask,
            assignto: subtask.assignto,
            priority: subtask.priority,
            status: "ToDo",
            time: "00:00",
            isdeleted: subtask.isdeleted,
            completed_date: null,
            task_starttime: subtask.task_starttime,
            client_id: subtask.client_id,
            tasklist: maintaskid,
            createdby: this.props.username,
            createdat: datetime_field,
            modifiedby: this.props.username,
            modifiedat: datetime_field,
          },
        });
      }
      console.log(resp.data);
    } catch (err) {
      consoleFunction(
        "Create user subtask via readytobill error",
        err.response.request.response
      );
      this.props.functionreload(
        this.props.tt_client,
        this.props.tt_company,
        this.props.tt_assignto,
        this.props.tt_plantype,
        this.props.tt_status
      );
    }
  };

  closeSubtasks = async (Subtaskdata) => {
    let date = moment(this.state.date).format("YYYY-MM-DD");
    let resp = 0;
    try {
      let datetime_field = getDateTimeField();
      for (const subtask of Subtaskdata) {
        resp = await axios({
          method: "put",
          url: `${authApi}subtasks/${subtask.id}/`,
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
          data: {
            date: subtask.date,
            subtask: subtask.subtask,
            assignto: subtask.assignto,
            priority: subtask.periority,
            status: "Completed",
            time: subtask.time,
            isdeleted: subtask.isdeleted,
            completed_date: date,
            task_starttime: subtask.task_starttime,
            client_id: subtask.client_id,
            tasklist: subtask.tasklist,
            modifiedby: this.props.username,
            modifiedat: datetime_field,
          },
        });
      }
      console.log(resp);
    } catch (err) {
      consoleFunction(
        "Subtask Completion error",
        err.response.request.response
      );
      this.props.functionreload(
        this.props.tt_client,
        this.props.tt_company,
        this.props.tt_assignto,
        this.props.tt_plantype,
        this.props.tt_status
      );
    }
  };

  OnSubmitInvoice = async () => {
    let count = 1,
      len = this.state.items.length;
    const parentTaskId = this.props.maintaskid;
    this.createInvoiceAndTransaction();
    this.deletestock();
    if (this.state.length === 0) {
      if (len === 0) {
        console.log("check");
      } else {
        for (const i of this.state.items) {
          if (i.types !== "") {
            let datetime_field = getDateTimeField();
            await axios({
              method: "post",
              url: `${authApi}stockinvoice/`,
              headers: {
                "content-type": "application/json",
                Authorization: `token ${this.props.token}`,
              },
              data: {
                types: i.types,
                unit: i.unit,
                quantity: i.quantity,
                rate: i.rate,
                amount: i.amount,
                tasklistrow: parentTaskId,
                createdby: this.props.username,
                createdat: datetime_field,
                modifiedby: this.props.username,
                modifiedat: datetime_field,
              },
            });
            if (len === count) {
              this.props.closebtn();
            } else {
              count = count + 1;
            }
          }
        }
      }
    } else {
      for (const i of this.state.items) {
        let task = 0;
        task = this.state.CheckData.filter(function (item) {
          return item.id === i.id && item.tasklistrow === i.tasklistrow;
        });
        if (task.length !== 0) {
          if (i.types !== "") {
            let datetime_field = getDateTimeField();
            await axios({
              method: "put",
              url: `${authApi}stockinvoice/${i.id}/`,
              headers: {
                "content-type": "application/json",
                Authorization: `token ${this.props.token}`,
              },
              data: {
                types: i.types,
                unit: i.unit,
                quantity: i.quantity,
                rate: i.rate,
                amount: i.amount,
                tasklistrow: parentTaskId,
                modifiedby: this.props.username,
                modifiedat: datetime_field,
              },
            });
            if (len === count) {
              this.props.closebtn();
            } else {
              count = count + 1;
            }
          }
        } else {
          if (i.types !== "") {
            let datetime_field = getDateTimeField();
            await axios({
              method: "post",
              url: `${authApi}stockinvoice/`,
              headers: {
                "content-type": "application/json",
                Authorization: `token ${this.props.token}`,
              },
              data: {
                types: i.types,
                unit: i.unit,
                quantity: i.quantity,
                rate: i.rate,
                amount: i.amount,
                tasklistrow: parentTaskId,
                createdby: this.props.username,
                createdat: datetime_field,
                modifiedby: this.props.username,
                modifiedat: datetime_field,
              },
            });
            if (len === count) {
              this.props.closebtn();
            } else {
              count = count + 1;
            }
          } else {
            console.log("check");
          }
        }
      }
    }
  };

  prepareStockstringdata = async (items, invoice_id) => {
    StocksStringdata = [];
    const stocksOutdata = items.filter(
      (i) => i.types !== "" && i.quantity !== "" && parseInt(i.quantity) > 0
    );
    stocksOutdata.forEach((element) => {
      let data = {
        amount: Math.round(element.amount),
        availablequantity: "",
        quantity: parseInt(element.quantity) * parseInt(element.unit),
        rate: Math.round(element.rate),
        tasklistrow: "",
        types: element.types,
        unit: element.unit,
        productcode: "0",
      };
      StocksStringdata = [...StocksStringdata, data];
    });
    axios({
      method: "post",
      url: `${authApi}sp_taskinvoiceinventorytransaction/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        deliverynoteid: invoice_id,
        userid: this.state.userid,
        companyid: this.props.maintask.company,
        stock: StocksStringdata,
      },
    })
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  createInvoiceAndTransaction = async () => {
    let received = parseInt(this.state.received_amount) || 0;
    console.log(this.state.maintask.client);
    await axios({
      method: "post",
      url: `${authApi}sp_createinvocieandtransaction/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        invoice_amount: Total,
        amount_received: received,
        client: this.state.maintask.client,
        userid: this.state.userid,
        discount: this.state.discount,
        subtotal: PreTotalAmount,
        companyid: this.props.maintask.company,
        taskid: this.props.maintask.id,
        pmtmode: this.state.PaymentMode,
        pmtreference: this.state.Referenceno,
      },
    })
      .then((res) => {
        if (
          res.data[0].alearyinvoice !== "Already Invoice created for this Task"
        ) {
          this.prepareStockstringdata(this.state.items, res.data[0].invoiceid);
          if (
            this.props.maintask.clientname === "General-Printing" ||
            this.props.maintask.clientname === "General"
          ) {
            let advance = parseInt(this.props.maintask.advanceamount) || 0;
            let TotalReceived = received + advance;
            if (TotalReceived > 0) {
              this.payforcurrentTaskInvoice(this.state.clientid);
            } else {
              this.props.closebtn();
              this.props.functionreload(
                this.props.tt_client,
                this.props.tt_company,
                this.props.tt_assignto,
                this.props.tt_plantype,
                this.props.tt_status
              );
            }
          } else {
            this.PayforUnpaidTask(this.state.clientid);
          }
        } else {
          this.setState({
            Invocieisalreadycreated: 1,
          });
        }
      })
      .catch((err) => {
        consoleFunction(
          "Pay for unpaid task error",
          err.response.request.response
        );
      });
  };

  PayforUnpaidTask = async (client) => {
    axios({
      method: "post",
      url: `${authApi}sp_payforunpaidinvoice/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        client_id: client,
        company_id_value: this.props.maintask.company,
        startdate: taxyearstartdate,
        enddate: taxyearenddate,
        userid: this.state.userid,
        clientname: this.props.maintask.clientname,
        received_amount: this.state.received_amount,
        description: "Advance",
      },
    })
      .then((res) => {
        if (res.status === 201) {
          this.props.closebtn();
          this.props.functionreload(
            this.props.tt_client,
            this.props.tt_company,
            this.props.tt_assignto,
            this.props.tt_plantype,
            this.props.tt_status
          );
        }
      })
      .catch((err) => {
        consoleFunction(
          "Pay for unpaid task error",
          err.response.request.response
        );
        this.props.functionreload(
          this.props.tt_client,
          this.props.tt_company,
          this.props.tt_assignto,
          this.props.tt_plantype,
          this.props.tt_status
        );
      });
    this.props.closebtn();
    this.props.functionreload(
      this.props.tt_client,
      this.props.tt_company,
      this.props.tt_assignto,
      this.props.tt_plantype,
      this.props.tt_status
    );
  };

  updateDraftingCharges = (Changeamount, user) => {
    this.state.DraftingCharge.forEach((i) => {
      if (i.draftinguser === user) {
        i.draftingamount = Changeamount;
        this.calculateDrafitingAmount();
      }
    });
  };

  calculateDrafitingAmount = () => {
    let amount = 0;
    this.state.DraftingCharge.forEach((i) => {
      amount = amount + i.draftingamount;
    });
    this.setState({
      DraftingAmount: amount,
    });
  };

  Adjustamount = (event) => {
    let action = event.target.value.slice(0, 1);
    let amount = event.target.value.substring(1);
    let discount = 0,
      total = 0,
      discountamount = 0,
      percent = 0,
      percentage = 0;
    if (action === "-") {
      let total = PreTotalAmount;
      let discountamount = parseInt(amount);
      let percent = (discountamount / total) * 100;
      let percentage = percent.toFixed(1);
      this.setState({
        discount: event.target.value,
        discountpercentage: percentage,
      });
    } else {
      discount = event.target.value;
      total = PreTotalAmount;
      discountamount = parseInt(discount);
      percent = -(discountamount / total) * 100;
      percentage = percent.toFixed(1);
      this.setState({
        discount: event.target.value,
        discountpercentage: percentage,
      });
    }
  };

  DiscountpercentageFunction = (event) => {
    let total = PreTotalAmount;
    let amount = -Math.floor((total * event.target.value) / 100);
    this.setState({
      discount: amount,
      discountpercentage: event.target.value,
    });
  };

  Paymentmodeoption = (event) => {
    this.setState({
      PaymentMode: event.target.value,
    });
  };

  ChangeReference = (event) => {
    this.setState({
      Referenceno: event.target.value,
    });
  };

  ChangeReceivedAmount = (event) => {
    this.setState({
      received_amount: event.target.value,
    });
  };

  handleRemoveRow = (i) => {
    const DelData = this.state.items.filter((item) => item.id !== i.id);
    this.setState(
      {
        items: [...DelData],
      },
      function () {
        this.CalculateStockvalue(this.state.items);
      }
    );
    this.state.CheckData.forEach((item) => {
      if (item.id === i.id && item.tasklistrow === i.tasklistrow) {
        let row = {
          id: i.id,
          types: i.stockname,
          unit: i.units,
          quantity: i.quantitys,
          availablequantity: i.availablequantitys,
          rate: i.defaultRate,
          amount: i.amount,
          tasklistrow: i.tasklistrow,
        };
        this.setState({
          deletedRows: [...this.state.deletedRows, row],
        });
      }
    });
  };

  deletestock = () => {
    this.state.deletedRows.forEach((i) => {
      axios({
        method: "delete",
        url: `${authApi}stockinvoice/${i.id}`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => {})
        .catch((err) => {
          consoleFunction("Stock removal error", err.response.request.response);
          this.props.functionreload(
            this.props.tt_client,
            this.props.tt_company,
            this.props.tt_assignto,
            this.props.tt_plantype,
            this.props.tt_status
          );
        });
    });
  };

  currentTaskCloneorNot = () => {
    if (this.state.ClonetaskCheckbox === true) {
      this.setState({
        ClonetaskCheckbox: false,
        cloneColor: "black",
      });
    } else {
      this.setState({
        ClonetaskCheckbox: true,
        cloneColor: "green",
      });
    }
  };

  createDictionaryforDraftingupdate = (user, rate, amount) => {
    Draftingdetails.forEach((drafting) => {
      if (drafting.user === user) {
        drafting.rate = rate;
        drafting.amount = amount;
      }
    });
    this.PrepareDraftingcharges();
  };

  createDictionaryforDrafting = (user, rate, unit, amount, tasklistrowid) => {
    let alreadythere = 0;
    Draftingdetails.forEach((drafting) => {
      if (drafting.user === user) {
        alreadythere = 1;
      }
    });
    if (alreadythere === 0) {
      let data = {
        user: user,
        unit: unit,
        rate: rate,
        amount: amount,
        tasklistrowid: tasklistrowid,
      };
      Draftingdetails = [...Draftingdetails, data];
      this.PrepareDraftingcharges();
    }
  };

  callClosningButton = () => {
    Draftingdetails = [];
    this.props.closebtn();
  };

  render() {
    const AddRowDetails = [...this.state.items].map((item) => (
      <AccountPopupRow
        key={item.id}
        id={item.id}
        types={item.types}
        unit={item.unit}
        quantity={item.quantity}
        availablequantity={item.availablequantity}
        rate={item.rate}
        amount={item.amount}
        tasklistrow={item.tasklistrow}
        addRowstock={this.ChangeTodoValue}
        deleteRow={this.handleRemoveRow}
      />
    ));
    const Subtaskdetails = [...this.state.subtask].map((subtask) => (
      <Subtask
        key={subtask.id}
        id={subtask.id}
        assignto={subtask.assignto}
        time={subtask.time}
        user={this.state.users}
        mainid={this.props.maintaskid}
        Drafting={this.calculateDraftingCharges}
        updateDrafting={this.updateDraftingCharges}
        StoreDrafting={this.props.plantype}
        createDictionaryforDrafting={this.createDictionaryforDrafting}
        createDictionaryforDraftingupdate={
          this.createDictionaryforDraftingupdate
        }
      />
    ));
    PreTotalAmount = this.state.allStockTotal + this.state.DraftingAmount;
    Total = parseInt(PreTotalAmount) + parseInt(this.state.discount);
    let advance = parseInt(this.state.maintask.advanceamount) || 0;
    let receive = parseInt(this.state.received_amount) || 0;
    let TotalGeneralamount = advance + receive;
    return (
      <React.Fragment>
        <Container className="mt--7" fluid>
          <Row style={{ marginTop: 60 }}>
            <Col md="7">
              <h2>Billing Details</h2>
            </Col>
            <Col md="3"></Col>
            <Col md="1"></Col>
            <Col md="1">
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "80%", height: 40, marginTop: -7 }}
                data-tip
                data-for="completed-Tip"
                onClick={this.props.closebtn}
              >
                Back
              </button>
            </Col>
          </Row>
          <Row style={{ marginTop: 3 }}>
            <Col md="1">
              <label style={{ marginTop: 10 }}>company</label>
            </Col>
            <Col md="2">
              <Input type="text" value={this.props.maintask.companyname} />
            </Col>
            <Col md="1">
              <label style={{ marginTop: 10 }}>ClientName</label>
            </Col>
            <Col md="3">
              <Input type="text" value={this.props.maintask.clientname} />
            </Col>
            <Col md="1">
              <label style={{ marginTop: 10 }}>TaskName</label>
            </Col>
            <Col md="4">
              <Input type="text" value={this.props.maintask.task} />
            </Col>
          </Row>

          <Row style={{ marginTop: 20 }}>
            <div className=" col" style={{ width: 90 }}>
              <Card className="shadow">
                <h3>Drafting Details</h3>
                {this.props.plantype !== "18Printing" ? (
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center"> Users </th>
                        <th className="text-center"> Unit </th>
                        <th className="text-center"> Rate </th>
                        <th className="text-center"> Amount </th>
                      </tr>
                    </thead>
                    <tbody>{Subtaskdetails}</tbody>
                  </Table>
                ) : null}
              </Card>
              <Card className="shadow">
                <h3>Printing Details</h3>
                <Table className="align-items-center table-flush" responsive>
                  <thead className="thead-light">
                    <tr
                      style={{
                        borderBottomWidth: 1,
                        borderBottomStyle: "solid",
                      }}
                    >
                      <th className="text-center"> Types </th>
                      <th className="text-center"> Unit </th>
                      <th className="text-center"> Quantity </th>
                      <th className="text-center">Inv Qty</th>
                      <th className="text-center"> Rate </th>
                      <th className="text-center"> Amount </th>
                      <th className="text-center"> Action </th>
                    </tr>
                  </thead>
                  <tbody>{AddRowDetails}</tbody>
                </Table>
                <div>
                  <button
                    className="btn btn-primary btn-sm"
                    onClick={this.handleAddRow}
                  >
                    <i className="fas fa-plus-circle fa-1x"></i>
                  </button>
                </div>
              </Card>
              <Card>
                <Table>
                  <Row style={{ marginTop: 6 }}>
                    <Col md="3">
                      <label
                        className="form-control-label"
                        style={{ marginTop: 15, marginLeft: 20 }}
                      >
                        Advance
                      </label>
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        className="form-control-alternative"
                        style={{ border: "1px solid #dee2e6" }}
                        value={this.state.advanceamount}
                        onChange={this.ChangeAdvanceAdmount}
                      />
                    </Col>
                    <Col md="3">
                      <label
                        className="form-control-label"
                        style={{ marginTop: 15 }}
                      >
                        Total
                      </label>
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        style={{ border: "1px solid #dee2e6" }}
                        className="form-control-alternative"
                        value={PreTotalAmount}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 6 }}>
                    <Col md="3">
                      <label
                        className="form-control-label"
                        style={{ marginTop: 15, marginLeft: 20 }}
                      >
                        Discount %
                      </label>
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        className="form-control-alternative"
                        value={this.state.discountpercentage}
                        onChange={this.DiscountpercentageFunction}
                        style={{ border: this.state.discountpercentageborder }}
                      />
                      {this.state.discountpercentageborder !==
                      "1px solid #dee2e6" ? (
                        <h5 style={{ color: "#fb6340" }}>
                          Please enter discount%
                        </h5>
                      ) : null}
                    </Col>
                    <Col md="3">
                      <label
                        className="form-control-label"
                        style={{ marginTop: 15 }}
                      >
                        Adjustment
                      </label>
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        className="form-control-alternative"
                        value={this.state.discount}
                        onChange={this.Adjustamount}
                        style={{ border: this.state.adjustmentborder }}
                      />
                      {this.state.adjustmentborder !== "1px solid #dee2e6" ? (
                        <h5 style={{ color: "#fb6340" }}>
                          Please add adjustment
                        </h5>
                      ) : null}
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 6 }}>
                    <Col md="3">
                      <label
                        className="form-control-label"
                        style={{ marginTop: 15, marginLeft: 20 }}
                      >
                        PaymentMode
                      </label>
                    </Col>
                    <Col md="3">
                      <Input
                        type="option"
                        list="company_value-list"
                        style={{ border: "1px solid #dee2e6" }}
                        className="form-control-alternative"
                        Value={this.state.PaymentMode}
                        onChange={this.Paymentmodeoption}
                      />
                      <datalist id="company_value-list">
                        {this.state.bank.map((item, key) => (
                          <option key={key} value={item.bankname} />
                        ))}
                      </datalist>
                    </Col>
                    <Col md="3">
                      <label
                        className="form-control-label"
                        style={{ marginTop: 15 }}
                      >
                        InvoiceAmount
                      </label>
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        style={{ border: "1px solid #dee2e6" }}
                        className="form-control-alternative"
                        value={Total}
                      />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: 6 }}>
                    <Col md="3">
                      <label
                        className="form-control-label"
                        style={{ marginTop: 15, marginLeft: 20 }}
                      >
                        Referenceno
                      </label>
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        className="form-control-alternative"
                        value={this.state.Referenceno}
                        onChange={this.ChangeReference}
                        style={{ border: this.state.referencenoborder }}
                      />
                      {this.state.referencenoborder !== "1px solid #dee2e6" ? (
                        <h5 style={{ color: "#fb6340" }}>
                          Please enter referenceno
                        </h5>
                      ) : null}
                    </Col>
                    <Col md="3">
                      <label
                        className="form-control-label"
                        style={{ marginTop: 15 }}
                      >
                        ReceivedAmount
                      </label>
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        className="form-control-alternative"
                        value={this.state.received_amount}
                        onChange={this.ChangeReceivedAmount}
                        style={{ border: this.state.receivedamountborder }}
                      />
                      {this.state.receivedamountborder !==
                      "1px solid #dee2e6" ? (
                        <h5 style={{ color: "#fb6340" }}>
                          Please enter receivedamount
                        </h5>
                      ) : null}
                    </Col>
                  </Row>
                  {this.state.Generaltask === 0 ? (
                    <Row style={{ marginTop: 6 }}>
                      <Col md="3">
                        <label
                          className="form-control-label"
                          style={{ marginTop: 15, marginLeft: 20 }}
                        >
                          ClientBalance
                        </label>
                      </Col>
                      <Col md="3">
                        <Input
                          type="text"
                          style={{ border: "1px solid #dee2e6" }}
                          className="form-control-alternative"
                          value={this.state.transactionClientBalance}
                        />
                      </Col>
                      <Col md="3">
                        <label
                          className="form-control-label"
                          style={{ marginTop: 15 }}
                        >
                          InvoiceBalance
                        </label>
                      </Col>
                      <Col md="3">
                        <Input
                          type="text"
                          style={{ border: "1px solid #dee2e6" }}
                          className="form-control-alternative"
                          value={this.state.transactioninvoicebalance}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {this.state.Generaltask === 1 ? (
                    <Row style={{ marginTop: 6 }}>
                      <Col md="3">
                        <label
                          className="form-control-label"
                          style={{ marginTop: 15, marginLeft: 20 }}
                        >
                          TotalAmount
                        </label>
                      </Col>
                      <Col md="3">
                        <Input
                          type="text"
                          className="form-control-alternative"
                          style={{ border: this.state.generaltaskborder }}
                          value={TotalGeneralamount}
                        />
                      </Col>
                    </Row>
                  ) : null}
                  {this.state.general !== 0 ? (
                    <h5
                      style={{
                        color: "#fb6340",
                        textAlign: "left",
                        paddingLeft: 20,
                      }}
                    >
                      Please enter amount less then invoicetotal
                    </h5>
                  ) : null}
                  {this.state.ZeroTotal !== 0 ? (
                    <h5 style={{ color: "#fb6340", textAlign: "center" }}>
                      Invoice total cannot be zero or less
                    </h5>
                  ) : null}
                  {this.state.Invocieisalreadycreated !== 0 ? (
                    <h5 style={{ color: "#fb6340", textAlign: "center" }}>
                      This task invoice is already created
                    </h5>
                  ) : null}
                  <br />
                  <Row>
                    <Col md="2">
                      <label
                        className="form-control-label"
                        style={{ marginTop: 15, marginLeft: 20 }}
                      >
                        <h4 style={{ color: this.state.cloneColor }}>
                          Clone Task
                        </h4>
                      </label>
                    </Col>
                    <Col md="2">
                      <label
                        className="form-control-label"
                        style={{ marginTop: 15 }}
                      >
                        <input
                          type="checkbox"
                          checked={this.state.ClonetaskCheckbox}
                          onChange={this.currentTaskCloneorNot}
                        />
                      </label>
                    </Col>
                    <Col md="3">
                      <label
                        className="form-control-label"
                        style={{ marginTop: 15 }}
                      >
                        ParentTaskbalance
                      </label>
                    </Col>
                    <Col md="3">
                      <Input
                        type="text"
                        style={{ border: "1px solid #dee2e6" }}
                        className="form-control-alternative"
                        value={parrentbalance}
                      />
                    </Col>
                    <Col md="2">
                      <button
                        id="buttonsave"
                        className="btn btn-dark"
                        style={{ marginTop: 4 }}
                        onClick={this.onSubmitForm}
                        disabled={this.state.isButtonDisabled}
                      >
                        Create
                      </button>
                    </Col>
                  </Row>
                </Table>
              </Card>
            </div>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
    allusers: state.allusers,
    tt_client: state.teamtask_client,
    tt_company: state.teamtask_company,
    tt_assignto: state.teamtask_assignto,
    tt_plantype: state.teamtask_plantype,
    tt_status: state.teamtask_status,
  };
};
export default connect(mapStateToProps, null)(AccountPopup);
