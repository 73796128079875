import React, { Component } from "react";
import Header from "../../../components/Headers/SimpleHeader";
import Chart from "react-apexcharts";
import { Link } from "react-router-dom";
import "./Invoicemanage.css";
import {
  Container,
  Row,
  Table,
  Col,
  Card,
  CardBody,
  Form,
  NavLink,
  CardTitle,
  Input,
  UncontrolledDropdown,
  DropdownMenu,
  DropdownToggle,
  DropdownItem,
} from "reactstrap";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import InvoiceRow from "./InvoiceRow";
import config from "react-global-configuration";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "../../../config";
import { connect } from "react-redux";
import consoleFunction from "../../../Helpers";
import moment from "moment";
import Popup from "reactjs-popup";
import InvoiceAppDF from "../PdfFormTypes/DetailedForm/InvoiceApp";
import InvoiceAppCF from "../PdfFormTypes/ConsolidateForm/InvoiceApp";
import InvoiceAppPS from "../PdfFormTypes/InvoicePendingForm/InvoiceApp";
import InvoiceAppOF from "../PdfFormTypes/OutstandingForm/InvoiceApp";
import ConInvoiceGroupForm from "../PdfFormTypes/ClientgroupInvoiceForm/ConsolidatedFolder/InvoiceApp";
import DetInvoiceGroupForm from "../PdfFormTypes/ClientgroupInvoiceForm/DetailedFolder/InvoiceApp";
import MultiInvoicePaid from "./MultiInvoicePaid";
import axios from "axios";

let details = 0,
  s = 0,
  globalyear = 0,
  yeardata = 0,
  year = new Date().getFullYear(),
  month = new Date().getMonth() + 1,
  previousyear = new Date().getFullYear() - 1;
let admincheck = "0",
  userid = 0;
let defaultclient = 99;
let noadmindefaultclient = 98;
let totalcompanies = [];
let filterinvoicesummary = [],
  q = "";
const authApi = config.get("apiDomain");
let selecteddata = [];
let todaydate = new Date().getDate(),
  threemonth = new Date().getMonth() - 2;
let client = "allclient",
  invoicestatus = ["Unpaid", "Partially Paid"],
  selectedcompany = 0;
let clientgroupId = 0;

let taxyearstart_date = "",
  taxyearend_date = "";

class InvoiceDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: true,
      InvoiceSummery: [],
      paid: 0,
      client_id: "",
      partiallypaid: 0,
      receivedamount: 0,
      unconfirmed: 0,
      balance: 0,
      paidstatus: 0,
      series: [],
      checked: false,
      checkedtick: false,
      clientdetails: this.props.clientdetails,
      statuseries: [],
      setClientNames: [],
      clientName: "",
      clientCompany: "",
      clientPhoneno: "",
      clientEmail: "",
      Invoice_Clientname: "",
      clientnameshow: 0,
      JsonObject: "",
      jsonclientname: "",
      jsonclientCompany: "",
      jsonclientphoneno: "",
      jsonclientemail: "",
      refershuser: "",
      dateRangeSt: new Date(),
      dateRangeEd: new Date(),
      year: "",
      Invociesummarydummy: [],
      taxyear: [],
      selectedcompanyid: "",
      selectedcompanyvalidate: "1px solid #dee2e6",
      selectedinvoice: [],
      companydetails: [],
      clientgroupDetails: [],
      clientGroup_Id: "",
      clientGroup_Name: "Select All",
      companyname: "",
      selectcompanyname: "Select Company",
      selectedinvoicehideshow: false,
      Totas: 20,
      Print: false,
      Printdata: [],
      allunpaid: 0,
      invoiceforperiod: 0,
      receivedforperiod: 0,
      Enddate: moment(new Date()).format("YYYY-MM-DD"),
      Startdate:
        month > 4
          ? moment(new Date(`${year}-${threemonth}-${todaydate}`)).format(
              "YYYY-MM-DD"
            )
          : moment(
              new Date(`${previousyear}-${threemonth}-${todaydate}`)
            ).format("YYYY-MM-DD"),
      datedat: "",
      OpeningBlance: 0,
      ClosingBlance: 0,
      currenttaxyearstartdate:
        month > 3
          ? moment(new Date(`${year}-04-01`)).format("YYYY-MM-DD")
          : moment(new Date(`${previousyear}-04-01`)).format("YYYY-MM-DD"),
      currenttaxyearenddate:
        month < 4
          ? moment(new Date(`${year}-03-31`)).format("YYYY-MM-DD")
          : moment(new Date(`${year + 1}-03-31`)).format("YYYY-MM-DD"),
      paymentseries: [0, 0, 0],
      statusseries: [0, 0, 0],
      paymentoptions: {
        chart: {
          type: "donut",
          width: 500,
          height: "90%",
        },
        theme: {
          palette: "palette1",
        },
        labels: [`Total(${0})`, `Outstanding(${0})`, `Received(${0})`],
        title: {
          text: "Payment",
          align: "2px",
        },
        events: {
          mounted: (chart) => {
            chart.windowResizeHandler();
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%",
            },
          },
        },
      },
      statusoptions: {
        chart: {
          type: "donut",
          width: 500,
          height: "90%",
        },
        theme: {
          palette: "palette1",
        },
        labels: [`Paid(${0})`, `PartiallyPaid(${0})`, `Unpaid(${0})`],
        title: {
          text: "Status",
          align: "2px",
        },
        events: {
          mounted: (chart) => {
            chart.windowResizeHandler();
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%",
            },
          },
        },
      },
    };
  }

  async componentDidMount() {
    let max = 0;
    let company_details = await fetch(`${authApi}companyfilter/?isactive=1`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    company_details.forEach((element) => {
      totalcompanies = [...totalcompanies, element.id];
    });
    this.setState({
      companydetails: company_details,
    });
    const responseyear = await this.checkTaxYear();
    responseyear.forEach((currentyear) => {
      if (currentyear.id > max) {
        max = currentyear.id;
      }
      if (this.state.currenttaxyearstartdate === currentyear.startdate) {
        this.setState({
          year: currentyear.taxyear,
        });
      }
    });

    this.setState({
      taxyear: responseyear,
      Startdate: this.state.currenttaxyearstartdate,
      Enddate: this.state.currenttaxyearenddate,
    });
    taxyearstart_date = this.state.currenttaxyearstartdate;
    taxyearend_date = this.state.currenttaxyearenddate;
    await this.checkAdmin();
    this.getInvoiceclientdetails();
    if (admincheck === "1") {
      client = "182";
    } else {
      client = "182";
    }
    invoicestatus = ["Unpaid", "Partially Paid"];
    selectedcompany = totalcompanies;
    if (this.state.clientGroup_Id === "") {
      clientgroupId = "NoClientGroupid";
    } else {
      clientgroupId = this.state.clientGroup_Id;
    }
    fetch(
      `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${this.state.Startdate}&enddate=${this.state.Enddate}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}
  `,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        let Invociedata = "";
        if (admincheck === "1") {
          //   Invociedata = json;
          // } else {
          Invociedata = json.filter(function (e) {
            // return (
            //   e.invoicesummaryclient_id === 110 ||
            //   e.invoicesummaryclient_id === 182
            // );
            return e.invoicesummaryclient_id === 182;
          });
          this.setState({
            Invoice_Clientname: "General-Printing",
          });
          // }

          // if (admincheck === "1") {
          //   this.calculatereceivedinvoiceamount(defaultclient);
          // } else {
          //this.calculatereceivedinvoiceamount(noadmindefaultclient);
          this.calculatereceivedinvoiceamount(client);
          this.calculateOpeningBalance(client);
          this.calculateclosingBalance(client);
          // }

          this.updatetaskname(Invociedata);
          this.calclulatesummerydetails(Invociedata);
          this.calculatestatus(Invociedata);
        } else {
          Invociedata = json.filter(function (e) {
            return e.invoicesummaryclient_id === 182;
          });
          this.setState({
            Invoice_Clientname: "General-Printing",
          });
          this.updatetaskname(Invociedata);
          this.calclulatesummerydetails(Invociedata);
          this.calculatestatus(Invociedata);
        }
      })
      .catch((err) => {
        console.log(err);
      });
    this.clientgroupdetails();
  }

  clientgroupdetails = () => {
    fetch(`${authApi}clientgroupfilter_new/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          clientgroupDetails: json,
        });
      });
  };

  updatetaskname = (summary) => {
    filterinvoicesummary = summary;
    this.setState({
      InvoiceSummery: summary,
      loading: false,
    });
  };

  search = (event) => {
    const cols =
      filterinvoicesummary[0] && Object.keys(filterinvoicesummary[0]);
    q = event.target.value;
    let filterdata = filterinvoicesummary.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c]
              .toString()
              .toLowerCase()
              .indexOf(event.target.value.toLowerCase()) > -1
          : null
      )
    );
    this.setState({
      InvoiceSummery: filterdata,
    });
  };

  checkAdmin = async () => {
    let currentuser = this.props.allusers.filter((userdetails) => {
      return userdetails.name === this.props.username;
    });
    admincheck = currentuser[0].isadmin;
    userid = currentuser[0].id;
  };

  checkTaxYear = () => {
    return fetch(`${authApi}taxyear/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  getInvoiceclientdetails = () => {
    fetch(`${authApi}jointclientdetails/`, {
      method: "post",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          setClientNames: json,
        });
      });
  };

  calclulatesummerydetails = (summary) => {
    let receivedamount = 0,
      balance = 0,
      totamt,
      ra,
      rb,
      rt = 0;
    const d = summary.filter((item) => {
      return item.invoicestatus !== "Cancelled";
    });
    d.forEach((element) => {
      receivedamount =
        parseInt(receivedamount) + parseInt(element.amount_received);
      balance = parseInt(balance) + parseInt(element.balancedue);
      totamt = receivedamount + balance;
      ra = Intl.NumberFormat("en-IN").format(receivedamount);
      rb = Intl.NumberFormat("en-IN").format(balance);
      rt = Intl.NumberFormat("en-IN").format(totamt);
    });
    this.setState({
      receivedamount: receivedamount,
      balance: balance,
      paymentseries: [0, parseInt(balance), parseInt(receivedamount)],
      paymentoptions: {
        chart: {
          type: "donut",
          width: 500,
          height: "90%",
        },
        theme: {
          palette: "palette1",
        },
        labels: [`Total(${rt})`, `Outstanding(${rb})`, `Received(${ra})`],
        title: {
          text: "Payment",
        },
        events: {
          mounted: (chart) => {
            chart.windowResizeHandler();
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%",
            },
          },
        },
      },
    });
  };

  calculatestatus = (summary) => {
    let partiallypaid = 0,
      paid = 0,
      Unpaid = 0;
    if (summary.length === 0) {
      this.updatePaidDonetChart(paid, partiallypaid, Unpaid);
    } else {
      const d = summary.filter((item) => {
        return item.InvoiceStatus !== "Cancelled";
      });
      d.forEach((element) => {
        if (element.invoicestatus === "Paid") {
          paid = paid + 1;
        } else {
          if (element.invoicestatus === "Partially Paid") {
            partiallypaid = partiallypaid + 1;
          } else {
            Unpaid = Unpaid + 1;
          }
        }
      });
      this.setState({
        paid: paid,
        partiallypaid: partiallypaid,
        unconfirmed: Unpaid,
        statuseries: [partiallypaid, paid, Unpaid],
      });
      this.updatePaidDonetChart(paid, partiallypaid, Unpaid);
    }
  };

  updatePaidDonetChart = (paid, partiallypaid, unpaid) => {
    this.setState({
      statusseries: [0, parseInt(partiallypaid), parseInt(unpaid)],
      statusoptions: {
        chart: {
          type: "donut",
          width: 500,
          height: "90%",
        },
        theme: {
          palette: "palette1",
        },
        labels: [
          `Paid(${paid})`,
          `PartiallyPaid(${partiallypaid})`,
          `Unpaid(${unpaid})`,
        ],
        title: {
          text: "Status",
        },
        events: {
          mounted: (chart) => {
            chart.windowResizeHandler();
          },
        },
        plotOptions: {
          pie: {
            donut: {
              size: "65%",
            },
          },
        },
      },
    });
  };

  selectClientgroupName = (event) => {
    this.setState({
      clientGroup_Name: event.target.value,
    });
    const value1 = event.target.value.replace(/ /g, "%20");
    fetch(`${authApi}clientgroupfilter_new/?name=${value1}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.length !== 0) {
          this.setState(
            {
              clientGroup_Id: json[0].id,
            },
            function () {
              this.callbackfunction();
            }
          );
        } else {
          this.setState(
            {
              clientGroup_Id: "",
            },
            function () {
              this.callbackfunction();
            }
          );
        }
      });
  };

  selectedctaxyeardetails = async () => {
    selecteddata = [];
    this.setState({
      InvoiceSummery: [],
      loading: true,
      selectedinvoicehideshow: false,
    });
    let Invociedata = 0;
    if (this.state.paidstatus === 0) {
      if (admincheck === "1") {
        if (this.state.clientGroup_Id === "") {
          clientgroupId = "NoClientGroupid";
        } else {
          clientgroupId = this.state.clientGroup_Id;
        }
        let summarydata = await fetch(
          `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${this.state.Startdate}&enddate=${this.state.Enddate}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${this.props.token}`,
            },
          }
        ).then((res) => res.json());
        Invociedata = summarydata;
      } else {
        if (this.state.clientGroup_Id === "") {
          clientgroupId = "NoClientGroupid";
        } else {
          clientgroupId = this.state.clientGroup_Id;
        }
        let summarydata = await fetch(
          `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${this.state.Startdate}&enddate=${this.state.Enddate}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${this.props.token}`,
            },
          }
        ).then((res) => res.json());
        Invociedata = summarydata;
        this.setState({
          Invoice_Clientname: "General",
        });
      }
      if (this.state.Invoice_Clientname === "") {
        let dates = [];
        Invociedata.forEach((invoice) => {
          dates.push(new Date(invoice.date));
        });
        if (admincheck === "1") {
          this.calculatereceivedinvoiceamount(defaultclient);
        } else {
          this.calculatereceivedinvoiceamount(noadmindefaultclient);
        }
        this.updatetaskname(Invociedata);
        this.calclulatesummerydetails(Invociedata);
        this.calculatestatus(Invociedata);
      } else {
        this.selectTaskClientName(this.state.Invoice_Clientname);
      }
    } else {
      if (this.state.Invoice_Clientname === "") {
        let enddate = moment(new Date()).format("YYYY-MM-DD");
        let d = new Date();
        d.setMonth(d.getMonth() - 3);
        let startdate = moment(d).format("YYYY-MM-DD");
        if (this.state.clientGroup_Id === "") {
          clientgroupId = "NoClientGroupid";
        } else {
          clientgroupId = this.state.clientGroup_Id;
        }
        let paiddetails = await fetch(
          `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${startdate}&enddate=${enddate}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${this.props.token}`,
            },
          }
        ).then((res) => res.json());
        if (admincheck === "1") {
          this.calculatereceivedinvoiceamount(defaultclient);
        } else {
          this.calculatereceivedinvoiceamount(noadmindefaultclient);
        }
        this.updatetaskname(paiddetails);
        this.calclulatesummerydetails(paiddetails);
        this.calculatestatus(paiddetails);
      } else {
        this.selectTaskClientName(this.state.Invoice_Clientname);
      }
    }
  };

  callbackfunction = async () => {
    selecteddata = [];
    this.setState({
      InvoiceSummery: [],
      loading: true,
      selectedinvoicehideshow: false,
    });
    let Invociedata = 0;
    if (this.state.paidstatus === 0) {
      if (admincheck === "1") {
        if (this.state.clientGroup_Id === "") {
          clientgroupId = "NoClientGroupid";
        } else {
          clientgroupId = this.state.clientGroup_Id;
        }
        let summarydata = await fetch(
          `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${taxyearstart_date}&enddate=${taxyearend_date}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${this.props.token}`,
            },
          }
        ).then((res) => res.json());
        Invociedata = summarydata;
      } else {
        if (this.state.clientGroup_Id === "") {
          clientgroupId = "NoClientGroupid";
        } else {
          clientgroupId = this.state.clientGroup_Id;
        }
        let summarydata = await fetch(
          `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${taxyearstart_date}&enddate=${taxyearend_date}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${this.props.token}`,
            },
          }
        ).then((res) => res.json());
        Invociedata = summarydata;
        this.setState({
          Invoice_Clientname: "General",
        });
      }

      if (this.state.Invoice_Clientname === "") {
        let dates = [];
        Invociedata.forEach((invoice) => {
          dates.push(new Date(invoice.date));
        });
        let minimumdate = new Date(Math.min.apply(null, dates));
        let currentyear = new Date(minimumdate).getFullYear();
        let currentmonth = new Date(minimumdate).getMonth() + 1;
        let currentstartdate =
          currentmonth > 3
            ? moment(new Date(`${currentyear}-04-01`)).format("YYYY-MM-DD")
            : moment(new Date(`${currentyear - 1}-04-01`)).format("YYYY-MM-DD");
        this.setState({
          Startdate: currentstartdate,
          Enddate: moment(new Date()).format("YYYY-MM-DD"),
        });
        if (admincheck === "1") {
          this.calculatereceivedinvoiceamount(defaultclient);
        } else {
          this.calculatereceivedinvoiceamount(noadmindefaultclient);
        }
        // })
        this.updatetaskname(Invociedata);
        this.calclulatesummerydetails(Invociedata);
        this.calculatestatus(Invociedata);
      } else {
        this.selectTaskClientName(this.state.Invoice_Clientname);
      }
    } else {
      if (this.state.Invoice_Clientname === "") {
        if (this.state.clientGroup_Id === "") {
          clientgroupId = "NoClientGroupid";
        } else {
          clientgroupId = this.state.clientGroup_Id;
        }
        let paiddetails = await fetch(
          `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${taxyearstart_date}&enddate=${taxyearend_date}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${this.props.token}`,
            },
          }
        ).then((res) => res.json());
        let dates = [];
        paiddetails.forEach((invoice) => {
          dates.push(new Date(invoice.date));
        });
        let minimumdate = new Date(Math.min.apply(null, dates));
        let currentyear = new Date(minimumdate).getFullYear();
        let currentmonth = new Date(minimumdate).getMonth() + 1;
        let currentstartdate =
          currentmonth > 3
            ? moment(new Date(`${currentyear}-04-01`)).format("YYYY-MM-DD")
            : moment(new Date(`${currentyear - 1}-04-01`)).format("YYYY-MM-DD");
        this.setState({
          Startdate: moment(currentstartdate).format("YYYY-MM-DD"),
          Enddate: moment(new Date()).format("YYYY-MM-DD"),
        });
        if (admincheck === "1") {
          this.calculatereceivedinvoiceamount(defaultclient);
        } else {
          this.calculatereceivedinvoiceamount(noadmindefaultclient);
        }
        this.updatetaskname(paiddetails);
        this.calclulatesummerydetails(paiddetails);
        this.calculatestatus(paiddetails);
      } else {
        this.selectTaskClientName(this.state.Invoice_Clientname);
      }
    }
  };

  getdate = (event, picker) => {
    q = "";
    this.setState({
      InvoiceSummery: [],
      dateRangeSt: picker.startDate,
      dateRangeEd: picker.endDate,
      Startdate: picker.startDate.format("YYYY-MM-DD"),
      Enddate: picker.endDate.format("YYYY-MM-DD"),
      selectedinvoicehideshow: false,
    });
    selecteddata = [];
    const sd = picker.startDate;
    const ed = picker.endDate;
    let a_sd = moment(sd).format("YYYY-MM-DD");
    let b_ed = moment(ed).format("YYYY-MM-DD");
    if (this.state.clientGroup_Id === "") {
      clientgroupId = "NoClientGroupid";
    } else {
      clientgroupId = this.state.clientGroup_Id;
    }
    if (this.state.client_id === "") {
      if (admincheck === "1") {
        this.calculatereceivedinvoiceamount(defaultclient);
      } else {
        this.calculatereceivedinvoiceamount(noadmindefaultclient);
      }
      fetch(
        `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${a_sd}&enddate=${b_ed}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((json) => {
          if (json.length === 0) {
            this.setState({
              invoiceforperiod: 0,
              receivedforperiod: 0,
            });
            this.calculatereceivedinvoiceamount(this.state.client_id);
            this.calculateOpeningBalance(this.state.client_id);
            this.calculateclosingBalance(this.state.client_id);
            this.calculatestatus(json);
            alert("No Invoice available for the Selected Filter.!");
          } else {
            if (this.state.paidstatus === 1) {
              this.updatetaskname(json);
              this.calclulatesummerydetails(json);
              this.calculatestatus(json);
            } else {
              const Invociedata = json.filter(function (e) {
                return (
                  e.invoicestatus !== "Paid" && e.invoicestatus !== "Cancelled"
                );
              });
              this.updatetaskname(Invociedata);
              this.calclulatesummerydetails(Invociedata);
              this.calculatestatus(Invociedata);
            }
          }
        });
    } else {
      fetch(
        `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${a_sd}&enddate=${b_ed}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((json) => {
          if (json.length === 0) {
            this.setState({
              invoiceforperiod: 0,
              receivedforperiod: 0,
            });
            this.calculatestatus(json);
            this.calculatereceivedinvoiceamount(this.state.client_id);
            this.calculateOpeningBalance(this.state.client_id);
            this.calculateclosingBalance(this.state.client_id);
            alert("No Invoice available for the Selected Filter.!");
          } else {
            if (admincheck === "1") {
              this.calculatereceivedinvoiceamount(client);
            } else {
              this.calculatereceivedinvoiceamount(client);
            }
            if (this.state.paidstatus === 1) {
              this.updatetaskname(json);
              this.calclulatesummerydetails(json);
              this.calculatestatus(json);
              if (this.state.client_id !== "") {
                this.setState({
                  OpeningBlance: 0,
                  ClosingBlance: 0,
                });
                this.calculatereceivedinvoiceamount(this.state.client_id);
                this.calculateOpeningBalance(this.state.client_id);
                this.calculateclosingBalance(this.state.client_id);
              }
            } else {
              const Invociedata = json.filter(function (e) {
                return (
                  e.invoicestatus !== "Paid" && e.invoicestatus !== "Cancelled"
                );
              });
              this.updatetaskname(Invociedata);
              this.calclulatesummerydetails(Invociedata);
              this.calculatestatus(Invociedata);
              if (this.state.client_id !== "") {
                this.setState({
                  OpeningBlance: 0,
                  ClosingBlance: 0,
                });
                this.calculatereceivedinvoiceamount(this.state.client_id);
                this.calculateOpeningBalance(this.state.client_id);
                this.calculateclosingBalance(this.state.client_id);
              }
            }
          }
        });
    }
  };

  selectClient = (event) => {
    q = "";
    this.selectTaskClientName(event.target.value);
  };

  selectTaskClientName = (clientname) => {
    selecteddata = [];
    this.setState({
      InvoiceSummery: [],
      loading: true,
      selectedinvoicehideshow: false,
    });
    let clientid = 0;
    if (clientname !== "") {
      this.state.clientdetails.forEach((ele) => {
        if (ele.company_name === clientname) {
          this.setState({
            clientnameshow: this.state.clientnameshow + 1,
            client_id: ele.id,
          });
          clientid = ele.id;
          client = ele.id;
        }
      });
    } else {
      client = "allclient";
      this.setState({
        OpeningBlance: 0,
        ClosingBlance: 0,
      });
    }
    this.setState({
      Invoice_Clientname: clientname,
      loading: true,
    });
    if (clientname === "") {
      if (this.state.clientGroup_Id === "") {
        clientgroupId = "NoClientGroupid";
      } else {
        clientgroupId = this.state.clientGroup_Id;
      }
      fetch(
        `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${taxyearstart_date}&enddate=${taxyearend_date}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((json) => {
          if (json.length === 0) {
            this.setState({
              invoiceforperiod: 0,
              receivedforperiod: 0,
            });
            this.calculatestatus(json);
            alert("No Task available for the Selected Filter.!");
            this.setState({
              loading: false,
            });
          } else {
            if (this.state.paidstatus === 1) {
              this.setState({
                client_id: "",
              });
              let enddate =
                this.state.currenttaxyearstartdate === taxyearstart_date
                  ? moment(new Date()).format("YYYY-MM-DD")
                  : taxyearend_date;

              this.setState({
                Enddate: moment(enddate).format("YYYY-MM-DD"),
              });
              if (admincheck === "1") {
                this.calculatereceivedinvoiceamount(defaultclient);
              } else {
                this.calculatereceivedinvoiceamount(noadmindefaultclient);
              }

              this.updatetaskname(json);
              this.calclulatesummerydetails(json);
              this.calculatestatus(json);
            } else {
              const Invociedata = json.filter(function (e) {
                return (
                  e.invoicestatus !== "Paid" && e.invoicestatus !== "Cancelled"
                );
              });
              let enddate =
                this.state.currenttaxyearstartdate === taxyearstart_date
                  ? moment(new Date()).format("YYYY-MM-DD")
                  : taxyearend_date;

              this.setState({
                Enddate: moment(enddate).format("YYYY-MM-DD"),
              });

              if (admincheck === "1") {
                this.calculatereceivedinvoiceamount(defaultclient);
              } else {
                this.calculatereceivedinvoiceamount(noadmindefaultclient);
              }

              this.updatetaskname(Invociedata);
              this.calclulatesummerydetails(Invociedata);
              this.calculatestatus(Invociedata);
            }
          }
        })
        .catch((err) => {
          consoleFunction(
            "Invoice filter data error",
            err.response.request.response
          );
        });
    } else {
      if (this.state.clientGroup_Id === "") {
        clientgroupId = "NoClientGroupid";
      } else {
        clientgroupId = this.state.clientGroup_Id;
      }
      fetch(
        `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${taxyearstart_date}&enddate=${taxyearend_date}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
        {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${this.props.token}`,
          },
        }
      )
        .then((res) => res.json())
        .then((json) => {
          if (json.length === 0) {
            this.setState({
              invoiceforperiod: 0,
              receivedforperiod: 0,
            });
            this.calculatestatus(json);
            alert("No Task available for the Selected Filter.!");
            this.setState({
              loading: false,
            });
            this.setState({
              OpeningBlance: 0,
              ClosingBlance: 0,
            });
            this.calculatereceivedinvoiceamount(clientid);
            this.calculateOpeningBalance(clientid);
            this.calculateclosingBalance(clientid);
          } else {
            if (this.state.paidstatus === 1) {
              this.setState({
                paid: 0,
                partiallypaid: 0,
              });
              let enddate =
                this.state.currenttaxyearstartdate === taxyearstart_date
                  ? moment(new Date()).format("YYYY-MM-DD")
                  : taxyearend_date;

              this.setState({
                Enddate: moment(enddate).format("YYYY-MM-DD"),
              });

              if (admincheck === "1") {
                this.calculatereceivedinvoiceamount(client);
              } else {
                this.calculatereceivedinvoiceamount(client);
              }

              this.updatetaskname(json);
              this.calclulatesummerydetails(json);
              this.calculatestatus(json);
              this.setState({
                OpeningBlance: 0,
                ClosingBlance: 0,
              });
              this.calculatereceivedinvoiceamount(clientid);
              this.calculateOpeningBalance(clientid);
              this.calculateclosingBalance(clientid);
            } else {
              const Invociedata = json.filter(function (e) {
                return (
                  e.invoicestatus !== "Paid" && e.invoicestatus !== "Cancelled"
                );
              });
              this.setState({
                paid: 0,
                partiallypaid: 0,
              });
              let enddate =
                this.state.currenttaxyearstartdate === taxyearstart_date
                  ? moment(new Date()).format("YYYY-MM-DD")
                  : taxyearend_date;

              this.setState({
                Enddate: moment(enddate).format("YYYY-MM-DD"),
              });

              if (admincheck === "1") {
                this.calculatereceivedinvoiceamount(client);
              } else {
                this.calculatereceivedinvoiceamount(client);
              }

              this.updatetaskname(Invociedata);
              this.calclulatesummerydetails(Invociedata);
              this.calculatestatus(Invociedata);
              this.setState({
                OpeningBlance: 0,
                ClosingBlance: 0,
              });
              this.calculatereceivedinvoiceamount(clientid);
              this.calculateOpeningBalance(clientid);
              this.calculateclosingBalance(clientid);
            }
          }
        })
        .catch((err) => {
          consoleFunction("Oops..!! Can you please refresh the page", err);
        });
    }
  };

  calculatereceivedinvoiceamount = async (clientid) => {
    let companyid = selectedcompany.length > 1 ? 0 : selectedcompany[0];
    let clientidnum = clientid === "allclient" ? 99 : clientid;
    await axios({
      method: "post",
      url: `${authApi}daterangeinvoicereceivedamount/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        clientid: clientidnum,
        company_id: companyid,
        startdate: this.state.Startdate,
        enddate: this.state.Enddate,
      },
    })
      .then((res) => {
        this.setState({
          invoiceforperiod: parseInt(res.data[0]) || 0,
          receivedforperiod: parseInt(res.data[1]) || 0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  calculateOpeningBalance = async (clientid) => {
    let taxyearstartdate =
      new Date(this.state.Startdate).getMonth() + 1 > 3
        ? `${new Date(this.state.Startdate).getFullYear()}-04-01`
        : `${new Date(this.state.Startdate).getFullYear() - 1}-04-01`;
    let companyid = selectedcompany.length > 1 ? 0 : selectedcompany[0];
    axios({
      method: "post",
      url: `${authApi}sp_calculateopeningbalance/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        company_id: companyid,
        client_id: clientid,
        taxyear_start_date: taxyearstartdate,
        start_date: this.state.Startdate,
      },
    })
      .then((res) => {
        this.setState({
          OpeningBlance:
            res.data.balance != null || res.data.balance !== ""
              ? res.data.balance
              : 0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };
  calculateclosingBalance = async (clientid) => {
    let taxyearstartdate =
      new Date(this.state.Enddate).getMonth() + 1 > 3
        ? `${new Date(this.state.Enddate).getFullYear()}-04-01`
        : `${new Date(this.state.Enddate).getFullYear() - 1}-04-01`;
    let companyid = selectedcompany.length > 1 ? 0 : selectedcompany[0];
    axios({
      method: "post",
      url: `${authApi}sp_calculateclosingbalance/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        client_id: clientid,
        company_id: companyid,
        startdate: taxyearstartdate,
        enddate: this.state.Enddate,
      },
    })
      .then((res) => {
        this.setState({
          ClosingBlance:
            res.data.balance != null || res.data.balance !== ""
              ? res.data.balance
              : 0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  DisplayPaidstatus = () => {
    q = "";
    if (this.state.paidstatus === 0) {
      invoicestatus = ["Unpaid", "Partially Paid", "Paid"];
      this.setState(
        {
          paidstatus: 1,
          checked: true,
        },
        function () {
          this.Paidstatusdetails();
        }
      );
    } else {
      invoicestatus = ["Unpaid", "Partially Paid"];
      this.setState(
        {
          paidstatus: 0,
          checked: false,
        },
        function () {
          this.Paidstatusdetails();
        }
      );
    }
  };

  Paidstatusdetails = async () => {
    this.setState({
      InvoiceSummery: [],
      loading: true,
    });

    if (this.state.Invoice_Clientname !== "") {
      this.state.clientdetails.forEach((ele) => {
        if (ele.company_name === this.state.Invoice_Clientname) {
          this.setState(
            {
              clientnameshow: this.state.clientnameshow + 1,
            },
            function () {
              client = ele.id;
              this.ClientPaiddetails();
            }
          );
        }
      });
    } else {
      client = "allclient";
      this.setState({
        clientnameshow: 0,
        Invociesummarydummy: [],
      });
      if (this.state.paidstatus === 1) {
        let summarydetails = [];
        invoicestatus = ["Unpaid", "Partially Paid", "Paid"];
        if (this.state.clientGroup_Id === "") {
          clientgroupId = "NoClientGroupid";
        } else {
          clientgroupId = this.state.clientGroup_Id;
        }
        summarydetails = await fetch(
          `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${this.state.Startdate}&enddate=${this.state.Enddate}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${this.props.token}`,
            },
          }
        ).then((res) => res.json());

        if (admincheck === "1") {
          this.calculatereceivedinvoiceamount(client);
        } else {
          this.calculatereceivedinvoiceamount(client);
        }

        this.updatetaskname(summarydetails);
        this.calclulatesummerydetails(summarydetails);
        this.calculatestatus(summarydetails);
      } else {
        if (this.state.paidstatus === 0) {
          if (this.state.clientGroup_Id === "") {
            clientgroupId = "NoClientGroupid";
          } else {
            clientgroupId = this.state.clientGroup_Id;
          }
          invoicestatus = ["Unpaid", "Partially Paid"];
          let summarydata = await fetch(
            `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${this.state.currenttaxyearstartdate}&enddate=${this.state.currenttaxyearenddate}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
            {
              method: "GET",
              headers: {
                "content-type": "application/json",
                Authorization: `token ${this.props.token}`,
              },
            }
          ).then((res) => res.json());

          if (admincheck === "1") {
            this.calculatereceivedinvoiceamount(client);
          } else {
            this.calculatereceivedinvoiceamount(client);
          }

          this.updatetaskname(summarydata);
          this.calclulatesummerydetails(summarydata);
          this.calculatestatus(summarydata);
        }
      }
    }
  };

  ClientPaiddetails = () => {
    if (this.state.clientGroup_Id === "") {
      clientgroupId = "NoClientGroupid";
    } else {
      clientgroupId = this.state.clientGroup_Id;
    }
    fetch(
      `${authApi}getinvoicewithfilterdata/?clientid=${client}&startdate=${this.state.Startdate}&enddate=${this.state.Enddate}&company_id=${selectedcompany}&status=${invoicestatus}&groupid=${clientgroupId}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        if (json.length === 0) {
          this.setState({
            invoiceforperiod: 0,
            receivedforperiod: 0,
          });
          this.calculatestatus(json);
          alert("No Task available for the Selected Filter.!");
          this.setState({
            loading: false,
          });
        } else {
          if (this.state.paidstatus === 1) {
            if (admincheck === "1") {
              this.calculatereceivedinvoiceamount(client);
            } else {
              this.calculatereceivedinvoiceamount(client);
            }

            this.updatetaskname(json);
            this.calclulatesummerydetails(json);
            this.calculatestatus(json);
          } else {
            let Invociedata = json.filter(function (e) {
              return (
                e.invoicestatus !== "Paid" && e.invoicestatus !== "Cancelled"
              );
            });

            if (admincheck === "1") {
              this.calculatereceivedinvoiceamount(client);
            } else {
              this.calculatereceivedinvoiceamount(client);
            }

            this.updatetaskname(Invociedata);
            this.calclulatesummerydetails(Invociedata);
            this.calculatestatus(Invociedata);
          }
        }
      })
      .catch((err) => {
        consoleFunction(
          "Oops..!! Can you please refresh the page",
          err.response.request.response
        );
        this.callbackfunction();
      });
  };

  selecttaxyear = (event) => {
    this.setState({
      year: event.target.value,
    });
    this.updateStartDateandEndDate(event.target.value);
  };

  updateStartDateandEndDate = (year) => {
    client = "allclient";
    selectedcompany = totalcompanies;
    if (this.state.paidstatus === 0) {
      invoicestatus = ["Unpaid", "Partially Paid"];
    } else {
      invoicestatus = ["Unpaid", "Partially Paid", "Paid"];
    }
    if (year === "Select All") {
      taxyearstart_date = "2019-01-01";
      taxyearend_date = "2019-01-01";
      this.setState(
        {
          Startdate: "2019-01-01",
          Enddate: "2019-01-01",
          selectedinvoicehideshow: false,
        },
        function () {
          this.callbackfunction();
        }
      );
    } else {
      this.state.taxyear.forEach((tax) => {
        if (tax.taxyear === year) {
          taxyearstart_date = tax.startdate;
          taxyearend_date = tax.enddate;
          this.setState(
            {
              Startdate: tax.startdate,
              Enddate: tax.enddate,
            },
            function () {
              this.selectedctaxyeardetails();
            }
          );
        }
      });
    }
  };

  getselectedinvoicedata = (invoiceid, selectdelete, companyid) => {
    let alreadyselected = 0;
    let data = {
      invoiceid: invoiceid,
      company_id: companyid,
    };
    if (selecteddata.length !== 0) {
      selecteddata.forEach((selectinvoiceid) => {
        if (selectinvoiceid.invoiceid === invoiceid) {
          alreadyselected = 1;
        }
      });
    }
    if (selectdelete === 1) {
      if (alreadyselected === 0) {
        selecteddata = [...selecteddata, data];
        this.setState({
          selectedinvoicehideshow: true,
        });
      }
    } else {
      let remainingdata = "";
      selecteddata.forEach((selectinvoiceid) => {
        if (selectinvoiceid.invoiceid !== invoiceid) {
          let data = {
            invoiceid: selectinvoiceid.invoiceid,
            company_id: selectinvoiceid.company_id,
          };
          remainingdata = [...remainingdata, data];
        }
      });
      selecteddata = [];
      selecteddata = remainingdata;
      if (selecteddata.length === 0) {
        this.setState({
          selectedinvoicehideshow: false,
        });
      }
    }
  };

  HandleCompany = (event) => {
    this.setState({
      companyname: event.target.value,
      selectedcompanyvalidate: "1px solid #dee2e6",
    });
    this.state.companydetails.forEach((company) => {
      if (company.companyname === event.target.value) {
        this.setState({
          selectedcompanyid: company.id,
        });
      }
    });
  };

  updateMoveInvoiceAnotherCompany = () => {
    let remainingdata = "";
    if (this.state.selectedcompanyid !== "") {
      selecteddata.forEach((selectinvoiceid) => {
        if (selectinvoiceid.company_id !== this.state.selectedcompanyid) {
          let data = {
            deliverynoteid: selectinvoiceid.invoiceid,
            companyid: selectinvoiceid.company_id,
          };
          remainingdata = [...remainingdata, data];
        }
      });
    } else {
      this.setState({
        selectedcompanyvalidate: "1px solid #fb6340",
      });
    }
    if (remainingdata !== "") {
      axios({
        method: "post",
        url: `${authApi}sp_moveselectedinvoice/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          invoicedetails: remainingdata,
          companyid: this.state.selectedcompanyid,
        },
      })
        .then((res) => {
          this.callbackfunction();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  selectCompanynameToFilter = (event) => {
    this.setState({
      selectcompanyname: event.target.value,
    });

    if ("Select All" !== event.target.value) {
      let companyid = 0;
      this.state.companydetails.forEach((company) => {
        if (company.companyname === event.target.value) {
          companyid = company.id;
        }
      });
      selectedcompany = [companyid];
      if (admincheck === "1") {
        this.calculatereceivedinvoiceamount(defaultclient);
      } else {
        this.calculatereceivedinvoiceamount(noadmindefaultclient);
      }
      this.callbackfunction();
    } else {
      selectedcompany = totalcompanies;
      this.callbackfunction();
    }
  };

  updateclientbasedClosingbalance = () => {
    axios({
      method: "post",
      url: `${authApi}sp_calculateclosingbalance/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        client_id: client,
        company_id: selectedcompany,
        startdate: this.state.currenttaxyearstartdate,
        enddate: this.state.Enddate,
      },
    })
      .then((res) => {
        this.setState({
          ClosingBlance:
            res.data.balance != null || res.data.balance !== ""
              ? res.data.balance
              : 0,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    const invoicedetails = [...this.state.InvoiceSummery]
      .reverse()
      .map((item) => (
        <InvoiceRow
          key={item.id}
          id={item.id}
          project_id={item.project_id}
          date={item.date}
          client={item.invoicesummaryclient_id}
          invoice_amount={item.invoice_amount}
          amount_received={item.amount_received}
          invoicestatus={item.invoicestatus}
          duedate={item.duedate}
          discount={item.discount}
          subtotal={item.subtotal}
          balancedue={item.balancedue}
          paymentmode={item.paymentmode}
          comment={item.comments}
          taskname={item.taskname}
          clientname={item.clientname}
          companyid={item.comp}
          clientcompanyname={item.clientcompanyname}
          isadmin={admincheck}
          currentuserid={userid}
          updatedata={this.callbackfunction}
          data={item}
          invoiceClientData={this.state.clientdetails}
          getselectedInvoice={this.getselectedinvoicedata}
          companydetails={this.state.companydetails}
        />
      ));
    if (this.state.clientnameshow === 0) {
      details = this.state.setClientNames.map((q) => q.company_name);
      s = details.filter((q, idx) => details.indexOf(q) === idx);
    }
    yeardata = this.state.taxyear.map((q) => q.taxyear);
    globalyear = yeardata.filter((q, idx) => yeardata.indexOf(q) === idx);
    globalyear = globalyear.reverse();
    let companynames = this.state.companydetails.map((q) => q.companyname);
    let nameofcompanyies = companynames.filter(
      (q, idx) => companynames.indexOf(q) === idx
    );
    return (
      <React.Fragment>
        <Header />
        <Container className="mt--7" fluid>
          <br />
          <div className="invoicegenerate">
            {this.state.Invoice_Clientname === "" ? null : (
              <Popup
                modal
                data-backdrop="static"
                trigger={
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ width: 40, height: 40, marginRight: -90 }}
                  >
                    <i className="fas fa-credit-card fa-1x"></i>
                  </button>
                }
              >
                {(close) => (
                  <MultiInvoicePaid
                    invoiceData={this.state.InvoiceSummery}
                    client={this.state.client_id}
                    closebtn={close}
                    refresh={this.callbackfunction}
                    Checkadmin={admincheck}
                    startdate={this.state.Startdate}
                    enddate={this.state.Enddate}
                  />
                )}
              </Popup>
            )}
            <UncontrolledDropdown nav style={{ marginLeft: 90 }}>
              <DropdownToggle nav>
                <button
                  className="btn btn-dark btn-sm"
                  style={{ width: "135%", height: 40 }}
                >
                  <i className="fa fa-download" aria-hidden="true"></i>
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                {this.state.Invoice_Clientname === "" ? (
                  <React.Fragment>
                    <DropdownItem size="sm">
                      <Popup
                        modal
                        data-backdrop="static"
                        trigger={<h5 className="text-left">Unpaid Reports</h5>}
                      >
                        {(close) => (
                          <InvoiceAppPS
                            invoiceData={this.state.InvoiceSummery}
                            dateStart={this.state.dateRangeSt}
                            dateEnd={this.state.dateRangeEd}
                            closebtn={close}
                          />
                        )}
                      </Popup>
                    </DropdownItem>
                    <DropdownItem size="sm">
                      <Popup
                        modal
                        data-backdrop="static"
                        trigger={<h5 className="text-left">Balance Reports</h5>}
                      >
                        {(close) => (
                          <InvoiceAppOF
                            dateStart={this.state.dateRangeSt}
                            dateEnd={this.state.dateRangeEd}
                            closebtn={close}
                          />
                        )}
                      </Popup>
                    </DropdownItem>
                  </React.Fragment>
                ) : null}
                {this.state.Invoice_Clientname === "" ? null : (
                  <React.Fragment>
                    <DropdownItem size="sm">
                      <Popup
                        modal
                        data-backdrop="static"
                        trigger={
                          <h5 className="text-left">Detailed Invoice</h5>
                        }
                      >
                        {(close) => (
                          <InvoiceAppDF
                            invoiceData={this.state}
                            closebtn={close}
                          />
                        )}
                      </Popup>
                    </DropdownItem>
                    <DropdownItem size="sm">
                      <Popup
                        modal
                        data-backdrop="static"
                        trigger={
                          <h5 className="text-left">Consolidated Invoice</h5>
                        }
                      >
                        {(close) => (
                          <InvoiceAppCF
                            invoiceData={this.state}
                            closebtn={close}
                          />
                        )}
                      </Popup>
                    </DropdownItem>
                  </React.Fragment>
                )}
                {this.state.clientGroup_Name === "" ||
                this.state.clientGroup_Name === "Select All" ? null : (
                  <React.Fragment>
                    <DropdownItem size="sm">
                      <Popup
                        modal
                        data-backdrop="static"
                        trigger={
                          <h5 className="text-left">CG Consolidate Report</h5>
                        }
                      >
                        {(close) => (
                          <ConInvoiceGroupForm
                            invoiceData={this.state.InvoiceSummery}
                            invoiceClientData={this.state.clientdetails}
                            dateStart={this.state.dateRangeSt}
                            dateEnd={this.state.dateRangeEd}
                            clientGroupName={this.state.clientGroup_Name}
                            closebtn={close}
                          />
                        )}
                      </Popup>
                    </DropdownItem>
                    <DropdownItem size="sm">
                      <Popup
                        modal
                        data-backdrop="static"
                        trigger={
                          <h5 className="text-left">CG Detailed Report</h5>
                        }
                      >
                        {(close) => (
                          <DetInvoiceGroupForm
                            invoiceData={this.state.InvoiceSummery}
                            invoiceClientData={this.state.clientdetails}
                            dateStart={this.state.dateRangeSt}
                            dateEnd={this.state.dateRangeEd}
                            clientGroupName={this.state.clientGroup_Name}
                            closebtn={close}
                          />
                        )}
                      </Popup>
                    </DropdownItem>
                  </React.Fragment>
                )}
                {admincheck === "1" ? (
                  <DropdownItem size="sm">
                    <NavLink
                      to={{ pathname: "/admin/filter/transaction-report" }}
                      tag={Link}
                    >
                      <h5 className="text-left" style={{ marginLeft: "-13px" }}>
                        Go to Transactions
                      </h5>
                    </NavLink>
                  </DropdownItem>
                ) : null}
                <DropdownItem size="sm">
                  <h5 className="text-left" onClick={this.DisplayPaidstatus}>
                    {this.state.checked ? "Hide Paid" : "Show Paid"}
                  </h5>
                </DropdownItem>
                {admincheck === "1" ? (
                  <NavLink
                    to={{ pathname: "/admin/management/invoicecorrection" }}
                    tag={Link}
                  >
                    <h5 className="text-left"> InvoiceCorrection </h5>
                  </NavLink>
                ) : null}
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
          <div
            role="tabpanel"
            class="tab-pane"
            style={{ marginTop: -40, marginLeft: -50 }}
          >
            <div class="tab-para">
              <div class="row">
                <div class="col-lg-2 col-md-2 col-sm-2">
                  <div class="single-tab-select-box">
                    <h5
                      style={{ color: "black", fontWeight: 700, fontSize: 13 }}
                    >
                      Tax Year
                    </h5>
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                      }}
                      type="select"
                      className="form-control"
                      value={this.state.year}
                      onChange={this.selecttaxyear}
                    >
                      {globalyear.map((item, key) => (
                        <option key={key} value={item}>
                          {item}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2"
                  style={{ marginLeft: -15 }}
                >
                  <div class="single-tab-select-box">
                    <h5
                      style={{ color: "black", fontWeight: 700, fontSize: 13 }}
                    >
                      Company
                    </h5>
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                      }}
                      type="select"
                      className="form-control"
                      value={this.state.selectcompanyname}
                      onChange={this.selectCompanynameToFilter}
                    >
                      <option value="Select All">All Company</option>
                      {nameofcompanyies.map((item, key) => (
                        <option key={key} value={item}>
                          {item}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div
                  class="col-lg-2 col-md-2 col-sm-2"
                  style={{ marginLeft: "-10px" }}
                >
                  <div class="single-tab-select-box">
                    <h5
                      style={{ color: "black", fontWeight: 700, fontSize: 13 }}
                    >
                      Client group
                    </h5>
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                      }}
                      type="select"
                      className="form-control"
                      value={this.state.clientGroup_Name}
                      onChange={this.selectClientgroupName}
                    >
                      <option value="Select All">All Clientgroup</option>
                      {this.state.clientgroupDetails.map((item, key) => (
                        <option key={key} value={item.name}>
                          {item.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                  <div class="single-tab-select-box">
                    <h5
                      style={{
                        color: "black",
                        fontWeight: 700,
                        fontSize: 13,
                        marginLeft: "-15px",
                      }}
                    >
                      Clients
                    </h5>
                    <Input
                      style={{
                        height: "35px",
                        overflow: "hidden",
                        padding: "2px 6px 2px",
                        marginLeft: "-15px",
                      }}
                      type="select"
                      className="form-control"
                      value={this.state.Invoice_Clientname}
                      onChange={this.selectClient}
                    >
                      {admincheck === "1" ? (
                        <option value="">All Client</option>
                      ) : null}
                      {s
                        .sort((a, b) => a.localeCompare(b))
                        .map((item, key) => (
                          <option key={key} value={item}>
                            {item}
                          </option>
                        ))}
                    </Input>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                  <div class="single-tab-select-box">
                    <h5
                      style={{
                        color: "black",
                        fontWeight: 700,
                        fontSize: 13,
                        marginLeft: "-30px",
                      }}
                    >
                      Date
                    </h5>
                    <DateRangePicker
                      autoUpdateInput={false}
                      onApply={this.getdate}
                      initialSettings={{
                        locale: {
                          format: "DD/MM/YYYY",
                        },
                      }}
                    >
                      <button
                        className="form-control"
                        style={{
                          height: "35px",
                          width: "142%",
                          overflow: "hidden",
                          padding: "2px 2px 2px",
                          marginLeft: "-30px",
                        }}
                      >
                        <i
                          className="fa fa-calendar-o"
                          style={{ paddingRight: 15 }}
                          aria-hidden="true"
                        ></i>
                        {moment(this.state.Startdate).format("DD/MM/YYYY")} -{" "}
                        {moment(this.state.Enddate).format("DD/MM/YYYY")}
                      </button>
                    </DateRangePicker>
                  </div>
                </div>
                <div class="col-lg-2 col-md-2 col-sm-2">
                  <div class="single-tab-select-box">
                    {this.state.Invoice_Clientname === "" ? (
                      <>
                        <Form
                          className="searchForm"
                          style={{ marginTop: 17, right: "-120px" }}
                        >
                          <input
                            type="search"
                            className="searchBox"
                            placeholder="Search"
                            value={q}
                            onChange={this.search}
                          ></input>
                        </Form>
                      </>
                    ) : (
                      <>
                        <Form
                          className="searchForm"
                          style={{ marginTop: 17, right: "-15px" }}
                        >
                          <input
                            type="search"
                            className="searchBox"
                            placeholder="Search"
                            value={q}
                            onChange={this.search}
                          ></input>
                        </Form>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div role="tabpanel" class="tab-pane" style={{ marginTop: -40 }}>
            <div class="tab-para">
              <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-12">
                  <div class="single-tab-select-box">
                    <Card className="card-stats">
                      <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total Invoiced
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.state.invoiceforperiod.toLocaleString(
                                navigator.language
                              )}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i className="fa fa-arrow-up" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="card-stats">
                      <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Total Received
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.state.receivedforperiod.toLocaleString(
                                navigator.language
                              )}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-success text-white rounded-circle shadow">
                              <i className="fa fa-arrow-down" />
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-12">
                  <div class="single-tab-select-box">
                    <Card className="card-stats">
                      <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Opening Balance
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.state.OpeningBlance.toLocaleString(
                                navigator.language
                              )}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i class="fas fa-balance-scale-right"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                    <Card className="card-stats">
                      <CardBody style={{ padding: "0.5rem 0.75rem" }}>
                        <Row>
                          <div className="col">
                            <CardTitle
                              tag="h5"
                              className="text-uppercase text-muted mb-0"
                            >
                              Closing Balance
                            </CardTitle>
                            <span className="h2 font-weight-bold mb-0">
                              {this.state.ClosingBlance.toLocaleString(
                                navigator.language
                              )}
                            </span>
                          </div>
                          <Col className="col-auto">
                            <div className="icon icon-shape bg-warning text-white rounded-circle shadow">
                              <i class="fas fa-balance-scale-left"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </div>
                </div>
                <Col md="6">
                  <Card>
                    <CardBody style={{ marginTop: -15, marginBottom: -10 }}>
                      <Chart
                        options={this.state.statusoptions}
                        series={this.state.statusseries}
                        type="donut"
                        width={500}
                        height="90%"
                      />
                    </CardBody>
                  </Card>
                </Col>
              </div>
            </div>
          </div>
          {this.state.selectedinvoicehideshow === true ? (
            <Row style={{ marginTop: -10 }}>
              <Col md="2" style={{ paddingTop: "8px", marginTop: -5 }}>
                <h3>
                  <b>MOVE TO</b>
                </h3>
              </Col>
              <Col md="4">
                <Input
                  style={{
                    height: "35px",
                    overflow: "hidden",
                    padding: "2px 6px 2px",
                    marginLeft: "-15px",
                    border: this.state.selectedcompanyvalidate,
                  }}
                  type="select"
                  className="form-control"
                  value={this.state.companyname}
                  onChange={this.HandleCompany}
                >
                  <option value="">All Company</option>
                  {nameofcompanyies.map((item, key) => (
                    <option key={key} value={item}>
                      {item}
                    </option>
                  ))}
                </Input>
                {this.state.selectedcompanyvalidate !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>Please select Company</h5>
                ) : null}
              </Col>
              <Col md="2">
                <button
                  type="button"
                  class="btn btn-info btn-rounded btn-sm"
                  style={{ width: 65, height: 30 }}
                  onClick={this.updateMoveInvoiceAnotherCompany}
                >
                  Move
                </button>
              </Col>
              <Col md="4"></Col>
            </Row>
          ) : (
            <Row style={{ marginTop: -40 }}></Row>
          )}
          {this.state.loading === true ? (
            <div>
              <ConPreLoader />
            </div>
          ) : (
            <Row>
              <div className=" col">
                <br />
                <Card className="shadow">
                  <Table className="align-items-center table-flush" responsive>
                    <thead className="thead-light">
                      <tr>
                        <th className="text-center"></th>
                        <th className="text-center"> Action </th>
                        <th className="text-center"> SalesorderId </th>
                        <th className="text-center"> InvoiceId </th>
                        <th className="text-center"> Task </th>
                        <th className="text-center"> Date </th>
                        <th className="text-center"> Clients </th>
                        <th className="text-center"> Company Name</th>
                        <th className="text-center"> InvoiceAmount </th>
                        <th className="text-center"> ReceivedAmount </th>
                        <th className="text-center"> Balance </th>
                        <th className="text-center"> Status </th>
                        <th className="text-center"> Comments </th>
                      </tr>
                    </thead>
                    <tbody>{invoicedetails}</tbody>
                  </Table>
                </Card>
              </div>
            </Row>
          )}
          <br />
          <br />
          <br />
          <br />
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails: state.clientdetails,
    username: state.username,
    allusers: state.allusers,
  };
};
export default connect(mapStateToProps, null)(InvoiceDetails);
