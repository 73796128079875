import React, { Component } from "react";
import { Link } from "react-router-dom";
import * as firebase from "firebase/app";
import axios from "axios";
import {
  userLogin,
  googleLogin,
  Usertoken,
} from "../../components/loginRedux/loginReducers";
import store from "../../components/loginRedux/Store";
import "firebase/auth";
import consoleFunction from "../../Helpers";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  NavLink,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Row,
  Col,
} from "reactstrap";

import config from "react-global-configuration";
import "../../config";

const authApi = config.get("apiDomain");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      error: "",
      items: [],
      username: "",
      approvedUser: "",
      token: "",
    };
    this.handleInputName = this.handleInputName.bind(this);
    this.handleInputpassword = this.handleInputpassword.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleGoogleLogin = this.handleGoogleLogin.bind(this);
    this.handleFacebookLogin = this.handleFacebookLogin.bind(this);
    this.CheckApprovedUser = this.CheckApprovedUser.bind(this);
  }

  componentDidMount() {
    fetch(`${authApi}users/`)
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
        });
      });
  }

  handleInputName = (event) => {
    this.setState({
      username: event.target.value,
    });
  };

  handleInputpassword = (event) => {
    this.setState(
      {
        password: event.target.value,
      },
      function () {
        this.CheckApprovedUser();
      }
    );
  };

  CheckApprovedUser = () => {
    this.state.items.forEach((item) => {
      if (item.name === this.state.username) {
        this.setState({
          approvedUser: item.isapproved,
        });
      }
    });
  };

  handleSubmit = (event) => {
    const { username, password } = this.state;
    let data = {
      username: this.state.username.replace(/\s/g, ""),
      password: this.state.password,
    };
    event.preventDefault();
    let userEmailid = "";
    this.state.items.forEach((item) => {
      if (item.name === username) {
        userEmailid = item.mailid;
      }
    });

    if (this.state.approvedUser === "1") {
      fetch(`${authApi}user/`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      }).then((res) => {
        if (res.status === 201 || res.status === 400) {
          axios
            .post(`${authApi}api-token-auth/`, {
              username: this.state.username.replace(/\s/g, ""),
              password: this.state.password,
            })
            .then((res) => {
              localStorage.setItem("token", res.data.token);
              store.dispatch({ type: Usertoken, detail: res.data.token });
              if (userEmailid === "") {
                return alert("username doesn't exists");
              } else {
                firebase
                  .auth()
                  .signInWithEmailAndPassword(userEmailid, password)
                  .then((response) => {
                    var info = firebase.auth().currentUser;
                    store.dispatch({ type: userLogin, detail: info });
                  })
                  .catch((event) => {
                    consoleFunction("Login credential error", event.message);
                    this.setState({ error: event.message });
                  });
              }
            })
            .catch((err) => {
              consoleFunction(
                "User credential error",
                err.response.request.response
              );
              this.setState({ error: err.response.request.response });
            });
        }
      });
    } else {
      return alert(
        "You are not approved User Please contact Admin Info@pgcadd.com"
      );
    }
  };

  handleGoogleLogin = (event) => {
    const provider = new firebase.auth.GoogleAuthProvider();
    event.preventDefault();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        var user = firebase.auth().currentUser;
        store.dispatch({ type: googleLogin, detail: user });
      })
      .catch((error) => {
        consoleFunction("Google login credential error", error.message);
        this.setState({ error: error.message });
      });
  };

  handleFacebookLogin = (event) => {
    const provider = new firebase.auth.FacebookAuthProvider();
    event.preventDefault();
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((res) => {
        var user = firebase.auth().currentUser;
        store.dispatch({ type: googleLogin, detail: user });
      })
      .catch((error) => {
        consoleFunction("Facebook login credential error", error.message);
        this.setState({ error: error.message });
      });
  };

  render() {
    return (
      <React.Fragment>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-5">
              <div className="text-muted text-center mt-2 mb-3">
                <small> Sign In with </small>
              </div>
              <div className="btn-wrapper text-center">
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  onClick={this.handleFacebookLogin}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/icons/common/facebook.svg")
                          .default
                      }
                    />
                  </span>
                  <span className="btn-inner--text"> Facebook </span>
                </Button>
                <Button
                  className="btn-neutral btn-icon"
                  color="default"
                  onClick={this.handleGoogleLogin}
                >
                  <span className="btn-inner--icon">
                    <img
                      alt="..."
                      src={
                        require("../../assets/img/icons/common/google.svg")
                          .default
                      }
                    />
                  </span>
                  <span className="btn-inner--text"> Google </span>
                </Button>
              </div>
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small> Or Sign In with credentials </small>
              </div>
              <Form role="form" onSubmit={this.handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="username"
                      placeholder="Username"
                      value={this.state.username}
                      onChange={this.handleInputName}
                    />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="password"
                      name="password"
                      placeholder="Password"
                      value={this.state.password}
                      onChange={this.handleInputpassword}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id=" customCheckLogin"
                    type="checkbox"
                  />
                  <label
                    className="custom-control-label"
                    htmlFor=" customCheckLogin"
                  >
                    <span className="text-muted">Remember Me</span>
                  </label>
                </div>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Sign In
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <span>{this.state.error}</span>
          <Row className="mt-3">
            <Col xs="6">
              <NavLink
                className="text-light"
                to="/auth/password/reset"
                tag={Link}
              >
                <small> Forgot Password ? </small>
              </NavLink>
            </Col>
            <Col className="text-right" xs="6">
              <NavLink className="text-light" to="/auth/register" tag={Link}>
                <small>Create New Account</small>
              </NavLink>
            </Col>
          </Row>
        </Col>
      </React.Fragment>
    );
  }
}

export default Login;
