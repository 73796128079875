import React from 'react';
import {Input,Label} from 'reactstrap';
import moment from 'moment';
class InvoiceCorrectionRow extends React.Component{
    constructor(props){
        super(props);
        this.state={
           Amount:this.props.invoicedata.amount,
           Pmtmode:this.props.invoicedata.pmtmode,
           Pmtreference:this.props.invoicedata.pmtreference,
           date:moment(this.props.invoicedata.date).format("YYYY-MM-DD"),
           edittable:false,
        }
    }
    componentDidMount(){
        
    }

    componentWillReceiveProps(nextProps) {
      this.setState({
        Amount: nextProps.invoicedata.amount,
        Pmtmode: nextProps.invoicedata.pmtmode,
        Pmtreference: nextProps.invoicedata.pmtreference,
        date: moment(nextProps.invoicedata.date).format("YYYY-MM-DD"),
      });
    }
  
    changeAmount=(event)=>{
     this.setState({
        Amount:event.target.value,
     },function(){
            this.upodatetransactiondetails();
        })
    }

    changePmtmode=(event)=>{
        this.setState({
            Pmtmode:event.target.value,
        },function(){
            this.upodatetransactiondetails();
        })
    }


    changePmtreference=(event)=>{
        this.setState({
            Pmtreference:event.target.value,
        },function(){
            this.upodatetransactiondetails();
        })
    }
    
    upodatetransactiondetails=()=>{
        this.props.updatetransactiondetails(this.props.invoicedata.id,this.state.Amount,this.state.Pmtmode,this.state.Pmtreference,this.state.date);
    }

    changeDate=(event)=>{
        this.setState({
          date:event.target.value,
        },function(){
          this.upodatetransactiondetails();
      })
    }

    editableTransaction=(event)=>{
        this.setState({
            edittable:!this.state.edittable
        })
    }

    render(){
        return(
            <>
            {this.state.edittable===true ?
            <tr>
              <td className='text-center'>
                <Input 
                  type="checkbox"
                  style={{ width: '20px',height: '20px',marginTop:-10}}
                  onChange={this.editableTransaction}
                />
              </td>
              <td className="text-center">
               <Input 
                 type='date'
                 style={{textAlign:'center'}}
                 value={this.state.date}
                 onChange={this.changeDate}
               />
             </td>
            <td className="text-center">
               <Input 
                 style={{textAlign:'center'}}
                 value={this.state.Amount}
                 onChange={this.changeAmount}
               />
            </td>
            <td className="text-center">
              <Input
                type="select"
                list="company_value-list"
                className="form-control-alternative"
                Value={this.state.Pmtmode}
                onChange={this.changePmtmode}
              >
                {this.props.bank
                  .sort((a, b) => (a.bankname > b.bankname ? 1 : -1))
                  .map((item, key) => (
                    <option key={key} value={item.bankname}>
                      {item.bankname}
                    </option>
                  ))}
              </Input>


            </td>
            <td className="text-center">
               <Input 
               style={{textAlign:'center'}}
               value={this.state.Pmtreference}
               onChange={this.changePmtreference}
               />
            </td>
       </tr>:
       <tr>
         <td className='text-center'>
                <Input 
                  type="checkbox"
                  style={{ width: '20px',height: '20px',marginTop:-10}}
                  onChange={this.editableTransaction}
                />
              </td>
              <td className="text-center">
               <Label>{this.state.date}</Label>
             </td>
            <td className="text-center">
            <Label>{this.state.Amount}</Label>
            </td>
            <td className="text-center">
            <Label>{this.state.Pmtmode}</Label>
            </td>
            <td className="text-center">
            <Label>{this.state.Pmtreference}</Label>
            </td>
       </tr>}
       </>
        )
    }
}

export default InvoiceCorrectionRow;