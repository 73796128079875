import React from "react";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../../config";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import {
  Container,
  Card,
  Row,
  Col,
  Input,
  CardHeader,
  Table,
} from "reactstrap";
import moment from "moment";
import EditDispatchedrow from "./EditDispatchedrow";
let StoreprocData = [],
  totalamountid = 0;
const authApi = config.get("apiDomain");
let Stockid = 1;
class EditDispatched extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Clientdetails:this.props.clientdetails,
      ClientName: this.props.Editdata.supplier,
      Clientid: "",
      Reference: this.props.Editdata.reference,
      Date: moment(new Date()).format("YYYY-MM-DD"),
      Expecteddispatchdate: moment(new Date() + 10)
        .add(10, "days")
        .format("YYYY-MM-DD"),
      CompanyDetails: [],
      SelectedSupplier: [],
      Company: this.props.Editdata.company,
      Companyid: "",
      Status: "Sales",
      Stockdetails: [],
      Stockname: [],
      gstcheck: true,
      TotalTaxableValue: 0,
      TotalCGSTAmount: 0,
      TotalSGSTAmount: 0,
      TotalAmount: 0,
      Gsttype: "SGST",
      TotalQuantity: 0,
      Adjustamount: parseFloat(this.props.Editdata.roundoff).toFixed(2),
      GrandTotalAmount: 0,
      ValidCompany: "none",
      ValidClientName: "none",
      ValidAdjustamount: "none",
      ValidVehicleNo: "none",
      nullvalueisthere: false,
      PurchaseOrderid: [],
      Invoiceno: "",
      Location: [],
      Batch: [],
      updateSalesQty: false,
      Inventorydata: [],
      ShowSaleRequest: false,
      TotalDispatchQty: 0,
      AdvanceAmount: parseFloat(this.props.Editdata.advancereceived) - parseFloat(this.props.Editdata.advanceused),
      userid: "",
      VehicleNumber: "",
      deliveryaddress: "",
    };
  }

  async componentDidMount() {
    this.setState({
      AdvanceAmount: parseFloat(this.state.AdvanceAmount).toFixed(2),
    })
    await this.getPurchaseInventorydata();
    await this.updateEditStockdetails();
    await this.getuserid();
    this.getTotalStockdetails();
    this.getLocations();
    this.getBatchs();
    await this.getClientDetails();
    this.getCompanydetails();
    this.calculateTotalAmount();
  }

  updateEditStockdetails = () => {
    this.props.Editdata.sales.forEach((sales) => {
      let available = 0;
      this.state.Inventorydata.forEach((inventory) => {
        if (inventory.stockname === sales.stockname) {
          available = inventory.totalquantity;
        }
      });
      totalamountid = sales.stockname === 'Total' ? sales.id : totalamountid;
      let salesqty=parseInt(sales.quantity) - parseInt(sales.usedqty);
      if ((sales.status === 'ReadyToDispatch' || sales.status === 'Partially Dispatched') && sales.stockname !== 'Total'&& salesqty>0) {
        let addStock = {
          id: sales.id,
          name: sales.stockname,
          productcode: sales.productcode,
          quantity: available,
          salesquantity: parseInt(sales.quantity) - parseInt(sales.usedqty),
          dispatchqty: 0,
          rate: sales.rate,
          amount: 0,
          cgstpercentage: sales.cgstpercentage,
          sgstpercentage: sales.sgstpercentage,
          total: 0,
        };
        this.setState({
          Stockdetails: [...this.state.Stockdetails, addStock],
        });
      }
    });
    this.updateTotalAmount();
  };

  updateTotalAmount = () => {
    this.props.Editdata.sales.forEach((sales) => {
      let available = 0;
      totalamountid = sales.stockname === 'Total' ? sales.id : totalamountid;
      if (sales.stockname === 'Total') {
        let addStock = {
          id: sales.id,
          name: sales.stockname,
          productcode: sales.productcode,
          quantity: available,
          salesquantity: parseInt(sales.quantity) - parseInt(sales.usedqty),
          dispatchqty: 0,
          rate: sales.rate,
          amount: 0,
          cgstpercentage: sales.cgstpercentage,
          sgstpercentage: sales.sgstpercentage,
          total: 0,
        };
        this.setState({
          Stockdetails: [...this.state.Stockdetails, addStock],
        });
      }
    });
  }



  getuserid = async () => {
    let response = await fetch(
      `${authApi}userfilter/?name=${this.props.username}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    ).then((res) => res.json());
    response.forEach((userid) => {
      this.setState({
        userid: userid.id,
      });
    });
  };

  getPurchaseInventorydata = async () => {
    await axios({
      method: "post",
      url: `${authApi}getavailableqtytemplate/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
    })
      .then(res => {
        this.setState({
          Inventorydata: res.data,
        });
      })
      .catch(err => {
        console.log(err)
      })

  };

  getTotalStockdetails = async () => {
    let response = await fetch(`${authApi}stocks/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    this.setState({
      Stockname: response,
    });
  };

  getLocations = async () => {
    let response = await fetch(`${authApi}location/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    this.setState({
      Location: response,
    });
  };

  getBatchs = async () => {
    let response = await fetch(`${authApi}batch/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    this.setState({
      Batch: response,
    });
  };

  getCompanydetails = () => {
    fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          CompanyDetails: json,
        });
        json.forEach((company) => {
          if (company.companyname === this.props.Editdata.company) {
            this.setState({
              Companyid: company.id,
              gstcheck: company.isgst === true ? true : false,
            });
          }
        });
      });
  };

  getClientDetails = async () => {
        this.props.clientdetails.forEach((client) => {
          if (client.company_name === this.props.Editdata.supplier) {
            this.setState({
              Clientid: client.id,
            });
          }
        });
  };

  selectClient = (event) => {
    if (event.target.value === "Select Supplier") {
      this.setState({
        ClientName: "",
      });
    } else {
      this.setState(
        {
          ClientName: event.target.value,
        },
        function () {
          this.getClientid(event.target.value);
        }
      );
    }
  };

  getClientid = (clientname) => {
    this.state.Clientdetails.forEach((client) => {
      if (client.company_name === clientname) {
        this.setState({
          Clientid: client.id,
        });
      }
    });
  };

  getReference = (event) => {
    this.setState({
      Reference: event.target.value,
    });
  };

  changeGst = (event) => {
    this.setState({
      Gsttype: event.target.value,
    });
  };
  changeDispatchdate = (event) => {
    this.setState({
      Expecteddispatchdate: event.target.value,
    });
  };

  changeDate = (event) => {
    this.setState({
      Date: event.target.value,
    });
  };

  selectCompany = (event) => {
    if (event.target.value === "Select Company") {
      this.setState({
        Company: "",
      });
    } else {
      this.state.CompanyDetails.forEach((company) => {
        if (company.companyname === event.target.value) {
          this.setState({
            Company: event.target.value,
            Companyid: company.id,
            gstcheck: company.isgst,
          });
        }
      });
    }
  };

  changeStatus = (event) => {
    this.setState({
      Status: event.target.value,
    });
  };

  addAnotherStock = () => {
    Stockid = Stockid + 1;
    let Totalstock = this.state.Stockdetails;
    let stock = {
      id: Stockid,
      name: "",
      productcode: "",
      quantity: 0,
      salesquantity: 0,
      rate: 0,
      amount: 0,
      cgstpercentage: "0%",
      sgstpercentage: "0%",
      total: 0,
    };
    Totalstock = [...Totalstock, stock];
    this.setState({
      Stockdetails: [],
    });
    Totalstock = Totalstock.sort((a, b) => (a.id > b.id ? 1 : -1));
    this.setState({
      Stockdetails: Totalstock,
    });
  };

  removeStockDetails = async (id) => {
    await fetch(
      `${authApi}deletesalesstock/?salesid=${this.props.Editdata.id}&salestransactionid=${id}`
    )
      .then((res) => {
        console.log(res);
      })
      .catch((err) => {
        alert("error delete sales transaction ", err);
      });
    let remainingdata = this.state.Stockdetails.filter(
      (item) => item.id !== id
    );
  
    this.setState(
      {
        Stockdetails: remainingdata,
      },
      function () {
        this.calculateTotalAmount();
      }
    );
  };

  updateStockDetails = (
    id,
    stockname,
    productcode,
    quantity,
    salesqty,
    rate,
    amount,
    cgstpercentage,
    sgstpercentage,
    totalamount,
    dispatchqty,
  ) => {
    this.state.Stockdetails.forEach((stock) => {
      if (stock.id === id) {
        stock.name = stockname;
        stock.productcode = productcode;
        stock.quantity = quantity;
        stock.salesquantity = salesqty;
        stock.rate = rate;
        stock.amount = amount;
        stock.cgstpercentage = cgstpercentage;
        stock.sgstpercentage = sgstpercentage;
        stock.total = totalamount;
        stock.dispatchqty = dispatchqty;
      }
    });
    this.calculateTotalAmount();
  };

  calculateTotalAmount = () => {
    let taxableamount = 0.0,
      granttotalamount = 0.0,
      totalquantity = 0.0,
      dispatchqty = 0.0;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.name !== 'Total') {
        let CheckAmount = parseFloat(stock.amount) || 0;
        let CheckGrandtotal = parseFloat(stock.total) || 0;
        let CheckQuantity = parseFloat(stock.salesquantity) || 0;
        let CheckDispatchQty = parseFloat(stock.dispatchqty) || 0;
        taxableamount = parseFloat(taxableamount) + CheckAmount;
        granttotalamount = parseFloat(granttotalamount) + CheckGrandtotal;
        totalquantity = parseFloat(totalquantity) + CheckQuantity;
        dispatchqty = parseFloat(dispatchqty) + CheckDispatchQty;
      }
    });
    this.state.Stockdetails.forEach((stock) => {
      if (stock.name === "Total") {
        stock.amount = taxableamount.toFixed(2);
        stock.total = granttotalamount.toFixed(2);
        stock.salesquantity = totalquantity.toFixed(2);
        stock.dispatchqty = dispatchqty.toFixed(2);
        let grandtotal = granttotalamount - parseFloat(this.state.Adjustamount);
        this.setState({
          TotalTaxableValue: taxableamount.toFixed(2),
          TotalAmount: granttotalamount.toFixed(2),
          GrandTotalAmount: grandtotal.toFixed(2),
          TotalQuantity: totalquantity.toFixed(2),
          TotalDispatchQty: dispatchqty.toFixed(2),
        });
      }
    });
  };

  adjustAmount = (event) => {
    let total = parseFloat(this.state.TotalAmount) || 0;
    let totalamount = total - parseFloat(event.target.value);
    let totalwithtofixed = totalamount.toFixed(2);
    this.setState({
      GrandTotalAmount: totalwithtofixed,
      Adjustamount: event.target.value,
    });
  };

  isvalidEntryInForm = async () => {
    let isvalid = false,
      count = 0;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.name !== "Total") {
        if (stock.salesquantity === 0) {
          count = count + 1;
        }
      }
    });
    if (this.state.VehicleNumber !== "") {
      this.setState({
        ValidVehicleNo: "none",
      })
      if (count < 1) {
        if (this.state.Company !== "") {
          this.setState({ updateSalesQty: false });
          if (this.state.ClientName !== "") {
            if (
              this.state.Adjustamount !== "" &&
              this.state.Adjustamount !== null &&
              this.state.Adjustamount !== "."
            ) {
              if (this.state.nullvalueisthere === false) {
                this.state.Stockdetails.forEach((stock) => {
                  if (stock.name === "Total") {
                    stock.id = totalamountid;
                  }
                });
                let remainingdata = this.state.Stockdetails.filter(
                  (item) => item.name !== ""
                );
                this.setState(
                  {
                    updateSalesQty: false,
                    ValidCompany: "none",
                    ValidClientName: "none",
                    Stockdetails: remainingdata,
                    ValidAdjustamount: "none",
                  },
                  function () {
                    this.stockDataPreparationForStock();
                  }
                );
                isvalid = true;
              } else {
                alert("Please specify value for the required fields");
              }
            } else {
              this.setState({
                ValidCompany: "none",
                ValidClientName: "none",
                ValidAdjustamount: "1px solid red",
              });
            }
          } else {
            this.setState({
              ValidCompany: "none",
              ValidClientName: "1px solid red",
            });
          }
        } else {
          this.setState({
            ValidCompany: "1px solid red",
            ValidClientName: "none",
          });
        }
      } else {
        this.setState({
          updateSalesQty: true,
        });
      }
    }
    else {
      this.setState({
        ValidVehicleNo: "1px solid red",
      });
    }
    return isvalid;
  };

  onSubmitForm = async (status) => {
    if (await this.isvalidEntryInForm()) {
      await axios({
        method: "post",
        url: `${authApi}sp_dispatchhandling/`,
        headers: {
          'content-type': "application/json",
          'Authorization': `token ${this.props.token}`,
        },
        data: {
          dispatchdate: this.state.Date,
          company_id: this.state.Companyid,
          totalamount: this.state.GrandTotalAmount,
          supplier_id: this.state.Clientid,
          userid: this.state.userid,
          salesorder_id: this.props.Editdata.id,
          advanceamount: this.state.AdvanceAmount,
          reference: this.state.Reference,
          roundoffamount: this.state.Adjustamount,
          vehicle: this.state.VehicleNumber,
          deliveryaddress: this.state.deliveryaddress,
          inventorydata: StoreprocData
        }
      })
        .then((res) => {
          StoreprocData = [];
          console.log(res);
          if (res.status === 200) {
            this.props.backtodispatch();
          }
        })
        .catch((err) => {
          StoreprocData = [];
        });
    }
  };

  stockDataPreparationForStock = () => {
    this.state.Stockdetails.forEach((stockdetails) => {
      let count = 0,
        cgstcount = 0;
      count = (stockdetails.sgstpercentage.match(/%/g) || []).length;
      cgstcount = (stockdetails.cgstpercentage.match(/%/g) || []).length;
      if (count > 0) {
        stockdetails.sgstpercentage = stockdetails.sgstpercentage.substring(
          0,
          stockdetails.sgstpercentage.length - 1
        );
      }
      if (cgstcount > 0) {
        stockdetails.cgstpercentage = stockdetails.cgstpercentage.substring(
          0,
          stockdetails.cgstpercentage.length - 1
        );
      }
    });
    let RemoveTotalAmount = this.state.Stockdetails.filter(
      (item) => item.name !== "Total" && item.dispatchqty !== 0 && item.quantity !== 0
    );
    RemoveTotalAmount.forEach(inventory => {
      let newinventorydetails = {
        salesstock_id: inventory.id,
        stockname: inventory.name,
        productcode: inventory.productcode,
        orderquantity: inventory.salesquantity,
        quantity: inventory.dispatchqty,
        rate: inventory.rate,
        amount: inventory.amount,
        cgstpercentage: inventory.cgstpercentage,
        sgstpercentage: inventory.sgstpercentage,
        total: inventory.total

      }
      StoreprocData = [...StoreprocData, newinventorydetails];
     
    });
  };

  CancelEdit = () => {
    this.props.history.push("/admin/management/purchase-order");
  };

  checkanynullvalue = (check) => {
    this.setState({
      nullvalueisthere: check,
    });
  };

  updateSupplierdetails = (order) => {
    let totaldata = this.state.Stockdetails;
    order.purchasestock.forEach((stock) => {
      if (stock.status !== "Received") {
        if (stock.name !== "Total") {
          let receivedquantity = parseInt(stock.received) || 0;
          let addStock = {
            id: stock.id,
            name: stock.name,
            productcode: stock.productcode,
            quantity: parseInt(stock.quantity) - receivedquantity,
            receivedquantity: 0,
            rate: stock.rate,
            amount: 0,
            cgstpercentage: stock.cgstpercentage,
            sgstpercentage: stock.sgstpercentage,
            total: 0,
            batch: "",
            location: "",
            purchasesorderid: stock.purchasesorderid,
          };
          totaldata = [...totaldata, addStock];
        }
      }
    });
    let remainingdata = totaldata.filter((item) => item.name !== "");
    remainingdata = remainingdata.sort((a, b) => (a.id > b.id ? 1 : -1));
    let max = 0;
    remainingdata.forEach((stock) => {
      if (stock.name !== "Total") {
        if (stock.id > max) {
          max = stock.id;
        }
      }
    });
    Stockid = max + 1;
    let orderdetails = {
      orderno: order.id,
    };
    this.setState(
      {
        Stockdetails: remainingdata,
        PurchaseOrderid: [...this.state.PurchaseOrderid, orderdetails],
        ValidClientName: "none",
      },
      function () {
        this.calculateTotalAmount();
      }
    );
  };

  checkUpdateClientid = () => {
    this.setState({
      ValidClientName: "1px solid red",
    });
  };

  changeVehicleNumber = (event) => {
    this.setState({
      VehicleNumber: event.target.value,
    })
  }

  changeDeliveryAddress = (event) => {
    this.setState({
      deliveryaddress: event.target.value,
    })
  }


  render() {
    const stockdata = this.state.Stockdetails.map((items) => (
      <EditDispatchedrow
        key={items.id}
        stock={items}
        gstCheck={this.state.gstcheck}
        Inventorydata={this.state.Inventorydata}
        taxableamount={this.state.TotalTaxableValue}
        totalcgstdiscount={this.state.TotalCGSTAmount}
        totalsgstdiscount={this.state.TotalSGSTAmount}
        granttotalamount={this.state.TotalAmount}
        totalquantity={this.state.TotalQuantity}
        totaldispatchqty={this.state.TotalDispatchQty}
        removestockdetails={this.removeStockDetails}
        stockname={this.state.Stockname}
        updatestock={this.updateStockDetails}
        addstock={this.addAnotherStock}
        checkifanynull={this.checkanynullvalue}
        nullvalueisthere={this.state.nullvalueisthere}
        location={this.state.Location}
        batch={this.state.Batch}
      />
    ));
    let totalclientname = this.state.Clientdetails.map((q) => q.company_name);
    let clientname = totalclientname.filter(
      (q, idx) => totalclientname.indexOf(q) === idx
    );
    let totalcompany = this.state.CompanyDetails.map((q) => q.companyname);
    let companyorder = totalcompany.filter(
      (q, idx) => totalcompany.indexOf(q) === idx
    );
    return (
      <React.Fragment>
        <Container className="mt--7" fluid>
          <br />
          <Row>
            <Col md="10">
              <CardHeader style={{ backgroundColor: 'transparent', padding: 10, marginTop: 20 }}>
                <h3 className="mb-0"> <i style={{ marginRight: 5 }} class="fas fa-cart-plus"></i> Add Dispatched </h3>
              </CardHeader>
            </Col>
            <Col md="1"></Col>
            <Col md="1" style={{ marginTop: 22, float: 'right', right: -15 }}>
              <button
                className="btn btn-primary btn-sm" style={{ width: 45, height: 40 }}
                data-tip
                data-for="completed-Tip"
                onClick={this.props.backtodispatch}
              >
          
                <i className="fa fa-arrow-left fa-1x"></i>
              </button>
              <ReactTooltip
                id="completed-Tip"
                place="left"
                type="dark"
                effect="solid"
              >
                Go To Dispatch
              </ReactTooltip>
            </Col>
          </Row>
          <br />
          <Row style={{ marginTop: -5 }}>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Company
              </label>
            </Col>
            <Col md="3">
              <Input
                type="select"
                value={this.state.Company}
                style={{ border: this.state.ValidCompany }}
                onChange={this.selectCompany}
              >
                <option value="Select Company">Select Company</option>
                {companyorder.map((item, key) => (
                  <option key={key} value={item}>
                 
                    {item}
                  </option>
                ))}
              </Input>
              {this.state.ValidCompany !== "none" ? (
                <h6 style={{ color: "red" }}>Please Select Company</h6>
              ) : null}
            </Col>
            <Col md="2">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Client Name
              </label>
            </Col>
            <Col md="3">
              <Input
                type="select"
                value={this.state.ClientName}
                style={{
                  border: this.state.ValidClientName,
                  marginLeft: -109,
                }}
                onChange={this.selectClient}
              >
                <option value="Select Supplier">Select Supplier</option>
                {clientname
                  .sort((a, b) => a.localeCompare(b))
                  .map((item, key) => (
                    <option key={key} value={item}>
                   
                      {item}
                    </option>
                  ))}
              </Input>
              {this.state.ValidClientName !== "none" ? (
                <h6 style={{ color: "red" }}>Please Select ClientName</h6>
              ) : null}
            </Col>
            <Col md="1">
              <label
                className="form-control-label"
                style={{ marginTop: 12, marginLeft: -90 }}
              >
                Date
              </label>
            </Col>
            <Col md="2">
              <Input
                className="form-control-alternative"
                type="date"
                style={{ marginLeft: -90, border: "none" }}
                Value={this.state.Date}
                onChange={this.changeDate}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Reference
              </label>
            </Col>
            <Col md="3">
              <Input
                type="text"
                style={{ border: "none" }}
                value={this.state.Reference}
                onChange={this.getReference}
              />
            </Col>
           
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                SGST Type
              </label>
            </Col>
            <Col md="3">
              <Input
                type="select"
                style={{ border: "none", marginLeft: 10 }}
                value={this.state.Gsttype}
                onChange={this.changeGst}
              >
                <option>SGST</option>
                <option>IGST</option>
              </Input>
            </Col>
            <Col md="2">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Dispatch Date
              </label>
            </Col>
            <Col md="2" style={{ marginLeft: -90 }}>
              <Input
                className="form-control-alternative"
                type="date"
                style={{ border: "none" }}
                defaultValue={this.state.Expecteddispatchdate}
                onChange={this.changeDispatchdate}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col
              md="2"
              className="form-control-label"
              style={{ marginTop: 12 }}
            >
              VehicleNo
            </Col>
            <Col md="3">
              <Input
                type="text"
                style={{ marginLeft: -120, border: this.state.ValidVehicleNo }}
                value={this.state.VehicleNumber}
                onChange={this.changeVehicleNumber}
              />
              {this.state.ValidVehicleNo !== "none" ? (
                <h6 style={{ color: "red", marginLeft: -104, }}>Please Enter VehicleNumber</h6>
              ) : null}
            </Col>
            <Col
              md="2"
              className="form-control-label"
              style={{ marginTop: 12, marginLeft: -120 }}
            >
              Delivery Address
            </Col>
            <Col md="3">
              <Input
                type="text"
                style={{ marginLeft: -110 }}
                value={this.state.deliveryaddress}
                onChange={this.changeDeliveryAddress}
              />
              {this.state.ValidVehicleNo !== "none" ? (
                <h6 style={{ color: "red", marginLeft: -120, }}>Please Enter VehicleNumber</h6>
              ) : null}
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12, marginLeft: -100 }}>
                Sales ID
              </label>
            </Col>
            <Col md="2">
              <Input
                type="text"
                style={{ marginLeft: -90, border: "none" }}
                value={this.props.Editdata.id}
              >
              </Input>
            </Col>
          </Row>
          <br />
          <Row>
            <div className=" col">
              <br />
              <Card className="shadow">
                <Table
                  className="align-items-center table-flush"
                  responsive-sm
                >
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">GOODS NAME</th>
                      <th className="text-center">PRO.CODE</th>
                      <th className="text-center">INV.QTY</th>
                      <th className="text-center">SAL.QTY</th>
                      <th className="text-center">DIS.QTY</th>
                      <th className="text-center">RATE</th>
                      {this.state.gstcheck === true ? <th className="text-center">AMOUNT</th> : null}
                      {this.state.gstcheck === true ? <th className="text-center">CGST/SGST %</th> : null}
                      <th className="text-center">TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>{stockdata}</tbody>
                </Table>
              </Card>
            </div>
          </Row>
          <Row>
            <Col md="2">
              <ul class="list-group list-group-flush" style={{ paddingLeft: 80, width: 290 }}>
                <li class="list-group-item">AdvanceAmount</li>
              </ul>
            </Col>
            <Col md="2">
              <ul class="list-group list-group-flush" style={{ paddingLeft: 60, width: 290 }}>
                <li class="list-group-item">{this.state.AdvanceAmount}</li>
              </ul>
            </Col>
            <Col md="4"></Col>
            <Col md="2">
              <ul
                class="list-group list-group-flush"
                style={{ paddingLeft: 90, width: 290 }}
              >
                {this.state.gstcheck === true ? <li class="list-group-item">TaxableValue</li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">CGST/SGST</li> : null}
             
                <li class="list-group-item">Amount</li>
                <li class="list-group-item" style={{ height: 62 }}>
                  Adjustment
                </li>
                <li class="list-group-item">GrandTotalAmount</li>
                <li class="list-group-item">Total</li>
              </ul>
            </Col>
            <Col md="2">
              <ul
                class="list-group list-group-flush align-center"
                style={{ paddingLeft: 60 }}
              >
                {this.state.gstcheck === true ? <li class="list-group-item">
                  {this.state.TotalTaxableValue}
                </li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">
                  {parseFloat(
                    parseFloat(this.state.TotalAmount) -
                    parseFloat(this.state.TotalTaxableValue)
                  ).toFixed(2)}
                </li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">{this.state.TotalAmount}</li> : <li class="list-group-item">{this.state.TotalTaxableValue}</li>}
                <li class="list-group-item">
                  <Input
                    type="text"
                    style={{
                      border: this.state.ValidAdjustamount,
                      textAlign: "left",
                      position: "relative",
                      display: "block",
                      top: 0,
                      fontSize: "15px",
                      margin: "0",
                      left: -12,
                      height: 33,
                      width: "110%",
                    }}
                    Value={this.state.Adjustamount}
                    onChange={this.adjustAmount}
                  />
                 
                </li>
                {this.state.gstcheck === true ?
                  <li class="list-group-item">{this.state.GrandTotalAmount}</li> :
                  <li class="list-group-item">
                    {parseFloat(
                      parseFloat(this.state.TotalTaxableValue) -
                      parseFloat(this.state.Adjustamount)
                    ).toFixed(2)}</li>}
                {this.state.gstcheck === true ?
                  <li class="list-group-item">
                    {parseFloat(this.state.GrandTotalAmount) - parseFloat(this.state.AdvanceAmount)}</li> :
                  <li class="list-group-item">
                    {parseFloat(
                      parseFloat(this.state.TotalTaxableValue) -
                      parseFloat(this.state.Adjustamount) -
                      parseFloat(this.state.AdvanceAmount)
                    ).toFixed(2)}
                  </li>}
              </ul>
            </Col>
          </Row>
          {this.state.nullvalueisthere === true ? (
            <Row>
              <Col md="5"></Col>
              <Col>
            
                <h3 style={{ color: "red" }}>
                  Please fill the required fields marked in red
                </h3>
              </Col>
            </Row>
          ) : null}
          {this.state.updateSalesQty === true ? (
            <Row>
              <Col md="5"></Col>
              <Col>
                
                <h3 style={{ color: "red" }}>
                  Please check Received quantity
                </h3>
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col md="2"></Col>
            <Col md="7"></Col>
            <Col md="2">
            </Col>
            <Col md="1">
              <button
                id="buttonsave"
                className="btn btn-dark"
                onClick={() => this.onSubmitForm()}
              >
                Submit
              </button>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.name,
    clientdetails:state.clientdetails
  };
};
export default connect(mapStateToProps, null)(EditDispatched);
