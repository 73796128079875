import React, { Component } from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Form,
  Button,
  Row,
  Col,
  Input,
} from "reactstrap";
import moment from "moment";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  NotificationManager,
  NotificationContainer,
} from "react-notifications";
import "./TaskDetails.css";
import config from "react-global-configuration";
import "../../../config";
import consoleFunction from "../../../Helpers";
import StockPopup from "./Stock/StockPopup";
import Popup from "reactjs-popup";
import { connect } from "react-redux";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import getDateTimeField from "../../../Utils";
const authApi = config.get("apiDomain");
let reloadexecutiontime = 1;
class Taskdetail extends Component {
  userInterated = false;
  constructor(props) {
    super(props);
    this.state = {
      timeValues: " ",
      dateNow: new Date(),
      pauseTaskButton: this.props.isDisabled,
      PauseTime: "",
      pauseTimeValues: "",
      taskstartTime: this.props.currentTaskDetails.ChangeStarttime,
      taskfunction: this.props.currentTaskDetails,
      checked: false,
      currentChangeTime: this.props.currentTaskDetails.ChangeTime,
      StartTime: this.props.currentTaskDetails.ChangeStarttime,
      totalsubtaskdetails: [
        this.props.currentTaskDetails.subtaskDetails.total_subtask,
      ],
      currentTime: moment(new Date()).utcOffset("+05:30").format("hh:mm:ss A"),
      Comments: "",
      InProgressTime: "",
      error: null,
      UserClick: "1",
      totalsubtask: this.props.currentTaskDetails.subtaskDetails.total_subtask,
      maintask: [],
      length: this.props.currentTaskDetails.subtaskDetails.total_subtask.length,
      ReadytoBill: false,
      TodayDate: new Date(),
      assignee: "",
      checkTime: "00:00:00",
      userdetails: "",
      Data: (
        <div>
          <button
            className="btn btn-dark btn-sm"
            onClick={this.reloadUserPanelChanges}
          >
            Reload
          </button>
          &nbsp;
          <button
            className="btn btn-dark btn-sm"
            onClick={this.Afterthirtyminues}
          >
            After 30 min
          </button>
        </div>
      ),
    };
  }

  componentDidMount() {
    this.timerID = setInterval(() => this.ticks(), 1000);
    this.UpdateCompleteMainTask();
    this.UpdateStatusInprogress();
    if (this.state.taskstartTime === null || this.state.taskstartTime === "") {
      const getTime = new Date();
      const startTime = moment(getTime)
        .utcOffset("+05:30")
        .format("hh:mm:ss A");
      this.setState({
        taskstartTime: startTime,
      });
    }
  }

  Afterthirtyminues = () => {
    reloadexecutiontime = 30;
  };

  UpdateCompleteMainTask = () => {
    let count = 1;
    this.state.totalsubtask.forEach((item) => {
      if (item.status === "Completed") {
        count = count + 1;
        if (count === this.state.length) {
          this.setState({
            ReadytoBill: true,
          });
        }
      } else {
        if (this.state.length === 1) {
          this.setState({
            ReadytoBill: true,
          });
        }
      }
    });

    fetch(
      `${authApi}Tasklist/${this.props.currentTaskDetails.subtaskDetails.tasklist_id}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState(
          {
            maintask: json,
          },
          function () {
            this.UpdateMaintaskInprogress();
          }
        );
      });
  };

  PendingMainTask = () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "put",
      url: `${authApi}Tasklist/${this.props.currentTaskDetails.subtaskDetails.tasklist_id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        status: "Pending",
        task_starttime: this.state.maintask.task_starttime,
        client: this.props.currentTaskDetails.ChangeClientid,
        completed_date: this.state.maintask.completed_date,
        advanceamount: this.state.maintask.advanceamount,
        company: this.props.currentTaskDetails.subtaskDetails.company_id,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    }).catch((err) => {
      consoleFunction(
        "Task pending updation error",
        err.response.request.response
      );
      this.props.rerender("TaskDetails");
    });
  };

  ReadytobillFunction = async () => {
    let completed_date = moment(this.state.TodayDate).format("YYYY-MM-DD");
    let data = null;
    let taskproject =
      this.props.currentTaskDetails.subtaskDetails.taskproject_id === data
        ? false
        : true;
    let datetime_field = getDateTimeField();
    if (taskproject) {
      axios({
        method: "put",
        url: `${authApi}Tasklist/${this.props.currentTaskDetails.subtaskDetails.tasklist_id}/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          status: "Completed",
          task_starttime: this.state.maintask.task_starttime,
          client: this.props.currentTaskDetails.ChangeClientid,
          completed_date: completed_date,
          advanceamount: this.state.maintask.advanceamount,
          company: this.props.currentTaskDetails.subtaskDetails.company_id,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      }).catch((err) => {
        consoleFunction(
          "Task Completed updation error",
          err.response.request.response
        );
        this.props.rerender("TaskDetails");
      });
    } else {
      axios({
        method: "put",
        url: `${authApi}Tasklist/${this.props.currentTaskDetails.subtaskDetails.tasklist_id}/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          status: "ReadyToBill",
          task_starttime: this.state.maintask.task_starttime,
          client: this.props.currentTaskDetails.ChangeClientid,
          completed_date: completed_date,
          advanceamount: this.state.maintask.advanceamount,
          company: this.props.currentTaskDetails.subtaskDetails.company_id,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      }).catch((err) => {
        console.log(err);
        consoleFunction(
          "Task readytobill updation error",
          err.response.request.response
        );
        this.props.rerender("TaskDetails");
      });
    }
  };

  UpdateStatusInprogress = () => {
    if (this.state.taskstartTime === null || this.state.taskstartTime === "") {
      const getTime = new Date();
      const startTime = moment(getTime)
        .utcOffset("+05:30")
        .format("hh:mm:ss A");
      const Complete = "InProgress";
      const Link = `${authApi}subtasks/${this.props.currentTaskDetails.subtaskDetails.id}/`;
      let datetime_field = getDateTimeField();
      axios({
        method: "put",
        url: Link,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: this.props.currentTaskDetails.subtaskDetails.date,
          subtask: this.props.currentTaskDetails.subtaskDetails.subtask,
          assignto: this.props.currentTaskDetails.subtaskDetails.assignto,
          priority: this.props.currentTaskDetails.subtaskDetails.priority,
          status: Complete,
          time: this.state.currentChangeTime,
          isdeleted: this.props.currentTaskDetails.subtaskDetails.isdeleted,
          completed_date: null,
          tasklist: this.props.currentTaskDetails.subtaskDetails.tasklist_id,
          task_starttime: startTime,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.props.rerender("TaskDetails");
          }
        })
        .catch((err) => {
          consoleFunction(
            "Task inprogress change error",
            err.response.request.response
          );
          this.props.rerender("TaskDetails");
          this.setState({ error: err.message });
        });
    } else {
      const startTime = moment(this.state.taskstartTime, "HH:mm:ss a");
      const endTime = moment(this.state.currentTime, "HH:mm:ss a");

      const duration = moment.duration(endTime.diff(startTime));

      const hours = parseInt(duration.asHours());

      const minutes = parseInt(duration.asMinutes()) % 60;

      this.setState({
        InProgressTime: `${hours}:${minutes}:00`,
      });
    }
  };

  UpdateMaintaskInprogress = () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "put",
      url: `${authApi}Tasklist/${this.props.currentTaskDetails.subtaskDetails.tasklist_id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        status: "InProgress",
        client: this.state.maintask.client,
        completed_date: this.state.maintask.completed_date,
        advanceamount: this.state.maintask.advanceamount,
        company: this.props.currentTaskDetails.subtaskDetails.company_id,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    }).catch((err) => {
      consoleFunction(
        "Task inprogress change error",
        err.response.request.response
      );
      this.props.rerender("TaskDetails");
    });
  };

  handleCheckBox = (e) => {
    this.setState({
      checked: e.target.checked,
    });
  };

  ticks() {
    let momenttime = 0;
    const startTime = moment(this.state.taskstartTime, "HH:mm:ss a");
    let notesTime = moment(new Date()).utcOffset("+05:30").format("hh:mm:ss A");
    const endTime = moment(notesTime, "HH:mm:ss a");
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;
    const sec = parseInt(duration.asSeconds()) % 60;
    const time = `${hours}:${minutes}:${sec}`;
    momenttime = moment(time, "HH:mm:ss").format("HH:mm:ss");
    this.setState({
      checkTime: momenttime,
    });
    let secounts = sec;
    let reloadornot = minutes % reloadexecutiontime;
    if (reloadornot === 0 && secounts === 0) {
      fetch(`${authApi}userfilter/?name=${this.props.username}`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      })
        .then((res) => res.json())
        .then((json) => {
          if (json[0].reload === true) {
            this.setState({
              userdetails: json[0],
            });
            this.createNotification("info");
          }
        });
    }
  }

  reloadUserPanelChanges = () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "put",
      url: `${authApi}users/${this.state.userdetails.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        name: this.state.userdetails.name,
        mailid: this.state.userdetails.mailid,
        isadmin: this.state.userdetails.isadmin,
        isapproved: this.state.userdetails.isapproved,
        default_rate: this.state.userdetails.default_rate,
        superuser: this.state.userdetails.superuser,
        userprofile: this.state.userdetails.userprofile,
        task: this.state.userdetails.task,
        admin: this.state.userdetails.admin,
        report: this.state.userdetails.report,
        sales: this.state.userdetails.sales,
        purchase: this.state.userdetails.purchase,
        voucher: this.state.userdetails.voucher,
        reload: false,
        isdeleted: false,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    }).then((res) => {
      if (res.status === 200) {
        window.location.reload();
      }
    });
  };

  createNotification = (type) => {
    NotificationManager.info(
      this.state.Data,
      "allocate more windows please Reload",
      55000,
      () => {}
    );
  };

  currentTaskButton = async (e) => {
    e.preventDefault();
    if (this.state.ReadytoBill === true) {
      this.ReadytobillFunction();
    }
    const dateTime = new Date();
    const date = moment(dateTime).format("YYYY-MM-DD");
    const Complete = "Completed";
    let datetime_field = getDateTimeField();
    const Link = `${authApi}subtasks/${this.props.currentTaskDetails.subtaskDetails.id}/`;
    axios({
      method: "put",
      url: Link,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        date: this.props.currentTaskDetails.subtaskDetails.date,
        subtask: this.props.currentTaskDetails.subtaskDetails.subtask,
        assignto: this.props.currentTaskDetails.subtaskDetails.assignto,
        priority: this.props.currentTaskDetails.subtaskDetails.priority,
        status: Complete,
        time: this.state.currentChangeTime,
        isdeleted: this.props.currentTaskDetails.subtaskDetails.isdeleted,
        completed_date: date,
        tasklist: this.props.currentTaskDetails.subtaskDetails.tasklist_id,
        task_starttime: "",
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.callBackfunction();
          if (
            this.props.currentTaskDetails.subtaskDetails.taskproject_id !== null
          ) {
            this.projectAmountCalculation();
          }
        }
      })
      .catch((err) => {
        consoleFunction(
          "Current task completion error",
          err.response.request.response
        );
        this.props.rerender("TaskDetails");
        this.setState({ error: err.message });
      });
  };

  projectAmountCalculation = async () => {
    this.updateProjectStatus();
    await axios({
      method: "post",
      url: `${authApi}projecttaskamountcalculation/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        taskid: this.props.currentTaskDetails.subtaskDetails.tasklist_id,
      },
    }).then((res) => {
      console.log(res);
      this.updateprojectamountcalculation();
    });
  };

  updateprojectamountcalculation = () => {
    axios({
      method: "post",
      url: `${authApi}sp_updateprojecttotalamount/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        projectid: this.props.currentTaskDetails.subtaskDetails.taskproject_id,
      },
    })
      .then((res) => {
        if (res.status === 201) {
        }
      })
      .catch((err) => {
        this.setState({ error: err.response.request.response });
      });
  };

  updateProjectStatus = () => {
    let check = null;
    if (check !== this.props.currentTaskDetails.subtaskDetails.taskproject_id) {
      axios({
        method: "put",
        url: `${authApi}updateprojectstatus/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          taskid: this.props.currentTaskDetails.subtaskDetails.tasklist_id,
          taskproject_id:
            this.props.currentTaskDetails.subtaskDetails.taskproject_id,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.status);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  callBackfunction = () => {
    let momenttime = 0,
      timetest = 0,
      total = 0,
      mins = 0;
    const dateTime = new Date();
    const date = moment(dateTime).format("YYYY-MM-DD");
    const startTime = moment(this.state.taskstartTime, "HH:mm:ss a");
    let notesTime = moment(new Date()).utcOffset("+05:30").format("hh:mm:ss A");
    const endTime = moment(notesTime, "HH:mm:ss a");
    const duration = moment.duration(endTime.diff(startTime));
    const hours = parseInt(duration.asHours());
    const minutes = parseInt(duration.asMinutes()) % 60;
    const sec = parseInt(duration.asSeconds()) % 60;
    const time = `${hours}:${minutes}:${sec}`;
    momenttime = moment(time, "HH:mm").format("HH:mm");
    timetest = momenttime.split(":");
    mins = timetest[0] * 60 + +timetest[1];
    total += mins;
    if (total !== 0) {
      let datetime_field = getDateTimeField();
      axios({
        method: "post",
        url: `${authApi}subtasktime/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: date,
          username: this.props.username,
          comments: this.state.Comments,
          time: momenttime,
          endtime: notesTime,
          start_time: this.state.taskstartTime,
          subtask: this.props.currentTaskDetails.subtaskDetails.id,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          if (res.status === 201) {
            this.props.taskOpenButton(this.state);
            this.props.rerender("TaskDetails");
          }
        })
        .catch((err) => {
          console.log(err);
          this.props.rerender("TaskDetails");
          this.setState({ error: err.message });
        });
      this.setState({
        taskfunction: !this.state.taskfunction,
        PauseTime: time,
      });
    } else {
      this.props.taskOpenButton(this.state);
      this.props.rerender("TaskDetails");
    }
  };

  pauseTime = (e) => {
    e.preventDefault();
    this.PendingMainTask();
    this.updateProjectStatus();
    let datetime_field = getDateTimeField();
    axios({
      method: "put",
      url: `${authApi}subtasks/${this.props.currentTaskDetails.subtaskDetails.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        date: this.props.currentTaskDetails.subtaskDetails.date,
        subtask: this.props.currentTaskDetails.subtaskDetails.subtask,
        assignto: this.props.currentTaskDetails.subtaskDetails.assignto,
        priority: this.props.currentTaskDetails.subtaskDetails.priority,
        status: "Pending",
        time: this.state.currentChangeTime,
        isdeleted: this.props.currentTaskDetails.subtaskDetails.isdeleted,
        completed_date: null,
        tasklist: this.props.currentTaskDetails.subtaskDetails.tasklist_id,
        task_starttime: "",
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.callBackfunction();
        }
      })
      .catch((err) => {
        console.log(err);
        this.props.rerender("TaskDetails");
        this.setState({ error: err.message });
      });
  };

  ChangeComments = (event) => {
    this.setState({
      Comments: event.target.value,
    });
  };
  updateComponent = () => {
    this.props.rerender("TaskDetails");
  };

  componentWillUnmount() {
    clearInterval(this.timerID);
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col className="order-xl-1" xl="8">
            <Card className="bg-secondary shadow">
              <CardBody>
                <Form>
                  <div className="pl-lg-4">
                    <Row>
                      <Col md="4">
                        <FormGroup>
                          <label className="form-control-label">
                            Plan Type
                          </label>
                          <p>
                            {
                              this.props.currentTaskDetails.subtaskDetails
                                .plantype
                            }
                          </p>
                        </FormGroup>
                      </Col>
                      <Col md="8">
                        <FormGroup>
                          <label className="form-control-label">
                            Task Name
                          </label>
                          <p>
                            {
                              this.props.currentTaskDetails.subtaskDetails
                                .tasklist
                            }
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="4">
                        <FormGroup>
                          <label className="form-control-label">Priority</label>
                          <p>
                            {
                              this.props.currentTaskDetails.subtaskDetails
                                .priority
                            }
                          </p>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label className="form-control-label">
                            Client Name
                          </label>
                          <p>
                            {
                              this.props.currentTaskDetails.subtaskDetails
                                .clientname
                            }
                          </p>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <label className="form-control-label">
                            Sub Client
                          </label>
                          <p>
                            {
                              this.props.currentTaskDetails.subtaskDetails
                                .subclient
                            }
                          </p>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <FormGroup>
                          <label className="form-control-label">Comments</label>
                          <br />
                          <Input
                            type="textarea"
                            name="form-control-alternative"
                            onChange={this.ChangeComments}
                            placeholder="Enter Comments"
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">
            <Card className="bg-secondary shadow">
              <CardBody>
                <Form>
                  <Row>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Task Starting Time
                        </label>
                        <lable className="stockdetails">
                          <Popup
                            modal
                            trigger={
                              <button
                                type="button"
                                className="btn btn-primary btn-sm"
                              >
                                <i className="ni ni-cart"></i>
                              </button>
                            }
                          >
                            {(close) => (
                              <StockPopup
                                closebtn={close}
                                EditRowSend={this.state.totalsubtaskdetails}
                                maintaskid={
                                  this.props.currentTaskDetails.subtaskDetails
                                    .tasklist_id
                                }
                                itsmytask="1"
                              />
                            )}
                          </Popup>
                        </lable>
                        <label className="addnewtask">
                          <NavLink to="/admin/management/addtask" tag={Link}>
                            <button className="btn btn-primary btn-sm">
                              <i className="fas fa-plus-circle fa-1x"></i>
                            </button>
                          </NavLink>
                        </label>
                        <p className="easyTimer">{this.state.taskstartTime}</p>
                      </FormGroup>
                    </Col>
                    <Col lg="12">
                      <FormGroup>
                        <label className="form-control-label">
                          Progressing Time
                        </label>
                        <p className="easyTimer"> {this.state.checkTime} </p>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col xs="12">
                      <div className="custom-control custom-control-alternative custom-checkbox">
                        <input
                          className="custom-control-input"
                          id="customCheckRegister"
                          type="checkbox"
                          onChange={this.handleCheckBox}
                          checked={this.state.checked}
                        />
                        <label
                          className="custom-control-label"
                          htmlFor="customCheckRegister"
                        >
                          <span className="text-muted">Is Task Completed</span>
                        </label>
                      </div>
                    </Col>
                    <br />
                    <br />
                    <Col className="text-right" xs="12">
                      {this.state.checked ? (
                        <Button
                          color="success"
                          size="sm"
                          onClick={this.currentTaskButton}
                        >
                          Completed
                        </Button>
                      ) : (
                        <Button
                          color="primary"
                          size="sm"
                          onClick={this.pauseTime}
                        >
                          Pause
                        </Button>
                      )}
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <NotificationContainer />
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    allusers: state.allusers,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(Taskdetail);
