import React, { Fragment } from 'react';
import {
  useTable,
  useSortBy,
  useFilters,
  usePagination,
} from 'react-table';
import { Table, Row, Col, Button, Input, Card } from 'reactstrap';
import { Filter, DefaultColumnFilter } from './filters';
import moment from "moment";
import { connect } from "react-redux";

const day = new Date();
const now = moment(day).format("YYYY-MM-DD");


const TableContainer = (props) => {	
  const { columns, data, checkedToggle } = props;
  let mytask_fils = props.cur_location === '/admin/mytask' ?
    props.mytask_fil.filter(item => item.value !== '' && item.value !== undefined)
    :
    []

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
    {
      columns,
      data,
      defaultColumn: { Filter: DefaultColumnFilter },
      initialState: {	
        filters: mytask_fils	
      }
    },
    useFilters,
    useSortBy,
    usePagination
);

const generateSortingIndicator = (column) => {
    return column.isSorted ? (column.isSortedDesc ? <i className="fa fa-arrow-up" aria-hidden="true"></i> : <i className="fa fa-arrow-down" aria-hidden="true"></i>) : '';
};

const onChangeInSelect = (event) => {
    setPageSize(Number(event.target.value));
};

return (
    <React.Fragment>
      <Card className="shadow">
      <Table className="align-items-center table-flush" bordered hover {...getTableProps()} responsive>
        <thead className="thead-light">
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th className="text-center" {...column.getHeaderProps()}>
                  <div {...column.getSortByToggleProps()}>
                    {column.render('Header')}
                    {generateSortingIndicator(column)}
                  </div>
                  { checkedToggle === false ? null : <Filter column={column} /> }
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <Fragment key={row.getRowProps().key}>
                <tr>
                  {row.cells.map((cell) => {
                    let style = {color: "", maxWidth: 150, overflow: "hidden"};
                    if (now > cell.row.original.enddate && cell.row.original.status !== 'ReadyToInvoice') {
                        style = { color: "red", maxWidth: 150, overflow: "hidden" };
                      } else {
                        style = { color: "", maxWidth: 150, overflow: "hidden" };
                    }
                    return (
                      <td {...cell.getCellProps({ style })} >{cell.render('Cell')}</td>
                    );
                  })}
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </Table>
      </Card>
      <br />

      <Row style={{ maxWidth: 1000, margin: '0 auto', textAlign: 'center' }}>
        <Col md={3}>
          <Button
            color='primary'
            onClick={previousPage}
            disabled={!canPreviousPage}
          >
            {'<'}
          </Button>
        </Col>
        <Col md={3} style={{ marginTop: 7 }}>
          Page{' '}
          <strong>
            {pageIndex + 1} of {pageOptions.length}
          </strong>
        </Col>
        <Col md={3}>
          <Input
            type='select'
            value={pageSize}
            onChange={onChangeInSelect}
          >
            {[50, 75, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                Show {pageSize}
              </option>
            ))}
          </Input>
        </Col>
        <Col md={3}>
          <Button color='primary' onClick={nextPage} disabled={!canNextPage}>
            {'>'}
          </Button>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {	
  return {	
    cur_location: state.current_location,	
    mytask_fil: state.mytask_filter,	
  };	
};

export default connect(mapStateToProps, null)(TableContainer);
