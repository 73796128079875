import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#bff0fd";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold",
  },
  description: {
    width: "80%",
    textAlign: "right",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingRight: 8,
    paddingTop: 4,
  },
  total: {
    width: "20%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
});

const InvoiceTableFooter = ({ itemsTotal }) => {
  const invoiceAmtRecv = itemsTotal.reduce(
    (i, ar) => i + parseInt(ar.amount_received, 0),
    0
  );
  const invoiceDue = itemsTotal.reduce(
    (i, t) => i + parseInt(t.balancedue, 0),
    0
  );
  const invoiceTot = invoiceAmtRecv + invoiceDue;

  return (
    <React.Fragment>
      <View style={styles.row}>
        <Text style={styles.description}>Invoice Amount</Text>
        <Text style={styles.total}>{parseFloat(invoiceTot).toFixed(2)}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Received Amount</Text>
        <Text style={styles.total}>
          {parseFloat(invoiceAmtRecv).toFixed(2)}
        </Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Invoice Due</Text>
        <Text style={styles.total}>{parseFloat(invoiceDue).toFixed(2)}</Text>
      </View>
    </React.Fragment>
  );
};

export default InvoiceTableFooter;
