import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#d9d9d9",
    backgroundColor: "#d9d9d9",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1
  },
  openingbalance: {
    width: "25%",
  },
  totalinvoiced: {
    width: "25%",
  },
  totalreceived: {
    width: "25%",
  },
  closingbalance: {
    width: "25%",
  }
});

const OverviewTransactHeader = () => (
  <View style={styles.container}>
    <Text style={styles.openingbalance}> Opening Balance </Text>
    <Text style={styles.totalinvoiced}> Total Invoiced </Text>
    <Text style={styles.totalreceived}> Total Received </Text>
    <Text style={styles.closingbalance}> Closing Balance </Text>
  </View>
);

export default OverviewTransactHeader;
