import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  description: {
    width: "54.7%",
    textAlign: "center",
    paddingLeft: 4,
    paddingTop: 4,
  },
  amount: {
    width: "15.8%",
    textAlign: "center",
    paddingRight: 16,
    paddingTop: 4,
  },
  discount: {
    width: "15.8%",
    textAlign: "center",
    paddingRight: 16,
    paddingTop: 4,
  },
  total: {
    width: "15.3%",
    textAlign: "right",
    paddingRight: 20,
    paddingTop: 4,
  },
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
  descriptions: {
    width: "33%",
    textAlign: "left",
    paddingLeft: 28,
    paddingTop: 4,
  },
  units: {
    width: "7%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  qtys: {
    width: "7%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  rates: {
    width: "7.8%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  amounts: {
    width: "15.8%",
    textAlign: "center",
    paddingRight: 16,
    paddingTop: 4,
  }
});

const InvoiceDrafting = ({ draftChar, discount, draft, printChar }) => 
{
  const draftDetail = draft.filter((item) => { return item.amount !== "0" })
  const invoiceTot = parseInt(draftChar) + parseInt(discount); 
  const invoicePrintTot = printChar.reduce((i,p) => i + parseInt(p.amount, 0), 0);
  const totals = invoicePrintTot + invoiceTot;

  const InvoicedrawingCharge = draftDetail.map((pr) => (
    <View style={styles.rows} key={pr.id}>
      <Text style={styles.descriptions}>{pr.user}</Text>
      <Text style={styles.units}>{pr.unit}</Text>
      <Text style={styles.qtys}></Text>
      <Text style={styles.rates}>{pr.rate}</Text>
      <Text style={styles.amounts}>{pr.amount}</Text>
    </View>
  ));

  return( 
    <React.Fragment>
      { draftChar === 0 ? null :
        <View style={styles.rows}>
          <Text style={styles.description}>Drafting Charges</Text>
          <Text style={styles.amount}>{draftChar}</Text>
          <Text style={styles.discount}>{parseFloat(discount).toFixed(2)}</Text>
          { invoiceTot !== 0 ? 
            <Text style={styles.total}>{totals.toFixed(2)}</Text> : null
          }
        </View>
      }
      { draftChar === 0 ? null :
        <View>
        {InvoicedrawingCharge}
        </View>
      }
    </React.Fragment>
  )
};

export default InvoiceDrafting;
