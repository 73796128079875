import React from "react";
import packageJson from "./../package.json";
import App from "./App";
import { BrowserRouter as Router, Route } from "react-router-dom";

class AppMain extends React.Component {
  componentDidMount() {
    this.Caching();
  }

  Caching() {
    let version = localStorage.getItem("version");

    if (version !== packageJson.version) {
      if ("caches" in window) {
        caches.keys().then((names) => {
          names.forEach((name) => {
            caches.delete(name);
          });
        });
        window.location.reload(true);
      }
      localStorage.clear();
      localStorage.setItem("version", packageJson.version);
    }
  }

  render() {
    return (
      <Router>
        <Route render={(props) => <App {...props} />} />
      </Router>
    );
  }
}

export default AppMain;
