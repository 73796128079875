import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import SummarizedHeader from "./SummarizedHeader";
import SummarizedTotal from "./SummarizedTotal";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 15,
    borderWidth: 1,
    width: "50%",
    borderColor: "#bff0fd",
  }
});

const SummarizedTable = ({ gd }) => {
  return( 
    <View style={styles.tableContainer} >
      <SummarizedHeader />
      <SummarizedTotal groupdata={gd} />
    </View>
  )
};

export default SummarizedTable;