import React, { Component } from 'react';
import '../../../config';
import "./Worktime.css";
import {connect} from "react-redux";

class WorktimeRow extends Component {
    constructor(props){
        super(props);
        this.state={
            id:this.props.id,
            date:this.props.date,
            username:this.props.username,
            time:this.props.time,
            endtime:this.props.endtime,
            start_time:this.props.start_time,
            subtask:this.props.subtask,
            subtaskname:this.props.subtaskname,
            maintaskname:this.props.maintaskname, 
            clientName:this.props.clientName,
        }
    }

    render() {
        return (
           <tr>
               <td className="text-center">
                   <label>{this.state.date}</label>
               </td>
               <td className="text-center">
                   <label>{this.state.clientName}</label>
               </td> 
               <td className="text-center">
                   <label>{this.state.maintaskname}</label>
               </td>
               <td  className="text-center">
                   <label>{this.state.subtaskname}</label>
               </td>
               <td  className="text-center">
                   <label>{this.state.username}</label>
               </td>
               <td className="text-center">
                   <label>{this.state.start_time}</label>
               </td>
               <td  className="text-center">
                   <label>{this.state.endtime}</label>
               </td>
               <td  className="text-center">
                   <label>{this.state.time}</label>
               </td>   
            </tr>
        )
    }
}
    
const mapStateToProps = state => {
    return {
      token:state.token,
    }
  }
export default connect(mapStateToProps, null)(WorktimeRow);