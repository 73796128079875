import React, { useEffect, useState, useMemo } from 'react';
import { Container,Row,Form, FormGroup } from 'reactstrap';
import TableContainerExpand from '../../../Filters/TableContainerExpand';
import TableContainerExpandTeam from '../../../Filters/TableContainerExpandTeam';
import { SelectColumnFilter } from '../../../Filters/filters';
import {connect} from 'react-redux';
import ReceiptEdit from "./ReceiptEdit";
import config from "react-global-configuration";
import '../../../../../config';
import ConPreLoader from "../../../../../components/Loader/Con_PreLoader";
import ReactTooltip from "react-tooltip";
import Header from '../../../../../components/Headers/SimpleHeader';
import { CurrentLocation } from "../../../../../components/loginRedux/loginReducers";	
import store from "../../../../../components/loginRedux/Store";

const authApi = config.get('apiDomain');
function Receipt(props) {
 
 const [Purchagesdata,storePurchagesData]=useState([]);
 const [q, setQ] = useState("");
 const [loading, setLoading] = useState(true);
 let [Editdata,setEditdata] = useState([]);
 const [showReceiptEntry,updateReceiptEntryscreen]=useState(false);


useEffect(()=>{
const getPurchagesDetails=async()=>{
  store.dispatch({ type: CurrentLocation, data: props.location.pathname })
  const response = await fetch(`${authApi}purchaseorderfilter/?status__in=Partially+Received%2CIssued%2CReceived&isdeleted=false`, {
    method: 'GET',
    headers: {
      'content-type': "application/json", 
      'Authorization': `token ${props.token}`,
    }
  })
  let body = await response.json();
  if(body.length !== 0)
  {
    let companyfulldetails=await getCompanyName();
    body.forEach(oreder => {
      companyfulldetails.forEach(company => {
        if(parseInt(oreder.companyid)===company.id){
          oreder.companyid=company.companyname;
        }
      });
    });

    body.forEach(order => {
      props.clientdetails.forEach(client => {
        if(parseInt(order.supplier)===client.id){
          order.supplier=client.company_name;
        }
      });
    });
    setLoading(false);
    storePurchagesData(body.reverse());
  }
  else{
    setLoading(false);
  }
}
getPurchagesDetails();
 },
 // eslint-disable-next-line
 []
 )

const getCompanyName=async()=>{
  const response = await fetch(`${authApi}companyfilter/`, {
    method: 'GET',
    headers: {
      'content-type': "application/json",
      'Authorization': `token ${props.token}`,
    }
  })
  const body = await response.json();
  return body;
 }

function search(rows) {
  const cols = rows[0] && Object.keys(rows[0]);
  return rows.filter((r) =>
    cols.some((c) => r[c] ? (r[c].toString().toLowerCase().indexOf((q).toLowerCase()) > -1 ) : null )
  );
}

const editDetails=(Row_data)=>{
   setEditdata(Row_data);
   updateReceiptEntryscreen(true);
}

const backtoReceipt=async()=>{
  updateReceiptEntryscreen(false);
  setLoading(true);
  const response = await fetch(`${authApi}purchaseorderfilter/?status__in=Partially+Received%2CIssued%2CReceived&isdeleted=false`, {
    method: 'GET',
    headers: {
      'content-type': "application/json", 
      'Authorization': `token ${props.token}`,
    }
  })
  let body = await response.json();
  if(body.length !== 0)
  {
    let companyfulldetails=await getCompanyName();
    body.forEach(oreder => {
      companyfulldetails.forEach(company => {
        if(parseInt(oreder.companyid)===company.id){
          oreder.companyid=company.companyname;
        }
      });
    });

    body.forEach(order => {
      props.clientdetails.forEach(client => {
        if(parseInt(order.supplier)===client.id){
          order.supplier=client.company_name;
        }
      });
    });
    setLoading(false);
    storePurchagesData(body.reverse());
  }
  else{
    setLoading(false);
  }
}

 const columnSecondLayer = useMemo(
  () => [
    {
      Header: "StockName",
      accessor: "name",
    },
    {
      Header: "ProductCode",
      accessor: "productcode",
    },
    {
      Header: "Quantity",
      accessor: "quantity"
    },
    {
      Header: "Received",
      accessor: "received"
    },
    {
      Header: "Rate",
      accessor: "rate",
    },
    {
      Header: "Amount",
      accessor: "amount",
    },
    {
      Header: "CGST",
      accessor: "cgstdiscountpercentage",
    },
    {
      Header: "SGST",
      accessor: "sgstdiscountpercentage",
    },
    {
      Header: "Total",
      accessor: "total",
    },
  ],
  // eslint-disable-next-line
  []
);

const renderRowSubComponent = ( row ) => {
  const rowdata = row.original.purchasestock.filter(function (item) {
    return item.isdeleted === false;
  });
   let stocks=[];
    stocks=rowdata.filter(function(item){
    return (item.name !== "Total"); 
   }); 
   rowdata.forEach(item => {
    if(item.name === "Total"){
      stocks=[...stocks,item]
    }
  });


  return (
    <React.Fragment>
          <br />
            <h5 className="thead-light">Purchase Stocks Details :</h5>
              <TableContainerExpandTeam
                  columns={columnSecondLayer}
                  data={stocks}
                />
            <br />
      </React.Fragment>
  );
};

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <i className="ni ni-bold-down" /> : <i className="ni ni-bold-right" /> }
          </span>
        )
      },
      { 
        Header:"Actions",
        Cell: (row) => {
             const row_data = row.row.original;
            return (
            <React.Fragment>
              <button className = "btn btn-primary btn-sm" style={{marginRight: '5px'}}  onClick={()=>editDetails(row_data)} disabled={row_data.status==="Received"?true:false}>
                    <i className="fa fa-edit" />
              </button>
            </React.Fragment>
            );
        }
      },
      {
        Header: "Purchase Id",
        accessor: "id",
        disableSortBy: true,
        filter: 'equals',
      },
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        filter: 'equals',
      },
      {
        Header: "Company Name",
        accessor: "companyid",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: "Supplier",
        accessor: "supplier",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: "Total",
        accessor: "total",
        disableSortBy: true,
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        }
      },
      {
        Header: "Reference",
        accessor: "reference",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Header />
       {showReceiptEntry ===false ? <Container className="mt--7" fluid><br />
          <Row>
            <Form className="searchForm" style = {{ marginTop : 14, right:"50px"}}>
              <FormGroup className="mb-0">
                <input className="searchBox " type="search" placeholder="Search" value={q} onChange={(e) => setQ(e.target.value)} />
              </FormGroup>
            </Form>
          </Row>
          { loading === true ? <div> <ConPreLoader /></div> :
          <Row style={{marginTop : 80}}>
            <div className=" col">
              <ReactTooltip place="top" type="dark" effect="float" />
                <TableContainerExpand
                  columns={columns}
                  data={search(Purchagesdata)}
                  renderRowSubComponent={renderRowSubComponent}
                />
            </div>
          </Row>
          }
          <br />
          <br /><br />
        </Container>:
        <ReceiptEdit
         Purchaseorder={Editdata}
         backfunction={backtoReceipt}
        />}
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    token:state.token,
    clientdetails:state.clientdetails
  }
}
export default connect(mapStateToProps, null)(Receipt);