import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
    borderWidth: 1,
    borderColor: "#bff0fd"
  },
  rows: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold"
  },
  tasks: {
    width: "29.6%",
    textAlign: "left",
    paddingRight: 8,
    paddingLeft: 2,
    paddingTop: 4,
  },
  details: {
    width: "70%",
    textAlign: "left",
    paddingRight: 8,
  }
});

const InvoiceSales = ({ task }) => 
{
   const InvoicetaskName = task.map((t) => (
    <React.Fragment>
      <View style={styles.rows} key={t.id}>
        <Text style={{ width: "20%", textAlign: "center" }}>{t.stockname}</Text>
        <Text style={{ width: "15%", textAlign: "center" }}>{t.productcode}</Text>
        <Text style={{ width: "15%", textAlign: "center" }}>{t.batch}</Text>
        <Text style={{ width: "10%", textAlign: "center" }}>{Math.abs(t.quantity)}</Text>
        <Text style={{ width: "10%", textAlign: "center" }}>{t.rate}</Text>
        <Text style={{ width: "15%", textAlign: "center" }}>{ t.cgstpercentage } / { t.sgstpercentage }</Text>
        <Text style={{ width: "15%", textAlign: "center" }}>{t.total}</Text>
      </View>
    </React.Fragment>
   ));

  return( 
    <React.Fragment>
      {InvoicetaskName}
    </React.Fragment>
  )
};

export default InvoiceSales;
