import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#bff0fd";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold"
  },
  description: {
    width: "85%",
    textAlign: "right",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingRight: 8,
    paddingTop: 4,
  },
  total: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  }
});

const PurchaseorderTableFooter = ({ itemsTotal }) => {
  const a = itemsTotal.purchasestock.filter((d) => d.name === "Total")
  return (
    <React.Fragment>
      <View style={styles.row} >
        <Text style={{ fontWeight: "bold", alignItems: "left" }} >Total</Text>
        <Text style={{ marginLeft: 50 }}>{a[0].amount}</Text>
        <Text style={{ marginLeft: 50 }}>{a[0].cgstdiscount}</Text>
        <Text style={{ marginLeft: 50 }}>{a[0].sgstdiscount}</Text>
        <Text style={{ marginLeft: 50 }}>{parseFloat(a[0].sgstdiscount) + parseFloat(a[0].cgstdiscount)}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Total</Text>
        <Text style={styles.total}>{Number.parseFloat(itemsTotal.total).toFixed(2)}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.description}>Advance Paid</Text>
        <Text style={styles.total}>{Number.parseFloat(itemsTotal.advancepaid).toFixed(2)}</Text>
      </View>
    </React.Fragment>
  );
};

export default PurchaseorderTableFooter;
