import React from 'react';
import { connect } from 'react-redux';
import Header from "../../../../../../components/Headers/SimpleHeader";
import config from "react-global-configuration";
import '../../../../../../config';
import axios from "axios";
import ReactTooltip from "react-tooltip";
import {
  Container,
  Card,
  Row,
  Col,
  Input,
  NavLink, Button,
  Table
} from "reactstrap";
import { Link } from "react-router-dom";
import Popup from "reactjs-popup";
import AddDispatchRows from "./AddDispatchRows";

import moment from "moment";
import SalesOrder from './SalesOrder';
let StoreprocData = [], AdvanceAmountstoreproc = [];
const authApi = config.get('apiDomain');
let Stockid = 1;
class AddDispatch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Clientdetails:this.props.clientdetails,
      SupplierName: "",
      Supplierid: "",
      Reference: "",
      Date: moment(new Date()).format("YYYY-MM-DD"),
      CompanyDetails: [],
      SelectedSupplier: [],
      Company: "",
      Companyid: "",
      Status: "Dispatched",
      Stockdetails: [],
      Stockname: [],
      TotalTaxableValue: 0,
      TotalCGSTAmount: 0,
      TotalSGSTAmount: 0,
      TotalAmount: 0,
      gstcheck: true,
      TotalQuantity: 0,
      Adjustamount: "0.00",
      GrandTotalAmount: 0,
      ValidCompany: "none",
      ValidSupplierName: "none",
      ValidAdjustamount: "none",
      nullvalueisthere: false,
      PurchaseOrderid: [],
      updateReceivedQty: false,
      Available: [],
      AdvanceAmount: [],
      AdvanceTotal: 0,
    }
  }

  async componentDidMount() {
    let stocknamedetails = await this.getTotalStockdetails();
    await this.getAvailablestocks();
    let Advancedetails = {
      salesid: "Total",
      advance: 0,
    }
    this.setState({
      Stockname: stocknamedetails,
      AdvanceAmount: [...this.state.AdvanceAmount, Advancedetails]
    })
    let stock = {
      id: Stockid,
      name: ``,
      productcode: "",
      quantity: 0,
      availableqty: 0,
      rate: 0,
      amount: 0,
      cgstdiscountpercentage: "0%",
      sgstdiscountpercentage: "0%",
      total: 0,
      salesorderid: 0,
    }
    this.setState({
      Stockdetails: [...this.state.Stockdetails, stock],
    })
    await this.getCompanydetails();
    let addStock = {
      id: 9999,
      name: "Total",
      productcode: "",
      quantity: "",
      availableqty: 0,
      rate: "",
      amount: "",
      cgstdiscountpercentage: "",
      sgstdiscountpercentage: "",
      total: "",
      salesorderid: 0,
    }
    this.setState({
      Stockdetails: [...this.state.Stockdetails, addStock],
    })
  }

  getTotalStockdetails = async () => {
    let response = await fetch(`${authApi}stocks/`, {
      method: 'GET',
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      }
    })
      .then(res => res.json())
    return response;
  }


  getAvailablestocks = async () => {
    await axios({
      method: "post",
      url: `${authApi}getavailableqtytemplate/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
    })
      .then(res => {
        this.setState({
          Available: res.data,
        })
      })
  }

  getCompanydetails = () => {
    fetch(`${authApi}companyfilter/`, {
      method: 'GET',
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      }
    })
      .then(res => res.json())
      .then(json => {
        this.setState({
          CompanyDetails: json,
        })
      })
  }
  selectClient = (event) => {
    if (event.target.value === "Select Supplier") {
      this.setState({
        SupplierName: "",
      })
    }
    else {
      this.setState({
        SupplierName: event.target.value,
      }, function () {
        this.getClientid(event.target.value);
      })
    }
  }


  getClientid = (clientname) => {
    this.state.Clientdetails.forEach(client => {
      if (client.company_name === clientname) {
        this.setState({
          Supplierid: client.id,
        })
      }
    });
  }

  getReference = (event) => {
    this.setState({
      Reference: event.target.value,
    })
  }

  changeDate = (event) => {
    this.setState({
      Date: event.target.value,
    })
  }

  selectCompany = (event) => {
    if (event.target.value === "Select Company") {
      this.setState({
        Company: "",
      })
    }
    else {
      this.state.CompanyDetails.forEach(company => {
        if (company.companyname === event.target.value) {
          this.setState({
            Company: event.target.value,
            Companyid: company.id,
            gstcheck: company.isgst,
          })
        }
      });
    }
  }

  changeStatus = (event) => {
    this.setState({
      Status: event.target.value,
    })
  }

  addAnotherStock = () => {
    Stockid = Stockid + 1;
    let Totalstock = this.state.Stockdetails;
    let stock = {
      id: Stockid,
      name: "",
      productcode: "",
      quantity: 0,
      availableqty: 0,
      rate: 0,
      amount: 0,
      cgstdiscountpercentage: "0%",
      sgstdiscountpercentage: "0%",
      total: 0,
      salesorderid: 0,
    }
    Totalstock = [...Totalstock, stock];
    this.setState({
      Stockdetails: []
    })
    Totalstock = Totalstock.sort((a, b) => a.id > b.id ? 1 : -1);
    this.setState({
      Stockdetails: Totalstock,
    })

  }

  removeStockDetails = (id) => {
    let remainingdata = this.state.Stockdetails.filter((item) => item.id !== id);
    this.setState({
      Stockdetails: remainingdata,
    }, function () {
      this.calculateTotalAmount();
    })
  }

  updateStockDetails = (id, stockname, productcode, quantity, availableqty, rate, amount, cgstdiscountpercentage, sgstdiscountpercentage, totalamount) => {
    this.state.Stockdetails.forEach(stock => {
      if (stock.id === id) {
        stock.name = stockname;
        stock.productcode = productcode;
        stock.quantity = quantity;
        stock.availableqty = availableqty;
        stock.rate = rate;
        stock.amount = amount;
        stock.cgstdiscountpercentage = this.state.gstcheck === false ? "0%" : cgstdiscountpercentage;
        stock.sgstdiscountpercentage = this.state.gstcheck === false ? "0%" : sgstdiscountpercentage;
        stock.total = totalamount;
      }
    });
    this.calculateTotalAmount();
  }


  calculateTotalAmount = () => {
    let taxableamount = 0.00, granttotalamount = 0.00, totalquantity = 0.00;
    this.state.Stockdetails.forEach(stock => {
      if (stock.id !== 9999) {
        let CheckAmount = parseFloat(stock.amount) || 0;
        let CheckGrandtotal = parseFloat(stock.total) || 0;
        let CheckQuantity = parseFloat(stock.quantity) || 0;
        taxableamount = parseFloat(taxableamount) + CheckAmount;
        granttotalamount = parseFloat(granttotalamount) + CheckGrandtotal;
        totalquantity = parseFloat(totalquantity) + CheckQuantity;
      }
    });

    this.state.Stockdetails.forEach(stock => {
      if (stock.id === 9999) {
        stock.amount = taxableamount.toFixed(2);
        stock.totalamount = granttotalamount.toFixed(2);
        stock.quantity = totalquantity.toFixed(2);
        this.setState({
          TotalTaxableValue: taxableamount.toFixed(2),
          TotalAmount: granttotalamount.toFixed(2),
          GrandTotalAmount: granttotalamount.toFixed(2),
          TotalQuantity: totalquantity.toFixed(2),
        })
      }
    });
    if (this.state.AdvanceAmount.length !== 0) {
      let total = 0;
      this.state.AdvanceAmount.forEach(data => {
        if (data.salesid !== 'Total') {
          total = total + data.advance;
        }
      });
      this.state.AdvanceAmount.forEach(data => {
        if (data.salesid === 'Total') {
          data.advance = total;
        }
      });
      let data = this.state.AdvanceAmount.filter(item => item.salesid !== 'Total');
      let Advancedetails = {
        salesid: 'Total',
        advance: total,
      }
      data = [...data, Advancedetails];
      this.setState({
        AdvanceTotal: total,
        AdvanceAmount: data,
      })
    }
  }

  adjustAmount = (event) => {
    let total = parseFloat(this.state.TotalAmount) || 0;
    let totalamount = total - parseFloat(event.target.value);
    let totalwithtofixed = totalamount.toFixed(2);
    this.setState({
      GrandTotalAmount: totalwithtofixed,
      Adjustamount: event.target.value,
    })
  }


  isvalidEntryInForm = async () => {
    let isvalid = false, count = 0;
    this.state.Stockdetails.forEach(stock => {
      if (stock.receivedquantity === 0) {
        count = count + 1;
      }
    });
    if (count < 1) {
      if (this.state.Company !== "") {
        this.setState({ updateReceivedQty: false });
        if (this.state.SupplierName !== "") {
          if (this.state.Adjustamount !== "" && this.state.Adjustamount !== null && this.state.Adjustamount !== ".") {
            if (this.state.nullvalueisthere === false) {
              let remainingdata = this.state.Stockdetails.filter((item) => item.itemname !== "");
              this.setState({
                updateReceivedQty: false,
                ValidCompany: "none",
                ValidSupplierName: "none",
                Stockdetails: remainingdata,
                ValidAdjustamount: "none",
              }, function () {
                this.stockDataPreparationForStock();
              })
              isvalid = true;
            }
            else {
              alert("Please specify value for the required fields");
            }
          }
          else {
            this.setState({
              ValidCompany: "none",
              ValidSupplierName: "none",
              ValidAdjustamount: "1px solid red",
            })
          }
        }
        else {
          this.setState({
            ValidCompany: "none",
            ValidSupplierName: "1px solid red",
          })
        }
      }
      else {
        this.setState({
          ValidCompany: "1px solid red",
          ValidSupplierName: "none",
        })
      }
    }
    else {
      this.setState({
        updateReceivedQty: true,
      })
    }
    return isvalid;
  }

  onSubmitForm = async () => {
    if (await this.isvalidEntryInForm()) {
      await fetch(`${authApi}createdispatchtransaction/?companyid=${this.state.Companyid}&supplierid=${this.state.Supplierid}&dispatchdate=${this.state.Date}
      &dispatchreference=${this.state.Reference}&totalamount=${this.state.GrandTotalAmount}&roundoff=${this.state.Adjustamount}&dispatchdata=${StoreprocData.toString()}&advanceamount=${AdvanceAmountstoreproc}`)
        .then(res => {
          if (res.status === 200) {
            this.props.history.push('/admin/management/dispatch');
          }
        })
        .catch(err => {
          alert("error purchase order", err.response.request.response);
        })
    }
  }


  stockDataPreparationForStock = () => {
    let RemoveTotalAmount = this.state.Stockdetails.filter((item) => (item.name !== "Total") && (item.quantity !== 0));
    StoreprocData = RemoveTotalAmount.reduce((result, item) => {
      return `${result}${item.id}|${item.name}|${item.productcode}|${item.quantity}|${item.rate}|${item.amount}|${item.cgstdiscountpercentage}|${item.sgstdiscountpercentage}|${item.total}|${item.salesorderid},`
    }, "");
    let RemoveTotal = this.state.AdvanceAmount.filter((item) => (item.salesid !== 'Total'));
    AdvanceAmountstoreproc = RemoveTotal.reduce((result, item) => {
      return `${result}${item.salesid}|${item.advance},`
    }, "");
  }

  CancelEdit = () => {
    this.props.history.push('/admin/management/purchase-order');
  }

  checkanynullvalue = (check) => {
    this.setState({
      nullvalueisthere: check,
    })
  }

  updateSupplierdetails = (salesorder) => {
    let totaldata = this.state.Stockdetails;
    salesorder.sales.forEach(stock => {
      if (stock.stockname !== "Total") {
        let data = this.state.Available.filter(stockdata => stockdata.StockName === stock.stockname);
        let availablequantity = data.length !== 0 ? data[0].TotalQty : 0;
        if (stock.status === 'Issued' || stock.status === 'Partially Dispatched') {
          let remaingqty = parseInt(stock.quantity) - parseInt(stock.usedqty);
          let addStock = {
            id: stock.id,
            name: stock.stockname,
            productcode: stock.productcode,
            quantity: remaingqty,
            availableqty: availablequantity,
            rate: 0,
            amount: 0,
            cgstdiscountpercentage: stock.cgstpercentage,
            sgstdiscountpercentage: stock.sgstpercentage,
            total: 0,
            salesorderid: stock.sales,
          }
          totaldata = [...totaldata, addStock]
        }
      }
    });
    let remainingdata = totaldata.filter((item) => item.name !== "");
    remainingdata = remainingdata.sort((a, b) => a.id > b.id ? 1 : -1);
    let max = 0;
    remainingdata.forEach(stock => {
      if (stock.name !== "Total") {
        if (stock.id > max) {
          max = stock.id;
        }
      }
    });
    Stockid = max + 1;
    let orderdetails = {
      orderno: salesorder.id,
    }
    let Advancedetails = {
      salesid: salesorder.id,
      advance: parseInt(salesorder.advancereceived) - parseInt(salesorder.advanceused),
    }
    this.setState({
      AdvanceAmount: [...this.state.AdvanceAmount, Advancedetails],
      Stockdetails: remainingdata,
      PurchaseOrderid: [...this.state.PurchaseOrderid, orderdetails],
      ValidSupplierName: "none",
    }, function () {
      this.calculateTotalAmount();
    })
  }

  checkUpdateSupplierid = () => {
    this.setState({
      ValidSupplierName: "1px solid red",
    })
  }

  render() {
    const stockdata = this.state.Stockdetails.map((items) => (
      <AddDispatchRows
        key={items.id}
        stock={items}
        gstCheck={this.state.gstcheck}
        taxableamount={this.state.TotalTaxableValue}
        totalcgstdiscount={this.state.TotalCGSTAmount}
        totalsgstdiscount={this.state.TotalSGSTAmount}
        granttotalamount={this.state.TotalAmount}
        totalquantity={this.state.TotalQuantity}
        Availablestock={this.state.Available}
        removestockdetails={this.removeStockDetails}
        stockname={this.state.Stockname}
        updatestock={this.updateStockDetails}
        addstock={this.addAnotherStock}
        checkifanynull={this.checkanynullvalue}
        nullvalueisthere={this.state.nullvalueisthere}
      />
    ));
    let totalclientname = this.state.Clientdetails.map(q => q.company_name);
    let clientname = totalclientname.filter((q, idx) => totalclientname.indexOf(q) === idx);
    let totalcompany = this.state.CompanyDetails.map(q => q.companyname);
    let companyorder = totalcompany.filter((q, idx) => totalcompany.indexOf(q) === idx);
    return (
      <React.Fragment>
        <Header />
        <Container className="mt--7" fluid><br />
          <Row>
            <Col md="10">
              <label className="form-control-label" style={{ marginTop: 20, }}>
                <h1><i class="fas fa-cart-plus"></i>Add Dispatch</h1>
              </label>
            </Col>
            <Col md="1">
              {this.state.Supplierid !== "" ?
                <Popup
                  modal
                  data-backdrop="static"
                  trigger=
                  {
                    <button id="buttonsave" className="btn btn-white" style={{ marginTop: 25, right: "-15px" }}>
                      <i className="fas fa-plus-circle fa-1x"></i>
                    </button>
                  }
                >
                  {
                    (close) => (
                      <SalesOrder
                        companydetails={this.state.CompanyDetails}
                        supplierdetails={this.updateSupplierdetails}
                        supplier={this.state.Supplierid}
                        suppliername={this.state.SupplierName}
                        closebtn={close}
                      />
                    )}
                </Popup>
                :
                <button id="buttonsave" className="btn btn-white" style={{ marginTop: 25, right: "-15px" }} onClick={this.checkUpdateSupplierid}>
                  <i className="fas fa-plus-circle fa-1x"></i>
                </button>
              }
            </Col>
            <Col md="1">
              <div className="addRow" style={{ position: "absolute", right: "45px", marginTop: 2 }}>
                <NavLink style={{ marginTop: 20 }}
                  to="/admin/management/dispatch"
                  tag={Link}
                >
                  <Button data-tip data-for="completed-Tip"> <i className="fa fa-arrow-left fa-1x"></i></Button>
                </NavLink>
                <ReactTooltip id="completed-Tip" place="left" type="dark" effect="solid">
                  Go To Dispatch
                </ReactTooltip>
              </div>
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12, }}>
                Company
              </label>
            </Col>
            <Col md="3">
              <Input type="select"
                value={this.state.Company}
                style={{ border: this.state.ValidCompany }}
                onChange={this.selectCompany} >
                <option value="Select Company">Select Company</option>
                {
                  companyorder.map((item, key) =>
                    (<option key={key} value={item}> {item} </option>))
                }
              </Input>
              {this.state.ValidCompany !== "none" ? <h6 style={{ color: "red" }}>Please Select Company</h6> : null}
            </Col>
            <Col md="2">
              <label className="form-control-label" style={{ marginTop: 12, }}>
                Supplier Name
              </label>
            </Col>
            <Col md="3">
              <Input type="select"
                value={this.state.SupplierName}
                style={{ border: this.state.ValidSupplierName, marginLeft: -90 }}
                onChange={this.selectClient} >
                <option value="Select Supplier">Select Supplier</option>
                {
                  clientname.sort((a, b) => a.localeCompare(b)).map((item, key) =>
                    (<option key={key} value={item}> {item} </option>))
                }
              </Input>
              {this.state.ValidSupplierName !== "none" ? <h6 style={{ color: "red" }}>Please Select SupplierName</h6> : null}
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12, marginLeft: -90 }}>
                Date
              </label>
            </Col>
            <Col md="2">
              <Input
                className="form-control-alternative"
                type="date"
                style={{ marginLeft: -90 }}
                Value={this.state.Date}
                onChange={this.changeDate}
              />
            </Col>
          </Row>
          <br />
          <Row>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12, }}>
                Reference
              </label>
            </Col>
            <Col md="3">
              <Input type="text"
                value={this.state.Reference}
                onChange={this.getReference} />
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Status
              </label>
            </Col>
            <Col md="3">
              <Input
                className="form-control-alternative"
                type="text"
                style={{ marginLeft: 18 }}
                defaultValue={this.state.Status}
                onChange={this.changeStatus} />
            </Col>
          </Row>
          <br />
          <Row>
            <div className=" col"><br />
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive-sm>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">ACTION</th>
                      <th className="text-center">SALESID</th>
                      <th className="text-center">GOODS NAME</th>
                      <th className="text-center">PRO.CODE</th>
                      <th className="text-center">AVI.QRY</th>
                      <th className="text-center">DIS.QTY</th>
                      <th className="text-center">RATE</th>
                      {this.state.gstcheck === true ? <th className="text-center">AMOUNT</th> : null}
                      {this.state.gstcheck === true ? <th className="text-center">CGST/SGST %</th> : null}
                      <th className="text-center">TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>{stockdata}</tbody>
                </Table>
              </Card>
            </div>
          </Row>
          <Row>
            <Col md="4">
              <Table className="align-items-center table-flush" responsive-sm style={{ backgroundColor: "white" }}>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">SO.ID</th>
                    <th className="text-center">ADVANCE</th>
                  </tr>
                </thead>
                <tbody>{this.state.AdvanceAmount.map((data) => (
                  <tr>
                    <td className="text-center">{data.salesid}</td>
                    <td className="text-center">{data.advance}</td>
                  </tr>
                ))}</tbody>
              </Table>
            </Col>
            <Col md="4"></Col>
            <Col md="2">
              <ul class="list-group list-group-flush" style={{ paddingLeft: 90, width: 290 }}>
                {this.state.gstcheck === true ? <li class="list-group-item">TaxableValue</li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">CGST/SGST</li> : null}
                <li class="list-group-item">Amount</li>
                <li class="list-group-item" style={{ height: 62 }}>Adjustment</li>
                <li class="list-group-item">GrandTotalAmount</li>
                <li class="list-group-item">Total</li>
              </ul>
            </Col>
            <Col md="2">
              <ul class="list-group list-group-flush align-center" style={{ paddingLeft: 60 }} >
                {this.state.gstcheck === true ? <li class="list-group-item">{this.state.TotalTaxableValue}</li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">{parseFloat(parseFloat(this.state.TotalAmount) - parseFloat(this.state.TotalTaxableValue)).toFixed(2)}</li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">{this.state.TotalAmount}</li> : <li class="list-group-item">{this.state.TotalTaxableValue}</li>}
                <li class="list-group-item"><Input type="text"
                  style={{
                    border: this.state.ValidAdjustamount,
                    textAlign: "left",
                    position: "relative",
                    display: "block",
                    top: 0,
                    fontSize: "15px",
                    margin: "0",
                    left: -12,
                    height: 33,
                    width: "110%",
                  }}
                  Value={this.state.Adjustamount}
                  onChange={this.adjustAmount} />
                </li>
                {this.state.gstcheck === true ?
                  <li class="list-group-item">{this.state.GrandTotalAmount}</li> :
                  <li class="list-group-item">
                    {parseFloat(
                      parseFloat(this.state.TotalTaxableValue) -
                      parseFloat(this.state.Adjustamount)
                    ).toFixed(2)}</li>}
                {this.state.gstcheck === true ?
                  <li class="list-group-item">
                    {parseFloat(this.state.GrandTotalAmount) - parseFloat(this.state.AdvanceAmount)}</li> :
                  <li class="list-group-item">
                    {parseFloat(
                      parseFloat(this.state.TotalTaxableValue) -
                      parseFloat(this.state.Adjustamount) -
                      parseFloat(this.state.AdvanceAmount)
                    ).toFixed(2)}
                  </li>}
              </ul>
            </Col>
          </Row>
          {this.state.nullvalueisthere === true ?
            <Row>
              <Col md="5"></Col>
              <Col> <h3 style={{ color: "red" }}>Please fill the required fields marked in red</h3></Col>
            </Row> : null}
          {this.state.updateReceivedQty === true ?
            <Row>
              <Col md="5"></Col>
              <Col> <h3 style={{ color: "red" }}>Please check Received quantity</h3></Col>
            </Row> : null}
          <Row>
            <Col md="2">
            </Col>
            <Col md="7">
            </Col>
            <Col md="2">
            </Col>
            <Col md="1">
              <button id="buttonsave" className="btn btn-dark" onClick={this.onSubmitForm}>
                Submit
              </button>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    token: state.token,
    clientdetails:state.clientdetails
  }
}
export default connect(mapStateToProps, null)(AddDispatch);
