import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  descriptions: {
    width: "81.7%",
    textAlign: "left",
    paddingLeft: 8,
    paddingTop: 4,
    color: "#000000",
  },
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
  amounts: {
    width: "18.3%",
    textAlign: "right",
    paddingRight: 10,
    paddingTop: 4,
    color: "#000000",
  },
});

const InvoiceDrafting = ({ draftChar }) => 
{
  return( 
    <React.Fragment>
      { draftChar === 0 ? null :
      <View style={styles.rows}>
        <Text style={styles.descriptions}>Drafting Charges</Text>
        <Text style={styles.amounts}>{Number.parseFloat(draftChar).toFixed(2)}</Text>
      </View>
      }
    </React.Fragment>
  )
};

export default InvoiceDrafting;
