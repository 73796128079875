import React from "react";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../config";
import DateRangePicker from "react-bootstrap-daterangepicker";
import Header from "../../../components/Headers/SimpleHeader";
import ReactTooltip from "react-tooltip";
import {
  Container,
  Card,
  NavLink,
  Row,
  Col,
  Input,
  Label,
  Table,
  Form,
  CardBody,
  CardHeader,
} from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import UserExsitingRequest from "./UserExsitingRequest";
import axios from "axios";
import getDateTimeField from "../../../Utils";
const authApi = config.get("apiDomain");

let userrequested = [];

class RequestPurchaseStock extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      Stockdetails: [],
      Stockname: "",
      username: this.props.username,
      userid: "",
      quantity: 0,
      ValidStockname: "1px solid #dee2e6",
      ValidQuantity: "1px solid #dee2e6",
      date: moment(new Date()).format("YYYY-MM-DD"),
      productcode: "",
      userExsitingRequestdata: [],
      status: "Requested",
      description: "",
      purchaseorderid: "",
      isdeleted: false,
      Startdate: moment(new Date()).format("YYYY-MM-DD"),
      Enddate: moment(new Date()).format("YYYY-MM-DD"),
      SelectStockname: "",
      FilterStatus: "Requested",
      Inventorydata: [],
      Availabequantity: 0,
      Inventoryquantity: 0,
      Purchaseorderquantity: 0,
      Salesquantity: 0,
      Hoverfont: 10,
    };
  }
  async componentDidMount() {
    let d = new Date();
    d.setMonth(d.getMonth() - 6);
    let startdate = moment(d).format("YYYY-MM-DD");
    let stocknamedetails = await this.getTotalStockdetails();
    this.setState({
      Stockdetails: stocknamedetails,
      Startdate: startdate,
    });
    await this.getuserid();
  }

  getTotalStockdetails = async () => {
    let response = await fetch(`${authApi}stocks/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());

    let totalavailabe = await axios({
      method: "post",
      url: `${authApi}getavailableqtytemplate/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    });
    let body = await totalavailabe.data;
    let inventorydetails = [];
    body.forEach((storeproc) => {
      response.forEach((stock) => {
        if (stock.stockname === storeproc.StockName) {
          let data = {
            stockname: storeproc.StockName,
            quantity: storeproc.TotalQty,
            inventoryqty: storeproc.InventoryQty,
            purchaseorderqty: storeproc.POQty,
            salesqty: storeproc.SOQty,
            productcode: stock.productcode,
          };
          inventorydetails = [...inventorydetails, data];
        }
      });
    });
    this.setState({
      Inventorydata: inventorydetails,
    });
    return response;
  };

  getuserid = () => {
    let currentuser = this.props.allusers.filter((users) => {
      return users.name === this.props.username;
    });
    this.setState(
      {
        userid: currentuser[0].id,
      },
      function () {
        this.getCurrentUserRequestData(currentuser[0].id);
      }
    );
  };

  getCurrentUserRequestData = (userid) => {
    fetch(`${authApi}purchaserequestfilter/?username=${userid}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        userrequested = json.reverse();
        this.setState({
          userExsitingRequestdata: userrequested.filter(
            (item) => item.status === "Requested"
          ),
        });
      });
  };
  hoverIn = (e) => {
    console.log(e);
    this.setState({
      Hoverfont: 14,
    });
  };
  hoverOut = () => {
    this.setState({
      Hoverfont: 10,
    });
  };
  getQuantity = (event) => {
    this.setState({
      quantity: event.target.value,
    });
  };
  getInventoryQuantity = (event) => {
    this.setState({
      inventoryqty: event.target.value,
    });
  };
  getPurchaseorderQuantity = (event) => {
    this.setState({
      purchaseorderqty: event.target.value,
    });
  };
  getSalesQuantity = (event) => {
    this.setState({
      salesqty: event.target.value,
    });
  };

  selectStock = (event) => {
    this.setState({
      Stockname: event.target.value,
    });
    this.state.Stockdetails.forEach((stock) => {
      if (event.target.value === stock.stockname) {
        this.setState({
          productcode: stock.productcode,
        });
      }
    });

    let available = 0;
    let inventoryavailable = 0;
    let purchaseavailable = 0;
    let salesavailable = 0;
    this.state.Inventorydata.forEach((inventory) => {
      if (inventory.stockname === event.target.value) {
        available = inventory.quantity;
      }
    });
    this.setState({
      Availabequantity: available,
    });
    this.state.Inventorydata.forEach((inventory) => {
      if (inventory.stockname === event.target.value) {
        inventoryavailable = inventory.inventoryqty;
      }
    });
    this.setState({
      Inventoryquantity: inventoryavailable,
    });
    this.state.Inventorydata.forEach((inventory) => {
      if (inventory.stockname === event.target.value) {
        purchaseavailable = inventory.purchaseorderqty;
      }
    });
    this.setState({
      Purchaseorderquantity: purchaseavailable,
    });
    this.state.Inventorydata.forEach((inventory) => {
      if (inventory.stockname === event.target.value) {
        salesavailable = inventory.salesqty;
      }
    });
    this.setState({
      Salesquantity: salesavailable,
    });
  };

  validation = () => {
    if (this.state.Stockname !== "" && this.state.StockName !== "Select All") {
      if (this.state.quantity !== "" && this.state.quantity !== 0) {
        this.setState({
          ValidQuantity: "1px solid #dee2e6",
          ValidStockname: "1px solid #dee2e6",
        });
        this.sumbitPurchaseRequest();
      } else {
        this.setState({
          ValidQuantity: "1px solid #fb6340",
          ValidStockname: "1px solid #dee2e6",
        });
      }
    } else {
      this.setState({
        ValidQuantity: "1px solid #dee2e6",
        ValidStockname: "1px solid #fb6340",
      });
    }
  };

  sumbitPurchaseRequest = () => {
    let today = new Date();
    let currenttime =
      today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let datetime_field = getDateTimeField();
    if (this.state.id === "") {
      axios({
        method: "post",
        url: `${authApi}purchaserequest/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: this.state.date,
          time: currenttime,
          username: this.state.userid,
          stockname: this.state.Stockname,
          productcode: this.state.productcode,
          quantity: this.state.quantity,
          inventoryqty: this.state.inventoryqty,
          purchaseorderqty: this.state.purchaseorderqty,
          salesqty: this.state.salesqty,
          status: this.state.status,
          description: this.state.description,
          purchaseorderid: this.state.purchaseorderid,
          isdeleted: this.state.isdeleted,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          if (res.status === 201) {
            this.setState(
              {
                id: "",
                Stockname: "",
                quantity: 0,
                date: moment(new Date()).format("YYYY-MM-DD"),
                productcode: "",
                status: "Requested",
                description: "",
                purchaseorderid: "",
                isdeleted: false,
                Availabequantity: 0,
                Inventoryquantity: 0,
                Purchaseorderquantity: 0,
                Salesquantity: 0,
              },
              function () {
                this.getCurrentUserRequestData(this.state.userid);
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      axios({
        method: "put",
        url: `${authApi}purchaserequest/${this.state.id}/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: this.state.date,
          time: currenttime,
          username: this.state.userid,
          stockname: this.state.Stockname,
          productcode: this.state.productcode,
          quantity: this.state.quantity,
          inventoryqty: this.state.inventoryqty,
          purchaseorderqty: this.state.purchaseorderqty,
          salesqty: this.state.purchaseorderqty,
          status: this.state.status,
          description: this.state.description,
          purchaseorderid: this.state.purchaseorderid,
          isdeleted: this.state.isdeleted,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            this.setState(
              {
                id: "",
                Stockname: "",
                quantity: 0,
                date: moment(new Date()).format("YYYY-MM-DD"),
                productcode: "",
                status: "Requested",
                description: "",
                purchaseorderid: "",
                isdeleted: false,
                Availabequantity: 0,
                Inventoryquantity: 0,
                Purchaseorderquantity: 0,
                Salesquantity: 0,
              },
              function () {
                this.getCurrentUserRequestData(this.state.userid);
              }
            );
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  getEditabledata = (editdata) => {
    let available = 0;
    this.state.Inventorydata.forEach((inventory) => {
      if (inventory.stockname === editdata.stockname) {
        available = inventory.quantity;
      }
    });
    let inventoryavailable = 0;
    this.state.Inventorydata.forEach((inventory) => {
      if (inventory.stockname === editdata.stockname) {
        inventoryavailable = inventory.inventoryqty;
      }
    });
    this.setState({
      Inventoryquantity: inventoryavailable,
    });
    let purchaseavailable = 0;
    this.state.Inventorydata.forEach((inventory) => {
      if (inventory.stockname === editdata.stockname) {
        purchaseavailable = inventory.purchaseorderqty;
      }
    });
    this.setState({
      Purchaseorderquantity: purchaseavailable,
    });
    let salesavailable = 0;
    this.state.Inventorydata.forEach((inventory) => {
      if (inventory.stockname === editdata.stockname) {
        salesavailable = inventory.salesqty;
      }
    });
    this.setState({
      Salesquantity: salesavailable,
    });
    this.setState({
      Availabequantity: available,
    });
    this.setState({
      id: editdata.id,
      Stockname: editdata.stockname,
      userid: editdata.username,
      quantity: editdata.quantity,
      inventoryqty: editdata.inventoryqty,
      purchaseorderqty: editdata.purchaseorderqty,
      salesqty: editdata.salesqty,
      date: editdata.date,
      productcode: editdata.productcode,
      description: editdata.description,
      purchaseorderid: editdata.purchaseorderid,
      isdeleted: editdata.isdeleted,
    });
  };

  selectProductCode = (event) => {
    this.setState({
      productcode: event.target.value,
    });
    this.state.Stockdetails.forEach((stock) => {
      if (stock.productcode === event.target.value) {
        this.setState({
          Stockname: stock.stockname,
        });
      }
    });
  };

  changeStockName = (event) => {
    this.setState(
      {
        SelectStockname: event.target.value,
      },
      function () {
        this.selectStockname(event.target.value);
      }
    );
  };

  selectStockname = async (stockname) => {
    this.state.Stockdetails.forEach((stock) => {
      if (stock.stockname === stockname) {
        this.setState({
          productcode: stock.productcode,
        });
      }
    });
  };

  getdate = (event, picker) => {
    this.setState({
      Startdate: moment(picker.startDate).format("YYYY-MM-DD"),
      Enddate: moment(picker.endDate).format("YYYY-MM-DD"),
    });
  };

  changeStatus = (event) => {
    this.setState({
      FilterStatus: event.target.value,
    });
  };

  searchFilter = () => {
    fetch(
      `${authApi}purchaserequestfilter/?username=${this.state.userid}&stockname=${this.state.SelectStockname}&status=${this.state.FilterStatus}&date__gte=${this.state.Startdate}&date__lte=${this.state.Enddate}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          userExsitingRequestdata: json.reverse(),
        });
      })
      .catch((err) => console.log(err));
  };

  keyEventOperation = (event) => {
    let index = [...event.target.form].indexOf(event.target);
    if (index <= 6) {
      if (!event.shiftKey && event.key === "Enter") {
        if (event.target.form.elements[index + 1] !== undefined && index <= 6) {
          event.target.form.elements[index + 1].focus();
          event.preventDefault();
        }
      } else {
        if (event.shiftKey && event.key === "Enter") {
          if (
            event.target.form.elements[index - 1] !== undefined &&
            index <= 6
          ) {
            event.target.form.elements[index - 1].focus();
            event.preventDefault();
          }
        }
      }
    }
  };

  render() {
    const stockdata = this.state.userExsitingRequestdata.map((items) => (
      <UserExsitingRequest
        key={items.id}
        stock={items}
        getEditedData={this.getEditabledata}
        id={this.state.id}
      />
    ));
    let totalstock = this.state.Stockdetails.map((q) => q.stockname);
    let orderstocks = totalstock.filter(
      (q, idx) => totalstock.indexOf(q) === idx
    );
    let availablestockname = userrequested.map((q) => q.stockname);
    let stocknames = availablestockname.filter(
      (q, idx) => availablestockname.indexOf(q) === idx
    );
    let selectedSatus = userrequested.map((q) => q.status);
    let statusdetails = selectedSatus.filter(
      (q, idx) => selectedSatus.indexOf(q) === idx
    );

    return (
      <React.Fragment>
        <Header />
        <Container className="mt--7" fluid>
          <div style={{ marginTop: 10, float: "right", marginRight: 10 }}>
            <NavLink style={{ marginTop: 6 }} to="/admin/myday" tag={Link}>
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                data-tip
                data-for="completed-Tip"
              >
                Back
              </button>
            </NavLink>
            <ReactTooltip
              id="completed-Tip"
              place="left"
              type="dark"
              effect="solid"
            >
              Go Back to MyDay
            </ReactTooltip>
          </div>
          <br />
          <br />
          <br />
          <Row>
            <Col md="12">
              <div className="card-wrapper">
                <Card>
                  <CardHeader
                    style={{ backgroundColor: "transparent", padding: 5 }}
                  >
                    <h3
                      className="mb-0"
                      style={{ marginTop: 5, marginLeft: 10 }}
                    >
                      Create Request
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row>
                        <Col md="2" style={{ marginTop: 12, marginLeft: 20 }}>
                          <Label>StockName</Label>
                        </Col>
                        <Col md="3">
                          <Input
                            type="select"
                            value={this.state.Stockname}
                            style={{ border: this.state.ValidStockname }}
                            onChange={this.selectStock}
                            autoFocus
                            onKeyDown={this.keyEventOperation}
                          >
                            <option value="Select All">Select Stock</option>
                            {orderstocks.map((item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </Input>
                          {this.state.ValidStockname !== "1px solid #dee2e6" ? (
                            <h5 style={{ color: "#fb6340" }}>
                              Please select stock
                            </h5>
                          ) : null}
                        </Col>
                        <Col md="2" style={{ marginTop: 12, marginLeft: 20 }}>
                          <Label>Productcode</Label>
                        </Col>
                        <Col md="3">
                          <Input
                            type="option"
                            list="nickname-list"
                            style={{ border: "1px solid #dee2e6" }}
                            className="form-control-alternative"
                            value={this.state.productcode}
                            onChange={this.selectProductCode}
                            onKeyDown={this.keyEventOperation}
                          />
                          <datalist id="nickname-list">
                            {this.state.Stockdetails.map((item, name) => (
                              <option key={name} value={item.productcode} />
                            ))}
                          </datalist>
                        </Col>
                        <Col md="2"></Col>
                      </Row>
                      <Row style={{ marginTop: 10 }}>
                        <Col md="3" style={{ marginTop: 12, marginLeft: 20 }}>
                          <Label>
                            Available Quantity
                            <Label
                              style={{
                                fontSize: this.state.Hoverfont,
                              }}
                            >
                              (Inv Qty+PO Qty-SO Qty)
                            </Label>
                          </Label>
                        </Col>
                        <Col md="2" style={{ marginTop: 12, marginLeft: -15 }}>
                          <Label>
                            {this.state.Inventoryquantity +
                              " + " +
                              this.state.Purchaseorderquantity +
                              " - " +
                              this.state.Salesquantity}
                            =
                            <button
                              className="btn btn-sm btn-default"
                              type="button"
                            >
                              {this.state.Availabequantity}
                            </button>
                          </Label>
                        </Col>
                        <Col md="2" style={{ marginTop: 12, marginLeft: 35 }}>
                          <Label>Quantity</Label>
                        </Col>
                        <Col md="3">
                          <Input
                            type="text"
                            style={{
                              fontSize: "16px",
                              border: this.state.ValidQuantity,
                            }}
                            value={this.state.quantity}
                            onChange={this.getQuantity}
                            onKeyDown={this.keyEventOperation}
                          />
                          {this.state.ValidQuantity !== "1px solid #dee2e6" ? (
                            <h5 style={{ color: "#fb6340" }}>
                              Please enter stockquantity
                            </h5>
                          ) : null}
                        </Col>
                        <Col md="1">
                          <button
                            id="buttonsave"
                            className="btn btn-dark"
                            style={{ marginTop: 2, height: 40, left: 20 }}
                            onClick={this.validation}
                          >
                            Submit
                          </button>
                        </Col>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
          <Row>
            <Col md="12">
              <div className="card-wrapper">
                <Card>
                  <CardHeader
                    style={{ backgroundColor: "transparent", padding: 5 }}
                  >
                    <h3
                      className="mb-0"
                      style={{ marginTop: 5, marginLeft: 10 }}
                    >
                      Requests
                    </h3>
                  </CardHeader>
                  <CardBody>
                    <Form>
                      <Row style={{ marginTop: -15 }}>
                        <Col md="3">
                          <h5
                            style={{
                              color: "black",
                              fontWeight: 700,
                              fontSize: 13,
                            }}
                          >
                            Date
                          </h5>
                          <DateRangePicker
                            autoUpdateInput={false}
                            onApply={this.getdate}
                            initialSettings={{
                              locale: {
                                format: "DD/MM/YYYY",
                              },
                            }}
                          >
                            <button
                              className="form-control"
                              style={{
                                height: "35px",
                                width: "90%",
                                overflow: "hidden",
                                padding: "2px 2px 2px",
                              }}
                            >
                              <i
                                className="fa fa-calendar-o"
                                style={{ paddingRight: 15 }}
                                aria-hidden="true"
                              ></i>
                              {moment(this.state.Startdate).format(
                                "DD/MM/YYYY"
                              )}{" "}
                              -{" "}
                              {moment(this.state.Enddate).format("DD/MM/YYYY")}
                            </button>
                          </DateRangePicker>
                        </Col>
                        <Col md="3">
                          <h5
                            style={{
                              color: "black",
                              fontWeight: 700,
                              fontSize: 13,
                            }}
                          >
                            StackName
                          </h5>
                          <Input
                            type="select"
                            value={this.state.SelectStockname}
                            style={{
                              height: "35px",
                              width: "90%",
                              overflow: "hidden",
                              padding: "2px 2px 2px",
                            }}
                            onChange={this.changeStockName}
                          >
                            <option value="">All Stock</option>
                            {stocknames.map((item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </Input>
                        </Col>
                        <Col md="3">
                          <h5
                            style={{
                              color: "black",
                              fontWeight: 700,
                              fontSize: 13,
                              marginLeft: 20,
                            }}
                          >
                            Status
                          </h5>
                          <Input
                            type="select"
                            value={this.state.FilterStatus}
                            style={{
                              height: "35px",
                              width: "90%",
                              overflow: "hidden",
                              padding: "2px 2px 2px",
                            }}
                            onChange={this.changeStatus}
                          >
                            <option value="">All Status</option>
                            {statusdetails.map((item, key) => (
                              <option key={key} value={item}>
                                {item}
                              </option>
                            ))}
                          </Input>
                        </Col>
                        <Col md="3" style={{ marginTop: 5, marginLeft: 0 }}>
                          <button
                            id="buttonsave"
                            className="btn btn-primary btn-sm"
                            style={{ width: 65, height: 40, marginTop: 15 }}
                            onClick={this.searchFilter}
                          >
                            Filter
                          </button>
                        </Col>
                      </Row>
                      <Row style={{ marginTop: 25 }}>
                        <div className=" col">
                          <Card className="shadow">
                            <Table
                              className="align-items-center table-flush"
                              responsive-sm
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th className="text-center">ACTIONS</th>
                                  <th className="text-center">DATE</th>
                                  <th className="text-center">STOCKNAME</th>
                                  <th className="text-center">PRODUCTCODE</th>
                                  <th className="text-center">QUANTITY</th>
                                  <th className="text-center">
                                    PURCHASEORDERID
                                  </th>
                                  <th className="text-center">STATUS</th>
                                </tr>
                              </thead>
                              <tbody>{stockdata}</tbody>
                            </Table>
                          </Card>
                        </div>
                      </Row>
                    </Form>
                  </CardBody>
                </Card>
              </div>
            </Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
    allusers: state.allusers,
  };
};
export default connect(mapStateToProps, null)(RequestPurchaseStock);
