import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  titleContainer: {
    flexDirection: "row",
    marginTop: 0,
  },
  reportTitle: {
    fontSize: 12,
    textAlign: "center",
    textTransform: "uppercase",
  },
});

const InvoiceTitle = () => (
  <React.Fragment>
    <View style={styles.titleContainer}>
      <Text style={styles.reportTitle}>Invoice</Text>
    </View>
  </React.Fragment>
);

export default InvoiceTitle;
