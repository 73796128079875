import React from "react";
import { connect } from "react-redux";
import Header from "../../../../components/Headers/SimpleHeader";
import AddSalesDetailsRequest from "./AddSalesDetailsRequest";
import config from "react-global-configuration";
import "../../../../config";
import ReactTooltip from "react-tooltip";
import axios from "axios";
import {
  Container,
  Card,
  Row,
  Col,
  Input,
  NavLink,
  CardHeader,
  Table,
} from "reactstrap";
import { Link } from "react-router-dom";
import SalesDetailsRow from "./SalesDetailsRow";
import moment from "moment";
let StoreprocData = [];
const authApi = config.get("apiDomain");
let Stockid = 1, checkgst = true,
  RequestedStock = [];
class AddSalesDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Clientdetails: this.props.clientdetails,
      ClientName: "",
      Clientid: "",
      Reference: "",
      Date: moment(new Date()).format("YYYY-MM-DD"),
      Expecteddispatchdate: moment(new Date() + 10)
        .add(7, "days")
        .format("YYYY-MM-DD"),
      CompanyDetails: [],
      SelectedSupplier: [],
      Company: "",
      Companyid: "",
      Status: "Sales",
      Stockdetails: [],
      Stockname: [],
      TotalTaxableValue: 0,
      TotalCGSTAmount: 0,
      TotalSGSTAmount: 0,
      TotalAmount: 0,
      TotalQuantity: 0,
      gstcheck: true,
      Adjustamount: "0.00",
      GrandTotalAmount: 0,
      ValidCompany: "none",
      ValidClientName: "none",
      ValidAdjustamount: "none",
      nullvalueisthere: false,
      PurchaseOrderid: [],
      Invoiceno: "",
      Gsttype: "SGST",
      Location: [],
      Batch: [],
      updateSalesQty: false,
      Inventorydata: [],
      ShowSaleRequest: false,
      AdvanceAmount: 0,
    };
  }

  async componentDidMount() {
    await this.getPurchaseInventorydata();
    await this.getTotalStockdetails();
    this.getLocations();
    this.getBatchs();
    let stock = {
      id: Stockid,
      name: ``,
      productcode: "",
      quantity: 0,
      salesquantity: 0,
      rate: 0,
      amount: 0,
      cgstdiscountpercentage: "0%",
      sgstdiscountpercentage: "0%",
      total: 0,
    };
    this.setState({
      Stockdetails: [...this.state.Stockdetails, stock],
    });
    await this.getCompanydetails();
    let addStock = {
      id: 9999,
      name: "Total",
      productcode: "",
      quantity: "",
      salesquantity: 0,
      rate: 0,
      amount: 0,
      cgstdiscountpercentage: "",
      sgstdiscountpercentage: "",
      total: 0,
    };
    this.setState({
      Stockdetails: [...this.state.Stockdetails, addStock],
    });
  }

  getPurchaseInventorydata = async () => {
    await axios({
      method: "post",
      url: `${authApi}getavailableqtytemplate/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
    }).then(res => {
      this.setState({
        Inventorydata: res.data,
      });
    })
      .catch(err => {
        console.log(err)
      })
  };

  getTotalStockdetails = async () => {
    let response = await fetch(`${authApi}stocks/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    this.setState({
      Stockname: response,
    });
  };

  getLocations = async () => {
    let response = await fetch(`${authApi}location/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    this.setState({
      Location: response,
    });
  };

  getBatchs = async () => {
    let response = await fetch(`${authApi}batch/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    this.setState({
      Batch: response,
    });
  };

  getCompanydetails = () => {
    fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          CompanyDetails: json,
        });
      });
  };

  selectClient = (event) => {
    if (event.target.value === "Select Client") {
      this.setState({
        ClientName: "",
      });
    } else {
      this.setState(
        {
          ClientName: event.target.value,
        },
        function () {
          this.getClientid(event.target.value);
        }
      );
    }
  };

  getClientid = (clientname) => {
    this.state.Clientdetails.forEach((client) => {
      if (client.company_name === clientname) {
        this.setState({
          Clientid: client.id,
        });
      }
    });
  };

  getReference = (event) => {
    this.setState({
      Reference: event.target.value,
    });
  };
  changeGst = (event) => {
    this.setState({
      Gsttype: event.target.value,
    });
  };
  changeDispatchdate = (event) => {
    this.setState({
      Expecteddispatchdate: event.target.value,
    });
  };
  changeDate = (event) => {
    this.setState({
      Date: event.target.value,
    });
  };

  selectCompany = (event) => {
    if (event.target.value === "Select Company") {
      this.setState({
        Company: "",
      });
    } else {
      this.state.CompanyDetails.forEach((company) => {
        if (company.companyname === event.target.value) {
          this.setState({
            Company: event.target.value,
            Companyid: company.id,
            gstcheck: company.isgst,
            Stockdetails: checkgst === company.isgst ? this.state.Stockdetails : this.state.Stockdetails.filter((item) => item.name === "Total"),
          }, function () {
            if (checkgst !== company.isgst) {
              checkgst = company.isgst;
              this.updategstcheck();
            }
          });
        }
      });
    }
  };

  updategstcheck = () => {
    Stockid = 1;
    let stockdata = {
      id: Stockid,
      name: ``,
      productcode: "",
      quantity: 0,
      rate: "",
      amount: "",
      CGSTdiscount: 0,
      SGSTdiscount: 0,
      CGSTdiscountpercentage: "0%",
      SGSTdiscountpercentage: "0%",
      totalamount: 0,
      type: "",
    };
    this.setState({
      Stockdetails: [...this.state.Stockdetails, stockdata],
    }, function () {
      this.calculateTotalAmount();
    });
  }

  changeStatus = (event) => {
    this.setState({
      Status: event.target.value,
    });
  };

  addAnotherStock = () => {
    Stockid = Stockid + 1;
    let Totalstock = this.state.Stockdetails;
    let stock = {
      id: Stockid,
      name: "",
      productcode: "",
      quantity: 0,
      salesquantity: 0,
      rate: 0,
      amount: 0,
      cgstdiscountpercentage: "0%",
      sgstdiscountpercentage: "0%",
      total: 0,
    };
    Totalstock = [...Totalstock, stock];
    this.setState({
      Stockdetails: [],
    });
    Totalstock = Totalstock.sort((a, b) => (a.id > b.id ? 1 : -1));
    this.setState({
      Stockdetails: Totalstock,
    });
  };

  removeStockDetails = (id) => {
    let remainingdata = this.state.Stockdetails.filter(
      (item) => item.id !== id
    );
    this.setState(
      {
        Stockdetails: remainingdata,
      },
      function () {
        this.calculateTotalAmount();
      }
    );
  };

  updateStockDetails = (
    id,
    stockname,
    productcode,
    quantity,
    salesqty,
    rate,
    amount,
    cgstdiscountpercentage,
    sgstdiscountpercentage,
    totalamount
  ) => {
    this.state.Stockdetails.forEach((stock) => {
      if (stock.id === id) {
        stock.name = stockname;
        stock.productcode = productcode;
        stock.quantity = quantity;
        stock.salesquantity = salesqty;
        stock.rate = rate;
        stock.amount = amount;
        stock.cgstdiscountpercentage = cgstdiscountpercentage;
        stock.sgstdiscountpercentage = sgstdiscountpercentage;
        stock.total = totalamount;
      }
    });
    this.calculateTotalAmount();
  };

  calculateTotalAmount = () => {
    let taxableamount = 0.0,
      granttotalamount = 0.0,
      totalquantity = 0.0;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.id !== 9999) {
        let CheckAmount = parseFloat(stock.amount) || 0;
        let CheckGrandtotal = parseFloat(stock.total) || 0;
        let CheckQuantity = parseFloat(stock.salesquantity) || 0;
        taxableamount = parseFloat(taxableamount) + CheckAmount;
        granttotalamount = parseFloat(granttotalamount) + CheckGrandtotal;
        totalquantity = parseFloat(totalquantity) + CheckQuantity;
      }
    });

    this.state.Stockdetails.forEach((stock) => {
      if (stock.id === 9999) {
        stock.amount = taxableamount.toFixed(2);
        stock.total = granttotalamount.toFixed(2);
        stock.salesquantity = totalquantity.toFixed(2);
        this.setState({
          TotalTaxableValue: taxableamount.toFixed(2),
          TotalAmount: granttotalamount.toFixed(2),
          GrandTotalAmount: granttotalamount.toFixed(2),
          TotalQuantity: totalquantity.toFixed(2),
        });
      }
    });
  };

  adjustAmount = (event) => {
    let total = parseFloat(this.state.TotalAmount) || 0;
    let totalamount = total - parseFloat(event.target.value);
    let totalwithtofixed = totalamount.toFixed(2);
    this.setState({
      GrandTotalAmount: totalwithtofixed,
      Adjustamount: event.target.value,
    });
  };

  isvalidEntryInForm = async () => {
    let isvalid = false, count = 0, ratecount = 0;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.name !== "Total") {
        if (stock.salesquantity === 0) {
          count = count + 1;
        }
        if (stock.rate === 0) {
          ratecount = ratecount + 1;
        }
      }
    });
    if (count < 1 && ratecount < 1) {
      if (this.state.Company !== "") {
        this.setState({ updateSalesQty: false });
        if (this.state.ClientName !== "") {
          if (
            this.state.Adjustamount !== "" &&
            this.state.Adjustamount !== null &&
            this.state.Adjustamount !== "."
          ) {
            if (this.state.nullvalueisthere === false) {
              let remainingdata = this.state.Stockdetails.filter(
                (item) => item.name !== ""
              );
              this.setState(
                {
                  updateSalesQty: false,
                  ValidCompany: "none",
                  ValidClientName: "none",
                  Stockdetails: remainingdata,
                  ValidAdjustamount: "none",
                },
                function () {
                  this.stockDataPreparationForStock();
                }
              );
              isvalid = true;
            } else {
              alert("Please specify value for the required fields");
            }
          } else {
            this.setState({
              ValidCompany: "none",
              ValidClientName: "none",
              ValidAdjustamount: "1px solid red",
            });
          }
        } else {
          this.setState({
            ValidCompany: "none",
            ValidClientName: "1px solid red",
          });
        }
      } else {
        this.setState({
          ValidCompany: "1px solid red",
          ValidClientName: "none",
        });
      }
    } else {
      this.setState({
        updateSalesQty: true,
      });
    }
    return isvalid;
  };

  onSubmitForm = async (status) => {
    if (await this.isvalidEntryInForm()) {
      let statusdetails = status === "Save as Issued" ? "Issued" : "Draft";
      await axios({
        method: 'post',
        url: `${authApi}sp_salestransaction/`,
        headers: {
          'content-type': "application/json",
          'Authorization': `token ${this.props.token}`,
        },
        data: {
          date: this.state.Date,
          supplier: this.state.Clientid,
          company: this.state.Companyid,
          amount: this.state.GrandTotalAmount,
          reference: this.state.Reference,
          roundoff: this.state.Adjustamount,
          status: statusdetails,
          salesdetails: StoreprocData
        }
      })
        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            StoreprocData = [];
            this.props.history.push("/admin/management/sales");
          }
        })
        .catch((err) => {
          StoreprocData = [];
          alert("error purchase order", err.response.request.response);
        });
    }
  };

  stockDataPreparationForStock = () => {
    this.state.Stockdetails.forEach((stockdetails) => {
      let count = 0,
        cgstcount = 0;
      count = (stockdetails.sgstdiscountpercentage.match(/%/g) || []).length;
      cgstcount = (stockdetails.cgstdiscountpercentage.match(/%/g) || [])
        .length;
      if (count > 0) {
        stockdetails.sgstdiscountpercentage =
          stockdetails.sgstdiscountpercentage.substring(
            0,
            stockdetails.sgstdiscountpercentage.length - 1
          );
      }
      if (cgstcount > 0) {
        stockdetails.cgstdiscountpercentage =
          stockdetails.cgstdiscountpercentage.substring(
            0,
            stockdetails.cgstdiscountpercentage.length - 1
          );
      }
    });
    this.state.Stockdetails.forEach(salesdetails => {
      let newsalesdetails = {
        requestid: salesdetails.id,
        stockname: salesdetails.name,
        productcode: salesdetails.productcode,
        quantity: salesdetails.salesquantity,
        rate: salesdetails.rate,
        amount: salesdetails.amount,
        cgstpercentage: salesdetails.cgstdiscountpercentage,
        sgstpercentage: salesdetails.sgstdiscountpercentage,
        total: salesdetails.total
      }
      StoreprocData = [...StoreprocData, newsalesdetails]
     });
  };

  CancelEdit = () => {
    this.props.history.push("/admin/management/purchase-order");
  };

  checkanynullvalue = (check) => {
    this.setState({
      nullvalueisthere: check,
    });
  };

  updateRequestedDataIntoSales = (Requestedstock) => {
    let totaldata = this.state.Stockdetails, alreadythere = false;
    let availabledata = this.state.Inventorydata.filter(
      (stock) => stock.StockName === Requestedstock.stockname
    );
    let gst = this.state.Stockname.filter(
      (item) => item.stockname === Requestedstock.stockname
    );

    this.state.Stockdetails.forEach(stock => {
      if (stock.name === Requestedstock.stockname) {
        stock.salesquantity = parseInt(stock.salesquantity) + parseInt(Requestedstock.quantity);
        alreadythere = true;
      }
    });
    if (alreadythere === false) {
      let stock = {
        id: Requestedstock.id,
        name: Requestedstock.stockname,
        productcode: Requestedstock.productcode,
        quantity: availabledata !== 0 ? availabledata[0].TotalQty : 0,
        salesquantity: Requestedstock.quantity,
        rate: 0,
        amount: 0,
        cgstdiscountpercentage: gst[0].cgstpercentage,
        sgstdiscountpercentage: gst[0].sgstpercentage,
        total: 0,
      };
      totaldata = [...totaldata, stock];
      RequestedStock = [...RequestedStock, stock];
      let remainingdata = totaldata.filter((item) => item.name !== "");
      remainingdata = remainingdata.sort((a, b) => (a.id > b.id ? 1 : -1));
      let max = 0;
      remainingdata.forEach((stock) => {
        if (stock.name !== "Total") {
          if (stock.id > max) {
            max = stock.id;
          }
        }
      });
      Stockid = max + 1;
      this.setState(
        {
          Stockdetails: remainingdata,
        },
        function () {
          this.calculateTotalAmount();
        }
      );
    }
    else {
      let stock = {
        id: Requestedstock.id,
        name: Requestedstock.stockname,
        productcode: Requestedstock.productcode,
        quantity: availabledata !== 0 ? availabledata[0].TotalQty : 0,
        salesquantity: Requestedstock.quantity,
        rate: 0,
        amount: 0,
        cgstdiscountpercentage: gst[0].cgstpercentage,
        sgstdiscountpercentage: gst[0].sgstpercentage,
        total: 0,
      };
      RequestedStock = [...RequestedStock, stock];
    }
    this.calculateTotalAmount();
  };

  checkUpdateClientid = () => {
    this.setState({
      ValidClientName: "1px solid red",
    });
  };

  BackToSales = () => {
    if (this.state.ShowSaleRequest === true) {
      this.setState({
        ShowSaleRequest: false,
      });
    } else {
      this.setState({
        ShowSaleRequest: true,
      });
    }
  };

  keyEventOperation = (event) => {
    let index = [...event.target.form].indexOf(event.target);
    if (index <= 6) {
      if (!event.shiftKey && event.key === 'Enter') {
        if (event.target.form.elements[index + 1] !== undefined && index <= 6) {
          event.target.form.elements[index + 1].focus();
          event.preventDefault();
        }
      }
      else {
        if (event.shiftKey && event.key === 'Enter') {
          if (event.target.form.elements[index - 1] !== undefined && index <= 6) {
            event.target.form.elements[index - 1].focus();
            event.preventDefault();
          }
        }
      }
    }
  }


  render() {
    let stockdetailsdata = this.state.Stockdetails.sort((a, b) => (a.id > b.id ? 1 : -1));
    const stockdata = stockdetailsdata.map((items) => (
      <SalesDetailsRow
        key={items.id}
        stock={items}
        gstCheck={this.state.gstcheck}
        Inventorydata={this.state.Inventorydata}
        taxableamount={this.state.TotalTaxableValue}
        totalcgstdiscount={this.state.TotalCGSTAmount}
        totalsgstdiscount={this.state.TotalSGSTAmount}
        granttotalamount={this.state.TotalAmount}
        totalquantity={this.state.TotalQuantity}
        removestockdetails={this.removeStockDetails}
        stockname={this.state.Stockname}
        updatestock={this.updateStockDetails}
        addstock={this.addAnotherStock}
        checkifanynull={this.checkanynullvalue}
        nullvalueisthere={this.state.nullvalueisthere}
        location={this.state.Location}
        batch={this.state.Batch}
        totalsaledata={this.state.Stockdetails}
      />
    ));
    let totalclientname = this.state.Clientdetails.map((q) => q.company_name);
    let clientname = totalclientname.filter(
      (q, idx) => totalclientname.indexOf(q) === idx
    );
    let totalcompany = this.state.CompanyDetails.map((q) => q.companyname);
    let companyorder = totalcompany.filter(
      (q, idx) => totalcompany.indexOf(q) === idx
    );
    return (
      <React.Fragment>
        <Header />
        {this.state.ShowSaleRequest === false ? (
          <Container className="mt--7" fluid>
            <br />
            <form>
              <Row>
                <Col md="10">
                  <CardHeader style={{ backgroundColor: 'transparent', padding: 10, marginTop: 20 }}>
                    <h3 className="mb-0"><i style={{ marginRight: 5 }} class="fas fa-cart-plus"></i> Add Sales</h3>
                  </CardHeader>
                </Col>
                <Col md="0.5" style={{ marginTop: 22, float: 'right' }}>
                  <button
                    className="btn btn-primary btn-sm" style={{ width: 45, height: 40, right: -65 }}
                    data-tip
                    data-for="completed-Tip"
                    onClick={this.BackToSales}
                  >
                    
                    <i className="fas fa-plus-circle fa-1x"></i>
                  </button>
                </Col>
                <Col md="0.5" style={{ marginTop: 18, float: 'right' }}>
                  <NavLink
                    to="/admin/management/sales"
                    tag={Link}
                  >
                    <button
                      className="btn btn-primary btn-sm" style={{ width: 45, height: 40, right: -63 }}
                      data-tip data-for="completed-Tip">
                    
                      <i className="fa fa-arrow-left fa-1x"></i>
                    </button>
                  </NavLink>
                  <ReactTooltip
                    id="completed-Tip"
                    place="left"
                    type="dark"
                    effect="solid"
                  >
                    Go To Bill
                  </ReactTooltip>
                </Col>
              </Row>
              <br />
              <Row>
                <Col md="1">
                  <label className="form-control-label" style={{ marginTop: 12 }}>
                    Company
                  </label>
                </Col>
                <Col md="3">
                  <Input
                    type="select"
                    value={this.state.Company}
                    style={{ border: this.state.ValidCompany }}
                    onChange={this.selectCompany}
                    onKeyDown={this.keyEventOperation}
                  >
                    <option value="Select Company">Select Company</option>
                    {companyorder.map((item, key) => (
                      <option key={key} value={item}>
                     
                        {item}
                      </option>
                    ))}
                  </Input>
                  {this.state.ValidCompany !== "none" ? (
                    <h6 style={{ color: "red" }}>Please Select Company</h6>
                  ) : null}
                </Col>
                <Col md="2">
                  <label className="form-control-label" style={{ marginTop: 12 }}>
                    Client Name
                  </label>
                </Col>
                <Col md="3">
                  <Input
                    type="select"
                    value={this.state.ClientName}
                    style={{
                      border: this.state.ValidClientName,
                      marginLeft: -109,
                    }}
                    onChange={this.selectClient}
                    onKeyDown={this.keyEventOperation}
                  >
                    <option value="Select Client">Select Client</option>
                    {clientname
                      .sort((a, b) => a.localeCompare(b))
                      .map((item, key) => (
                        <option key={key} value={item}>
                         
                          {item}
                        </option>
                      ))}
                  </Input>
                  {this.state.ValidClientName !== "none" ? (
                    <h6 style={{ color: "red" }}>Please Select ClientName</h6>
                  ) : null}
                </Col>
                <Col md="1">
                  <label
                    className="form-control-label"
                    style={{ marginTop: 12, marginLeft: -90 }}
                  >
                    Date
                  </label>
                </Col>
                <Col md="2">
                  <Input
                    className="form-control-alternative"
                    type="date"
                    style={{ marginLeft: -90 }}
                    Value={this.state.Date}
                    onChange={this.changeDate}
                    onKeyDown={this.keyEventOperation}
                  />
                </Col>
              </Row>
              <br />
              <Row>
             
                <Col md="1">
                  <label className="form-control-label" style={{ marginTop: 12 }}>
                    Reference
                  </label>
                </Col>
                <Col md="3">
                  <Input
                    type="text"
                    value={this.state.Reference}
                    onChange={this.getReference}
                    onKeyDown={this.keyEventOperation}
                  />
                </Col>
                <Col md="1">
                  <label className="form-control-label" style={{ marginTop: 12 }}>
                    SGST Type
                  </label>
                </Col>
                <Col md="3">
                  <Input
                    type="select"
                    value={this.state.Gsttype}
                    onChange={this.changeGst}
                  >
                    <option>SGST</option>
                    <option>IGST</option>
                  </Input>
                </Col>
                <Col md="2">
                  <label className="form-control-label" style={{ marginTop: 12 }}>
                    Dispatch Date
                  </label>
                </Col>
                <Col md="2" style={{ marginLeft: -90 }}>
                  <Input
                    className="form-control-alternative"
                    type="date"
                    defaultValue={this.state.Expecteddispatchdate}
                    onChange={this.changeDispatchdate}
                  />
                </Col>
                <Col md="6"></Col>
              </Row>
              <br />
              <Row>
                <div className=" col">
                  <br />
                  <Card className="shadow">
                    <Table
                      className="align-items-center table-flush"
                      responsive-sm
                    >
                      <thead className="thead-light">
                        <tr>
                          <th className="text-center">ACTION</th>
                          <th className="text-center">GOODS NAME</th>
                          <th className="text-center">PRO.CODE</th>
                          <th className="text-center">INV.QTY</th>
                          <th className="text-center">SAL.QTY</th>
                          <th className="text-center">RATE</th>
                          {this.state.gstcheck === true ? <th className="text-center">AMOUNT</th> : null}
                          {this.state.gstcheck === true ? <th className="text-center">CGST/SGST %</th> : null}
                          <th className="text-center">TOTAL</th>
                        </tr>
                      </thead>
                      <tbody>{stockdata}</tbody>
                    </Table>
                  </Card>
                </div>
              </Row>
              <Row>
                <Col md="8"></Col>
                <Col md="2">
                  <ul
                    class="list-group list-group-flush"
                    style={{ paddingLeft: 90, width: 290 }}
                  >
                    {this.state.gstcheck === true ? <li class="list-group-item">TaxableValue</li> : null}
                    {this.state.gstcheck === true ? <li class="list-group-item">CGST/SGST</li> : null}
                   
                    <li class="list-group-item">Amount</li>
                    <li class="list-group-item" style={{ height: 62 }}>
                      Adjustment
                    </li>
                    <li class="list-group-item">GrandTotalAmount</li>
                  </ul>
                </Col>
                <Col md="2">
                  <ul
                    class="list-group list-group-flush align-center"
                    style={{ paddingLeft: 60 }}
                  >
                    {this.state.gstcheck === true ? <li class="list-group-item">
                      {this.state.TotalTaxableValue}
                    </li> : null}
                    {this.state.gstcheck === true ? <li class="list-group-item">
                      {parseFloat(
                        parseFloat(this.state.TotalAmount) -
                        parseFloat(this.state.TotalTaxableValue)
                      ).toFixed(2)}
                    </li> : null}
                    {this.state.gstcheck === true ? <li class="list-group-item">{this.state.TotalAmount}</li> : <li class="list-group-item">{this.state.TotalTaxableValue}</li>}
                    <li class="list-group-item">
                      <Input
                        type="text"
                        style={{
                          border: this.state.ValidAdjustamount,
                          textAlign: "left",
                          position: "relative",
                          display: "block",
                          top: 0,
                          fontSize: "15px",
                          margin: "0",
                          left: -12,
                          height: 33,
                          width: "110%",
                        }}
                        Value={this.state.Adjustamount}
                        onChange={this.adjustAmount}
                      />
                    </li>
                    {this.state.gstcheck === true ? <li class="list-group-item">{this.state.GrandTotalAmount}</li>
                      : <li class="list-group-item">{parseFloat(parseFloat(this.state.TotalTaxableValue) - parseFloat(this.state.Adjustamount)).toFixed(2)}</li>}
                  </ul>
                </Col>
              </Row>
              {this.state.nullvalueisthere === true ? (
                <Row>
                  <Col md="5"></Col>
                  <Col>
                    
                    <h3 style={{ color: "red" }}>
                      Please fill the required fields marked in red
                    </h3>
                  </Col>
                </Row>
              ) : null}
              {this.state.updateSalesQty === true ? (
                <Row>
                  <Col md="5"></Col>
                  <Col>
                    
                    <h3 style={{ color: "red" }}>
                      Please check Received Quantity or Rate
                    </h3>
                  </Col>
                </Row>
              ) : null}
              <Row>
                <Col md="2"></Col>
                <Col md="7"></Col>
                <Col md="2">
                  <button
                    id="buttonsave"
                    className="btn btn-dark btn-sm"
                    style={{ width: 100, height: 40, float: 'right', right: 20 }}
                    onClick={() => this.onSubmitForm("Save as Draft")}
                  >
                    Save as Draft
                  </button>
                </Col>
                <Col md="1" style={{ marginLeft: -10 }}>
                  <button
                    id="buttonsave"
                    className="btn btn-dark btn-sm"
                    style={{ width: 100, height: 40, float: 'right' }}
                    onClick={() => this.onSubmitForm("Save as Issued")}
                  >
                    Submit
                  </button>
                </Col>
              </Row>
            </form>
          </Container>
        ) : (
          <AddSalesDetailsRequest
            callbackFunction={this.BackToSales}
            requesteddata={this.updateRequestedDataIntoSales}
            alreadyaddedstock={RequestedStock}
          />
        )}

      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails:state.clientdetails
  };
};
export default connect(mapStateToProps, null)(AddSalesDetails);




