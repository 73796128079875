import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import OverviewReportsHeader from "./OverviewReportsHeader";
import OverviewTransactHeader from "./OverviewTransactHeader";
import TransactionRow from "./OverviewReportFolder/TransactionRow";
import PaymentRow from "./OverviewReportFolder/PaymentRow";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: -45,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  paytableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 10,
    borderWidth: 1,
    borderColor: "#d9d9d9",
    marginBottom: 10
  }
});

const OverviewReportsTable = ({ manageData,clientdetails }) => {
  const ascendingReport = manageData.sort((a, b) => a.id - b.id);

  return( 
    <View>
      <View style={styles.tableContainer} >
        <OverviewReportsHeader clientdata={clientdetails} />
        <TransactionRow taskData={ascendingReport} cd={clientdetails} />
      </View>
      <View style={styles.paytableContainer} >
        <OverviewTransactHeader />
        <PaymentRow taskData={ascendingReport} />
      </View>
  </View>
  )
};

export default OverviewReportsTable;