import React, { Component } from "react";
import * as firebase from 'firebase/app';

import {
  Button,
  Card,
  CardBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Col
} from "reactstrap";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      error: '',
    }
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleResetPassword = this.handleResetPassword.bind(this);
  }

  handleInputChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handleResetPassword = (event) => {
    const { email } = this.state;
    event.preventDefault();
    firebase.auth().sendPasswordResetEmail(email);
    event.preventDefault();
    alert("Password Reset Requested.!");
    this.props.history.push("/auth/login");
  };

  render() {
    return (
      <React.Fragment>
        <Col lg="5" md="7">
          <Card className="bg-secondary shadow border-0">
            <CardBody className="px-lg-5 py-lg-5">
              <div className="text-center text-muted mb-4">
                <small> Reset Password </small>
              </div>
              <Form role="form" onSubmit={this.handleResetPassword}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      type="text"
                      name="email"
                      placeholder="Email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                  </InputGroup>
                </FormGroup>
                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    Send Reset Link
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
          <span>{this.state.error}</span>
        </Col>
      </React.Fragment>
    );
  }
}

export default ForgotPassword;