import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1
  },
  date: {
    width: "10%",
  },
  task: {
    width: "40%",
  },
  client: {
    width: "10%",
  },
  subtask: {
    width: "10%",
  },
  starttime: {
    width: "10%",
  },
  endtime: {
    width: "10%",
  },
  time: {
    width: "10%",
  }
});

const ReportTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.date}> Date </Text>
    <Text style={styles.task}> Task </Text>
    <Text style={styles.client}> Client </Text>
    <Text style={styles.subtask}>Subtask</Text>
    <Text style={styles.starttime}>Starttime</Text>
    <Text style={styles.endtime}>Endtime</Text>
    <Text style={styles.time}>Time</Text>
  </View>
);

export default ReportTableHeader;
