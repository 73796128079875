import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1,
  },
  date: {
    width: "15%",
  },
  invoiceno: {
    width: "19%",
  },
  task: {
    width: "28%",
  },
  type: {
    width: "15%",
  },
  clientbalance: {
    width: "11.5%",
  },
  invoiceamount: {
    width: "11.5%",
  },
});

const OverviewReportsHeader = (clientdata) => {
  return (
    <View style={styles.container}>
      <Text style={styles.date}> Date </Text>
      {clientdata.clientdata !== undefined ? (
        <Text style={styles.invoiceno}> DelNote </Text>
      ) : (
        <Text style={styles.invoiceno}> Client </Text>
      )}
      <Text style={styles.task}> Task </Text>
      <Text style={styles.type}> Type </Text>
      <Text style={styles.invoiceamount}> Credit </Text>
      <Text style={styles.clientbalance}> Debit </Text>
    </View>
  );
};

export default OverviewReportsHeader;
