import React, { useEffect, useState, useMemo } from 'react';
import { Container, Row, Form, FormGroup } from 'reactstrap';
import TableContainerExpand from '../../../Filters/TableContainerExpand';
import TableContainerExpandTeam from '../../../Filters/TableContainerExpandTeam';
import { SelectColumnFilter } from '../../../Filters/filters';
import { connect } from 'react-redux';
import config from "react-global-configuration";
import '../../../../../config';
import ConPreLoader from "../../../../../components/Loader/Con_PreLoader";
import ReactTooltip from "react-tooltip";
import Header from '../../../../../components/Headers/SimpleHeader';
import { CurrentLocation } from "../../../../../components/loginRedux/loginReducers";	
import store from "../../../../../components/loginRedux/Store";

const authApi = config.get('apiDomain');
function DispatchBill(props) {

  const [SalesInvoicedata, storeSalesInvoicedata] = useState([]);
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(true);
  const [btnClass, setBtnClass] = useState("btn btn-danger btn-sm")
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    const getPurchagesDetails = async () => {
      store.dispatch({ type: CurrentLocation, data: props.location.pathname })
      const response = await fetch(`${authApi}summaryfilter/?invoicetype=sales`, {
        method: 'GET',
        headers: {
          'content-type': "application/json",
          'Authorization': `token ${props.token}`,
        }
      })
      let body = await response.json();
      if (body.length !== 0) {
        let companyfulldetails = await getCompanyName();
        body.forEach(invoice => {
          companyfulldetails.forEach(company => {
            if (parseInt(invoice.companyid) === company.id) {
              invoice.deliverynoteid = (parseInt(company.companycode) + 1) + "-" + invoice.deliverynoteid;
              invoice.companyid = company.companyname;
            }
          });
        });

        body.forEach(order => {
          props.clientdetails.forEach(client => {
            if (parseInt(order.invoicesummaryclientid) === client.id) {
              order.client = client.company_name;
            } 
          });
        });
        setLoading(false);
        storeSalesInvoicedata(body.reverse());
      }
      else {
        setLoading(false);
      }
    }
    getPurchagesDetails();
  },
    // eslint-disable-next-line
    []
  )

  function getFilterButton(event) {
    if (event === true) {
      setChecked(false)
      setBtnClass("btn btn-success btn-sm")
    }
    else {
      setChecked(true)
      setBtnClass("btn btn-danger btn-sm")
    }
  }

  const getCompanyName = async () => {
    const response = await fetch(`${authApi}companyfilter/`, {
      method: 'GET',
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${props.token}`,
      }
    })
    const body = await response.json();
    return body;
  }

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) => r[c] ? (r[c].toString().toLowerCase().indexOf((q).toLowerCase()) > -1) : null)
    );
  }

  const columnSecondLayer = useMemo(
    () => [
      {
        Header: "StockName",
        accessor: "stockname",
      },
      {
        Header: "ProductCode",
        accessor: "productcode",
      },
      {
        Header: "Quantity",
        accessor: "quantity"
      },
      {
        Header: "Rate",
        accessor: "rate",
      },
      {
        Header: "Amount",
        accessor: "amount",
      },
      {
        Header: "CGST",
        accessor: "cgstpercentage",
      },
      {
        Header: "SGST",
        accessor: "sgstpercentage",
      },
      {
        Header: "Total",
        accessor: "total",
      },
      {
        Header: "Batch",
        accessor: "batch",
      },
      {
        Header: "SalesOrderid",
        accessor: "sales",
      }
    ],
    // eslint-disable-next-line
    []
  );

  const renderRowSubComponent = (row) => {
    let stocks = [];
    stocks = row.original.task_invoiceno.filter(function (item) {
      return item.stockname !== "Total";
    });
    row.original.task_invoiceno.forEach((item) => {
      if (item.name === "Total") {
        stocks = [...stocks, item];
      }
    });
    const rowdata = stocks;
    return (
      <React.Fragment>
        <br />
        <h5 className="thead-light">DispatchBill Stocks Details :</h5>
        <TableContainerExpandTeam
          columns={columnSecondLayer}
          data={rowdata}
        />
        <br />
      </React.Fragment>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? <i className="ni ni-bold-down" /> : <i className="ni ni-bold-right" />}
          </span>
        )
      },
      {
        Header: "Deliverynoteid",
        accessor: "deliverynoteid",
        disableSortBy: true,
        filter: 'equals',
      },
      {
        Header: "Date",
        accessor: "date",
        disableSortBy: true,
        filter: 'equals',
      },
      {
        Header: "Company Name",
        accessor: "companyid",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: "Client Name",
        accessor: "client",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
      {
        Header: "Invoice Amt",
        accessor: "invoice_amount",
        disableSortBy: true,
        filter: 'equals',
      },
      {
        Header: "Received Amt",
        accessor: "amount_received",
        disableSortBy: true,
        filter: 'equals',
      },
      {
        Header: "BalanceDue",
        accessor: "balancedue",
        disableSortBy: true,
        filter: 'equals',
      },
      {
        Header: "Status",
        accessor: "invoicestatus",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: 'equals',
      },
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--7" fluid><br />
        <Row>
          <Form className="searchForm" style={{ marginTop: 14, right: "95px" }}>
            <FormGroup className="mb-0">
              <input className="searchBox " type="search" placeholder="Search" value={q} onChange={(e) => setQ(e.target.value)} />
            </FormGroup>
          </Form>
          <div className="invoicegenerate" style={{ position: 'absolute', right: '20px', marginTop: 16 }} >
            <button className={btnClass} style={{ width: '135%', height: 40 }} onClick={(e) => getFilterButton(checked)}>
              <i className="fa fa-filter" aria-hidden="true"></i>
            </button>
          </div>
        </Row>
        {loading === true ? <div> <ConPreLoader /></div> :
          <Row style={{ marginTop: 80 }}>
            <div className=" col">
              <ReactTooltip place="top" type="dark" effect="float" />
              <TableContainerExpand
                columns={columns}
                checkedToggle={checked}
                data={search(SalesInvoicedata)}
                renderRowSubComponent={renderRowSubComponent}
              />
            </div>
          </Row>
        }
      </Container>
      <br /><br />
    </React.Fragment>
  );
};

const mapStateToProps = state => {
  return {
    token: state.token,
    clientdetails:state.clientdetails
  }
}
export default connect(mapStateToProps, null)(DispatchBill);