export const googleLogin = "Google_Login";
export const userLogin = "Normal_Login";
export const refreshLogin = "Refresh_Login";
export const opencloseSideBar = "Close_SideBar";
export const Usertoken = "User_Token";
export const FilteredData = "Filter_Data";
export const ClientTransactData = "Client_TransactData";
export const AllClientDetails = "AllClient_Details";
export const AllUsers = "All_Users";
export const CurrentLocation = "CurrentLocation";
export const MyTaskFilters = "MyTaskFilters";
export const TeamTaskFilters = "TeamTaskFilters";
export const ProjectFilter = "ProjectFilter";

const initialState = {
  name: null,
  email: null,
  username: localStorage.getItem("username"),
  profilepic: null,
  sideToggle: true,
  token: localStorage.getItem("token"),
  filterData: "",
  clientdata: "",
  clientdetails: JSON.parse(localStorage.getItem("clientdetails")),
  allusers: JSON.parse(localStorage.getItem("userdetails")),
  current_location: "",
  mytask_filter: [
    {
      id: "clientname",
      value: "",
    },
    {
      id: "plantype",
      value: "",
    },
    {
      id: "assignto",
      value: "",
    },
    {
      id: "status",
      value: "",
    },
    {
      id: "priority",
      value: "",
    },
  ],
  teamtask_filter: [
    {
      id: "clientname",
      value: "",
    },
    {
      id: "companyname",
      value: "",
    },
    {
      id: "plantype",
      value: "",
    },
    {
      id: "assignto",
      value: "",
    },
    {
      id: "status",
      value: "",
    },
    {
      id: "projectname",
      value: "",
    },
  ],
  project_filter: [
    {
      id: "clientname",
      value: "",
    },
    {
      id: "companyname",
      value: "",
    },
    {
      id: "projectlead",
      value: "",
    },
    {
      id: "status",
      value: "",
    },
  ],
};

const Userreducers = (state = initialState, action) => {
  switch (action.type) {
    case googleLogin:
      return {
        ...state,
        name: action.detail.displayName,
        email: action.detail.email,
        profilepic: action.detail.photoURL,
      };
    case userLogin:
      return {
        ...state,
        name: action.detail.displayName,
        email: action.detail.email,
        profilepic: action.detail.photoURL,
      };
    case refreshLogin:
      return {
        ...state,
        username: action.detail.displayName,
        email: action.detail.email,
        profilepic: action.detail.photoURL,
      };
    case opencloseSideBar:
      return {
        ...state,
        sideToggle: action.detail,
      };
    case Usertoken:
      return {
        ...state,
        token: action.detail,
      };
    case FilteredData:
      return {
        ...state,
        filterData: action.datas,
      };
    case ClientTransactData:
      return {
        ...state,
        clientdata: action.data,
      };
    case AllClientDetails:
      return {
        ...state,
        clientdetails: action.data,
      };
    case AllUsers:
      return {
        ...state,
        allusers: action.detail,
      };
    case MyTaskFilters:
      let data = state.mytask_filter;
      if (
        action.data.clientname !== undefined ||
        action.data.mytask_id === "clientname"
      ) {
        data[0].value = action.data.clientname;
      }
      if (
        action.data.plantype !== undefined ||
        action.data.mytask_id === "plantype"
      ) {
        data[1].value = action.data.plantype;
      }
      if (
        action.data.assignto !== undefined ||
        action.data.mytask_id === "assignto"
      ) {
        data[2].value = action.data.assignto;
      }
      if (
        action.data.status !== undefined ||
        action.data.mytask_id === "status"
      ) {
        data[3].value = action.data.status;
      }
      if (
        action.data.priority !== undefined ||
        action.data.mytask_id === "priority"
      ) {
        data[4].value = action.data.priority;
      }
      return {
        ...state,
        mytask_filter: data,
      };
    case TeamTaskFilters:
      let teamdata = state.teamtask_filter;
      if (
        action.data.clientname !== undefined ||
        action.data.teamtask_id === "clientname"
      ) {
        teamdata[0].value = action.data.clientname;
      }
      if (
        action.data.companyname !== undefined ||
        action.data.teamtask_id === "companyname"
      ) {
        teamdata[1].value = action.data.companyname;
      }
      if (
        action.data.plantype !== undefined ||
        action.data.teamtask_id === "plantype"
      ) {
        teamdata[2].value = action.data.plantype;
      }
      if (
        action.data.assignto !== undefined ||
        action.data.teamtask_id === "assignto"
      ) {
        teamdata[3].value = action.data.assignto;
      }
      if (
        action.data.status !== undefined ||
        action.data.teamtask_id === "status"
      ) {
        teamdata[4].value = action.data.status;
      }
      if (
        action.data.projectname !== undefined ||
        action.data.teamtask_id === "projectname"
      ) {
        if (
          action.data.projectname === undefined ||
          action.data.projectname === undefined
        )
          teamdata[5].value = "";
        else teamdata[5].value = action.data.projectname;
      }
      return {
        ...state,
        teamtask_filter: teamdata,
      };
    case ProjectFilter:
      let project_data = state.project_filter;
      if (
        action.data.clientname !== undefined ||
        action.data.project_id === "clientname"
      ) {
        project_data[0].value = action.data.clientname;
      }
      if (
        action.data.companyname !== undefined ||
        action.data.project_id === "companyname"
      ) {
        project_data[1].value = action.data.companyname;
      }
      if (
        action.data.projectlead !== undefined ||
        action.data.project_id === "projectlead"
      ) {
        if (
          action.data.projectlead === undefined ||
          action.data.projectlead === undefined
        )
          project_data[2].value = "";
        else project_data[2].value = action.data.projectlead;
      }
      if (
        action.data.status !== undefined ||
        action.data.project_id === "status"
      ) {
        project_data[3].value = action.data.status;
      }
      return {
        ...state,
        project_filter: project_data,
      };
    case CurrentLocation:
      return {
        ...state,
        current_location: action.data,
      };

    default:
      return state;
  }
};

export default Userreducers;
