import React from 'react';
import {connect} from 'react-redux';

class PurchaseOrderRow extends React.Component{
    constructor(props){
        super(props);
            this.state={
              suppliername:"",
              companyname:"",
              buttondisable:false,
            }
    }

componentDidMount(){
    this.props.purchaseorderid.forEach(order => {
        if (order.orderno===this.props.orderdata.id){
            this.setState({
                buttondisable:true,
            })
        }
    });
 this.props.clients.forEach(client => {
    if(parseInt(this.props.orderdata.supplier)===client.id){
        this.setState({
            suppliername:client.company_name,
        })
    }
 });

 this.props.company.forEach(company => {
     if(parseInt(this.props.orderdata.companyid)=== company.id){
        this.setState({
            companyname:company.companyname,
        })
     }
     
 });
}

updatePurchaseDetails=()=>{
    this.props.AddPurchase(this.props.orderdata);
}

render(){
        return(
            <tr>
                <td>
                <button className = "btn btn-primary btn-sm" style={{marginRight: '5px'}} onClick={this.updatePurchaseDetails} disabled={this.state.buttondisable}>
                 Add Bill
                </button>
                </td>
                <td>{this.props.orderdata.id}</td>
                <td>{this.state.companyname}</td>
                <td>{this.state.suppliername}</td>
            </tr>
        );
    }
}
const mapStateToProps = state => {
    return {
      token:state.token, 
    }
  }
  export default connect(mapStateToProps, null)(PurchaseOrderRow);