import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Invoice from "./Invoice";
import { Row, Card } from "reactstrap";
import "../../../config";
import { connect } from "react-redux";

class InvoiceApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      JsonObject: this.props.data,
      salesorderid: this.props.salesorderid,
      loading: true,
    };
  }

  componentDidMount() {
    this.setState({
      loading: false,
    });
  }

  render() {
    if (this.state.loading) {
      return null;
    } else {
      return (
        <React.Fragment>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">Invoice</div>
          <Row>
            <div className=" col">
              <div className="Scrolls-Contents">
                <Card className="shadow">
                  <PDFViewer width="100%" height="600" className="app">
                    <Invoice
                      individualInvoicedata={this.state.JsonObject}
                      salesOrderId={this.state.salesorderid}
                    />
                  </PDFViewer>
                </Card>
              </div>
            </div>
          </Row>
        </React.Fragment>
      );
    }
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(InvoiceApp);
