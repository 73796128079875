import React,{useState,useEffect} from 'react';
import {connect} from 'react-redux';
import config from "react-global-configuration";
import {Row,Container,Col,Table,Card} from 'reactstrap';
import AddEditBatch from './AddEditBatch';
import AddEditLocation from './AddEditLocation';
import Header from 'components/Headers/SimpleHeader';
import '../../../../config';
import Popup from "reactjs-popup";
const authApi = config.get('apiDomain');

function BatchAndLocation(props){
   const [location,getlocation]=useState([]);
   const [batch,getbatch]=useState([]);
   let [showbatch,updateshowbatch]=useState(true);
  let [showlocation,updateshowlocation]=useState(false);

useEffect(()=>{
   const getBatchandLocation=async()=>{
    const responsebatch = await fetch(`${authApi}batch/`, {
        method: 'GET',
        headers: {
          'content-type': "application/json", 
          'Authorization': `token ${props.token}`,
        }
      })
      let batch = await responsebatch.json();
      getbatch(batch);
      const responseLocation = await fetch(`${authApi}location/`, {
        method: 'GET',
        headers: {
          'content-type': "application/json", 
          'Authorization': `token ${props.token}`,
        }
      })
      let location = await responseLocation.json();
      getlocation(location);
   }
   getBatchandLocation();
},
 // eslint-disable-next-line
[]
)

const callBackfunction=async()=>{
  const responsebatch = await fetch(`${authApi}batch/`, {
    method: 'GET',
    headers: {
      'content-type': "application/json", 
      'Authorization': `token ${props.token}`,
    }
  })
  let batch = await responsebatch.json();
  getbatch(batch);
  const responseLocation = await fetch(`${authApi}location/`, {
    method: 'GET',
    headers: {
      'content-type': "application/json", 
      'Authorization': `token ${props.token}`,
    }
  })
  let location = await responseLocation.json();
  getlocation(location);
}




const showBatchdetails=()=>{
    updateshowbatch(true);
    updateshowlocation(false);
}

const showlocationdetails=()=>{
    updateshowlocation(true);
    updateshowbatch(false);
}


return(
   <React.Fragment>
    <Header />
        <Container className="mt--7" fluid ><br /><br />
          <Row style={{ marginTop: -5 }}>
            <Col md="2">
              <button type="button" style={{color:'black',border:'1px solid #ddded7',borderBottom:'0',width:"150px",backgroundColor:showbatch===true ? "#e6f7e9":"white"}} 
                class="btn btn-outline-secondary btn-lg" onClick={showBatchdetails}>
                Batch
              </button>
            </Col>
            <Col md="1">
              <button type="button" style={{color:'black',border:'1px solid #ddded7',borderBottom:'0',marginLeft:-50,width:"150px",backgroundColor:showlocation===true ? "#e6f7e9":"white"}} 
                class="btn btn-outline-secondary btn-lg" onClick={showlocationdetails}>
                Location
              </button>
            </Col>
            <Col md="8"></Col>
            <Col md="1">
            {showbatch === true ?<Popup 
                  modal
                  trigger={
                          <button type="button" className="btn btn-primary btn-sm"
                            style={{ width: 45, height: 40 , float: 'right', marginTop: 9}} >
                          <i className="fas fa-plus-circle fa-1x"></i>
                          </button> 
                          }
                          >
                           {
                             (close) => (
                               <AddEditBatch
                                  closebtn={close}
                                   batchdetails={""}
                                   referesh={callBackfunction}
                               />
                             )
                           }
            </Popup>:  
            <Popup 
                  modal
                  trigger={
                          <button type="button" className="btn btn-primary btn-sm"
                          style={{ width: 45, height: 40 , float: 'right', marginTop: 9}}>
                            <i className="fas fa-plus-circle fa-1x"></i>
                          </button> 
                          }
                          >
                           {
                             (close) => (
                               <AddEditLocation
                                  closebtn={close}
                                  locationdetails={""}
                                  referesh={callBackfunction}
                               />
                             )
                           }
            </Popup> } 
           </Col>
        </Row>
        {showbatch === true ?<Row style={{ marginTop: 8 }}>
            <div className=" col">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive-sm>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">ACTION</th>
                      <th className="text-center">BATCH NUMBER</th>
                      <th className="text-center">DESCRIPTIONS</th>
                    </tr>
                  </thead>
                 <tbody>{batch.map((batches)=>(
                      <tr>
                      <td className="text-center">  
                      <Popup 
                      modal
                     trigger={
                    <button className = "btn btn-primary btn-sm" style={{marginRight: '5px'}} >
                          <i className="fa fa-edit" />
                    </button>
                          }
                          >
                           {
                             (close) => (
                               <AddEditBatch
                                  closebtn={close}
                                  batchdetails={batches}
                                  referesh={callBackfunction}
                               />
                             )
                           }
                 </Popup>
                </td>
                      <td className="text-center">{ batches.batchno}</td>
                      <td className="text-center">{ batches.description }</td>
                    </tr> 
                 ))}
                 </tbody>
                </Table>
              </Card>
            </div>
          </Row>:
          <Row style={{ marginTop: 8 }}>
            <div className=" col">
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive-sm>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">ACTION</th>
                      <th className="text-center">LOCATION</th>
                      <th className="text-center">SHORTLOCATION</th>
                      <th className='text-center'>GODOWN</th>
                      <th className="text-center">DESCRIPTIONS</th>
                    </tr>
                  </thead>
                 <tbody>{location.map((location)=>(
                      <tr>
                      <td className="text-center">
                      <Popup 
                  modal
                  trigger={
                    <button className = "btn btn-primary btn-sm" style={{marginRight: '5px'}} >
                    <i className="fa fa-edit" />
                      </button>
                          }
                          >
                           {
                             (close) => (
                               <AddEditLocation
                                  closebtn={close}
                                  locationdetails={location}
                                  referesh={callBackfunction}
                               />
                             )
                           }
               </Popup>
                      </td>
                      <td className="text-center">{ location.location }</td>
                      <td className="text-center">{ location.shortlocation }</td>
                      <td className="text-center">{ location.godown }</td>
                      <td className="text-center">{ location.description }</td>
                    </tr> 
                 ))}
                 </tbody>
                </Table>
              </Card>
            </div>
          </Row>}
        </Container>
   </React.Fragment>
);
}

const mapStateToProps = state => {
    return {
      token:state.token, 
    }
  }
  
  export default connect(mapStateToProps, null)(BatchAndLocation);