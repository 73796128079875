import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import ReportTableHeader from "./ReportTableHeader";
import WorkTask from "./WorktaskFolder/WorkTaskName";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  }
});

const ReportTable = ({ gd }) => {

  
  return( 
    <View style={styles.tableContainer} >
      <ReportTableHeader />
      <WorkTask task={gd} />
    </View>
  )
};

export default ReportTable;