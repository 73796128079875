import React, { Component } from 'react'
import config from "react-global-configuration";
import {Input,Row,Col} from "reactstrap";
import '../../../config';
import axios from 'axios';
import { connect } from "react-redux";

const authApi = config.get('apiDomain');

class MakeInvoice extends Component {
    constructor(props){
        super(props);
        this.state={
          defaultradio: "no"
        }
    }

async componentDidMount(){
  
}

CreateCompanyInvoice=()=>{
    if(this.state.defaultradio === "yes"){
      axios({
        method: "post",
        url: `${authApi}makeinvoice/`,
        headers: { 'content-type': "application/json",
        'Authorization': `token ${this.props.token}`, },
      data: {
        deliverynoteid:this.props.deliverynoteid,
        companyid:this.props.companyid
      }
     })
       .then(res => {
            this.props.closebtn();
            this.props.callbackfunction();
         })
       .catch((err) => {
             console.log(err)
       });
    }
    else
    {
        this.props.closebtn();
    }
    
}

onMoveChanged = (event) => {
    this.setState({
        defaultradio : event.target.value
    })
}

render() {
        return (
            <React.Fragment>
             <button className="x" onClick={this.props.closebtn}>
            x
            </button>
            <div className="header">
             Create Invoice
            </div>
            <br />
            <Row>
                <Col md="6">
                    <h3 className="form-control-label">
                        Do you want to create invoice ?
                    </h3>
                </Col>
                <Col md="3">
                    <div className="custom-control custom-radio mb-3">
                      <Input
                        className="custom-control-input"
                        id="customRadio1"
                        name="custom-radio-1"
                        type="radio"
                        value="yes"
                        onChange={this.onMoveChanged}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadio1"
                      >
                        Yes
                      </label>
                    </div>
                </Col>
                <Col md="3">
                    <div className="custom-control custom-radio mb-3">
                      <Input
                        className="custom-control-input"
                        defaultChecked
                        id="customRadio2"
                        name="custom-radio-1"
                        type="radio"
                        value="no"
                        onChange={this.onMoveChanged}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customRadio2"
                      >
                        No
                      </label>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col md='10'></Col>
                <Col md='2'>
                <button id="buttonsave" className="btn btn-dark" onClick={this.CreateCompanyInvoice} >
                  Save
                </button>
                </Col>
            </Row>
            </React.Fragment>
        )
    }
}

const mapStateToProps = state => {
  return {
    token:state.token, 
  }
}
export default connect(mapStateToProps, null)(MakeInvoice);