import React, { Component } from "react";
import "./Loader.css";

class PreLoader extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div className="load-content">
            <div className="loading">
                <p>loading</p>
                <span></span>
            </div>
        </div>
      </React.Fragment>
    );
  }
}

export default PreLoader;
