// Auth Routes
import Register from "./views/examples/Register.js";
import Login from "./views/examples/Login.js";
import ForgotPassword from "./views/examples/Passwordreset.js";

// User Routes
import Profile from "./views/examples/Profile.js";
import Todolist from "./views/Components/Tasklist/Tasklist";
import Teamtask from "./views/Components/Teamtask/Teamtask";
import Projects from "./views/Components/TaskProjects/taskProjects";
import CompletedProject from "./views/Components/TaskProjects/CompletedProject/compaletedproject";
import AddProjects from "./views/Components/TaskProjects/AddProjects";
import Addquotation from "./views/Components/Myday/Addquotation";
import MyDay from "./views/Components/Myday/MyDay.js";
import KanbanBoard from "./views/Components/Myday/KanbanBoard/Kanban.js";
import TeamtaskKanbanBoard from "./views/Components/Teamtask/KanbanBoard/TeamKanban";
import Addtask from "./views/Components/Myday/AddTask/Addtask.js";
import DayWorktime from "./views/Components/Myday/WorkingTime/Worktime";
import ClientDetails from "views/Components/ClientTaskPanel/Clients";
import ClientGroups from "views/Components/ClientTaskPanel/ClientGroup/Clientgroup";
import ManageUsers from "./views/Components/UserManagement/ManageUsers";
import UserAccessControl from "./views/Components/UserManagement/UserAccessControl";
import Worktime from "./views/Components/WorkingTime/Worktime";
import InvoiceDetails from "./views/Components/InvoiceDetails/Invoicemanage";
import InvoiceCorrection from "./views/Components/InvoiceDetails/InvoiceCorrection/InvoiceCorrection";
import InvoiceGenerate from "./views/Components/InvoiceDetails/InvoiceGenerate";
import StockDetails from "./views/Components/Stocklist/Stocks";
import StockGroups from "./views/Components/Stocklist/Stockgroup/Stockgroup";
import UnitGroups from "views/Components/Stocklist/Unittype/Unitgroup.js";
import Clientpanel from "./views/Components/ClientOveralldetailpanel/Clientpanel";
import CompanyDetails from "./views/Components/CompanyPanel/Company";
import Bankaccounts from "./views/Components/BankAccountPage/Bankaccount";
import AddSalesQuotation from "./views/Components/Myday/Quotation/AddSalesQuotation";
//Purchase
import Purchase from "views/Components/InventoryManagement/Purchase/Purchase.js";
import BatchAndLocation from "./views/Components/InventoryManagement/BatchAndLocation/BatchAndLocation";
import AddPurchase from "./views/Components/InventoryManagement/Purchase/AddPurchase";
import PurchaseRequest from "./views/Components/InventoryManagement/Purchase/PurchaseRequest/PurchaseRequest";
import SalesRequest from "./views/Components/Myday/SalesRequest";
import RequestPurchaseStock from "./views/Components/Myday/RequestPurchaseStock";
import Bills from "./views/Components/InventoryManagement/PurchaseEntry/Bills";
import AddBills from "./views/Components/InventoryManagement/PurchaseEntry/AddBills/AddBills";
import Inventory from "./views/Components/InventoryManagement/Inventory/Inventory.js";
import InventoryDetails from "./views/Components/InventoryManagement/Inventory/InventoryDetails";
import LowStock from "views/Components/InventoryManagement/Inventory/LowStock.js";
import ConversionTable from "./views/Components/InventoryManagement/Inventory/ConversionTable";
import InventoryMonthly from "./views/Components/InventoryManagement/Inventory/InventoryMonthly";
import Receipt from "./views/Components/InventoryManagement/Purchase/ReceptionPanel/Receipt";
//SalesDetails Start
import SalesDetails from "./views/Components/InventoryManagement/Sales/SalesDetails";
import AddSalesDetails from "./views/Components/InventoryManagement/Sales/AddSalesDetails";
import SalesRequestedData from "./views/Components/InventoryManagement/Sales/SalesRequest";
import Quotations from "./views/Components/InventoryManagement/Sales/Quotations/Quotations";
import Dispatch from "./views/Components/InventoryManagement/Sales/Dispatch/Dispatch";
import AddDispatch from "./views/Components/InventoryManagement/Sales/Dispatch/AddDispatch/AddDispatch";
import DispatchBill from "./views/Components/InventoryManagement/Sales/DispatchBill/DispatchBill";
//SalesDetails End
//Voucher Management
import LedgerTypes from "views/Components/VoucherManagement/LedgerDetails/LedgerTypes";
import Ledgergroup from "views/Components/VoucherManagement/LedgerDetails/Ledgergroup/Ledgergroups";
import PaymentsVoucher from "views/Components/VoucherManagement/PaymentVoucher/PaymentVouchers.js";
import ReceiptsVoucher from "views/Components/VoucherManagement/ReceiptVoucher/ReceiptVouchers.js";
import AddPayVouchers from "views/Components/VoucherManagement/PaymentVoucher/AddPayVouchers.js";
import AddRecVouchers from "views/Components/VoucherManagement/ReceiptVoucher/AddRecVouchers.js";
//Expense Management

import IndividualInvoice from "./views/Components/PdfRenderer/IndividualInvoice";
import IndividualDispatchPreview from "views/Components/InventoryManagement/Sales/Dispatch/DispatchView/IndividualDispatchPreview.js";
import IndividualDispatchBillPreview from "views/Components/InventoryManagement/Sales/DispatchBill/DispatchView/IndividualDispatchBillPreview.js";
import IndividualSalesorder from "views/Components/InventoryManagement/Sales/SalesOrderView/IndividualSalesorder.js";
import IndividualPurchaseorder from "views/Components/InventoryManagement/Purchase/PurchaseOrderView/IndividualPurchaseorder";
import IndividualBills from "views/Components/InventoryManagement/PurchaseEntry/BillsView/IndividualBills.js";
// Filter Routes
import Tab_Filter_Teamtask from "./views/Components/Filters/TeamtaskFilterTable";
import Tab_Filter_CompletedTeamtask from "./views/Components/Teamtask/CompletedTeamTasks";
import ReportGenerator from "./views/Components/ClientReport/ReportsFilterTable";
import TransactionDetails from "./views/Components/ClientTaskPanel/TransactionDetail/Transactions";
import TransactionOverview from "./views/Components/ClientTaskPanel/TransactionDetail/TransactionOverview";
import Cashbook from "./views/Components/ClientTaskPanel/TransactionDetail/Cashbook";
import LowStockPurchaseRequest from "views/Components/InventoryManagement/Inventory/LowStockPurchaseRequest.js";

var routes = [
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25 text-info",
    component: Login,
    layout: "/auth",
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-circle-08 text-pink",
    component: Register,
    layout: "/auth",
  },
  {
    path: "/password/reset",
    name: "Forgot Password",
    icon: "ni ni-key-25 text-info",
    component: ForgotPassword,
    layout: "/auth",
  },
  {
    path: "/clientpanel",
    name: "Dashboard",
    icon: "ni ni-tv-2 text-primary",
    component: Clientpanel,
    layout: "/admin",
    isadmincheck: "0",
  },
  {
    collapse: true,
    name: "Tasks",
    icon: "ni ni-user-run text-red",
    state: "tasksCollapse",
    isad: "0",
    showpanel: "1",
    views: [
      {
        path: "/myday",
        name: "My Day",
        miniName: "MD",
        component: MyDay,
        layout: "/admin",
        isadmincheck: "0",
      },
      {
        path: "/mytask",
        name: "My Tasks",
        miniName: "MT",
        component: Todolist,
        layout: "/admin",
        isadmincheck: "0",
      },
      {
        path: "/teamtask",
        name: "Team Tasks",
        miniName: "TT",
        component: Teamtask,
        layout: "/admin",
        isadmincheck: "0",
      },
    ],
  },
  {
    path: "/projects",
    name: "Projects",
    miniName: "Tp",
    icon: "fas fa-project-diagram text-green",
    component: Projects,
    layout: "/admin",
    isadmincheck: "0",
    showpanel: "1",
  },
  {
    path: "/invoice/invoice-management",
    name: "Invoice",
    icon: "fa fa-credit-card text-pink",
    component: InvoiceDetails,
    layout: "/admin",
    isadmincheck: "0",
    showpanel: "1",
  },
  {
    path: "/user-profile",
    name: "User Profile",
    icon: "ni ni-single-02 text-yellow",
    component: Profile,
    layout: "/admin",
    isadmincheck: "0",
    showpanel: "1",
  },
  {
    collapse: true,
    name: "Admin",
    icon: "ni ni-laptop text-info",
    state: "adminsCollapse",
    isad: "1",
    showpanel: "1",
    views: [
      {
        path: "/management/bankaccounts",
        name: "Bank Account",
        component: Bankaccounts,
        layout: "/admin",
        miniName: "BA",
        isadmincheck: "1",
      },
      {
        path: "/management/clients",
        name: "Clients",
        component: ClientDetails,
        layout: "/admin",
        miniName: "CL",
        isadmincheck: "1",
      },
      {
        path: "/management/company",
        name: "Company",
        component: CompanyDetails,
        layout: "/admin",
        miniName: "CM",
        isadmincheck: "1",
      },
      {
        path: "/management/users",
        name: "Users",
        component: ManageUsers,
        layout: "/admin",
        miniName: "US",
        isadmincheck: "1",
      },
      {
        path: "/stocklist/stocks",
        name: "Stocks",
        miniName: "ST",
        component: StockDetails,
        layout: "/admin",
        isadmincheck: "1",
      },
      {
        path: "/ledger/ledgertypes",
        name: "Ledgers",
        miniName: "LE",
        component: LedgerTypes,
        layout: "/admin",
        isadmincheck: "1",
      },
    ],
  },
  {
    collapse: true,
    name: "Reports",
    icon: "ni ni-building text-green",
    state: "reportsCollapse",
    isad: "1",
    showpanel: "1",
    views: [
      {
        path: "/management/client-report",
        name: "Client Report",
        component: ReportGenerator,
        layout: "/admin",
        miniName: "CR",
        isadmincheck: "1",
      },
      {
        path: "/management/user-report",
        name: "User Report",
        component: Worktime,
        layout: "/admin",
        miniName: "UR",
        isadmincheck: "1",
      },
      {
        path: "/filter/transaction-report",
        name: "Transaction Report",
        component: TransactionOverview,
        layout: "/admin",
        miniName: "TR",
        isadmincheck: "1",
      },
      {
        path: "/filter/cashbook-report",
        name: "Cashbook Report",
        component: Cashbook,
        layout: "/admin",
        miniName: "CA",
        isadmincheck: "1",
      },
    ],
  },
  {
    path: "/management/inventory",
    name: "Inventory",
    icon: "ni ni-briefcase-24 text-dark",
    component: Inventory,
    layout: "/admin",
    isadmincheck: "1",
    showpanel: "1",
  },
  {
    collapse: true,
    name: "Purchase",
    icon: "fas fa-cart-plus text-primary",
    state: "purchaseCollapse",
    isad: "1",
    showpanel: "1",
    views: [
      {
        path: "/management/purchase-request",
        name: "PurchaseRequest",
        component: PurchaseRequest,
        layout: "/admin",
        miniName: "PR",
        isadmincheck: "1",
      },
      {
        path: "/management/purchase-order",
        name: "PurchaseOrder",
        component: Purchase,
        layout: "/admin",
        miniName: "PO",
        isadmincheck: "1",
      },
      {
        path: "/management/receipt",
        name: "Receipt",
        component: Receipt,
        layout: "/admin",
        miniName: "RE",
        isadmincheck: "1",
      },
      {
        path: "/management/bills",
        name: "PurchaseBills",
        component: Bills,
        layout: "/admin",
        miniName: "PB",
        isadmincheck: "1",
      },
      {
        path: "/management/batchlocation",
        name: "Batch And Location",
        component: BatchAndLocation,
        layout: "/admin",
        miniName: "BL",
        isadmincheck: "1",
      },
    ],
  },
  {
    collapse: true,
    name: "Sale",
    icon: "far fa-share-square text-red",
    state: "saleCollapse",
    isad: "1",
    showpanel: "1",
    views: [
      {
        path: "/management/quotations",
        name: "Quotations",
        component: Quotations,
        layout: "/admin",
        miniName: "QT",
        isadmincheck: "1",
      },
      {
        path: "/management/sales",
        name: "SalesOrder",
        component: SalesDetails,
        layout: "/admin",
        miniName: "IN",
        isadmincheck: "1",
      },
      {
        path: "/management/dispatch",
        name: "Dispatch",
        component: Dispatch,
        layout: "/admin",
        miniName: "DS",
        isadmincheck: "1",
      },
      {
        path: "/management/dispatchbill",
        name: "DispatchBill",
        component: DispatchBill,
        layout: "/admin",
        miniName: "DB",
        isadmincheck: "1",
      },
    ],
  },
  {
    collapse: true,
    name: "Voucher",
    icon: "fas fa-file-invoice text-info",
    state: "voucherCollapse",
    isad: "1",
    showpanel: "1",
    views: [
      {
        path: "/voucher/paymentvoucher",
        name: "Payment Voucher",
        component: PaymentsVoucher,
        layout: "/admin",
        miniName: "PV",
        isadmincheck: "1",
      },
      {
        path: "/voucher/receiptvoucher",
        name: "Receipt Voucher",
        component: ReceiptsVoucher,
        layout: "/admin",
        miniName: "RV",
        isadmincheck: "1",
      },
    ],
  },
  {
    path: "/kanbanboard",
    name: "Kanban",
    component: KanbanBoard,
    layout: "/admin/kanban",
  },
  {
    path: "/teamtaskkanbanboard",
    name: "Kanban",
    component: TeamtaskKanbanBoard,
    layout: "/admin/kanban",
  },
  {
    path: "/dayworktime",
    name: "My Achievement",
    component: DayWorktime,
    layout: "/admin/management",
  },
  {
    path: "/invoice-generate",
    name: "Invoice Generator",
    component: InvoiceGenerate,
    layout: "/admin/invoice",
  },
  {
    path: "/teamfilter",
    name: "Billable Tasks",
    component: Tab_Filter_Teamtask,
    layout: "/admin/filter",
  },
  {
    path: "/Completedteamfilter",
    name: "Completed Tasks",
    component: Tab_Filter_CompletedTeamtask,
    layout: "/admin/filter",
  },
  {
    path: "/transactions",
    name: "Transactions",
    component: TransactionDetails,
    layout: "/admin/filter",
  },
  {
    path: "/addpurchase",
    name: "Add/Edit Purchase Order",
    component: AddPurchase,
    layout: "/admin/addpurchase",
  },
  {
    path: "/requestpurchasestock",
    name: "Purchase Request Stock",
    component: RequestPurchaseStock,
    layout: "/admin/requestpurchasestock",
  },
  {
    path: "/salesrequest",
    name: "Quotation",
    component: SalesRequest,
    layout: "/admin/salestock",
  },
  {
    path: "/salesrequestdata",
    name: "Sales Request Stockdetails",
    component: SalesRequestedData,
    layout: "/admin/salestock",
  },
  {
    path: "/ledgergroups",
    name: "LedgerGroups",
    component: Ledgergroup,
    layout: "/admin/ledger",
  },
  {
    path: "/stockgroups",
    name: "Stock Groups",
    component: StockGroups,
    layout: "/admin/stocklist",
  },
  {
    path: "/unittype",
    name: "Unit Type",
    component: UnitGroups,
    layout: "/admin/stocklist",
  },
  {
    path: "/clientgroups",
    name: "Client Groups",
    component: ClientGroups,
    layout: "/admin/management",
  },
  {
    path: "/addbill",
    name: "Add Bill",
    component: AddBills,
    layout: "/admin/addbill",
  },
  {
    path: "/adddispatch",
    name: "Add Dispatch",
    component: AddDispatch,
    layout: "/admin/adddispatch",
  },
  {
    path: "/inventorydetails",
    name: "Inventory Details",
    component: InventoryDetails,
    layout: "/admin/inventory",
  },
  {
    path: "/lowstock",
    name: "Low Stock",
    component: LowStock,
    layout: "/admin/inventory",
  },
  {
    path: "/lowstockpurchaserequest",
    name: "LowStock purchaserequest",
    component: LowStockPurchaseRequest,
    layout: "/admin/inventory",
  },
  {
    path: "/conversiontable",
    name: "Conversion Table",
    component: ConversionTable,
    layout: "/admin/inventory",
  },
  {
    path: "/inventorymonthly",
    name: "Inventory Monthly",
    component: InventoryMonthly,
    layout: "/admin/inventory",
  },
  {
    path: "/addsalesdetails",
    name: "Add Sales",
    component: AddSalesDetails,
    layout: "/admin/sales",
  },
  {
    path: "/print-details",
    name: "View Invoice",
    component: IndividualInvoice,
    layout: "/admin/management",
  },
  {
    path: "/view-purchaseorder",
    name: "View Purchaseorder",
    component: IndividualPurchaseorder,
    layout: "/admin/management",
  },
  {
    path: "/view-bills",
    name: "View Bills",
    component: IndividualBills,
    layout: "/admin/management",
  },
  {
    path: "/view-dispatch",
    name: "View Dispatch",
    component: IndividualDispatchPreview,
    layout: "/admin/management",
  },
  {
    path: "/view-dispatchbill",
    name: "View DispatchBill",
    component: IndividualDispatchBillPreview,
    layout: "/admin/management",
  },
  {
    path: "/view-salesorder",
    name: "View Salesorder",
    component: IndividualSalesorder,
    layout: "/admin/management",
  },
  {
    path: "/add-paymentvoucher",
    name: "Add PaymentVoucher",
    component: AddPayVouchers,
    layout: "/admin/voucher",
  },
  {
    path: "/add-receiptvoucher",
    name: "Add ReceiptVoucher",
    component: AddRecVouchers,
    layout: "/admin/voucher",
  },
  {
    path: "/quotation",
    name: "Add quataion",
    component: Addquotation,
    layout: "/admin/sales",
  },
  {
    path: "/salesquotation",
    name: "Add Sales Quataion",
    component: AddSalesQuotation,
    layout: "/admin/sales",
  },
  {
    path: "/accesscontrol",
    name: "Users",
    component: UserAccessControl,
    layout: "/admin/management",
  },
  {
    path: "/addprojects",
    name: "AddProjects",
    component: AddProjects,
    layout: "/admin/management",
  },
  {
    path: "/completedproject",
    name: "CompletedProject",
    component: CompletedProject,
    layout: "/admin/management",
  },
  {
    path: "/addtask",
    name: "AddTask",
    component: Addtask,
    layout: "/admin/management",
  },
  {
    path: "/invoicecorrection",
    name: "InvoiceCorrection",
    component: InvoiceCorrection,
    layout: "/admin/management",
  },
];
export default routes;
