import React from "react";
import { connect } from "react-redux";
import { Row, Col } from "reactstrap";
import axios from "axios";
import config from "react-global-configuration";
import "../../../config";
import getDateTimeField from "../../../Utils";
const authApi = config.get("apiDomain");

class Deleteuser extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteuser: this.props.deleteuser,
    };
  }

  componentDidMount() {
    console.log(this.state.deleteuser.name);
  }

  delete_user = () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "put",
      url: `${authApi}users/${this.state.deleteuser.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        name: this.state.deleteuser.name,
        mailid: this.state.deleteuser.mailid,
        isadmin: this.state.deleteuser.isadmin,
        isapproved: this.state.deleteuser.isapproved,
        default_rate: this.state.deleteuser.default_rate,
        superuser: this.state.deleteuser.superuser,
        userprofile: this.state.deleteuser.userprofile,
        isdeleted: true,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    }).then((res) => {
      if (res.status === 200) {
        this.props.closebtn();
        this.props.reloadpage();
      }
    });
  };

  cancel_delete = () => {
    this.props.closebtn();
  };

  render() {
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Delete User</div>
        <Row style={{ paddingTop: 10 }}>
          <Col md="4"></Col>
          <Col md="7">
            <h3>Do you want to delete this user ?</h3>
          </Col>
          <Col md="1"></Col>
        </Row>
        <Row style={{ paddingBottom: 10 }}>
          <Col md="5"></Col>
          <Col md="1">
            <button
              type="button"
              class="btn btn-outline-success"
              onClick={this.delete_user}
            >
              Yes
            </button>
          </Col>
          <Col md="1" style={{ paddingLeft: 50 }}>
            <button
              type="button"
              class="btn btn-outline-danger"
              onClick={this.cancel_delete}
            >
              No
            </button>
          </Col>
          <Col md="5"></Col>
        </Row>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(Deleteuser);
