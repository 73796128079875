import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "left",
    fontStyle: "bold",
    flexGrow: 1,
  },
  column: {
    width: "25%",
  }
});

const SummarizedHeader = () => (
  <View style={styles.container}>
    <Text style={styles.column}> Client </Text>
    <Text style={styles.column}> Total Invoiced </Text>
    <Text style={styles.column}> Total Received </Text>
    <Text style={styles.column}> Total Balanced </Text>
  </View>
);

export default SummarizedHeader;
