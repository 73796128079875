import React from "react";
import { View, Text, StyleSheet } from "@react-pdf/renderer";
import InvoiceDrafting from "./InvoiceDraftingCharges";
import InvoicePrinting from "./InvoicePrintingCharges";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 50,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
  rows: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    fontStyle: "bold"
  },
  tasks: {
    width: "29.6%",
    textAlign: "left",
    paddingRight: 8,
    paddingLeft: 2,
    paddingTop: 4,
    color: "#000000",
    borderRightColor: "#bff0fd",
    borderRightWidth: 1,
  },
  details: {
    width: "70%",
    textAlign: "left",
    paddingRight: 8,
    color: "#808080",
    borderLeftColor: "#bff0fd",
    borderLeftWidth: 1,
  }
});

const InvoiceTask = ({ task }) => 
{
   const InvoicetaskName = task.map((t) => (
    <React.Fragment>
      <View style={styles.rows} key={t.Name}>
        <Text style={styles.tasks}>{t.Name}</Text>
        <View style={styles.details}>
          <View>
            <InvoiceDrafting draftChar = {t.DraftingCharge} />
          </View>
          <View>
            <InvoicePrinting printChar = {t.PrintingCharge} />
          </View>  
        </View>
      </View>
    </React.Fragment>
   ));

  return( 
    <React.Fragment>
      {InvoicetaskName}
    </React.Fragment>
  )
};

export default InvoiceTask;
