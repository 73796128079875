import React from "react";
import { Input, Row, Col } from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import config from "react-global-configuration";
import consoleFunction from "../../../../Helpers";
import "../../../../config";
import getDateTimeField from "../../../../Utils";

const authApi = config.get("apiDomain");

class AddStockgrp extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      StockgroupName: "",
      stockgroupnameborder: "1px solid #dee2e6",
      isButtonDisabled: false,
    };
  }

  Formvalidation = () => {
    if (this.state.StockgroupName !== "") {
      this.setState({
        stockgroupnameborder: "1px solid #dee2e6",
        isButtonDisabled: true,
      });
      this.Addstockgrp();
    } else {
      this.setState({
        stockgroupnameborder: "1px solid #fb6340",
      });
    }
  };

  Addstockgrp = () => {
    if (this.state.id === "") {
      let datetime_field = getDateTimeField();
      axios({
        method: "post",
        url: `${authApi}stockgroup/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          name: this.state.StockgroupName,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          if (res.status) {
            this.props.closebtn();
            this.props.callbackfunction();
          }
        })
        .catch((err) => {
          consoleFunction(
            " Stockgroup name creation error",
            err.response.request.response
          );
          this.setState({ error: err.message });
        });
    }
  };

  handleStockgroupName = (event) => {
    this.setState({
      StockgroupName: event.target.value.replace(/[^\w\s]/gi, ""),
    });
  };

  render() {
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Add Stockgroup name</div>
        <div className="Scroll-Content">
          <Row>
            <Col md="6">
              <label className="form-control-label">Name</label>
              <Input
                type="text"
                className="form-control-alternative"
                defaultValue={this.state.StockgroupName}
                style={{ border: this.state.stockgroupnameborder }}
                onChange={this.handleStockgroupName}
                placeholder="enter stockgroup name"
              />
              {this.state.stockgroupnameborder !== "1px solid #dee2e6" ? (
                <h5 style={{ color: "#fb6340" }}>Please enter groupname</h5>
              ) : null}
            </Col>
            <Col md="6">
              <div className="button">
                <button
                  id="buttonsave"
                  className="btn btn-dark"
                  style={{ float: "right", marginRight: 15 }}
                  onClick={this.Formvalidation}
                  disabled={this.state.isButtonDisabled}
                >
                  Add
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(AddStockgrp);
