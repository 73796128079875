import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import Report from "./Report";
import {Row, Card} from "reactstrap";
import moment from "moment";

class ReportAppDF extends Component {
  constructor(props) {
    super(props);
    this.state = {
      ManageData : this.props.generateData,
      TotalTime : this.props.totalTime,
      jsonObject: "",
      startDate : "",
      endDate : "",
      loading : true,
    };
  }

  componentDidMount(){
    const a = moment(this.props.dateStart).format("YYYY-MM-DD");
    const b = moment(this.props.dateEnd).format("YYYY-MM-DD");
    this.setState({
      startDate: a,
      endDate: b,
      loading: false
    })
  }

  render() {
    if(this.state.loading)
    {
      return null;
    }
    else
    {
      return (
        <React.Fragment>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">
            Report
          </div>
          <Row>
            <div className=" col">
              <div className="Scrolls-Contents">
                <Card className="shadow">
                  <PDFViewer width="100%" height="600" className="app">
                    <Report 
                      GenerateData = {this.state.ManageData}
                      TimeCal = {this.state.TotalTime}
                      sD = { this.state.startDate }
                      eD = { this.state.endDate }
                    />
                  </PDFViewer>
                </Card>
              </div>
            </div>
          </Row>
        </React.Fragment>
      );
    }
  }
  }

export default ReportAppDF;