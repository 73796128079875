import React, { Component } from 'react';
class ClientpannelActivetaskrow extends Component {
    render() {
        return (
                <tbody>
                  <tr>
                      <td>{this.props.task.task}</td>
                      <td>{this.props.task.assignto}</td>
                      <td>{this.props.task.status}</td>
                  </tr>
                </tbody>
        );
    }
}

export default ClientpannelActivetaskrow;