import React from "react";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../../config";
import { Card, Row, Col, Input, Table, DropdownItem, CardHeader } from "reactstrap";
import ReactTooltip from "react-tooltip";
import EditPurchaseStocks from "./EditPurchaseStocks";
import RequestedStocks from "./RequestedStocks";
import CancelEdit from "../CancelEdit";
import Popup from "reactjs-popup";
import moment from "moment";
import axios from "axios";

let StoreprocData = [];
const authApi = config.get("apiDomain");
let Stockid = 1, TotalamountStockid = "";

class EditPurchaseDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Clientdetails:this.props.clientdetails,
      SupplierName: this.props.Editdata.supplier,
      Supplierid: "",
      Reference: this.props.Editdata.reference,
      Date: moment(this.props.Editdata.date).format("YYYY-MM-DD"),
      Expecteddeliverydate: moment(new Date())
        .add(7, "days")
        .format("YYYY-MM-DD"),
      CompanyDetails: [],
      Company: this.props.Editdata.companyid,
      Companyid: "",
      gstcheck: true,
      Id: this.props.Editdata.id,
      Status: this.props.Editdata.status,
      Stockdetails: this.props.Editdata.purchasestock.filter(
        (item) => item.isdeleted === false
      ),
      Stockname: [],
      TotalTaxableValue: 0,
      TotalCGSTAmount: 0,
      TotalSGSTAmount: 0,
      TotalAmount: 0,
      TotalQuantity: 0,
      Adjustamount: this.props.Editdata.roundoff,
      GrandTotalAmount: this.props.Editdata.total,
      ValidCompany: "none",
      ValidSupplierName: "none",
      ValidAdjustamount: "none",
      nullvalueisthere: false,
      Adjustfocus: false,
      ShowparchaseRequest: false,
      Gsttype: "SGST",
      updateSalesQty: false,
    };
  }

  async componentDidMount() {

    if (this.props.Editdata.purchasestock.length === 0) {
      this.props.callBack();
    }
    let stocknamedetails = await this.getTotalStockdetails();
    this.setState({
      Stockname: stocknamedetails,
    });
    let max = 0;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.id > max) {
        max = stock.id;
      }
    });
    Stockid = max + 1;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.name === "Total") {
        TotalamountStockid = stock.id;
        stock.id = 9999;
      }
    });
    this.setState({
      Stockdetails: this.state.Stockdetails.sort((a, b) =>
        a.id > b.id ? 1 : -1
      ),
    });
    await this.getClientDetails();
    await this.getCompanydetails();
    await this.updateTotalGstQuantity();
    await this.calculateTotalAmount();
  }

  updateTotalGstQuantity = () => {
    this.props.Editdata.purchasestock.forEach((stock) => {
      if (stock.name === "Total") {
        this.setState({
          TotalTaxableValue: stock.amount,
          TotalCGSTAmount: stock.cgstdiscount,
          TotalSGSTAmount: stock.sgstdiscount,
          TotalAmount: stock.total,
          TotalQuantity: stock.quantity,
          Adjustamount: this.props.Editdata.roundoff,
        });
      }
    });
  };

  getTotalStockdetails = async () => {
    let response = await fetch(`${authApi}stocks/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
    return response;
  };

  getCompanydetails = () => {
    fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        json.forEach((company) => {
          if (company.companyname === this.state.Company) {
            this.setState({
              Companyid: company.id,
              gstcheck: company.isgst === true ? true : false,
            });
          }
        });
        this.setState({
          CompanyDetails: json,
        });
      });
  };

  getClientDetails = async () => {
    this.props.clientdetails.forEach((client) => {
      if (client.company_name === this.state.SupplierName) {
        this.setState({
          Supplierid: client.id,
        });
      }
    })
  };

  selectClient = (event) => {
    if (event.target.value === "Select Supplier") {
      this.setState({
        SupplierName: "",
      });
    } else {
      this.setState(
        {
          SupplierName: event.target.value,
        },
        function () {
          this.getClientid(event.target.value);
        }
      );
    }
  };

  getClientid = (clientname) => {
    this.state.Clientdetails.forEach((client) => {
      if (client.company_name === clientname) {
        this.setState({
          Supplierid: client.id,
        });
      }
    });
  };

  getReference = (event) => {
    this.setState({
      Reference: event.target.value,
    });
  };

  changeDeliverydate = (event) => {
    this.setState({
      Expecteddeliverydate: event.target.value,
    });
  };

  changeDate = (event) => {
    console.log(event.target.value);
    this.setState({
      Date: event.target.value,
    });
  };

  selectCompany = (event) => {
    if (event.target.value === "Select Company") {
      this.setState({
        Company: "",
      });
    } else {
      this.state.CompanyDetails.forEach((company) => {
        if (company.companyname === event.target.value) {
          if (this.state.gstcheck === company.isgst) {
            this.setState({
              Company: event.target.value,
              Companyid: company.id,
              gstcheck: company.isgst,
            });
          }
          else {
            alert("PurchaseOrder can not be changed from GST company to Non GST company")
          }
        }
      });
    }
  };

  changeStatus = (event) => {
    this.setState({
      Status: event.target.value,
    });
  };

  addAnotherStock = () => {
    Stockid = Stockid + 1;
    let Totalstock = this.state.Stockdetails;
    let stock = {
      id: Stockid,
      name: "",
      productcode: "",
      quantity: 0,
      rate: "",
      amount: "",
      cgstdiscount: 0,
      sgstdiscount: 0,
      cgstdiscountpercentage: "0%",
      sgstdiscountpercentage: "0%",
      total: 0,
    };
    Totalstock = [...Totalstock, stock];
    this.setState({
      Stockdetails: [],
    });
    Totalstock = Totalstock.sort((a, b) => (a.id > b.id ? 1 : -1));
    this.setState({
      Stockdetails: Totalstock,
    });
  };

  removeStockDetails = async (id) => {
    this.state.Stockdetails.forEach((stock) => {
      if (stock.id === id) {
        axios({
          method: "post",
          url: `${authApi}sp_deletepurchasestock/`,
          headers: {
            'content-type': "application/json",
            'Authorization': `token ${this.props.token}`,
          },
          data: {
            purchasestock_id: stock.id,
            purchaseorder_id: stock.purchasesorderid
          }

        })
          // fetch(
          //   `${authApi}deletepurchasestock/?purchasestockid=${stock.id}&purchaseorderid=${stock.purchasesorderid}`
          // )
          .then((res) => res.data)
          .then((json) => {
            console.log(json);
          });
      }
    });
    let remainingdata = this.state.Stockdetails.filter(
      (item) => item.id !== id
    );
    this.setState(
      {
        Stockdetails: remainingdata,
      },
      function () {
        this.calculateTotalAmount();
      }
    );
  };

  updateStockDetails = (
    id,
    stockname,
    productcode,
    quantity,
    rate,
    amount,
    cgstdiscount,
    sgstdiscount,
    cgstdiscountpercentage,
    sgstdiscountpercentage,
    totalamount
  ) => {
    this.state.Stockdetails.forEach((stock) => {
      if (stock.id === id) {
        stock.name = stockname;
        stock.productcode = productcode;
        stock.quantity = quantity;
        stock.rate = rate;
        stock.amount = amount;
        stock.cgstdiscount = cgstdiscount;
        stock.sgstdiscount = sgstdiscount;
        stock.cgstdiscountpercentage = this.state.gstcheck === false ? "0%" : cgstdiscountpercentage;
        stock.sgstdiscountpercentage = this.state.gstcheck === false ? "0%" : sgstdiscountpercentage;
        stock.total = totalamount;
      }
    });
    this.calculateTotalAmount();
  };

  calculateTotalAmount = () => {
    let taxableamount = 0.0,
      totalcgstdiscount = 0.0,
      totalsgstdiscount = 0.0,
      granttotalamount = 0.0,
      totalquantity = 0.0;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.id !== 9999) {
        let CheckAmount = parseFloat(stock.amount) || 0;
        let CheckCgstdiscount = parseFloat(stock.cgstdiscount) || 0;
        let CheckSgstdiscount = parseFloat(stock.sgstdiscount) || 0;
        let CheckGrandtotal = parseFloat(stock.total) || 0;
        let CheckQuantity = parseFloat(stock.quantity) || 0;
        taxableamount = parseFloat(taxableamount) + CheckAmount;
        totalcgstdiscount = parseFloat(totalcgstdiscount) + CheckCgstdiscount;
        totalsgstdiscount = parseFloat(totalsgstdiscount) + CheckSgstdiscount;
        granttotalamount = parseFloat(granttotalamount) + CheckGrandtotal;
        totalquantity = parseFloat(totalquantity) + CheckQuantity;
      }
    });

    this.state.Stockdetails.forEach((stock) => {
      let adjustmentamount = parseFloat(this.state.Adjustamount) || 0;
      let grandtotal = granttotalamount - adjustmentamount;
      if (stock.id === 9999) {
        stock.amount = taxableamount.toFixed(2);
        stock.cgstdiscount = totalcgstdiscount.toFixed(2);
        stock.sgstdiscount = totalsgstdiscount.toFixed(2);
        stock.total = granttotalamount.toFixed(2);
        stock.quantity = totalquantity.toFixed(2);
        this.setState({
          TotalTaxableValue: taxableamount.toFixed(2),
          TotalCGSTAmount: totalcgstdiscount.toFixed(2),
          TotalSGSTAmount: totalsgstdiscount.toFixed(2),
          TotalAmount: granttotalamount.toFixed(2),
          GrandTotalAmount: grandtotal.toFixed(2),
          TotalQuantity: totalquantity.toFixed(2),
        });
      }
    });
  };

  adjustAmount = (event) => {
    let total = parseFloat(this.state.TotalAmount) || 0;
    let totalamount = total - parseFloat(event.target.value);
    let totalwithtofixed = totalamount.toFixed(2);
    this.setState({
      GrandTotalAmount: totalwithtofixed,
      Adjustamount: event.target.value,
    });
  };

  isvalidEntryInForm = async () => {
    let isvalid = false,
      count = 0, ratecount = 0;
    this.state.Stockdetails.forEach((stock) => {
      if (stock.name !== "Total") {
        if (parseInt(stock.quantity) === 0) {
          count = count + 1;
        }
        if (parseInt(stock.rate) === 0) {
          ratecount = ratecount + 1;
        }
      }
    });
    ratecount = this.state.SupplierName === 'Manuel Stock Entry' ? 0 : ratecount;
    if (count < 1 && ratecount < 1) {
      this.setState({
        updateSalesQty: false,
      })
      if (this.state.Company !== "") {
        if (this.state.SupplierName !== "") {
          if (this.state.Adjustamount !== "" && this.state.Adjustamount !== ".") {
            let remainingdata = this.state.Stockdetails.filter(
              (item) => item.name !== ""
            );
            if (this.state.nullvalueisthere === false) {
              this.setState(
                {
                  ValidCompany: "none",
                  ValidSupplierName: "none",
                  Stockdetails: remainingdata,
                  ValidAdjustamount: "none",
                },
                function () {
                  this.stockDataPreparationForStock();
                }
              );
              isvalid = true;
            } else {
              alert("Please specify value for the required fields");
            }
          } else {
            this.setState({
              ValidCompany: "none",
              ValidSupplierName: "none",
              ValidAdjustamount: "1px solid red",
            });
          }
        } else {
          this.setState({
            ValidCompany: "none",
            ValidSupplierName: "1px solid red",
          });
        }
      } else {
        this.setState({
          ValidCompany: "1px solid red",
          ValidSupplierName: "none",
        });
      }
    }
    else {
      this.setState({
        updateSalesQty: true,
      })
    }
    return isvalid;
  };

  onSubmitForm = async (createStatus) => {
    let payment = "Cash";
    let isdeleted = 0;
    let status = createStatus === "Submit" ? "Issued" : "Draft";
    if (await this.isvalidEntryInForm()) {
      axios({
        method: "post",
        url: `${authApi}sp_updatepurchaseorder/`,
        headers: {
          'content-type': "application/json",
          'Authorization': `token ${this.props.token}`,
        },
        data: {
          date: this.state.Date,
          companyid: this.state.Companyid,
          total: this.state.GrandTotalAmount,
          supplier: this.state.Supplierid,
          payment: payment,
          status: status,
          isdeleted: isdeleted,
          reference: this.state.Reference,
          gsttype: this.state.Gsttype,
          roundoff: this.state.Adjustamount,
          purchaseid: this.props.Editdata.id,
          stockdetails: StoreprocData
        }
      })
        
        .then((res) => {
          console.log(res);
          StoreprocData=[];
          if (res.status === 201) {
            this.props.callBack();
          }
        });
    }
  };

  stockDataPreparationForStock = () => {
    this.state.Stockdetails.forEach((stock) => {
      if (stock.name === "Total") {
        stock.id = TotalamountStockid;
      }
    });

    this.state.Stockdetails.forEach((stockdetails) => {
      let count = 0,
        cgstcount = 0;
      count = (stockdetails.sgstdiscountpercentage.match(/%/g) || []).length;
      cgstcount = (stockdetails.cgstdiscountpercentage.match(/%/g) || [])
        .length;
      if (count > 0) {
        stockdetails.sgstdiscountpercentage =
          stockdetails.sgstdiscountpercentage.substring(
            0,
            stockdetails.sgstdiscountpercentage.length - 1
          );
      }
      if (cgstcount > 0) {
        stockdetails.cgstdiscountpercentage =
          stockdetails.cgstdiscountpercentage.substring(
            0,
            stockdetails.cgstdiscountpercentage.length - 1
          );
      }
    });

    this.state.Stockdetails.forEach(stockdetails => {
      let newstockdetails = {
        name: stockdetails.name,
        productcode: stockdetails.productcode,
        quantity: stockdetails.quantity,
        rate: stockdetails.rate,
        amount: stockdetails.amount,
        cgstdiscount: stockdetails.cgstdiscount,
        sgstdiscount: stockdetails.sgstdiscount,
        cgstdiscountpercentage: stockdetails.cgstdiscountpercentage,
        sgstdiscountpercentage: stockdetails.sgstdiscountpercentage,
        total: stockdetails.total,
        stockid: stockdetails.id
      }
      StoreprocData = [...StoreprocData, newstockdetails]

     
    });
  };

  BacktoPurchase = () => {
    this.props.callBack();
  };

  CancelEdit = () => {
    this.props.callBack();
  };

  checkanynullvalue = (check) => {
    this.setState({
      nullvalueisthere: check,
    });
  };

  showParchaseRequestdetails = async () => {
    let payment = "Cash";
    let isdeleted = 0;
    let status = "Draft";
    if (await this.isvalidEntryInForm()) {
      axios({
        method: "post",
        url: `${authApi}sp_updatepurchaseorder/`,
        headers: {
          'content-type': "application/json",
          'Authorization': `token ${this.props.token}`,
        },
        data: {
          date: this.state.Date,
          companyid: this.state.Companyid,
          total: this.state.GrandTotalAmount,
          supplier: this.state.Supplierid,
          payment: payment,
          status: status,
          isdeleted: isdeleted,
          reference: this.state.Reference,
          gsttype: this.state.Gsttype,
          roundoff: this.state.Adjustamount,
          purchaseid: this.props.Editdata.id,
          stockdetails: StoreprocData
        }
      })
        
        .then((res) => {
          console.log(res);
          if (res.status === 201) {
            this.setState({
              ShowparchaseRequest: true,
            });
          }
        });
    }
  };

  callBackFunction = async () => {
    this.setState({
      ShowparchaseRequest: false,
    });
    await this.updateAddedPurchaseData();
  };

  updateAddedPurchaseData = async () => {
    this.setState({
      Stockdetails: [],
    });
    fetch(`${authApi}purchaseorder/${this.props.Editdata.id}/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        let max = 0;
        json.purchasestock.forEach((stock) => {
          if (stock.id > max) {
            max = stock.id;
          }
        });
        Stockid = max + 1;
        this.setState(
          {
            Stockdetails: json.purchasestock.filter(
              (item) => item.isdeleted === false
            ),
          },
          function () {
            this.state.Stockdetails.forEach((stock) => {
              if (stock.name === "Total") {
                TotalamountStockid = stock.id;
                stock.id = 9999;
              }
            });
            this.setState({
              Stockdetails: this.state.Stockdetails.sort((a, b) =>
                a.id > b.id ? 1 : -1
              ),
            });
          }
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  changeSGst = (event) => {
    this.setState({
      Gsttype: event.target.value,
    });
  };

  render() {
    const stockdata = this.state.Stockdetails.map((items) => (
      <EditPurchaseStocks
        key={items.id}
        stock={items}
        gstCheck={this.state.gstcheck}
        taxableamount={this.state.TotalTaxableValue}
        totalcgstdiscount={this.state.TotalCGSTAmount}
        totalsgstdiscount={this.state.TotalSGSTAmount}
        granttotalamount={this.state.TotalAmount}
        totalquantity={this.state.TotalQuantity}
        removestockdetails={this.removeStockDetails}
        stockname={this.state.Stockname}
        updatestock={this.updateStockDetails}
        addstock={this.addAnotherStock}
        checkifanynull={this.checkanynullvalue}
        nullvalueisthere={this.state.nullvalueisthere}
        totalstockdetails={this.state.Stockdetails}
      />
    ));
    let totalclientname = this.state.Clientdetails.map((q) => q.company_name);
    let clientname = totalclientname.filter(
      (q, idx) => totalclientname.indexOf(q) === idx
    );
    let totalcompany = this.state.CompanyDetails.map((q) => q.companyname);
    let companyorder = totalcompany.filter(
      (q, idx) => totalcompany.indexOf(q) === idx
    );
    return (
      <React.Fragment>
        {this.state.ShowparchaseRequest === false ? (
          <Row>
            <Col md="10">
              <CardHeader style={{ backgroundColor: 'transparent', padding: 10, marginTop: 20 }}>
                <h3 className="mb-0"><i style={{ marginRight: 5 }} class="fas fa-cart-plus"></i> Edit Purchase Order </h3>
              </CardHeader>
            </Col>
            <Col md="0.5" style={{ marginTop: 20, float: 'right' }}>
              <ReactTooltip
                id="teamTask-Tip"
                place="left"
                type="dark"
                effect="solid"
              >
                Back to Purchase
              </ReactTooltip>
              <button
                className="btn btn-primary btn-sm" style={{ width: 45, height: 40, right: -65 }}
                data-tip
                data-for="teamTask-Tip"
                onClick={this.BacktoPurchase}
              >
                <i className="fa fa-arrow-left"></i>
              </button>
            </Col>
            &nbsp;
            <Col md="0.5" style={{ marginTop: 20, float: 'right' }}>
              <ReactTooltip
                id="request-Tip"
                place="right"
                type="dark"
                effect="solid"
              >
                Purchase Request
              </ReactTooltip>
              <button
                className="btn btn-primary btn-sm" style={{ width: 45, height: 40, right: -75 }}
                data-tip
                data-for="request-Tip"
                onClick={this.showParchaseRequestdetails}
              >
                <i className="fa fa-plus-circle"></i>
              </button>
            </Col>
          </Row>
        ) : null}
        {this.state.ShowparchaseRequest === false ? <br /> : null}
        {this.state.ShowparchaseRequest === false ? (
          <Row>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Company
              </label>
            </Col>
            <Col md="3">
              <Input
                type="select"
                value={this.state.Company}
                style={{ border: this.state.ValidCompany }}
                onChange={this.selectCompany}
              >
                <option value="Select Company">Select Company</option>
                {companyorder.map((item, key) => (
                  <option key={key} value={item}>
                    
                    {item}
                  </option>
                ))}
              </Input>
              {this.state.ValidCompany !== "none" ? (
                <h6 style={{ color: "red" }}>Please Select Company</h6>
              ) : null}
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                SupplierName
              </label>
            </Col>
            <Col md="3">
              <Input
                type="select"
                value={this.state.SupplierName}
                style={{ border: this.state.ValidSupplierName }}
                onChange={this.selectClient}
              >
                <option value="Select Supplier">Select Supplier</option>
                {clientname
                  .sort((a, b) => a.localeCompare(b))
                  .map((item, key) => (
                    <option key={key} value={item}>
                   
                      {item}
                    </option>
                  ))}
              </Input>
              {this.state.ValidSupplierName !== "none" ? (
                <h6 style={{ color: "red" }}>Please Select SupplierName</h6>
              ) : null}
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Date
              </label>
            </Col>
            <Col md="2">
              <Input
                className="form-control-alternative"
                type="date"
                Value={this.state.Date}
                onChange={this.changeDate}
              />
            </Col>
          </Row>
        ) : null}
        {this.state.ShowparchaseRequest === false ? <br /> : null}
        {this.state.ShowparchaseRequest === false ? (
          <Row>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Reference
              </label>
            </Col>
            <Col md="3">
              <Input
                type="text"
                value={this.state.Reference}
                onChange={this.getReference}
              />
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                SGST Type
              </label>
            </Col>
            <Col md="3">
              <Input
                type="select"
                value={this.state.Gsttype}
                onChange={this.changeSGst}
              >
                <option>SGST</option>
                <option>IGST</option>
              </Input>
            </Col>
            <Col md="2">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Delivery Date
              </label>
            </Col>
            <Col md="2" style={{ marginLeft: -110 }}>
              <Input
                className="form-control-alternative"
                type="date"
                defaultValue={this.state.Expecteddeliverydate}
                onChange={this.changeDeliverydate}
              />
            </Col>
          </Row>
        ) : null}
        {this.state.ShowparchaseRequest === false || this.state.Company === "Select Company" ? (
          <Row>
            <div className=" col">
              <br />
              <Card className="shadow">
                <Table className="align-items-center table-flush" responsive-sm>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">ACTION</th>
                      <th className="text-center">GOODS DESCRIPTION</th>
                      <th className="text-center">PRO.CODE</th>
                      <th className="text-center">AVI.QTY</th>
                      <th className="text-center">QUANTITY</th>
                      <th className="text-center">RATE</th>
                      {this.state.gstcheck === true ? <th className="text-center">TAXABLE AMT</th> : null}
                      {this.state.gstcheck === true ? <th className="text-center">CGST</th> : null}
                      {this.state.gstcheck === true ? <th className="text-center">{this.state.Gsttype}</th> : null}
                      <th className="text-center">TOTAL</th>
                    </tr>
                  </thead>
                  <tbody>{stockdata}</tbody>
                </Table>
              </Card>
            </div>
          </Row>
        ) : null}
        {this.state.ShowparchaseRequest === false ? (
          <Row>
            <Col md="8"></Col>
            <Col md="2">
              <ul
                class="list-group list-group-flush"
                style={{ paddingLeft: 90, width: 290 }}
              >
                {this.state.gstcheck === true ? <li class="list-group-item">TaxableValue</li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">TotalCGSTAmount</li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">TotalSGSTAmount</li> : null}
                <li class="list-group-item">Amount</li>
                <li class="list-group-item" style={{ height: 62 }}>
                  Adjustment
                </li>
                <li class="list-group-item">GrandTotalAmount</li>
              </ul>
            </Col>
            <Col md="2">
              <ul
                class="list-group list-group-flush align-center"
                style={{ paddingLeft: 60 }}
              >
                {this.state.gstcheck === true ? <li class="list-group-item">{this.state.TotalTaxableValue}</li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">{this.state.TotalCGSTAmount}</li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">{this.state.TotalSGSTAmount}</li> : null}
                {this.state.gstcheck === true ? <li class="list-group-item">{this.state.TotalAmount}</li> :
                  <li class="list-group-item">{this.state.TotalTaxableValue}</li>}
                <li class="list-group-item">
                  <Input
                    type="text"
                    style={{
                      border: this.state.ValidAdjustamount,
                      textAlign: "left",
                      position: "relative",
                      display: "block",
                      top: 0,
                      fontSize: "15px",
                      margin: "0",
                      left: -12,
                      height: 33,
                      width: "110%",
                    }}

                    Value={this.state.Adjustamount}
                    onChange={this.adjustAmount}
                  />
                  {this.state.ValidAdjustamount !== "none" ? (
                    <h6 style={{ color: "red" }}>not null</h6>
                  ) : null}

                </li>

                {this.state.gstcheck === true ? <li class="list-group-item">{this.state.GrandTotalAmount}</li> :
                  <li class="list-group-item">{parseFloat(parseFloat(this.state.TotalTaxableValue) - parseFloat(this.state.Adjustamount)).toFixed(2)}</li>}
              </ul>
            </Col>
          </Row>
        ) : null}
        {this.state.nullvalueisthere === true &&
          this.state.ShowparchaseRequest === false ? (
          <Row>
            <Col md="5"></Col>
            <Col>
              <h3 style={{ color: "red" }}>
                Please fill the required fields marked in red
              </h3>
            </Col>
          </Row>
        ) : null}
        {this.state.updateSalesQty === true ? (
          <Row>
            <Col md="5"></Col>
            <Col>
            
              <h3 style={{ color: "red" }}>
                Please check Received Quantity or Rate
              </h3>
            </Col>
          </Row>
        ) : null}
        {this.state.ShowparchaseRequest === false ? (
          <Row>
            <Col md="2">
              <DropdownItem size="sm">
                <Popup
                  modal
                  data-backdrop="static"
                  trigger={
                    <button
                      id="buttonsave"
                      className="btn btn-dark btn-sm"
                      style={{ width: 100, height: 40, paddingTop: 5 }}
                    >
                      Cancel Edit
                    </button>
                  }
                >
                  {(close) => (
                    <CancelEdit
                      clearpurchase={this.CancelEdit}
                      Editdata={true}
                      purchaseorder={false}
                      closebtn={close}
                    />
                  )}
                </Popup>
              </DropdownItem>
            </Col>
            <Col md="6"></Col>
            <Col md="2">
              <button
                id="buttonsave"
                className="btn btn-dark btn-sm"
                style={{ width: 100, height: 40, float: 'right', right: -65 }}
                onClick={() => this.onSubmitForm("Save as Draft")}
              >
                Save as Draft
              </button>
            </Col>
            <Col md="2">
              <button
                id="buttonsave"
                className="btn btn-dark btn-sm"
                style={{ width: 100, height: 40, float: 'right' }}
                onClick={() => this.onSubmitForm("Submit")}
              >
                Submit
              </button>
            </Col>
          </Row>
        ) : null}
        {this.state.ShowparchaseRequest === true ? (
          <>
            <RequestedStocks
              backtoEditPurchase={this.callBackFunction}
              purchaseorderid={this.state.Id}
              purchasestock={this.props.Editdata.purchasestock}
              Suppliername={this.state.SupplierName}
            />
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails: state.clientdetails
  };
};
export default connect(mapStateToProps, null)(EditPurchaseDetails);
