import React, { Component } from "react";
import { Col, Input, Label, Row } from "reactstrap";
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import config from "react-global-configuration";
import ReactBSAlert from "react-bootstrap-sweetalert";
import getDateTimeField from "../../../../Utils";
const authApi = config.get("apiDomain");

class LowStockPurchaseRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      quantity: 0,
      quantityState: null,
      username: this.props.username,
      userid: "",
      date: moment(new Date()).format("YYYY-MM-DD"),
      status: "Requested",
      alert: false,
    };
  }

  componentDidMount() {
    this.getCurrentUser();
  }

  getCurrentUser = () => {
    let currentuserdetails = this.props.allusers.filter((userdetails) => {
      return userdetails.name === this.props.username;
    });
    this.setState({
      userid: currentuserdetails[0].id,
    });
  };

  handleRequestQuantity = (event) => {
    this.setState({
      quantity: event.target.value,
    });
  };

  sweetalert = () => {
    const getsuccessAlert = () => (
      <ReactBSAlert
        success
        style={{ display: "block", marginTop: "-100px" }}
        title="Success!"
        confirmBtnBsStyle="success"
        btnSize=""
        onConfirm={() => this.goBackPage()}
      >
        Requested Product Added Successfully..!
      </ReactBSAlert>
    );

    this.setState({
      alert: getsuccessAlert(),
    });
  };

  goBackPage = () => {
    this.setState({
      alert: false,
    });
    this.props.closebtn();
  };

  validatePurchaseRequestForm = () => {
    if (this.state.quantity === "" || parseInt(this.state.quantity) <= 0) {
      this.setState({
        quantityState: "invalid",
      });
    } else {
      this.setState({
        quantityState: "valid",
      });
      this.handlePurchaseRequest();
    }
  };

  handlePurchaseRequest = () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "post",
      url: `${authApi}purchaserequest/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        date: this.state.date,
        username: this.state.userid,
        stockname: this.props.editabledata.stockname,
        productcode: this.props.editabledata.productcode,
        quantity: this.state.quantity,
        status: this.state.status,
        description: this.state.description,
        purchaseorderid: this.state.purchaseorderid,
        isdeleted: this.state.isdeleted,
        createdby: this.props.username,
        createdat: datetime_field,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    }).then((res) => {
      if (res.status === 201) {
        this.sweetalert();
      }
    });
  };

  render() {
    return (
      <form>
        {this.state.alert}
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div>
          <div className="header">Request Stocks</div>
          <br />
          <Row>
            <Col md="1"></Col>
            <Col md="3" style={{ marginLeft: -15 }}>
              <Label className="form-control-label">Stockname</Label>
              <Input type="text" value={this.props.editabledata.stockname} />
            </Col>
            <Col md="3">
              <Label className="form-control-label">Productcode</Label>
              <Input type="text" value={this.props.editabledata.productcode} />
            </Col>
            <Col className="mb-3" md="3">
              <label className="form-control-label">Quantity</label>
              <Input
                type="text"
                valid={this.state.quantityState === "valid"}
                invalid={this.state.quantityState === "invalid"}
                onChange={(e) => {
                  this.setState({
                    quantity: e.target.value,
                  });
                  if (e.target.value === "" || parseInt(e.target.value) <= 0) {
                    this.setState({
                      quantityState: "invalid",
                    });
                  } else {
                    this.setState({
                      quantityState: "valid",
                    });
                  }
                }}
              />
              <div className="invalid-feedback">Please enter a quantity.</div>
            </Col>
            <Col md="1" style={{ marginTop: 32 }}>
              <button
                className="btn btn-dark md"
                onClick={this.validatePurchaseRequestForm}
              >
                Add
              </button>
            </Col>
          </Row>
          <br />
        </div>
      </form>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
    allusers: state.allusers,
  };
};
export default connect(mapStateToProps, null)(LowStockPurchaseRequest);
