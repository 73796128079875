import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  container: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    backgroundColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    textAlign: "center",
    fontStyle: "bold",
    flexGrow: 1
  },
});

const PurchaseorderTableHeader = () => (
  <View style={styles.container}>
    <Text style={{ width: '10%' }}>Code</Text>
    <Text style={{ width: '25%' }}>Stock</Text>
    <Text style={{ width: '15%' }}>Qty</Text>
    <Text style={{ width: '15%' }}>Rate</Text>
    <Text style={{ width: '20%' }}>CGST / SGST</Text>
    <Text style={{ width: '15%' }}>Amount</Text>
  </View>
);

export default PurchaseorderTableHeader;
