import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import ReportTableHeader from "./ReportTableHeader";
import ReportTableRow from "./ReportTableRow";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  }
});

const ReportTable = ({ manageData }) => {
  const ascendingReport = manageData.sort((a, b) => a.date.localeCompare(b.date))
  
  return( 
    <View style={styles.tableContainer} >
      <ReportTableHeader />
      <ReportTableRow reportData = {ascendingReport} />
    </View>
  )
};

export default ReportTable;