import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  descriptions: {
    width: "37%",
    textAlign: "left",
    paddingLeft: 8,
    paddingTop: 4,
    color: "#000000",
  },
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
  description: {
    width: "37%",
    textAlign: "left",
    paddingLeft: 28,
    paddingTop: 4,
  },
  units: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  qtys: {
    width: "15%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  rates: {
    width: "14.7%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
  },
  amount: {
    width: "14.6%",
    textAlign: "right",
    paddingRight: 0,
    paddingTop: 4,
  },
  amounts: {
    width: "14.6%",
    textAlign: "right",
    paddingRight: 0,
    paddingTop: 4,
  }
});

const InvoicePrinting = ({ printChar }) => 
{
  const invoicePrintTot = printChar.reduce((i,p) => i + parseInt(p.amount, 0), 0);

  const InvoiceprintCharge = printChar.map((pr) => (
    <View style={styles.rows} key={pr.types}>
      <Text style={styles.description}>{pr.types}</Text>
      <Text style={styles.units}>{pr.unit}</Text>
      <Text style={styles.qtys}>{pr.quantity}</Text>
      <Text style={styles.rates}>{pr.rate}</Text>
      <Text style={styles.amounts}>{Number.parseFloat(pr.amount).toFixed(2)}</Text>
    </View>
  ));
  
  return( 
    <React.Fragment>
      { invoicePrintTot === 0 ? null :
        <View style={styles.rows}>
          <Text style={styles.descriptions}>Printing Details</Text>
          <Text style={styles.units}></Text>
          <Text style={styles.qtys}></Text>
          <Text style={styles.rates}></Text>
          <Text style={styles.amount}></Text>
        </View>
      }
        {InvoiceprintCharge}
      {/* { invoicePrintTot === 0 ? null :
        <View style={styles.rows}>
          <Text style={styles.descriptions}>Total Printing Charges</Text>
          <Text style={styles.units}></Text>
          <Text style={styles.qtys}></Text>
          <Text style={styles.rates}></Text>
          <Text style={styles.amount}> {Number.parseFloat(invoicePrintTot).toFixed(2)}</Text>
        </View>
      } */}
    </React.Fragment>
  )
};

export default InvoicePrinting;
