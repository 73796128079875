import React from 'react';
import logo from "assets/img/brand/pgcadd.png"

const ViewIndividualSalesorder = ({ salesorderViewdata }) => {
  return(
  <>
    <div>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            height: '100%',
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            top: "45%",
            width: '100%',
          }}
        >
          <div
            style={{
              color: 'rgba(0, 0, 0, 0.2)',
              fontSize: `${8 * 0.5}rem`,
              fontWeight: 'bold',
              textTransform: 'uppercase',
              transform: 'rotate(-45deg)',
              userSelect: 'none',
            }}
          >
            { salesorderViewdata.status }
          </div>
        </div>
        <div style={{ width: 300,marginRight: "auto",marginTop : 40 }}>
            <h2 style={{ color: "#11cdef",letterSpacing: 1,fontSize: 20,marginLeft: 23,textAlign: "left",textTransform: "uppercase"}}>
                {salesorderViewdata.company} 
            </h2>
        </div>
        <div>
           <p style={{ width: 300,height: 66,marginRight: "auto",marginLeft: 23, fontFamily: 'sans-serif' }}>
             334, opp. Indusind bank,
             Mettur road <br />
             Erode - 638001.
           </p>
        </div>
        <div>
           <img style={{marginTop: -135,width: 75,height: 100,marginRight:'30px' ,marginBottom: 5,float:'right'}} src={logo} alt='' />
        </div>

        <div style={{ width: 200,height: 66,marginLeft: 23,marginTop : 20,fontFamily: 'sans-serif' }}>
          <p style={{ width: 200, fontSize: 15 }}> {salesorderViewdata.supplier} </p>
        </div>

         
        <div style={{ marginTop: -67,width: 200,height: 66,marginLeft: "auto",marginRight: 0, fontFamily: 'sans-serif'}}>
          <p style={{ width: 200, fontSize: 15 }}>Salesorder Id : {salesorderViewdata.id}</p>
          <p style={{ width: 200, marginTop : -20, fontSize: 15 }}>Date : {salesorderViewdata.date}</p>
        </div>
        
        
        <table className="align-items-center table-flush" style={{ fontFamily: 'sans-serif',marginLeft: 5, marginRight: 5, marginTop: 5 }}>
            <thead style={{ flexDirection: "row",
                backgroundColor: "#bff0fd",
                alignItems: "center",
                height: 24,
                textAlign: "center",
                flexGrow: 1}}
            >
                <tr>
                  <th style={{ width: '5%'}}>Code</th>
                  <th style={{ width: '20%'}}>Stock</th>
                  <th style={{ width: '10%'}}>Qty</th>
                  <th style={{ width: '10%'}}>Rate</th>
                  <th style={{ width: '20%'}}>CGST / SGST</th>
                  <th style={{ width: '15%'}}>Amount</th>
                </tr>
            </thead>
            { salesorderViewdata.sales.filter((d) => d.stockname !== "Total" && d.isdeleted === false).map((t, id) => {
              return (
                  <tbody key={id}>
                    <tr style={{ flexDirection: "row",alignItems: "center"}} key={t.id}>
                      <td style={{ width: "5%",textAlign: "left",paddingLeft: 8,paddingTop: 4,}}>{t.productcode}</td>
                      <td style={{ width: "20%",textAlign: "left",paddingLeft: 8,paddingTop: 4,}}>{t.stockname}</td>
                      <td style={{ width: "10%",textAlign: "center",paddingRight: 8,paddingTop: 4,}}>{t.quantity}</td>
                      <td style={{ width: "10%",textAlign: "center",paddingRight: 8,paddingTop: 4,}}>{t.rate}</td>
                      <td style={{ width: "20%",textAlign: "center",paddingRight: 8,paddingTop: 4,}}>{t.cgstpercentage} / {t.sgstpercentage}</td>
                      <td style={{ width: "15%",textAlign: "center",paddingRight: 0,paddingTop: 4,}}>{Number.parseFloat(t.total).toFixed(2)}</td>
                    </tr>
                  </tbody>
                );
            })}
          <br />
          <tfoot>
            <tr style={{ flexDirection: "row",alignItems: "center"}}>
              <td colSpan={5} style={{ width: "85%",textAlign: "right",paddingLeft: 8,paddingTop: 4 }}> Total </td>
              <td colSpan={1} style={{ width: "15%",textAlign: "center",paddingRight: 0,paddingTop: 4 }}>{Number.parseFloat(salesorderViewdata.amount).toFixed(2)}</td>
            </tr>
            <tr style={{ flexDirection: "row",alignItems: "center",marginTop: 10}}>
              <td colSpan={5} style={{ width: "85%",textAlign: "right",paddingLeft: 8,paddingTop: 4 }}> Advance Received </td>
              <td colSpan={1} style={{ width: "15%",textAlign: "center",paddingRight: 0,paddingTop: 4 }}>{Number.parseFloat(salesorderViewdata.advancereceived).toFixed(2)}</td>
            </tr>
          </tfoot>
        </table>
        <br /> 
     </div>
  </>
  )
};

export default ViewIndividualSalesorder;