import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import ClientReportsTableHeader from "./ClientReportsTableHeader";
import ClientReportsTask from "./ClientReportsFolder/ClientReportsTaskName";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  }
});

const ClientReportsTable = ({ manageData }) => {
  const ascendingReport = manageData.sort((a, b) => a.id - b.id);

  return( 
    <View style={styles.tableContainer} >
      <ClientReportsTableHeader />
      <ClientReportsTask taskData={ascendingReport} />
    </View>
  )
};

export default ClientReportsTable;