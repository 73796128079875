import React, { useEffect, useState, useMemo } from "react";
import ReactTooltip from "react-tooltip";
import AddLedgerType from "./AddLedgerType";
import ConPreLoader from "components/Loader/Con_PreLoader";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import EditLedgerType from "./EditLedgerType";
import TableContainerExpand from "../../Filters/TableContainerExpand";
import { SelectColumnFilter } from "../../Filters/filters";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";
import { Link } from "react-router-dom";
import classnames from "classnames";
import { Row, Nav, NavItem, NavLink, Container } from "reactstrap";
import { CurrentLocation } from "../../../../components/loginRedux/loginReducers";	
import store from "../../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");
let currentActiveTab = "1";

function LedgerTypes(props) {
  const [data, setData] = useState([]);
  const [checked, setChecked] = useState(true);
  const [loading, setLoading] = useState(true);
  const [btnClass, setBtnClass] = useState("btn btn-danger btn-sm")

  useEffect(
    () => {
      const doFetch = async () => {
        store.dispatch({ type: CurrentLocation, data: props.location.pathname })
        const response = await fetch(`${authApi}ledgertypefilter/?isvisible=true`, {
          method: "GET",
          headers: {
            "content-type": "application/json",
            Authorization: `token ${props.token}`,
          },
        });
        const ledgerTypes = await response.json();
        if (ledgerTypes.length === 0) {
          alert("Not available ledgertypes!");
          setLoading(false);
        } else {
          setData(ledgerTypes);
          setLoading(false);
          setChecked(true)
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function refreshfunction() {
    const doFetch = async () => {
      const response = await fetch(`${authApi}ledgertypefilter/?isvisible=true`, {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${props.token}`,
        },
      });
      const ledgerTypes = await response.json();
      if (ledgerTypes.length === 0) {
        alert("Not available ledgertypes!");
      } else {
        setData(ledgerTypes);
      }
    };
    doFetch();
  }

  function getFilterButton(event){
    if(event === true ){
      setChecked(false)
      setBtnClass("btn btn-success btn-sm")
    }
    else{
      setChecked(true)
      setBtnClass("btn btn-danger btn-sm")
    }
  }

  const columns = useMemo(
    () => [
      {
        Header: "Actions",
        Cell: (row) => {
          const row_data = row.row.original;
          return (
            <div>
              <Popup
                modal
                trigger={
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ marginRight: "5px" }}
                  >
                    <i className="fa fa-edit" />
                  </button>
                }
                position="center"
              >
                {(close) => (
                  <EditLedgerType
                    EditRowSend={row_data}
                    closebtn={close}
                    callbackfunction={refreshfunction}
                  />
                )}
              </Popup>
            </div>
          );
        },
      },
      {
        Header: "TypeName",
        accessor: "ledgertypename",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      },
      {
        Header: "ReferenceName",
        accessor: "ledgertypegroup",
        Filter: SelectColumnFilter,
        filter: "equals",
        disableSortBy: true,
        Cell: (row) => <div style={{ textAlign: "center" }}>{row.value}</div>,
      }
    ],
    // eslint-disable-next-line
    []
  );

  return (
    <>
      <div style={{ marginTop: 0 }}>
        <Nav tabs style={{ padding: "25px 20px 0", fontSize: 20, backgroundColor: '#11cdef' }}>
          <NavItem>
            <NavLink
              className={classnames({
                active: currentActiveTab === "1",
              })}
              to="/admin/ledger/ledgertypes"
              tag={Link}
            >
              Ledger Type
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink style={{ color: 'white' }}
              className={classnames({
                active: currentActiveTab === "2",
              })}
              to="/admin/ledger/ledgergroups"
              tag={Link}
            >
              Ledger Group
            </NavLink>
          </NavItem>
        </Nav>
        <Container className="mt--6" fluid>
          <br />
          <Row>
            <div
              className="addRow"
              style={{ position: "absolute", right: "105px" }}
            >
              <ReactTooltip
                id="ledgertype-Tip"
                place="left"
                type="dark"
                effect="solid"
              >
                Add New LedgerType
              </ReactTooltip>
              <Popup
                modal
                trigger={
                  <button
                    className="btn btn-primary btn-sm"
                    style={{ width: "135%", height: 40 }}
                    data-tip
                    data-for="ledgertype-Tip"
                  >
                    <i className="fas fa-plus-circle fa-1x"></i>
                  </button>
                }
              >
                {(close) => (
                  <AddLedgerType
                    closebtn={close}
                    callbackfunction={refreshfunction}
                  />
                )}
              </Popup>
            </div>
            <div className="addRow" style={{position:"absolute", right:"55px"}}>
              <button className={btnClass} style = {{ width:'135%', height:40 }} onClick={(e) => getFilterButton(checked)}>
                <i className="fa fa-filter" aria-hidden="true"></i>
              </button>
            </div>
          </Row>
          <br />
          <br />
          <Row style={{ marginTop: 10}}>
            {loading === true ? (
              <div>
                
                <ConPreLoader />
              </div>
            ) : (
              <div className="col">
                <TableContainerExpand
                  columns={columns}
                  data={data}
                  checkedToggle={checked}
                />
              </div>
            )}
          </Row>
        </Container>
      </div>
    </>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(LedgerTypes);
