import React from "react";
import { View, StyleSheet } from "@react-pdf/renderer";
import InvoiceTableHeader from "./InvoiceTableHeader";
import InvoiceDetailsRow from "./InvoiceFolder/InvoiceDetail";

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: "row",
    flexWrap: "wrap",
    marginTop: 24,
    borderWidth: 1,
    borderColor: "#bff0fd",
  },
});

const ReportTable = ({ data }) => {
  const details = data.sort((a, b) => a.clientname.localeCompare(b.clientname));

  return (
    <View style={styles.tableContainer}>
      <InvoiceTableHeader />
      <InvoiceDetailsRow outstandingBal={details} />
    </View>
  );
};

export default ReportTable;
