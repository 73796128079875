import React, { useEffect, useState, useMemo } from 'react';
import { Container,Row, Form, FormGroup } from 'reactstrap';
import TableContainerExpand from '../Filters/TableContainerExpand';
import Header from "../../../components/Headers/SimpleHeader";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import "reactjs-popup/dist/index.css";
import Popup from "reactjs-popup";
import AddBankaccount from "./AddBankaccount";
import EditBankaccount from "./EditBankAccount";
import {connect} from 'react-redux';
import config from "react-global-configuration";
import '../../../config';
import ReactTooltip from "react-tooltip";
import { CurrentLocation } from "../../../components/loginRedux/loginReducers";	
import store from "../../../components/loginRedux/Store";

const authApi = config.get('apiDomain');
function Bankaccounts(props) {
  const [data, setData] = useState([]);
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(true);
  useEffect(() => {
      const doFetch = async () => {
        store.dispatch({ type: CurrentLocation, data: props.location.pathname })
        const response = await fetch(`${authApi}companyfilter/`, {
          method: 'GET',
          headers: {
          'content-type': "application/json",
          'Authorization': `token ${props.token}`,
          }
        })
        const companydata = await response.json();
        const bankresponse = await fetch(`${authApi}bankfilter/`, {
          method: 'GET',
          headers: {
          'content-type': "application/json",
          'Authorization': `token ${props.token}`,
          }
        })
        let bank = await bankresponse.json();
        if(bank.length === 0){
          setLoading(false);
          alert("Bank accounts not available");
        }
        else {
          let details = bank.sort((a, b) => (a.id - b.id));
          details.forEach(bank => {
            companydata.forEach(companydetails => {
              if(bank.companydetails===companydetails.id){
                bank.companydetails=companydetails.companyname;
              }
            });
          });
          setData(details);
          setLoading(false);
        }}
      doFetch();
    },
// eslint-disable-next-line
  []
);
function CallbackBankaccount(){
  const doFetch = async () => {
    const companydetails = await fetch(`${authApi}companyfilter/`, {
      method: 'GET',
      headers: {
      'content-type': "application/json",
      'Authorization': `token ${props.token}`,
      }
    })
    const companydata = await companydetails.json();
    const response = await fetch(`${authApi}bankfilter/`, {
      method: 'GET',
      headers: {
      'content-type': "application/json",
      'Authorization': `token ${props.token}`,
      }
    })
    let bankdetails = await response.json();
    if(bankdetails.length === 0){
      alert("Bank accounts not available");
      setLoading(false);
    }
    else {
      bankdetails.forEach(bank => {
        companydata.forEach(companydetails => {
          if(bank.companydetails===companydetails.id){
            bank.companydetails=companydetails.companyname;
          }
        });
      });
      setData(bankdetails);
      setLoading(false);
    }}
  doFetch();
}
function search(rows) {
  const cols = rows[0] && Object.keys(rows[0]);
  return rows.filter((r) =>
    cols.some((c) => r[c] ? (r[c].toString().toLowerCase().indexOf((q).toLowerCase()) > -1 ) : null )
  );
}
const columns = useMemo(
    () => [
      {
        Header:"Actions",
        Cell: (row) => {
          const row_data = row.row.original;
          return (
            <React.Fragment>
              <Popup
                modal
                trigger={
                  <button className="btn btn-primary btn-sm" style={{marginRight: '5px', textAlign: 'center'}}>
                    <i className="fa fa-edit" />
                  </button>
                }
              >
                {(close) => (
                  <EditBankaccount
                    EditRowSend={row_data}
                    closebtn={close}
                    callbackfunction={CallbackBankaccount}
                  />
                )}
              </Popup>
            </React.Fragment>
          );
        }
      },
      {
        Header: "Bank Name",
        accessor: "bankname",
        disableSortBy: false,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: "Account No.",
        accessor: "accountno",
        disableSortBy: true,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: "UPI Id",
        accessor: "upi",
        disableSortBy: true,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: "IFSC Code",
        accessor: "ifsc",
        disableSortBy: true,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
      {
        Header: "Company Mame",
        accessor: "companydetails",
        disableSortBy: true,
        Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>
      },
    ],
    // eslint-disable-next-line
    []
);
return (
    <React.Fragment>
      <Header />
        <Container className="mt--7" fluid><br />
          <Row>
            <Form className="searchForm" style = {{ marginTop : 16, right:"105px"}}>
              <FormGroup className="mb-0">
                  <input className="searchBox " type="search"
                    placeholder="Search" value={q} onChange={(e) => setQ(e.target.value)} />
              </FormGroup>
            </Form>
            <div className="addRow" style={{position:"absolute", right:"65px", marginTop: 18}}>
                <ReactTooltip id="addClients-Tip" place="left" type="dark" effect="solid">
                  Add Bankaccount
                </ReactTooltip>
                <Popup
                  modal
                  position="center"
                  trigger={
                  <button className="btn btn-primary btn-sm"
                    style={{ width: "135%", height: 40 }} data-tip data-for="addClients-Tip">
                    <i class="fas fa-university"></i>
                  </button>
                  }
                  >
                  {
                    (close) => (
                      <AddBankaccount
                        closebtn={close}
                        callbackfunction={CallbackBankaccount}
                      />
                    )}
                </Popup>
            </div>
          <br /><br /><br />
        </Row>
          { loading === true ? <div> <ConPreLoader /></div> :
          <Row>
            <div className=" col">
            <ReactTooltip place="top" type="dark" effect="float" />
              <TableContainerExpand
                columns={columns}
                data={search(data)}
              >
              </TableContainerExpand>
            </div>
          </Row>
          }
          <br /><br />
          </Container>
        </React.Fragment>
      );
}
const mapStateToProps = state => {
  return {
    token:state.token,
  }
}
export default connect(mapStateToProps, null)(Bankaccounts);