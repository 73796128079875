import React from "react";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../../config";

const authApi = config.get("apiDomain");

class OutwardTableRow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stockItems: [],
      id: this.props.id,
      stocknames: this.props.stockname,
      units: this.props.unit,
      quantities: this.props.quantity,
      productcodes: this.props.productcode,
      stockbatchamounts: this.props.stockbatchamount,
      outwardrowtotals: this.props.outwardrowtotal,
    };
  }

  componentDidMount() {
    fetch(`${authApi}stockfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          stockItems: json,
        });
      });
  }

  selectOutStock = (event) => {
    this.state.stockItems.forEach((i) => {
      if (i.stockname === event.target.value) {
        this.setState({
          stocknames: i.stockname,
          units: i.unit,
          productcodes: i.productcode,
        });
      }
    });
    this.setState(
      {
        stocknames: event.target.value,
      },
      function () {
        this.props.updateAddRow(this.state);
      }
    );
  };

  selectOutStockQuantity = (event) => {
    let val = 0;
    if (
      !isNaN(
        parseFloat(this.state.stockbatchamounts) * parseInt(event.target.value)
      )
    ) {
      val =
        parseFloat(this.state.stockbatchamounts) * parseInt(event.target.value);
    }
    this.setState(
      {
        quantities: event.target.value,
        outwardrowtotals: val,
      },
      function () {
        this.props.updateAddRow(this.state);
      }
    );
  };

  selectOutStockamount = (event) => {
    let val = 0;
    if (
      !isNaN(parseInt(this.state.quantities) * parseFloat(event.target.value))
    ) {
      val = Math.ceil(
        parseInt(this.state.quantities) * parseFloat(event.target.value)
      );
    }
    this.setState(
      {
        stockbatchamounts: event.target.value,
        outwardrowtotals: val,
      },
      function () {
        this.props.updateAddRow(this.state);
      }
    );
  };

  deleteOutwardRow = () => {
    this.props.deleteRow(this.state);
  };

  render() {
    return (
      <React.Fragment>
        <tr>
          <td>
            <input
              type="option"
              list="outwardstocklist"
              className="form-control-alternative"
              style={{ width: 200, border: "none", height: 25 }}
              defaultValue={this.state.stocknames}
              onChange={this.selectOutStock}
            />
            <datalist id="outwardstocklist">
              {this.state.stockItems.map((item, i) => (
                <option key={i.stockname} value={item.stockname} />
              ))}
            </datalist>
          </td>
          <td>
            <input
              type="number"
              className="form-control-alternative"
              style={{ width: 100, border: "none", height: 25 }}
              value={this.state.quantities}
              onChange={this.selectOutStockQuantity}
            />
       
            <input
              type="text"
              className="form-control-alternative"
              style={{ width: 100, border: "none", height: 25 }}
              defaultValue={this.state.units}
              disabled
            />
          </td>
          <td>
            <input
              type="number"
              className="form-control-alternative"
              style={{ width: 100, border: "none", height: 25 }}
              value={this.state.stockbatchamounts}
              onChange={this.selectOutStockamount}
            />
            <input
              type="text"
              className="form-control-alternative"
              style={{ width: 100, border: "none", height: 25 }}
              defaultValue={this.state.outwardrowtotals}
              disabled
            />
          </td>
          <td>
            <button
              className="btn btn-primary btn-sm"
              onClick={this.deleteOutwardRow}
            >
              <i className="fa fa-trash" aria-hidden="true"></i>
            </button>
          </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};

export default connect(mapStateToProps, null)(OutwardTableRow);
