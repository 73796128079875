import React, { Component } from 'react';
import '../../../../config';
import { connect } from "react-redux";
import { Card, Table } from 'reactstrap';
import config from "react-global-configuration";
const authApi = config.get('apiDomain');

class PrintingDetails extends Component {
    constructor(props) {
        super(props)

        this.state = {
            id: this.props.data.id,
            date: this.props.data.date,
            username: this.props.data.username,
            subtask: this.props.data.subtask,
            subtaskname: "",
            Manitaskid: "",
            maintaskname: "",
            clientid: "",
            clientName: "",
            printingdetails: [],
            stockinvoiceid: "",
            alreadytaskdisplay:0,
        }
    }

    componentDidMount() {
        this.subtaskfilter();
    }

    subtaskfilter = async () => {
        await fetch(`${authApi}subtasks/${this.state.subtask}/`, {
            method: 'GET',
            headers: {
                'content-type': "application/json",
                'Authorization': `token ${this.props.token}`,
            }
        })
            .then(res => res.json())
            .then(json => {
                this.setState({
                    subtaskname: json.subtask,
                    Manitaskid: json.tasklist,
                }, function () {
                    this.Filtertaskname();
                })
            })
    }

    Filtertaskname = () => {
        this.props.taskid.forEach(element => {
            if(element===this.state.Manitaskid){
                this.setState({
                 alreadytaskdisplay:1,
                })
                
            }
        });

        if(this.state.alreadytaskdisplay===0){
        this.props.taskdetails(this.state.Manitaskid);
         }
        if (this.props.taskid.length === 0 || this.state.alreadytaskdisplay===0) {
            fetch(`${authApi}Tasklist/${this.state.Manitaskid}/`, {
                method: 'GET', 
                headers: {
                    'content-type': "application/json",
                    'Authorization': `token ${this.props.token}`,
                }
            })
                .then(res => res.json())
                .then(json => {
                    this.setState({
                        clientid: json.client,
                        maintaskname: json.task,
                        stockinvoiceid: json.id,
                    }, function () {
                        this.props.taskdetails(this.state.Manitaskid);
                        this.ClientNameFilter();
                    })
                })
        }
    }

    ClientNameFilter = () => {
        let filter_clientdetails=this.props.clientdetails.filter((clientdata)=>{
            return clientdata.id === this.state.clientid
        })
        this.setState({
            clientName: filter_clientdetails[0].company_name,
        }, function () {
            this.Stockinvoicefilter();
        })
    }
    
    Stockinvoicefilter = async () => {
        await fetch(`${authApi}stockinvoicefilter/?tasklistrow=${this.state.stockinvoiceid}`, {
            method: 'GET',
            headers: {
                'content-type': "application/json",
                'Authorization': `token ${this.props.token}`,
            }
        }).then(res => res.json()
            .then(json => {
                if (json.length !== 0) {
                    this.setState({ printingdetails: json })
                }
                else{
                    this.setState({
                        alreadytaskdisplay:1,
                    })
                }
            })
        )
    } 


    render() {
        return (
            <>   
               {this.state.alreadytaskdisplay ===0 ? <tr>
                <td className="text-center">
                    <label>{this.state.maintaskname}</label>
                </td>
                <td>
                    <Card>
                        <Table
                            className="align-items-center table-flush"
                            responsive-sm
                        > <thead className="thead-light">
                                <tr>
                                    <th className="text-center">STOCK NAME</th>
                                    <th className="text-center">QUANTITY</th>
                                    <th className="text-center">RATE</th>
                                    <th className="text-center">AMOUNT</th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.state.printingdetails.map((printing) => (
                                    <tr>
                                  
                                        <td className="text-center">{printing.types}</td>
                                        <td className="text-center">{printing.quantity}</td>
                                        <td className="text-center">{printing.rate}</td>
                                        <td className="text-center">{printing.amount}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </Card>
                </td>
            </tr>:null}
            </>
        )
    }
}
const mapStateToProps = state => {
    return {
        token: state.token,
        clientdetails:state.clientdetails
    }
}
export default connect(mapStateToProps, null)(PrintingDetails)