import React from "react";
import { Input } from "reactstrap";
import {connect} from "react-redux";
import config from "react-global-configuration";
import '../../../../config';

const authApi = config.get('apiDomain');

class PayVoucherRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            id: this.props.id,
            pay_purchaseId: this.props.purchase_Id,
            pay_subType: this.props.paysubType,
            pay_purchaseTotal: this.props.purchase_Total,
            pay_amtReceived: this.props.payamtReceived,
            pay_subReferType: this.props.paysubReferType,
            pay_purchaseBalance: this.props.purchase_Balance,
            pay_ledgerType : this.props.voucherLedgerType,
            purchaseOrderDetails : this.props.purchaseOrderData,
            purchaseBillDetails : this.props.purchaseBillData,
            amountborder: "none"
        };
    }

    deletePayVoucherRow=()=>{
      this.props.deleteRow(this.state);
    }

    handleVoucherPaymentId = (event) => {
      if(this.state.pay_ledgerType === "PurchaseBills"){
        fetch(`${authApi}purchaseinvoicebillfilter/?id=${event.target.value}`, {
          method: 'GET',
          headers: {
          'content-type': "application/json",
          'Authorization': `token ${this.props.token}`,
          }
          })
          .then(  res => res.json())
          .then(json=>{
            if(json.length !== 0 && json.length !== undefined){
              let balance = 0
              let amount_received = json[0].amountreceived
              if(amount_received === null || amount_received === 0){
                amount_received = 0
              }
              balance = parseFloat(json[0].amount) - parseFloat(amount_received)
              this.setState({
                pay_purchaseId : json[0].id,
                pay_purchaseTotal : json[0].amount,
                pay_amtReceived : '',
                pay_purchaseBalance: parseFloat(balance)
              },function(){
                 this.props.updateAddRow(this.state)
              })
            }
            else{
              alert('Purchase invoice bill amount has zero')
            }
        })
      }
      else if(this.state.pay_ledgerType === "PurchaseAdvance"){
        fetch(`${authApi}purchaseorderfilter/?id=${event.target.value}`, {
          method: 'GET',
          headers: {
          'content-type': "application/json",
          'Authorization': `token ${this.props.token}`,
          }
          })
          .then(  res => res.json())
          .then(json=>{
            if(json.length !== 0 && json.length !== undefined){
              let balance = 0
              let amount_advancepaid = json[0].advancepaid
              if(amount_advancepaid === null || amount_advancepaid === 0){
                amount_advancepaid = 0
              }
              balance = parseFloat(json[0].total) - parseFloat(amount_advancepaid)

              this.setState({
                pay_purchaseId : json[0].id,
                pay_purchaseTotal : json[0].total,
                pay_amtReceived : '',
                pay_purchaseBalance: parseFloat(balance).toFixed(2)
              },function(){
                 this.props.updateAddRow(this.state)
              })
            }
            else{
              alert('Purchase invoice bill amount has zero')
            }
        })
      }
    }

    handlePaymentName = (event) => {
      this.setState({
        pay_subType : event.target.value,
      },function(){
        this.props.updateAddRow(this.state);
      })
    }

    handlePayVoucherAmtRecevd = (event) => {
      if(this.state.pay_ledgerType === "PurchaseBills" || this.state.pay_ledgerType === "PurchaseAdvance"){
        if(parseFloat(this.state.pay_purchaseBalance) < parseFloat(event.target.value)){
          this.setState({
            amountborder : "1px solid red",
            pay_amtReceived : event.target.value
          })
        }
        else {
          this.setState({
            pay_amtReceived : event.target.value,
            amountborder : "none"
          },function(){
            this.props.updateAddRow(this.state);
          })
        }
      }
    }

    handleVoucherAmountRecevd = (event) => {
      if(this.state.pay_subReferType === ''){
        this.setState({
          amountborder : "1px solid red",
          pay_amtReceived : event.target.value
        })
      }
      else {
        this.setState({
          pay_amtReceived : event.target.value,
          amountborder : "none"
        },function(){
          this.props.updateAddRow(this.state);
        })
      }
    }

    handleVoucherPaymentReference = (event) => {
      this.setState({
        pay_subReferType : event.target.value,
      },function(){
        this.props.updateAddRow(this.state);
      })
    }

render() {
    return (
      <React.Fragment>
        <tr>
        { this.state.pay_ledgerType === "PurchaseAdvance" || this.state.pay_ledgerType === "PurchaseBills" ? 
          <>
            <td>
              <Input 
                type="select" 
                className="form-control-alternative"
                style={{ height : "100%"}}
                defaultValue={this.state.pay_purchaseId}
                placeholder="enter payment"
                onChange={this.handleVoucherPaymentId}
              >
              { this.state.pay_ledgerType === "PurchaseBills" ?
                <>
                  <option value="">Bills</option>
                  {
                    this.state.purchaseBillDetails.sort((a,b) => a.id-b.id).map((item, key) =>
                    (<option key={key.id} value={item.id}> {item.id} </option>))
                  }
                </> : null 
              }
              { this.state.pay_ledgerType === "PurchaseAdvance" ?
                <>
                  <option value="">Orders</option>
                  {
                    this.state.purchaseOrderDetails.sort((a,b) => a.id-b.id).map((item, key) =>
                    (<option key={key.id} value={item.id}> {item.id} </option>))
                  }
                </> : null 
              }
              </Input>
            </td>
            <td>
              <Input
                type="number"
                className="form-control-alternative"
                style={{ height : "100%"}}
                placeholder="Total"
                defaultValue={this.state.pay_purchaseTotal}
                value={this.state.pay_purchaseTotal}
                disabled
              />
            </td>
            <td>
              <Input
                type="number"
                className="form-control-alternative"
                style={{ height : "100%"}}
                placeholder="Balance"
                defaultValue={this.state.pay_purchaseBalance}
                value={this.state.pay_purchaseBalance}
                disabled
              />
            </td>
            <td>
              <Input
                type="text"
                className="form-control-alternative"
                style={{ height : "100%", border:this.state.amountborder }}
                placeholder="enter amount"
                defaultValue={this.state.pay_amtReceived}
                value={this.state.pay_amtReceived}
                onChange={this.handlePayVoucherAmtRecevd}
              />
              {this.state.amountborder !=="none" ? <h6 style={{color:"red"}}>Received amount is greater than due amount</h6>:null}
            </td>
          </> :
          <>
            <td>
              <Input
                type="text"
                className="form-control-alternative"
                style={{ height : "100%"}}
                placeholder="enter type"
                defaultValue={this.state.pay_subType}
                onChange={this.handlePaymentName}
              />
            </td>
            <td>
              <Input
                type="text"
                className="form-control-alternative"
                style={{ height : "100%", border:this.state.amountborder }}
                placeholder="enter reference"
                defaultValue={this.state.pay_subReferType}
                onChange={this.handleVoucherPaymentReference}
              />
              {this.state.amountborder !=="none" ? <h6 style={{color:"red"}}>Please enter valuable reference</h6>:null}
            </td>
            <td>
              <Input
                type="text"
                className="form-control-alternative"
                style={{ height : "100%"}}
                placeholder="enter amount"
                defaultValue={this.state.pay_amtReceived}
                onChange={this.handleVoucherAmountRecevd}
              />
            </td>
          </>
        }
            <td className="text-center">
              <button className="btn btn-primary btn-sm" style={{ marginTop: 8 }} onClick={this.deletePayVoucherRow} >
                <i className="fa fa-trash" aria-hidden="true"></i>
              </button>
            </td>
        </tr>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    token:state.token, 
  }
}

export default connect(mapStateToProps, null)(PayVoucherRow);