import React, { Component } from "react";

import moment from "moment";
import axios from "axios";
import {
  Container,
  Table,
  Row,
  Card,
  Input,
  Col,
  CardHeader,
} from "reactstrap";

import { ToggleSwitch } from "react-dragswitch";
import EditProjectInvoiceRowNew from "./EditProjectInvoiceRowNew";
import PrintingDetailsRow from "./PrintingDetailsRow";
import config from "react-global-configuration";
import "../../../../config";
import consoleFunction from "../../../../Helpers";
import getDateTimeField from "../../../../Utils";
import { connect } from "react-redux";
let draftingid = 0;
const authApi = config.get("apiDomain");

class CreateNewProjectTask extends Component {
  constructor(props) {
    super(props);
    this.state = {
      received_amount: 0,
      taskid: [],
      id: "",
      Adjustment: "0",
      ChangeDate: "",
      Addrow: [],
      rate: "",
      ChangeTask: this.props.task_name,
      ChangeStart: moment(new Date()).format("YYYY-MM-DD"),
      ChangeEnd: moment(new Date()).format("YYYY-MM-DD"),
      ChangeAssign: this.props.username,
      ChangePriority: "Medium",
      ChangeStatus: "ToDo",
      ChangeTime: "",
      ChangeIsdeleted: 0,
      ChangePlanType: this.props.Editdata.taskproject[0].plantype,
      subclient: this.props.Editdata.subclient,
      file_path: "",
      amount: "0",
      advanceamount: 0,
      phonenumber: "",
      completed_date: moment(new Date()).format("YYYY-MM-DD"),
      Clientname: "",
      Username: "",
      clientid: [],
      userid: [],
      id_value: "",
      plan: [],
      currentuser: this.props.username,
      items: this.props.allusers,
      print_items: [],
      nickname: [],
      Client_id: "",
      User_id: "",
      client_alldetails: this.props.clientdetails,
      user_alldetails: [],
      subclient_details: [],
      current_id_user: [],
      current_user_id: [],
      select_clientName: [],
      selected_client_details: [],
      companydetails: [],
      planId: "",
      CheckData: [],
      Phonenum: "",
      pgcaddTaskNames: [],
      todaydate: moment(new Date()).format("YYYYMMDD"),
      error: null,
      Maintaskid: "",
      enableprinting: 0,
      border: "1px solid #dee2e6",
      checked: false,
      PrinterName: "",
      checkclient: 0,
      drawingTitle: "",
      checkClientid: 0,
      clientidborder: "1px solid #dee2e6",
      palntypeborder: "1px solid #dee2e6",
      subclientborder: "1px solid #dee2e6",
      companyborder: "1px solid #dee2e6",
      Unpaiddetails: [],
      isButtonDisabled: false,
      companyid: "1",
      invoice_company_id: this.props.Editdata.company,
      company_name: "",
      chanageproject: this.props.project_name,
      ProjectId: 0,
      ProjectnameDetails: [],
      countrefresh: 0,
      discount: 0,
      discountpercentage: 0,
      bank: [],
      PaymentMode: "Cash",
      projectdata: "",
      loading: true,
    };
  }

  async componentDidMount() {
    let currentuser = this.props.allusers.filter((userdetails) => {
      return userdetails.name === this.props.username;
    });
    await this.Projectdetails();
    let max = 0;
    const responseyear = await this.checkTaxYear();
    responseyear.forEach((currentyear) => {
      if (currentyear.id > max) {
        max = currentyear.id;
      }
    });
    this.getBankDetailsForCurrenttaskCompany();
    this.checkprinting();
    let advanceamount = parseInt(this.state.advanceamount);
    this.setState({
      advanceamount: advanceamount,
      currentuser: this.props.username,
      userid: currentuser[0].id,
    });
    this.PlanTypeFunction();
    this.CompanyDetails();
    this.filtereditclient();
    this.Storeprintername();
    await this.upateClientdetails(this.props.Editdata.clientname);
  }

  upateClientdetails = async (data) => {
    if (data === "General-Printing" || this.state.Clientname === "General") {
      let startdate = moment(this.state.todaydate).format("YYYY-MM-DD");
      let enddate = moment(this.state.todaydate).format("YYYY-MM-DD");
      this.setState({
        enableprinting: 1,
        ChangeStart: startdate,
        ChangeEnd: enddate,
      });
    } else {
      this.setState({
        enableprinting: 0,
      });
    }
    const currentclient = data;
    let currentclientdata = this.props.clientdetails.filter((clientdata) => {
      return clientdata.company_name === currentclient;
    });
    this.setState(
      {
        select_clientName: currentclientdata,
        Clientname: data,
      },
      function () {
        this.updateClientID();
      }
    );
  };

  getBankDetailsForCurrenttaskCompany = () => {
    fetch(`${authApi}bankfilter/?companydetails=${this.state.companyid}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          bank: json,
        });
      });
  };

  Projectdetails = async () => {
    const response = await fetch(
      `${authApi}projectfilter/?status__in=Initiate%2CPending%2CInProgress`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    );
    let body = await response.json();
    this.setState({
      ProjectnameDetails: body,
    });
  };

  checkTaxYear = () => {
    return fetch(`${authApi}taxyear/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    }).then((res) => res.json());
  };

  getUserid = (user) => {
    fetch(`${authApi}userfilter/?name=${user}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          userid: json[0].id,
        });
      });
  };

  Storeprintername = () => {
    this.state.items.forEach((i) => {
      if (i.userprofile === "Printer") {
        this.setState({
          PrinterName: i.name,
        });
      }
    });
  };

  checkprinting = () => {
    if (
      this.state.Clientname === "General-Printing" ||
      this.state.Clientname === "General"
    ) {
      this.setState({
        enableprinting: 1,
      });
    }
  };

  CompanyDetails = () => {
    fetch(`${authApi}companyfilter/?isactive=1`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          companydetails: json,
          companyid: json[0].id,
          company_name: json[0].companyname,
        });
      });
  };

  filtereditclient = () => {
    if (this.state.id_value !== "" && this.state.id_value !== undefined) {
      let clientdata = this.props.clientdetails.filter((clientdata) => {
        return clientdata.id === this.state.id_value;
      });
      this.setState(
        {
          current_id_user: clientdata,
        },
        function () {
          this.updatenickname();
        }
      );
    }
  };

  PlanTypeFunction = () => {
    fetch(`${authApi}Plans/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          plan: json,
        });
      });
  };

  Userdetails = () => {
    fetch(`${authApi}userfilter/?isdeleted=false`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        const details = json.sort((a, b) => a.userid - b.userid);
        this.setState({
          user_alldetails: details,
        });
      });
  };

  HandleChangeTask = (event) => {
    const b = event.target.value;
    this.setState({
      ChangeTask: b,
    });
  };

  HandleChangeTime = (event) => {
    this.setState({
      ChangeTime: event.target.value,
    });
  };

  HandleChangeAssign = (event) => {
    const c = event.target.value;
    this.setState({
      ChangeAssign: c,
    });
  };

  HandleUser = (event) => {
    const user = event.target.value;
    this.setState({
      Username: user,
    });
  };

  HandleRate = (event) => {
    this.setState({
      Rate: event.target.value,
    });
  };

  HandleAmount = (Rate, ChangeTime) => {
    this.setState({
      amount: ChangeTime * Rate,
    });
  };

  HandleChangePriority = (event) => {
    const d = event.target.value;
    this.setState({
      ChangePriority: d,
    });
  };

  HandleChangeStart = (event) => {
    const e = event.target.value;
    this.setState({
      ChangeStart: e,
    });
  };

  HandleChangeEnd = (event) => {
    const f = event.target.value;
    this.setState({
      ChangeEnd: f,
    });
  };

  HandleChangeStatus = (event) => {
    const g = event.target.value;
    this.setState({
      ChangeStatus: g,
    });
  };

  Formvalidation = async () => {
    if (this.state.id_value !== "") {
      if (this.state.ChangePlanType !== "") {
        if (this.state.subclient !== "") {
          if (this.state.company_name !== "") {
            this.setState({
              isButtonDisabled: true,
            });
            await this.UpdationRow();
          } else {
            this.setState({
              companyborder: "1px solid #fb6340",
              subclientborder: "1px solid #dee2e6",
              clientidborder: "1px solid #dee2e6",
              palntypeborder: "1px solid #dee2e6",
            });
          }
        } else {
          this.setState({
            subclientborder: "1px solid #fb6340",
            clientidborder: "1px solid #dee2e6",
            palntypeborder: "1px solid #dee2e6",
          });
        }
      } else {
        this.setState({
          palntypeborder: "1px solid #fb6340",
          clientidborder: "1px solid #dee2e6",
        });
      }
    } else {
      this.setState({
        clientidborder: "1px solid #fb6340",
      });
    }
  };

  UpdationRow = async () => {
    let datetime_field = getDateTimeField();
    await this.subclientUpdation();
    const date = this.state.ChangeStart;
    const task = this.state.ChangeTask;
    const assignto = this.state.ChangeAssign;
    const periority = this.state.ChangePriority;
    const startdate = this.state.ChangeStart;
    const enddate = this.state.ChangeEnd;
    const time = this.state.ChangeTime;
    const isdeleted = this.state.ChangeIsdeleted;
    await axios({
      method: "post",
      url: `${authApi}Tasklist/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        date: date,
        task: task,
        assignto: assignto,
        priority: periority,
        startdate: startdate,
        enddate: enddate,
        status: "Completed",
        time: time,
        isdeleted: isdeleted,
        plantype: this.state.ChangePlanType,
        subclient: this.state.subclient,
        task_starttime: "",
        client: this.state.id_value,
        file_path: this.state.file_path,
        completed_date: this.state.completed_date,
        advanceamount: this.state.advanceamount,
        phonenumber: this.state.phonenumber,
        clientname: this.state.Clientname,
        drawingtitle: this.state.drawingTitle,
        parenttaskid: "",
        parenttaskbalance: 0,
        company: this.state.companyid,
        companyname: this.state.company_name,
        taskproject: this.props.Editdata.id,
        createdby: this.props.username,
        createdat: datetime_field,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          this.setState(
            {
              Maintaskid: res.data.id,
            },
            async function () {
              await this.SubtaskCreate(res.data.id);
              await this.printingDetails(res.data.id);
              await this.projectAmountCalculation(res.data.id);
              await this.updateprojectamountcalculation();
              await this.backtoInvoice();
            }
          );
        }
      })
      .catch((err) => {
        consoleFunction("Task updation error", err.response);
        this.props.history.push("/admin/myday");
        this.setState({ error: err.response.request.response });
      });
  };

  projectAmountCalculation = async (taskid) => {
    await axios({
      method: "post",
      url: `${authApi}projecttaskamountcalculation/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        taskid: taskid,
      },
    });
  };

  updateprojectamountcalculation = () => {
    axios({
      method: "post",
      url: `${authApi}sp_updateprojecttotalamount/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        projectid: this.props.Editdata.id,
      },
    })
      .then((res) => {
        if (res.status === 201) {
        }
      })
      .catch((err) => {
        this.setState({ error: err.response.request.response });
      });
  };

  SubtaskCreate = async (id) => {
    if (this.state.Addrow.length !== 0) {
      await axios({
        method: "post",
        url: `${authApi}sp_subtasktimecreation/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          subtask_data: this.state.Addrow,
          taskid: id,
        },
      })
        .then((res) => {
          if (res.status === 200) {
            console.log(res.status);
          }
        })
        .catch((err) =>
          consoleFunction(
            "Subtask Creation Error",
            err.response.request.response
          )
        );
    }
  };

  printingDetails = async (id) => {
    if (this.state.print_items.length !== 0) {
      await axios({
        method: "post",
        url: `${authApi}sp_printingstockinvoice/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          prinding_details: this.state.print_items,
          task_id: id,
        },
      }).then((res) => {
        console.log(res);
      });
    }
  };

  subclientUpdation = async () => {
    let SubName = this.state.subclient;
    let nameSub = this.state.subclient_details.filter(function (item) {
      return item.name === SubName;
    });
    if (nameSub.length === 0) {
      let datetime_field = getDateTimeField();
      await axios({
        method: "post",
        url: `${authApi}subclients/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          name: this.state.subclient,
          phoneno: this.state.Phonenum,
          clients: this.state.id_value,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      });
    }
  };

  selectClient_Name = async (event) => {
    if (
      event.target.value === "General-Printing" ||
      this.state.Clientname === "General"
    ) {
      let startdate = moment(this.state.todaydate).format("YYYY-MM-DD");
      let enddate = moment(this.state.todaydate).format("YYYY-MM-DD");
      this.setState({
        enableprinting: 1,
        ChangeStart: startdate,
        ChangeEnd: enddate,
      });
    } else {
      this.setState({
        enableprinting: 0,
      });
    }
    const currenclient = event.target.value.replace(/ /g, "%20");
    let currentclientdata = this.props.clientdetails.filter((clientdata) => {
      return clientdata.company_name === currenclient;
    });
    this.setState(
      {
        select_clientName: currentclientdata,
        Clientname: event.target.value,
      },
      function () {
        this.updateClientID();
      }
    );
  };

  updateClientID = () => {
    let plantype = this.state.ChangePlanType.substr(0, 2);
    this.state.select_clientName.map((item) => {
      this.setState(
        {
          Client_id: item.clientid,
          subclient_details: item.clients,
          id_value: item.id,
          ChangeTask:
            this.state.Client_id.toString() +
            plantype +
            "_" +
            this.state.subclient +
            "_" +
            this.state.todaydate,
        },
        function () {
          this.TaskUpdate();
        }
      );
      return this.state.Client_id;
    });
  };

  selectplantype = (event) => {
    let plantype = this.state.ChangePlanType.substr(0, 2);
    this.state.plan.map((item) => {
      if (item.planname === this.state.ChangePlanType) {
        this.setState({
          planId: item.id,
          ChangeTask:
            this.state.Client_id.toString() +
            plantype +
            "_" +
            this.state.subclient +
            "_" +
            this.state.todaydate,
        });
      }
      return this.state.plan;
    });
    this.setState(
      {
        ChangePlanType: event.target.value,
      },
      function () {
        this.TaskUpdate();
      }
    );
  };

  selectid = (event) => {
    if (event.target.value === "00300" || event.target.value === "00100") {
      let startdate = moment(this.state.todaydate).format("YYYY-MM-DD");
      let enddate = moment(this.state.todaydate).format("YYYY-MM-DD");
      this.setState({
        enableprinting: 1,
        ChangeStart: startdate,
        ChangeEnd: enddate,
      });
    } else {
      this.setState({
        enableprinting: 0,
      });
    }
    let currentclient = this.props.clientdetails.filter((clientdata) => {
      return clientdata.clientid === event.target.value;
    });
    this.setState(
      {
        selected_client_details: currentclient,
      },
      function () {
        this.SelectClient_nickname();
      }
    );
    this.setState({
      Client_id: event.target.value,
    });
  };

  SelectClient_nickname = () => {
    let plantype = this.state.ChangePlanType.substr(0, 2);
    this.state.selected_client_details.map((item) => {
      this.setState(
        {
          Clientname: item.company_name,
          subclient_details: item.clients,
          id_value: item.id,
          ChangeTask:
            this.state.Client_id.toString() +
            plantype +
            "_" +
            this.state.subclient +
            "_" +
            this.state.todaydate,
        },
        function () {
          this.TaskUpdate();
        }
      );
      return this.state.Clientname;
    });
  };

  updatenickname = () => {
    this.state.current_id_user.map((item) => {
      if (item.clientid === "00300") {
        this.setState({
          Clientname: item.company_name,
          Client_id: item.clientid,
          enableprinting: 1,
        });
      } else {
        this.setState({
          Clientname: item.company_name,
          Client_id: item.clientid,
        });
      }
      return this.state.Clientname;
    });
  };

  handlesubclient = (event) => {
    this.setState(
      {
        subclient: event.target.value,
        clientname: event.target.value,
        Phonenum: "",
        phonenumber: "",
      },
      function () {
        this.PhonenumberUpd();
        this.TaskUpdate();
      }
    );
  };

  PhonenumberUpd = () => {
    this.state.subclient_details.forEach((ph) => {
      if (ph.name === this.state.subclient) {
        this.setState({
          Phonenum: ph.phoneno,
          phonenumber: ph.phoneno,
        });
      }
    });
  };

  TaskUpdate = () => {
    let plantype = this.state.ChangePlanType.substr(0, 2);
    let task =
      this.state.Client_id.toString() +
      plantype +
      "_" +
      this.state.subclient +
      "_" +
      this.state.drawingTitle +
      "_" +
      this.state.todaydate;
    this.setState({
      ChangeTask: task,
    });
  };

  HandleAdvanceAmount = (event) => {
    this.setState({
      advanceamount: event.target.value,
    });
  };

  HandlePhonenum = (event) => {
    this.setState({
      Phonenum: event.target.value,
      phonenumber: event.target.value,
    });
  };

  HandleDrawingTitle = (event) => {
    this.setState(
      {
        drawingTitle: event.target.value,
      },
      function () {
        this.TaskUpdate();
      }
    );
  };

  HandleCompany = (event) => {
    event.persist();
    this.setState({
      company_name: event.target.value,
    });
    fetch(`${authApi}companyfilter/?companyname=${event.target.value}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.length !== 0) {
          this.setState({
            companyid: json[0].id,
          });
        }
      });
  };

  Printingdeails = (event) => {
    let currentclient = this.props.clientdetails.filter((clientdata) => {
      return clientdata.company_name === "General-Printing";
    });
    if (this.state.checked === false) {
      let startdate = moment(this.state.todaydate).format("YYYY-MM-DD");
      let enddate = moment(this.state.todaydate).format("YYYY-MM-DD");
      this.setState(
        {
          checked: true,
          Client_id: "00300",
          ChangePlanType: "18Printing",
          Clientname: "General-Printing",
          enableprinting: 1,
          ChangeAssign: this.state.PrinterName,
          ChangeStart: startdate,
          ChangeEnd: enddate,
          subclient_details: currentclient[0].clients,
          id_value: currentclient[0].id,
          ChangeDate: moment(this.state.todaydate).format("YYYY-MM-DD"),
          ChangeTime: "00:00",
          completed_date: null,
          file_path: "",
          advanceamount: "0",
        },
        function () {
          this.updateprintingTaskname();
        }
      );
    } else {
      let startdate = moment(this.state.todaydate).format("YYYY-MM-DD");
      let enddate = moment(this.state.todaydate).format("YYYY-MM-DD");
      this.setState({
        checked: false,
        Client_id: "",
        ChangePlanType: "",
        Clientname: "",
        enableprinting: 0,
        ChangeStart: startdate,
        ChangeEnd: enddate,
        subclient_details: [],
        ChangeAssign: this.props.assigneeName,
        ChangePriority: "Medium",
        ChangeStatus: "ToDo",
        drawingTitle: "",
        subclient: "",
        Phonenum: "",
        ChangeTask: "",
      });
    }
  };

  updateprintingTaskname = () => {
    this.setState({
      ChangeTask:
        this.state.Client_id.toString() +
        this.state.ChangePlanType.substr(0, 2) +
        "_" +
        this.state.subclient +
        "_" +
        this.state.drawingTitle +
        "_" +
        this.state.todaydate,
    });
  };

  ChangeProjectname = (event) => {
    this.setState({
      Projectname: event.target.value,
    });
    this.state.ProjectnameDetails.forEach((element) => {
      if (element.projectname === event.target.value) {
        this.setState({
          ProjectId: element.id,
        });
      }
    });
  };

  backtoPosition = () => {
    this.props.history.push("/admin/myday");
  };

  SubtasktimeTime = (event) => {
    let times = event.target.value.split(":");
    let valid = true;
    if (parseInt(times[1]) > 59) {
      valid = false;
    }
    if (times.length === 2 && valid === true) {
      this.setState({
        time: event.target.value,
        border: "1px solid #dee2e6",
      });
    } else {
      this.setState({
        time: event.target.value,
        border: "1px solid #fb6340",
      });
    }
  };

  handleEnter = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    } else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  handleAddRow = () => {
    let addrow = this.state.Addrow;
    let new_row = {
      id: draftingid + 1,
      user: "",
      unit: "00:00",
      rate: "0",
      amount: "0",
      border: "",
    };
    addrow = [...addrow, new_row];
    this.setState({
      Addrow: [],
    });
    let sortedRowdata = addrow.sort((a, b) => (a.id > b.id ? 1 : -1));
    this.setState({
      Addrow: sortedRowdata,
    });
    draftingid = draftingid + 1;
  };

  updateDraftingdetails = (id, user, unit, rate, amount) => {
    let usernamealreadypresent = 0;
    this.state.Addrow.forEach((element) => {
      if (element.id === id) {
        element.user = user;
        element.amount = amount;
        element.rate = rate;
        element.unit = unit;
      }

      if (element.user === user) {
        usernamealreadypresent = usernamealreadypresent + 1;
      }
    });
    if (usernamealreadypresent === 2) {
      this.state.Addrow.forEach((element) => {
        if (element.user === user) {
          element.border = "1px solid red";
        }
      });
    }
    this.updateTotal();
  };

  updateTotal = () => {
    let drafting_total = 0,
      printing_total = 0;
    this.state.Addrow.forEach((element) => {
      drafting_total = drafting_total + parseInt(element.amount);
    });
    this.state.print_items.forEach((element) => {
      printing_total = printing_total + parseInt(element.amount);
    });
    this.setState({
      PaymentMode: "Cash",
    });
  };

  deleteDraftingrow = (i) => {
    const DelData = this.state.Addrow.filter((item) => item.id !== i.id);
    this.setState(
      {
        Addrow: DelData,
        countrefresh: draftingid - 1,
        ChangeAssign: this.state.ChangeAssign,
      },
      function () {
        this.updateboder(i.user);
      }
    );
  };

  updateboder = (name) => {
    let data = this.state.Addrow;
    data.forEach((element) => {
      if (element.user === name) {
        element.border = "";
      }
    });
    this.setState({
      Addrow: data,
    });
  };

  Update_details = (DelData) => {
    this.setState({
      Addrow: DelData,
    });
  };

  handlePrintingAddRow = () => {
    const item = {
      id: this.state.id + 1,
      types: "",
      unit: "",
      availablequantity: "",
      quantity: "",
      rate: "",
      amount: "",
      tasklistrow: "",
    };
    this.setState({
      id: item.id,
      print_items: [...this.state.print_items, item],
    });
  };

  ChangeTodoValue = (x) => {
    this.setState(
      (prevState) => {
        const updatedTodoData = prevState.print_items.map((todo) => {
          if (todo.id === x.id) {
            todo.id = x.id;
            todo.types = x.stockname;
            todo.rate = x.defaultRate;
            todo.availablequantity = x.availablequantitys;
            todo.unit = x.units;
            todo.quantity = x.quantitys;
            todo.amount = x.amount;
          }
          return todo;
        });
        return {
          print_items: updatedTodoData,
        };
      },
      function () {
        this.updateTotal();
        this.CalculateStockvalue(this.state.print_items);
      }
    );
  };

  CalculateStockvalue = (x) => {
    let StockTotal = 0;
    x.forEach((i) => {
      StockTotal = StockTotal + parseInt(i.amount);
    });
    this.setState({
      allStockTotal: StockTotal,
    });
  };

  handleRemoveRow = (i) => {
    const DelData = this.state.print_items.filter((item) => item.id !== i.id);
    this.setState({
      print_items: [...DelData],
    });
  };

  ChangeReceivedAmount = (event) => {
    this.setState({
      received_amount: event.target.value,
    });
  };
  backtoInvoice = () => {
    this.props.backtodetails();
  };

  render() {
    const AddRowDetails = [...this.state.print_items].map((item) => (
      <PrintingDetailsRow
        key={item.id}
        id={item.id}
        types={item.types}
        unit={item.unit}
        quantity={item.quantity}
        availablequantity={item.availablequantity}
        rate={item.rate}
        amount={item.amount}
        tasklistrow={item.tasklistrow}
        addRowstock={this.ChangeTodoValue}
        deleteRow={this.handleRemoveRow}
      />
    ));
    const Userlist = [...this.state.Addrow].map((user) => (
      <EditProjectInvoiceRowNew
        userdetails={this.state.items}
        id={user.id}
        user={user.user}
        unit={user.unit}
        rate={user.rate}
        amount={user.amount}
        border={user.border}
        data={user}
        draftingdetails={this.updateDraftingdetails}
        deleteDraftingrowdetails={this.deleteDraftingrow}
        refresh={this.state.countrefresh}
      />
    ));
    return (
      <React.Fragment>
        <Container className="mt--7" fluid>
          <br />
          <form>
            <Row>
              <Col md="11">
                <CardHeader
                  style={{
                    backgroundColor: "transparent",
                    padding: 10,
                    marginTop: 20,
                  }}
                >
                  <h3 className="mb-0">
                    Add New Task for {this.state.chanageproject}
                  </h3>
                </CardHeader>
              </Col>
              <Col md="1">
                <button
                  className="btn btn-primary btn-sm"
                  style={{ marginTop: 25, width: 65, height: 40 }}
                  data-tip
                  data-for="completed-Tip"
                  onClick={this.backtoInvoice}
                >
                  Back
                </button>
              </Col>
            </Row>
          </form>
          <div style={{ marginTop: 30 }}>
            <div className="Scroll-Content" style={{ marginTop: 10 }}>
              <Row style={{ marginTop: 10 }}>
                <form style={{ width: "100%" }}>
                  <Col xl="12">
                    <Row>
                      <Col md="2">
                        <label className="form-control-label">PrintTask</label>
                        <br />
                        <label
                          style={{
                            textAlign: "center",
                            marginLeft: 30,
                            marginTop: 2,
                          }}
                        >
                          <ToggleSwitch
                            offColor="#ff1a1a"
                            onColor="#00cc00"
                            checked={this.state.checked}
                            onChange={this.Printingdeails}
                          />
                          {this.state.checked === true ? (
                            <h6>On</h6>
                          ) : (
                            <h6>Off</h6>
                          )}
                        </label>
                      </Col>
                      <Col md="2">
                        <label className="form-control-label">Client Id</label>
                        <Input
                          type="option"
                          list="id_value-list"
                          className="form-control-alternative"
                          value={this.state.Client_id}
                          style={{ border: this.state.clientidborder }}
                          onChange={this.selectid}
                          onKeyDown={this.handleEnter}
                          autoFocus="autofocus"
                        />
                        <datalist id="id_value-list">
                          {this.state.client_alldetails.map((item, key) => (
                            <option key={key} value={item.clientid} />
                          ))}
                        </datalist>
                      </Col>
                      <Col md="4">
                        <label className="form-control-label">
                          Client Name
                        </label>
                        <Input
                          type="option"
                          list="nickname-list"
                          className="form-control-alternative"
                          defaultValue={this.state.Clientname}
                          style={{ border: this.state.clientidborder }}
                          onChange={this.selectClient_Name}
                          onKeyDown={this.handleEnter}
                        />
                        <datalist id="nickname-list">
                          {this.state.client_alldetails.map((item, name) => (
                            <option key={name} value={item.company_name} />
                          ))}
                        </datalist>
                        {this.state.clientidborder !== "1px solid #dee2e6" ? (
                          <h5 style={{ color: "#fb6340" }}>
                            Please select name
                          </h5>
                        ) : null}
                      </Col>
                      <Col md="4">
                        <label className="form-control-label">AssignTo</label>
                        <Input
                          type="select"
                          className="form-control-alternative"
                          style={{ border: "1px solid #dee2e6" }}
                          value={this.state.ChangeAssign}
                          onChange={this.HandleChangeAssign}
                          onKeyDown={this.handleEnter}
                        >
                          <option>Select</option>
                          {this.state.items.map((obj, assign) => (
                            <option key={assign} value={obj.name}>
                              {obj.name}
                            </option>
                          ))}
                        </Input>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: -5 }}>
                      <Col md="4">
                        <label className="form-control-label">Plan type</label>
                        <Input
                          type="option"
                          list="models-list"
                          className="form-control-alternative"
                          value={this.state.ChangePlanType}
                          style={{ border: this.state.palntypeborder }}
                          onChange={this.selectplantype}
                          onKeyDown={this.handleEnter}
                        />
                        <datalist id="models-list">
                          {this.state.plan.map((item, plan) => (
                            <option
                              key={plan}
                              value={item.planname}
                              newvalue={item.id}
                            />
                          ))}
                        </datalist>
                        {this.state.palntypeborder !== "1px solid #dee2e6" ? (
                          <h5 style={{ color: "#fb6340" }}>
                            Please select plantype
                          </h5>
                        ) : null}
                      </Col>
                      <Col md="4">
                        <label className="form-control-label">Subclient</label>
                        <Input
                          type="option"
                          list="subclient-list"
                          className="form-control-alternative"
                          style={{ border: this.state.subclientborder }}
                          value={this.state.subclient}
                          onChange={this.handlesubclient}
                          onKeyDown={this.handleEnter}
                        ></Input>
                        <datalist id="subclient-list">
                          {this.state.subclient_details.map((item, name) => (
                            <option key={name} value={item.name} />
                          ))}
                        </datalist>
                        {this.state.subclientborder !== "1px solid #dee2e6" ? (
                          <h5 style={{ color: "#fb6340" }}>
                            Please enter subclient
                          </h5>
                        ) : null}
                      </Col>
                      <Col md="2">
                        <label className="form-control-label">
                          Phonenumber
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          style={{ border: "1px solid #dee2e6" }}
                          value={this.state.Phonenum}
                          onChange={this.HandlePhonenum}
                          onKeyDown={this.handleEnter}
                        />
                      </Col>
                      <Col md="2">
                        <label className="form-control-label">
                          ProjectAmount
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          style={{ border: "1px solid #dee2e6" }}
                          value={this.props.projecttotalamount}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="2">
                        <label className="form-control-label">Status</label>
                        <Input
                          type="select"
                          className="form-control-alternative"
                          style={{ border: "1px solid #dee2e6" }}
                          defaultValue={this.state.ChangeStatus}
                          onChange={this.HandleChangeStatus}
                          onKeyDown={this.handleEnter}
                        >
                          <option value="0">Select</option>
                          <option value="ToDo">ToDo</option>
                          <option value="Pending">Pending</option>
                          <option value="InProgress">InProgress </option>
                          <option value="Completed">Completed</option>
                        </Input>
                      </Col>
                      <Col md="2">
                        <label className="form-control-label">Priority</label>
                        <Input
                          type="select"
                          className="form-control-alternative"
                          style={{ border: "1px solid #dee2e6" }}
                          defaultValue={this.state.ChangePriority}
                          onChange={this.HandleChangePriority}
                          onKeyDown={this.handleEnter}
                        >
                          <option value="0">Select</option>
                          <option value="Low">Low</option>
                          <option value="Medium">Medium</option>
                          <option value="High">High</option>
                        </Input>
                      </Col>
                      <Col md="4">
                        <label className="form-control-label">
                          Drawing Title
                        </label>
                        <Input
                          className="form-control-alternative"
                          type="text"
                          style={{ border: "1px solid #dee2e6" }}
                          value={this.state.drawingTitle}
                          onChange={this.HandleDrawingTitle}
                          onKeyDown={this.handleEnter}
                        />
                      </Col>
                      <Col md="4">
                        <label className="form-control-label">End Date</label>
                        <Input
                          className="form-control-alternative"
                          type="date"
                          style={{ border: "1px solid #dee2e6" }}
                          defaultValue={this.state.ChangeEnd}
                          onChange={this.HandleChangeEnd}
                          onKeyDown={this.handleEnter}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col md="4">
                        <label className="form-control-label">Company</label>
                        <Input
                          type="select"
                          className="form-control-alternative"
                          value={this.state.company_name}
                          style={{ border: this.state.companyborder }}
                          onChange={this.HandleCompany}
                          onKeyDown={this.handleEnter}
                        >
                          <option value="">Select Company</option>
                          {this.state.companydetails.map((item, key) => (
                            <option key={key} value={item.companyname}>
                              {item.companyname}
                            </option>
                          ))}
                        </Input>
                        {this.state.companyborder !== "1px solid #dee2e6" ? (
                          <h5 style={{ color: "#fb6340" }}>
                            Please select companyname
                          </h5>
                        ) : null}
                      </Col>
                      <Col md="4">
                        <label className="form-control-label">Task Name</label>
                        <Input
                          type="option"
                          list="id_value-lists"
                          style={{ border: "1px solid #dee2e6" }}
                          className="form-control-alternative"
                          value={this.state.ChangeTask}
                          onChange={this.HandleChangeTask}
                          onKeyDown={this.handleEnter}
                        />
                        <datalist id="id_value-lists">
                          {this.state.pgcaddTaskNames.map((item, key) => (
                            <option key={key} value={item.task} />
                          ))}
                        </datalist>
                      </Col>
                      <Col md="4">
                        <label className="form-control-label">
                          Project Name
                        </label>
                        <Input
                          type="option"
                          list="id_value-projectlist"
                          className="form-control-alternative"
                          value={this.state.chanageproject}
                          onKeyDown={this.handleEnter}
                        />
                      </Col>
                    </Row>
                  </Col>
                </form>
              </Row>
              <Row style={{ marginTop: 20 }}>
                <div className=" col" style={{ width: 90 }}>
                  <Row>
                    <div className=" col">
                      <br />
                      <Card className="shadow">
                        <h3>Drafting Details</h3>
                        <Table
                          className="align-items-center table-flush"
                          responsive-sm
                        >
                          <thead className="thead-light">
                            <tr>
                              <th className="text-center">USER</th>
                              <th className="text-center">UNIT</th>
                              <th className="text-center">RATE</th>
                              <th className="text-center">AMOUNT</th>
                              <th className="text-center">ACTION</th>
                            </tr>
                          </thead>
                          <tbody>{Userlist}</tbody>
                        </Table>
                        <div>
                          <button
                            className="btn btn-primary btn-sm"
                            onClick={this.handleAddRow}
                          >
                            <i className="fas fa-plus-circle fa-1x"></i>
                          </button>
                        </div>
                      </Card>
                    </div>
                  </Row>

                  <Card className="shadow">
                    <h3>Printing Details</h3>
                    <Table
                      className="align-items-center table-flush"
                      responsive
                    >
                      <thead className="thead-light">
                        <tr
                          style={{
                            borderBottomWidth: 1,
                            borderBottomStyle: "solid",
                          }}
                        >
                          <th className="text-center"> Types </th>
                          <th className="text-center"> Unit </th>
                          <th className="text-center"> Quantity </th>
                          <th className="text-center">Inv Qty</th>
                          <th className="text-center"> Rate </th>
                          <th className="text-center"> Amount </th>
                          <th className="text-center"> Action </th>
                        </tr>
                      </thead>
                      <tbody>{AddRowDetails}</tbody>
                    </Table>
                    <div>
                      <button
                        className="btn btn-primary btn-sm"
                        onClick={this.handlePrintingAddRow}
                      >
                        <i className="fas fa-plus-circle fa-1x"></i>
                      </button>
                    </div>
                  </Card>
                  <Row>
                    <Col md="11"></Col>
                    <Col md="1">
                      <button
                        id="buttonsave"
                        className="btn btn-dark "
                        onClick={this.Formvalidation}
                        disabled={this.state.isButtonDisabled}
                      >
                        Create
                      </button>
                    </Col>
                  </Row>
                </div>
              </Row>
            </div>
          </div>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails: state.clientdetails,
    allusers: state.allusers,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(CreateNewProjectTask);
