import React, { useEffect, useState, useMemo } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  FormGroup,
  Table,
  InputGroupAddon,
  InputGroupText,
  Input,
  InputGroup,
  NavLink,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
} from "reactstrap";
import moment from "moment";
import Popup from "reactjs-popup";
import TableContainerExpand from "../Filters/TableContainerExpand";
import TableContainerExpandTeam from "../Filters/TableContainerExpandTeam";
import { SelectColumnFilter } from "../Filters/filters";
import ConPreLoader from "../../../components/Loader/Con_PreLoader";
import { connect } from "react-redux";
import config from "react-global-configuration";
import "../../../config";
import ClientReportsAppDF from "./DetailedForm/ClientReportsApp";
import ClientReportsAppCF from "./ConsolidateForm/ClientReportsApp";
import ReactTooltip from "react-tooltip";
import DateRangePicker from "react-bootstrap-daterangepicker";
import "bootstrap-daterangepicker/daterangepicker.css";
import Header from "../../../components/Headers/SimpleHeader";
import { CurrentLocation } from "../../../components/loginRedux/loginReducers";
import store from "../../../components/loginRedux/Store";

const authApi = config.get("apiDomain");
let sdToday = new Date();
let Date1 = new Date(sdToday.getFullYear(), sdToday.getMonth(), 1);
let Date2 = new Date(sdToday.getFullYear(), sdToday.getMonth() + 1, 0);

function ReportGenerator(props) {
  const [data, setData] = useState([]);
  const [q, setQ] = useState("");
  const [loading, setLoading] = useState(true);
  const [checked, setChecked] = useState(true);
  const [dateRangeSt, setDateRangeSt] = useState(new Date());
  const [dateRangeEd, setDateRangeEd] = useState(new Date());
  const [btnClass, setBtnClass] = useState("btn btn-danger btn-sm");

  useEffect(
    () => {
      const doFetch = async () => {
        store.dispatch({
          type: CurrentLocation,
          data: props.location.pathname,
        });
        let PresentDay = new Date();
        let LastPresentDay = new Date(
          PresentDay.getFullYear(),
          PresentDay.getMonth(),
          PresentDay.getDate() - 31
        );
        let D1 = moment(PresentDay).format("YYYY-MM-DD");
        let D2 = moment(LastPresentDay).format("YYYY-MM-DD");
        const response = await fetch(
          `${authApi}tasklistfilter/?isdeleted=false&startdate__gte=${D2}&startdate__lte=${D1}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const a = await response.json();
        const response1 = await fetch(
          `${authApi}tasklistfilter/??isdeleted=false&status__in=ToDo%2CPending%2CInProgress%2CReadyToInvoice%2CReadyToBill&startdate__lte=${D2}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const b = await response1.json();
        const c = b;
        const body = [...a, ...c];
        if (body.length !== 0) {
          CalculateTaskTime(body.reverse());
          callback(body);
        } else {
          setLoading(false);
          alert("No task available!");
        }
      };
      doFetch();
    },
    // eslint-disable-next-line
    []
  );

  function CalculateTaskTime(body) {
    body.forEach((e) => {
      e.time = calculateSubtaskTime(e);
    });
  }

  function calculateSubtaskTime(e) {
    let timeSub = "00:00";
    let timetotal = 0,
      mins = 0,
      times = 0,
      stringtime = 0,
      h = 0,
      m = 0;

    e.subtask.forEach((st) => {
      if (st.isdeleted === false) {
        st.time = calculateSecondLayerSubtaskTime(st);
        if (st.time === 0) {
          timeSub = "00:00";
        } else {
          stringtime = st.time;
          times = stringtime.split(":");
          mins = times[0] * 60 + +times[1];
          timetotal += mins;
          h = ((timetotal / 60) | 0) % 50;
          m = timetotal % 60;
          function z(n) {
            return (n < 10 ? "0" : "") + n;
          }
          timeSub = z(h) + ":" + z(m);
        }
      }
    });
    return timeSub;
  }

  function calculateSecondLayerSubtaskTime(st2) {
    let total = 0,
      mins = 0,
      times = 0,
      stringtime = 0,
      h = 0,
      m = 0,
      subTime = "00:00";
    st2.subtaskTime.forEach((d) => {
      stringtime = d.time;
      times = stringtime.split(":");
      mins = times[0] * 60 + +times[1];
      total += mins;
      h = ((total / 60) | 0) % 50;
      m = total % 60;
      function z(n) {
        return (n < 10 ? "0" : "") + n;
      }
      subTime = z(h) + ":" + z(m);
    });
    return subTime;
  }

  function callback(body) {
    const details = body.sort((a, b) => b.status.localeCompare(a.status));
    setData(details);
    setLoading(false);
  }

  function handleDateRangeCallback(event, picker) {
    Date1 = picker.startDate._d.toISOString();
    Date2 = picker.endDate._d.toISOString();
    setLoading(true);
    const sd = picker.startDate;
    const ed = picker.endDate;
    setDateRangeSt(picker.startDate);
    setDateRangeEd(picker.endDate);
    let a_sd = moment(sd).format("YYYY-MM-DD");
    let b_ed = moment(ed).format("YYYY-MM-DD");
    fetch(
      `${authApi}tasklistfilter/?startdate__gte=${a_sd}&startdate__lte=${b_ed}&isdeleted=false`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((body) => {
        if (body.length === 0) {
          alert("No Task available for the Selected Filter.!");
          setData([]);
          setLoading(false);
        } else {
          CalculateTaskTime(body.reverse());
          callback(body);
        }
      });
  }

  function handleFilter(event) {
    if (event.target.value === "all") {
      const doFetch = async () => {
        setLoading(true);
        let PresentDay = new Date();
        let LastPresentDay = new Date(
          PresentDay.getFullYear(),
          PresentDay.getMonth(),
          PresentDay.getDate() - 31
        );
        let D1 = moment(PresentDay).format("YYYY-MM-DD");
        let D2 = moment(LastPresentDay).format("YYYY-MM-DD");
        const response = await fetch(
          `${authApi}tasklistfilter/?isdeleted=false&startdate__gte=${D2}&startdate__lte=${D1}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const a = await response.json();
        const response1 = await fetch(
          `${authApi}tasklistfilter/?isdeleted=false&status__in=ToDo%2CPending%2CInProgress%2CReadyToInvoice%2CReadyToBill&startdate__lte=${D2}`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const b = await response1.json();
        const c = b;
        const body = [...a, ...c];
        CalculateTaskTime(body.reverse());
        callback(body);
      };
      doFetch();
    }
    if (event.target.value === "today") {
      const doFetch = async () => {
        setLoading(true);
        let today = new Date();
        let now = moment(today).format("YYYY-MM-DD");
        const response = await fetch(
          `${authApi}tasklistfilter/?startdate=${now}&isdeleted=false`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const body = await response.json();
        if (body.length === 0) {
          alert("No Task available for the Selected Filter.!");
          setData([]);
          setLoading(false);
        } else {
          CalculateTaskTime(body.reverse());
          callback(body);
        }
      };
      doFetch();
    }

    if (event.target.value === "yesterday") {
      const doFetch = async () => {
        setLoading(true);
        let today = new Date();
        let Yesterday = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 1
        );
        let before = moment(Yesterday).format("YYYY-MM-DD");
        const response = await fetch(
          `${authApi}tasklistfilter/?startdate=${before}&isdeleted=false`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const body = await response.json();
        if (body.length === 0) {
          alert("No Task available for the Selected Filter.!");
          setData([]);
          setLoading(false);
        } else {
          CalculateTaskTime(body.reverse());
          callback(body);
        }
      };
      doFetch();
    }
    if (event.target.value === "lastweek") {
      const doFetch = async () => {
        setLoading(true);
        let today = new Date();
        let Lastweek = new Date(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 8
        );
        let now = moment(today).format("YYYY-MM-DD");
        let beforeweek = moment(Lastweek).format("YYYY-MM-DD");
        const response = await fetch(
          `${authApi}tasklistfilter/?startdate__gte=${beforeweek}&startdate__lte=${now}&isdeleted=false`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const body = await response.json();
        CalculateTaskTime(body.reverse());
        callback(body);
      };
      doFetch();
    }
    if (event.target.value === "lastmonth") {
      const doFetch = async () => {
        setLoading(true);
        let today = new Date();
        let Lastmonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          today.getDate()
        );
        let beforemonth = moment(Lastmonth).format("YYYY-MM-DD");
        let now = moment(today).format("YYYY-MM-DD");
        const response = await fetch(
          `${authApi}tasklistfilter/?startdate__gte=${beforemonth}&startdate__lte=${now}&isdeleted=false`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const body = await response.json();
        CalculateTaskTime(body.reverse());
        callback(body);
      };
      doFetch();
    }
    if (event.target.value === "last3months") {
      const doFetch = async () => {
        setLoading(true);
        let today = new Date();
        let Lastmonth = new Date(
          today.getFullYear(),
          today.getMonth() - 3,
          today.getDate()
        );
        let beforemonth = moment(Lastmonth).format("YYYY-MM-DD");
        let now = moment(today).format("YYYY-MM-DD");
        const response = await fetch(
          `${authApi}tasklistfilter/?startdate__gte=${beforemonth}&startdate__lte=${now}&isdeleted=false`,
          {
            method: "GET",
            headers: {
              "content-type": "application/json",
              Authorization: `token ${props.token}`,
            },
          }
        );
        const body = await response.json();
        CalculateTaskTime(body.reverse());
        callback(body);
      };
      doFetch();
    }
  }

  function search(rows) {
    const cols = rows[0] && Object.keys(rows[0]);
    return rows.filter((r) =>
      cols.some((c) =>
        r[c]
          ? r[c].toString().toLowerCase().indexOf(q.toLowerCase()) > -1
          : null
      )
    );
  }

  function getFilterButton(event) {
    if (event === true) {
      setChecked(false);
      setBtnClass("btn btn-success btn-sm");
    } else {
      setChecked(true);
      setBtnClass("btn btn-danger btn-sm");
    }
  }

  const renderRowSecondSubComponent = (row) => {
    return (
      <React.Fragment>
        <br />
        <h6 className="thead-light">Working Details :</h6>
        <Table className="align-items-center table-flush" responsive>
          <thead className="thead-light">
            <tr>
              <th className="text-center">Date</th>
              <th className="text-center">Username </th>
              <th className="text-center">Time</th>
              <th className="text-center">Comments</th>
            </tr>
          </thead>
          {row.original.subtaskTime.map((r, id) => {
            return (
              <tbody key={id}>
                <tr>
                  <td className="text-center">{r.date}</td>
                  <td className="text-center">{r.username}</td>
                  <td className="text-center">{r.time}</td>
                  <td className="text-center">{r.comments}</td>
                </tr>
              </tbody>
            );
          })}
        </Table>
        <br />
      </React.Fragment>
    );
  };

  const columnSecondLayer = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Subtask",
        accessor: "subtask",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Assignto",
        accessor: "assignto",
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Priority",
        accessor: "priority",
      },
    ],
    []
  );

  const renderRowSubComponent = (row) => {
    const rowdata = row.original.subtask.filter(function (item) {
      return item.isdeleted !== true;
    });
    return (
      <React.Fragment>
        <br />
        <h5 className="thead-light">Subtask Details :</h5>
        <TableContainerExpandTeam
          columns={columnSecondLayer}
          data={rowdata}
          renderRowSubComponent={renderRowSecondSubComponent}
          checkedToggle={checked}
        />
        <br />
      </React.Fragment>
    );
  };

  const columns = useMemo(
    () => [
      {
        Header: () => null,
        id: "expander",
        Cell: ({ row }) => (
          <span {...row.getToggleRowExpandedProps()}>
            {row.isExpanded ? (
              <i className="ni ni-bold-down" />
            ) : (
              <i className="ni ni-bold-right" />
            )}
          </span>
        ),
      },
      {
        Header: "Client",
        accessor: "clientname",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Sub Client",
        accessor: "subclient",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "Plan Type",
        accessor: "plantype",
        disableSortBy: true,
      },
      {
        Header: "Task",
        accessor: "task",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
        Cell: (row) => {
          return <span data-tip={row.value}>{row.value}</span>;
        },
      },
      {
        Header: "AssignTo",
        accessor: "assignto",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Status",
        accessor: "status",
        disableSortBy: true,
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Priority",
        accessor: "priority",
        disableSortBy: true,
      },
      {
        Header: "Time",
        accessor: "time",
      },
      {
        Header: "Start Date",
        accessor: "startdate",
      },
      {
        Header: "End Date",
        accessor: "enddate",
      },
      {
        Header: "Completed Date",
        accessor: "completed_date",
      },
    ],
    []
  );

  return (
    <React.Fragment>
      <Header />
      <Container className="mt--7" fluid>
        <br />
        <Row>
          <Form style={{ marginTop: 20, marginLeft: 20 }}>
            <FormGroup className="mb-0">
              <InputGroup className="input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="fas fa-filter" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  style={{
                    height: "35px",
                    overflow: "hidden",
                    padding: "2px 6px 2px",
                    border: "none",
                  }}
                  type="select"
                  className="form-control-alternative"
                  onChange={handleFilter}
                >
                  <option value="all"> Default </option>
                  <option value="today"> Today </option>
                  <option value="yesterday"> Yesterday </option>
                  <option value="lastweek"> Last Week </option>
                  <option value="lastmonth"> Last Month </option>
                  <option value="last3months"> Last 3 Months </option>
                </Input>
              </InputGroup>
            </FormGroup>
          </Form>
          <Col md="3">
            <NavLink style={{ marginTop: 16, marginLeft: -15 }}>
              <DateRangePicker
                autoUpdateInput={false}
                onApply={handleDateRangeCallback}
                initialSettings={{
                  locale: {
                    format: "DD/MM/YYYY",
                  },
                }}
              >
                <button
                  className="form-control-alternative"
                  style={{
                    height: "35px",
                    width: "100%",
                    overflow: "hidden",
                    padding: "2px 2px 2px",
                    backgroundColor: "white",
                    border: "none",
                  }}
                >
                  <i
                    className="fa fa-calendar-o"
                    style={{ paddingRight: 15, opacity: "0.3" }}
                    aria-hidden="true"
                  ></i>
                  {moment(Date1).format("DD/MM/YYYY")} -
                  {moment(Date2).format("DD/MM/YYYY")}
                </button>
              </DateRangePicker>
            </NavLink>
          </Col>
          <Col md="2.5"></Col>
          <Form
            className="searchForm"
            style={{ marginTop: 14, right: "150px" }}
          >
            <FormGroup className="mb-0">
              <input
                className="searchBox "
                type="search"
                placeholder="Search"
                value={q}
                onChange={(e) => setQ(e.target.value)}
              />
            </FormGroup>
          </Form>
          <div
            className="invoicegenerate"
            style={{ position: "absolute", right: "25px", marginTop: 16 }}
          >
            <button
              className={btnClass}
              style={{ width: "135%", height: 40 }}
              onClick={(e) => getFilterButton(checked)}
            >
              <i className="fa fa-filter" aria-hidden="true"></i>
            </button>
          </div>
          <div style={{ position: "absolute", right: "100px", marginTop: 12 }}>
            <UncontrolledDropdown nav>
              <DropdownToggle nav>
                <button
                  className="btn btn-sm btn-dark align-items-center"
                  style={{ width: "135%", height: 40 }}
                >
                  <i className="fa fa-download" aria-hidden="true"></i>
                </button>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-arrow" right>
                <DropdownItem size="sm">
                  <Popup
                    modal
                    data-backdrop="static"
                    trigger={<h5 className="text-center">Detailed Reports</h5>}
                  >
                    {(close) => (
                      <ClientReportsAppDF
                        dateStart={dateRangeSt}
                        dateEnd={dateRangeEd}
                        closebtn={close}
                      />
                    )}
                  </Popup>
                </DropdownItem>
                <DropdownItem size="sm">
                  <Popup
                    modal
                    data-backdrop="static"
                    trigger={
                      <h5 className="text-center">Consolidated Reports</h5>
                    }
                  >
                    {(close) => (
                      <ClientReportsAppCF
                        dateStart={dateRangeSt}
                        dateEnd={dateRangeEd}
                        closebtn={close}
                      />
                    )}
                  </Popup>
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </div>
        </Row>
        <br />
        {loading === true ? (
          <div>
            <ConPreLoader />
          </div>
        ) : (
          <Row>
            <div className=" col">
              <ReactTooltip place="top" type="dark" effect="float" />
              <TableContainerExpand
                columns={columns}
                data={search(data)}
                renderRowSubComponent={renderRowSubComponent}
                checkedToggle={checked}
              />
            </div>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
  };
};
export default connect(mapStateToProps, null)(ReportGenerator);
