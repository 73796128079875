import React from "react";
import {connect} from 'react-redux';
import {Row,Col} from 'reactstrap';

class CancelEdit extends React.Component{
    constructor(props){
        super(props);
        this.setState={

        }
    }

componentDidMount(){
}

cancelPurchase=()=>{
    if(this.props.purchaseorder === true){
     this.props.clearpurchase(this.props.Editdata);
    }
    else{
    this.props.clearpurchase();
   }
}

notCancelPurchase=()=>{
        this.props.closebtn();
}

    render(){
        return(
            <React.Fragment>
              <button className="x" onClick={this.props.closebtn}>
            x
            </button>
            <div className="header">
             Cancel Edit
            </div>
            <Row style={{paddingTop:10}}>
                <Col md="3">
                </Col>
                {this.props.purchaseorder===true ?
                <Col md="7"><h3>Do you want to cancel this purchase order?</h3></Col>
                :<Col  md="7"><h3>Do you want to discard all your changes?</h3></Col>}
                <Col md="2"></Col>
            </Row>
            <Row style={{paddingBottom:10}}>
                <Col md="4"></Col>
                <Col md="1"><button type="button" class="btn btn-outline-success" onClick={this.cancelPurchase}>Yes</button></Col>
                <Col md="2" style={{paddingLeft:50}}><button type="button" class="btn btn-outline-danger" onClick={this.notCancelPurchase}>No</button></Col>
                <Col md="5"></Col>
            </Row>
            </React.Fragment>
        );

    }
}
const mapStateToProps = state => {
    return {
      token:state.token, 
    }
  }
export default connect(mapStateToProps, null)(CancelEdit);