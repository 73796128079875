import React from "react";
import {connect} from "react-redux";
class UserExsitingRequest extends React.Component{
    constructor(props){
        super(props);
        this.state={
            id:this.props.stock.id,
            date:this.props.stock.date,
            stockname:this.props.stock.stockname,
            productcode:this.props.stock.productcode,
            quantity:this.props.stock.quantity,
            purchaseorderid:this.props.stock.purchaseorderid,
            status:this.props.stock.status,
            mainid:this.props.id,
        }
    }

componentWillReceiveProps(nextProps){
    this.setState({
            id:nextProps.stock.id,
            date:nextProps.stock.date,
            stockname:nextProps.stock.stockname,
            productcode:nextProps.stock.productcode,
            quantity:nextProps.stock.quantity,
            purchaseorderid:nextProps.stock.purchaseorderid,
            status:nextProps.stock.status,
            mainid:nextProps.id,
    })
}


    render(){
        return(
            <tr style={{backgroundColor:this.state.mainid===this.state.id ? "#d7eff5":"white"}}>
                <td style={{textAlign:"center"}}>
                <button className = "btn btn-primary btn-sm" style={{marginRight: '5px'}} onClick={()=>this.props.getEditedData(this.props.stock)} >
                    <i className="fa fa-edit" />
                </button>
                </td>
                <td style={{textAlign:"center"}}>{this.state.date}</td>
                <td style={{textAlign:"center"}}>{this.state.stockname}</td>
                <td style={{textAlign:"center"}}>{this.state.productcode}</td>
                <td style={{textAlign:"center"}}>{this.state.quantity}</td>
                <td style={{textAlign:"center"}}>{this.state.purchaseorderid}</td>
                <td style={{textAlign:"center"}}>{this.state.status}</td>
            </tr>
        );
    }
}
const mapStateToProps = state => {
    return {
      token:state.token, 
    }
  }
  export default connect(mapStateToProps, null)(UserExsitingRequest);