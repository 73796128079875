import React from "react";
import { Container, Row, Input, Col } from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import config from "react-global-configuration";
import ReactTooltip from "react-tooltip";
import "../../../config";
import getDateTimeField from "../../../Utils";
const authApi = config.get("apiDomain");

class UserAccessControl extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [],
      loading: true,
      Name: this.props.Editdata.name,
      Emailid: this.props.Editdata.mailid,
      DefaultRate: this.props.Editdata.default_rate,
      isApproved: this.props.Editdata.isapproved === "1" ? true : false,
      isAdmin: this.props.Editdata.isadmin === "1" ? true : false,
      Profile: this.props.Editdata.userprofile,
      Task: this.props.Editdata.task === "1" ? true : false,
      Admin: this.props.Editdata.admin === "1" ? true : false,
      Report: this.props.Editdata.report === "1" ? true : false,
      Sales: this.props.Editdata.sales === "1" ? true : false,
      Purchase: this.props.Editdata.purchase === "1" ? true : false,
      Voucher: this.props.Editdata.voucher === "1" ? true : false,
      nameInitial:
        this.props.Editdata.initial === null ||
        this.props.Editdata.initial === ""
          ? this.props.Editdata.name
              .charAt(this.props.Editdata.name.length - 1)
              .toUpperCase()
          : this.props.Editdata.initial,
      userColor:
        this.props.Editdata.colour === null || this.props.Editdata.colour === ""
          ? "#5e72e4"
          : this.props.Editdata.colour,
    };
  }

  componentDidMount() {
    fetch(`${authApi}userfilter/?isdeleted=false`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
          loading: false,
        });
      });
  }

  Callbackfunction = () => {
    fetch(`${authApi}userfilter/?isdeleted=false`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          items: json,
          loading: false,
        });
      });
  };

  changeName = (event) => {
    this.setState({
      Name: event.target.value,
    });
  };

  changeMailid = (event) => {
    this.setState({
      Emailid: event.target.value,
    });
  };

  changeAmount = (event) => {
    this.setState({
      DefaultRate: event.target.value,
    });
  };

  changeisApproved = () => {
    this.setState({
      isApproved: !this.state.isApproved,
    });
  };

  changeisAdmin = () => {
    this.setState({
      isAdmin: !this.state.isAdmin,
    });
  };

  changeTask = () => {
    this.setState({
      Task: !this.state.Task,
    });
  };

  changeAdmin = () => {
    this.setState({
      Admin: !this.state.Admin,
    });
  };

  changeReport = () => {
    this.setState({
      Report: !this.state.Report,
    });
  };

  changeProfile = (event) => {
    this.setState({
      Profile: event.target.value,
    });
  };

  changeSales = () => {
    this.setState({
      Sales: !this.state.Sales,
    });
  };

  changePurchase = () => {
    this.setState({
      Purchase: !this.state.Purchase,
    });
  };

  changeVoucher = () => {
    this.setState({
      Voucher: !this.state.Voucher,
    });
  };

  changeInitial = (event) => {
    this.setState({
      nameInitial: event.target.value.toUpperCase(),
    });
  };

  changeUsercolor = (event) => {
    this.setState({
      userColor: event.target.value,
    });
  };

  SaveChanges = () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "put",
      url: `${authApi}users/${this.props.Editdata.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        name: this.state.Name,
        mailid: this.state.Emailid,
        isadmin: this.state.isAdmin === true ? "1" : "0",
        isapproved: this.state.isApproved === true ? "1" : "0",
        default_rate: this.state.DefaultRate,
        superuser: this.props.Editdata.superuser,
        userprofile: this.state.Profile,
        task: this.state.Task === true ? "1" : "0",
        admin: this.state.Admin === true ? "1" : "0",
        report: this.state.Report === true ? "1" : "0",
        sales: this.state.Sales === true ? "1" : "0",
        purchase: this.state.Purchase === true ? "1" : "0",
        voucher: this.state.Voucher === true ? "1" : "0",
        initial:
          this.state.nameInitial === null || this.state.nameInitial === ""
            ? this.state.Name.charAt(this.state.Name.length - 1).toUpperCase()
            : this.state.nameInitial,
        colour:
          this.state.userColor === null || this.state.userColor === ""
            ? "#5e72e4"
            : this.state.userColor,
        reload: true,
        isdeleted: false,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    }).then((res) => {
      if (res.status === 200) {
        this.props.hideEditdata("After Put Operation");
      }
    });
  };

  render() {
    return (
      <React.Fragment>
        <Container className="mt--7" fluid>
          <br />
          <Row>
            <div>
              <h2 style={{ position: "absolute", left: "65px", marginTop: 22 }}>
                <i class="fa fa-users"></i>&nbsp; User Access Control Panel
              </h2>
            </div>
            <div
              className="addRow"
              style={{ position: "absolute", right: "65px", marginTop: 18 }}
            >
              <ReactTooltip
                id="addClients-Tip"
                place="left"
                type="dark"
                effect="solid"
              >
                Back To Users
              </ReactTooltip>
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                data-tip
                data-for="addClients-Tip"
                onClick={() => this.props.hideEditdata("Back")}
              >
                Back
              </button>
            </div>
            <br />
            <br />
            <br />
          </Row>
          <Row style={{ marginTop: 2 }}>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Name
              </label>
            </Col>
            <Col md="4">
              <Input
                type="text"
                value={this.state.Name}
                onChange={this.changeName}
              ></Input>
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Email
              </label>
            </Col>
            <Col md="4">
              <Input
                type="text"
                value={this.state.Emailid}
                onChange={this.changeMailid}
              ></Input>
            </Col>
            <Col md="1"></Col>
          </Row>
          <br />
          <Row style={{ marginTop: 2 }}>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Default_Rate
              </label>
            </Col>
            <Col md="4">
              <Input
                type="text"
                value={this.state.DefaultRate}
                onChange={this.changeAmount}
              ></Input>
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Profile
              </label>
            </Col>
            <Col md="4">
              <Input
                type="select"
                value={this.state.Profile}
                onChange={this.changeProfile}
              >
                <option value="Engineer">Engineer</option>
                <option value="Printer">Printer</option>
                <option value="IT">IT</option>
              </Input>
            </Col>
          </Row>
          <br />
          <Row style={{ marginTop: 2 }}>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Initial
              </label>
            </Col>
            <Col md="4">
              <Input
                type="text"
                value={this.state.nameInitial}
                defaultValue={this.state.nameInitial}
                onChange={this.changeInitial}
              ></Input>
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                UserColor
              </label>
            </Col>
            <Col md="4">
              <Input
                onChange={this.changeUsercolor}
                value={this.state.userColor}
                defaultValue={this.state.userColor}
                id="example-color-input"
                type="color"
              />
            </Col>
          </Row>
          <br />
          <Row style={{ marginTop: 2 }}>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                IsApproved
              </label>
            </Col>
            <Col md="4">
              <Input
                type="checkbox"
                style={{ width: 20, height: 35, marginLeft: 5 }}
                checked={this.state.isApproved}
                onChange={this.changeisApproved}
              ></Input>
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                IsAdmin
              </label>
            </Col>
            <Col md="4">
              <Input
                type="checkbox"
                style={{ width: 20, height: 35, marginLeft: 5 }}
                checked={this.state.isAdmin}
                onChange={this.changeisAdmin}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Task
              </label>
            </Col>
            <Col md="4">
              <Input
                type="checkbox"
                style={{ width: 20, height: 35, marginLeft: 5 }}
                checked={this.state.Task}
                onChange={this.changeTask}
              ></Input>
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Admin
              </label>
            </Col>
            <Col md="4">
              <Input
                type="checkbox"
                style={{ width: 20, height: 35, marginLeft: 5 }}
                checked={this.state.Admin}
                onChange={this.changeAdmin}
              ></Input>
            </Col>
          </Row>
          <Row>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Report
              </label>
            </Col>
            <Col md="4">
              <Input
                type="checkbox"
                checked={this.state.Report}
                style={{ width: 20, height: 35, marginLeft: 5 }}
                onChange={this.changeReport}
              />
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Sales
              </label>
            </Col>
            <Col md="4">
              <Input
                type="checkbox"
                style={{ width: 20, height: 35, marginLeft: 5 }}
                checked={this.state.Sales}
                onChange={this.changeSales}
              ></Input>
            </Col>
          </Row>
          <Row style={{ marginTop: 2 }}>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Purchase
              </label>
            </Col>
            <Col md="4">
              <Input
                type="checkbox"
                style={{ width: 20, height: 35, marginLeft: 5 }}
                checked={this.state.Purchase}
                onChange={this.changePurchase}
              ></Input>
            </Col>
            <Col md="1">
              <label className="form-control-label" style={{ marginTop: 12 }}>
                Voucher
              </label>
            </Col>
            <Col md="4">
              <Input
                type="checkbox"
                checked={this.state.Voucher}
                style={{ width: 20, height: 35, marginLeft: 5 }}
                onChange={this.changeVoucher}
              />
            </Col>
          </Row>
          <Row>
            <Col md="9"></Col>
            <Col md="2">
              <div className="button">
                <button
                  id="buttonsave"
                  className="btn btn-dark"
                  style={{ float: "center", marginRight: 15 }}
                  onClick={this.SaveChanges}
                >
                  Save
                </button>
              </div>
            </Col>
            <Col md="1"></Col>
          </Row>
        </Container>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    allusers: state.allusers,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(UserAccessControl);
