import React from "react";
import { Input, Row, Col } from "reactstrap";
import axios from "axios";
import { connect } from "react-redux";
import config from "react-global-configuration";
import consoleFunction from "../../../Helpers";
import "../../../config";
import getDateTimeField from "../../../Utils";

const authApi = config.get("apiDomain");

class EditBankaccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bankname: this.props.EditRowSend.bankname,
      accountno: this.props.EditRowSend.accountno,
      upiid: this.props.EditRowSend.upi,
      companycode: "",
      companyname: this.props.EditRowSend.companydetails,
      comments: this.props.EditRowSend.comments,
      ifsccode: this.props.EditRowSend.ifsc,
      companydetails: [],
      error: null,
      banknameborder: "1px solid #dee2e6",
      accountnoborder: "1px solid #dee2e6",
      companynameborder: "1px solid #dee2e6",
      isButtonDisabled: false,
    };
  }

  async componentDidMount() {
    fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        json.forEach((element) => {
          if (this.state.companyname === element.companyname) {
            this.setState({
              companycode: element.id,
            });
          }
        });
        this.setState({
          companydetails: json,
        });
      });
  }

  updatebankdetails = () => {
    fetch(`${authApi}companyfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        json.forEach((element) => {
          if (this.state.companyname === element.companyname) {
            this.setState({
              companycode: element.id,
            });
          }
        });
        this.setState({
          companydetails: json,
        });
      });
  };

  Formvalidation = () => {
    if (this.state.bankname !== "") {
      if (this.state.accountno !== "") {
        if (this.state.companyname !== "") {
          this.setState({
            isButtonDisabled: true,
          });
          this.updateBankaccount();
        } else {
          this.setState({
            companynameborder: "1px solid #fb6340",
            accountnoborder: "1px solid #dee2e6",
          });
        }
      } else {
        this.setState({
          accountnoborder: "1px solid #fb6340",
          banknameborder: "1px solid #dee2e6",
        });
      }
    } else {
      this.setState({
        banknameborder: "1px solid #fb6340",
      });
    }
  };

  updateBankaccount = () => {
    let datetime_field = getDateTimeField();
    axios({
      method: "put",
      url: `${authApi}bank/${this.props.EditRowSend.id}/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        bankname: this.state.bankname,
        accountno: this.state.accountno,
        upi: this.state.upiid,
        comments: this.state.comments,
        ifsc: this.state.ifsccode,
        companydetails: this.state.companycode,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 200) {
          this.props.closebtn();
          this.props.callbackfunction();
        }
      })
      .catch((err) => {
        consoleFunction(
          "Bankaccount Creation error",
          err.response.request.response
        );
        this.setState({ error: err.message });
      });
  };

  getBankname = (event) => {
    this.setState({
      bankname: event.target.value,
    });
  };

  getAccountno = (event) => {
    this.setState({
      accountno: event.target.value,
    });
  };

  getUpiId = (event) => {
    this.setState({
      upiid: event.target.value,
    });
  };

  getCompanyname = (event) => {
    event.persist();
    this.setState({
      companyname: event.target.value,
    });
    fetch(`${authApi}companyfilter/?companyname=${event.target.value}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        if (json.length !== 0) {
          this.setState({
            companycode: json[0].id,
          });
        }
      });
  };

  getComments = (event) => {
    this.setState({
      comments: event.target.value,
    });
  };

  getIFSCcode = (event) => {
    this.setState({
      ifsccode: event.target.value,
    });
  };

  keyEventOperation = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    } else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  render() {
    let companynames = this.state.companydetails.map((q) => q.companyname);
    let nameofcompanyies = companynames.filter(
      (q, idx) => companynames.indexOf(q) === idx
    );
    return (
      <React.Fragment>
        <button className="x" onClick={this.props.closebtn}>
          x
        </button>
        <div className="header">Edit BankDetails</div>
        <div className="Scroll-Content">
          <form>
            <Row>
              <Col md="4">
                <label className="form-control-label">Bank</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  defaultValue={this.state.bankname}
                  style={{ border: this.state.banknameborder }}
                  onChange={this.getBankname}
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.banknameborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>Please enter bankname </h5>
                ) : null}
              </Col>
              <Col md="4">
                <label className="form-control-label">Account No.</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  value={this.state.accountno}
                  style={{ border: this.state.accountnoborder }}
                  onChange={this.getAccountno}
                  onKeyDown={this.keyEventOperation}
                />
                {this.state.accountnoborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>Please enter accountno. </h5>
                ) : null}
              </Col>
              <Col md="4">
                <label className="form-control-label">UPI Id</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  defaultValue={this.state.upiid}
                  onChange={this.getUpiId}
                  onKeyDown={this.keyEventOperation}
                  style={{ border: "1px solid #dee2e6" }}
                />
              </Col>
            </Row>
            <Row>
              <Col md="4">
                <label className="form-control-label">Company</label>
                <Input
                  type="select"
                  className="form-control"
                  value={this.state.companyname}
                  onChange={this.getCompanyname}
                  onKeyDown={this.keyEventOperation}
                  style={{ border: this.state.companynameborder }}
                >
                  {nameofcompanyies.map((item, key) => (
                    <option key={key} value={item}>
                      {" "}
                      {item}{" "}
                    </option>
                  ))}
                </Input>
                {this.state.companynameborder !== "1px solid #dee2e6" ? (
                  <h5 style={{ color: "#fb6340" }}>
                    Please select companyname
                  </h5>
                ) : null}
              </Col>
              <Col md="3">
                <label className="form-control-label">Comments</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  defaultValue={this.state.comments}
                  onChange={this.getComments}
                  onKeyDown={this.keyEventOperation}
                  style={{ border: "1px solid #dee2e6" }}
                />
              </Col>
              <Col md="3">
                <label className="form-control-label">IFSC Code</label>
                <Input
                  type="text"
                  className="form-control-alternative"
                  defaultValue={this.state.ifsccode}
                  style={{ border: "1px solid #dee2e6" }}
                  onChange={this.getIFSCcode}
                  onKeyDown={this.keyEventOperation}
                />
              </Col>
              <Col md="2">
                <div className="button">
                  <button
                    id="buttonsave"
                    className="btn btn-dark"
                    style={{ float: "center", marginRight: 15 }}
                    onClick={this.Formvalidation}
                    disabled={this.state.isButtonDisabled}
                  >
                    Add
                  </button>
                </div>
              </Col>
            </Row>
          </form>
        </div>
      </React.Fragment>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    token: state.token,
    username: state.username,
  };
};
export default connect(mapStateToProps, null)(EditBankaccount);
