import React, { Component } from "react";
import "./Con_PreLoader.css";

class ConPreLoader extends Component {
  render() {
    return (
        <div className="con-container">
          <div className="preloader">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
    );
  }
}

export default ConPreLoader;
