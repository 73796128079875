import React from 'react';
import { Page, Document, StyleSheet, Text, View, } from "@react-pdf/renderer";
import ReportTableFooter from "./ReportTableFooter";
import ReportTable from "./ReportTable";
import SummarizedTable from "./SummarizedTable";
import ReportTitle from "./ReportTitle";
import SummarizedTitle from "./SummarizedTitle"

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 40,
    paddingLeft: 60,
    paddingRight: 60,
    paddingBottom: 40,
    lineHeight: 1.5,
    flexDirection: "column",
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 16,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  
  titleContainer: {
    marginTop: 0,
    width: 300,
    height: 66,
    marginLeft: "auto",
    marginRight: -25,
    marginBottom: 5,
  },

  containerSummary: {
    marginTop: 10,
  },

  reportTitle: {
    marginTop: -20,
    marginRight: -5,
    color: "#11cdef",
    letterSpacing: 1,
    fontSize: 20,
    textAlign: "center",
    textTransform: "uppercase"
  },

  titlelocation: {
    marginTop: -60,
    width: 140,
    height: 66,
    marginLeft: "auto",
    marginRight: 15,
    marginBottom: 5,
    fontSize: 12,
    textAlign: "right",
  },
  titlelocation1: {
    marginTop: -55,
    width: 140,
    height: 66,
    marginLeft: "auto",
    marginRight: 15,
    marginBottom: 5,
    fontSize: 12,
    textAlign: "right",
  },
});

const Report = ({ TimeCal, sD, eD, group_data, summarizedData }) => {
  return(
  <Document>
    <Page size="A4" style={styles.page} orientation="landscape">
 
      <View style={styles.titleContainer}>
        <Text style={styles.reportTitle}> PG Cadd Printers </Text>
      </View>

      <Text style={styles.titlelocation} >
        334, Mettur road,
      </Text>
      <Text style={styles.titlelocation1} >
        Erode - 638001.
      </Text>

      <ReportTitle stDa={sD} edDa={eD} />

      <ReportTable gd = {group_data} />
      <View>
        <ReportTableFooter TasksTotal={ TimeCal} />
      </View>

      <View style={styles.containerSummary} wrap={false}>
        <SummarizedTitle />
        <SummarizedTable gd = {summarizedData} />
      </View>

      <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
        `${pageNumber} / ${totalPages}`
      )} fixed />
     </Page>
  </Document>
  )
};

export default Report;