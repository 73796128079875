import React from "react";
import { Text, View, StyleSheet } from "@react-pdf/renderer";

const borderColor = "#bff0fd";
const styles = StyleSheet.create({
  row: {
    flexDirection: "row",
    borderBottomColor: "#bff0fd",
    borderBottomWidth: 1,
    alignItems: "center",
    height: 24,
    fontSize: 12,
    fontStyle: "bold"
  },
  description: {
    width: "90%",
    textAlign: "right",
    borderLeftColor: borderColor,
    borderLeftWidth: 1,
    paddingRight: 8,
    paddingTop: 4,
  },
  total: {
    width: "10%",
    textAlign: "center",
    paddingRight: 8,
    paddingTop: 4,
    borderRightColor: borderColor,
    borderRightWidth: 1,
  }
});

const InvoiceTableFooter = ({ itemsTotal }) => {

  const invoiceTot = itemsTotal.reduce((i,t) => i + parseInt(t.subtotal, 0), 0);
  const invoiceDisc = itemsTotal.reduce((i,d) => i + parseInt(d.discount, 0), 0);
  const invoiceAmt = itemsTotal.reduce((i,a) => i + parseInt(a.invoice_amount, 0), 0);

  return (
    <React.Fragment>
      <View style={styles.row}>
        <Text style={styles.description}>Total</Text>
        <Text style={styles.total}>{parseFloat(invoiceTot).toFixed(2)}</Text>
      </View>
      { invoiceDisc === 0 ? null :
        <View style={styles.row}>
          { 
            invoiceDisc > 0 ?
            <React.Fragment>
              <Text style={styles.description}>Additional Drafting Charges</Text> 
              <Text style={styles.total}>{parseFloat(invoiceDisc).toFixed(2)}</Text>
            </React.Fragment>
            : 
            <React.Fragment>
              <Text style={styles.description}>Discount</Text>
              <Text style={styles.total}>{parseFloat(invoiceDisc).toFixed(2)}</Text>
            </React.Fragment>
          }
        </View>
      }
      <View style={{ flexDirection: "row",borderBottomColor: "#bff0fd",borderBottomWidth: 1,alignItems: "center",
        height: 24,fontSize: 12,fontStyle: "bold",marginBottom: 10 }}>
        <Text style={styles.description}>Invoice Amount</Text>
        <Text style={styles.total}>{parseFloat(invoiceAmt).toFixed(2)}</Text>
      </View>
    </React.Fragment>
  );
};

export default InvoiceTableFooter;
