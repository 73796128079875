import React, { Component } from "react";
import { PDFViewer } from "@react-pdf/renderer";
import OverviewReports from "./OverviewReports";
import {Row, Card} from "reactstrap";
import '../../../../../config';
import { connect } from "react-redux";
import axios from "axios";
import moment from "moment";
import config from "react-global-configuration";
const authApi = config.get('apiDomain');

let rowData = "";
let year=new Date().getFullYear(),month=new Date().getMonth(),previousyear=new Date().getFullYear()-1;
let selectedcompany = 0;

class OverviewDetailReportsApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientReport: this.props.dataFilter,
      jsonObject: "",
      startDate : "",
      endDate : "",
      selected_Clientname: undefined,
      loading : true,
      OpeningBalance: '0',
      ClosingBalance: '0',
      currenttaxyearstartdate:month>3 ? moment(new Date(`${year}-04-01`)).format("YYYY-MM-DD"):moment(new Date(`${previousyear}-04-01`)).format("YYYY-MM-DD"),
      currenttaxyearenddate:month<4 ? moment(new Date(`${year}-03-31`)).format("YYYY-MM-DD"):moment(new Date(`${previousyear}-03-31`)).format("YYYY-MM-DD"),
    };
  }
  
  componentDidMount() {
    rowData = [];
    let d1 = new Date(this.props.dateStart)-1;
    const startdate = moment(d1).format("YYYY-MM-DD");
    const enddate = moment(this.props.dateEnd).format("YYYY-MM-DD");
    this.setState({
      startDate: startdate,
      endDate: enddate,
    })
    this.downloadFunction();
  }

  HandleClientId = (client, sdate, edate) => {
    let currentclient = this.props.clientdetails.filter((clientdata)=>{
      return clientdata.company_name ===  client
    })
      if(currentclient.length !== 0){
        this.calculateOpeningBalance(currentclient[0].id,sdate);
        this.calculateclosingBalance(currentclient[0].id,edate);
      }
  }

  downloadFunction = () => {
    this.state.clientReport.forEach((d)=> {
      rowData = [...rowData, d.original]
    })
    this.setState({
      jsonObject: rowData.reverse(),
    },function(){
      if(this.props.selectedClientname.length !== 0){
        if(this.props.selectedClientname.length === 1){
          this.setState({
            selected_Clientname : this.props.selectedClientname[0].value,
          })
          this.HandleClientId(this.props.selectedClientname[0].value,this.state.startDate,this.state.endDate);
          this.HandleLoading();
        }
        else {
          this.setState({
            selected_Clientname : undefined
          })
          this.HandleLoading();
        }
      }
      else {
        this.setState({
          selected_Clientname : undefined
        })
        this.HandleLoading();
      }
    })
  } 

  calculateOpeningBalance = async(clientid, sdate) => {

    let date = moment(this.props.dateStart).format("YYYY-MM-DD");
    let taxyearstartdate=new Date(date).getMonth()+1>3? `${new Date(date).getFullYear()}-04-01`:`${new Date(date).getFullYear()-1}-04-01`;

    await axios({
      method: "post",
      url: `${authApi}sp_calculateopeningbalance/`,
      headers: { 'content-type': "application/json",
      'Authorization': `token ${this.props.token}`, },
      data: {
        company_id:selectedcompany,
        client_id:clientid,
        taxyear_start_date:taxyearstartdate,
        start_date:date
      }
    })
    .then(res=>{
      this.setState({
            OpeningBalance:res.data.balance>0?res.data.balance:0
          })
    })
  }

    calculateclosingBalance=async(clientid,edate)=>{
      let date = moment(this.props.dateEnd).format("YYYY-MM-DD");
      let taxyearstartdate=new Date(date).getMonth()+1>3? `${new Date(date).getFullYear()}-04-01`:`${new Date(date).getFullYear()-1}-04-01`;
      await axios({
        method: "post",
        url: `${authApi}sp_calculateclosingbalance/`,
        headers: { 'content-type': "application/json",
        'Authorization': `token ${this.props.token}`, },
        data: {
        client_id:clientid,
        company_id:selectedcompany,
        startdate: taxyearstartdate,
        enddate:date
        }
      })
      .then(res=>{
        this.setState({
          ClosingBalance:res.data.balance>0?res.data.balance:0
            })
      })
    
   
    }

  HandleLoading = () => {
    this.setState({
      loading : false,
    })
  }

  render() {
    if(this.state.loading)
    {
      return null;
    }
    else
    {
      return (
        <React.Fragment>
          <button className="x" onClick={this.props.closebtn}>
            x
          </button>
          <div className="header">
            Report
          </div>
          <Row>
            <div className=" col">
              <div className="Scrolls-Contents">
                <Card className="shadow">
                  <PDFViewer width="100%" height="600" className="app">
                    <OverviewReports
                      GenerateData = {this.state.jsonObject}
                      sD = { this.state.startDate }
                      eD = { this.state.endDate }
                      obalance = {this.state.OpeningBalance}
                      cbalance = {this.state.ClosingBalance}
                      clientdetail = { this.state.selected_Clientname }
                    />
                  </PDFViewer>
                </Card>
              </div>
            </div>
          </Row>
        </React.Fragment>
      );
    }
    }
  }

const mapStateToProps = state => {
  return {
    token:state.token,
    dataFilter: state.filterData,
    clientdetails:state.clientdetails
  }
}

export default connect(mapStateToProps, null)(OverviewDetailReportsApp);