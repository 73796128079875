import React from "react";
import {
  Card,
  CardBody,
  FormGroup,
  Table,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";
import moment from "moment";
import { connect } from "react-redux";
import RecVoucherRow from "./AddRecVoucherRow";
import config from "react-global-configuration";
import "../../../../config";
import ReactBSAlert from "react-bootstrap-sweetalert";
import axios from "axios";
import Header from "components/Headers/SimpleHeader";
import getDateTimeField from "../../../../Utils";

const authApi = config.get("apiDomain");
let rec_voucherStringData = "";

class AddRecVouchers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rec_voucherId: 1,
      user_name: "",
      rec_voucherType: "Receipt",
      multi_invoice: false,
      rec_voucherDate: moment(new Date()).format("YYYY-MM-DD"),
      rec_voucherClientId: "",
      rec_voucherClientName: "",
      rec_voucherCompanyId: "",
      rec_voucherCompanyName: "",
      rec_voucherLedgerType: "",
      rec_voucherPaymentMode: "",
      rec_projectdetails: "",
      rec_voucherReceivedAmount: 0,
      rec_voucherAmount: 0,
      rec_voucherRowList: [],
      companyDetails: [],
      clientDetails: this.props.clientdetails,
      bankDetails: [],
      selectedCompanyBanks: [],
      project_details: [],
      salesDispatchDetails: [],
      salesOrderDetails: [],
      ledgerTypeDetails: [],
      alert: false,
      currentUser: "",
      currentUserId: "",
    };
  }
  componentDidMount() {
    this.getCompanyDetails();
    this.getBankDetails();
    this.getCurrentUser();
    this.getLedgertypeDetails();
  }

  getCurrentUser = () => {
    let currentuserdetails = this.props.allusers.filter((userdetails) => {
      return userdetails.name === this.props.username;
    });
    this.setState({
      currentUserId: currentuserdetails[0].id,
      user_name: currentuserdetails[0].name,
    });
  };

  getCompanyDetails = () => {
    fetch(`${authApi}companyfilter/?isactive=1`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          companyDetails: json,
        });
      });
  };

  getLedgertypeDetails = () => {
    fetch(`${authApi}ledgertypefilter/?isvisible=true`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          ledgerTypeDetails: json,
        });
      });
  };

  getBankDetails = () => {
    fetch(`${authApi}bankfilter/`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          bankDetails: json,
        });
      });
  };

  handleVoucherDate = (event) => {
    this.setState({
      rec_voucherDate: event.target.value,
    });
  };

  handleVoucherClientname = (event) => {
    this.state.clientDetails.forEach((d) => {
      if (d.company_name === event.target.value) {
        this.setState({
          rec_voucherClientId: d.id,
          rec_voucherClientName: d.company_name,
          rec_voucherLedgerType: "",
          rec_voucherAmount: 0,
          rec_voucherRowList: [],
        });
      } else {
        this.setState({
          rec_voucherClientName: event.target.value,
          rec_voucherLedgerType: "",
          rec_voucherAmount: 0,
          rec_voucherRowList: [],
        });
      }
    });
  };

  handleVoucherCompany = (event) => {
    fetch(
      `${authApi}companyfilter/?isactive=1&companyname=${event.target.value}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState(
          {
            rec_voucherCompanyName: json[0].companyname,
            rec_voucherCompanyId: json[0].id,
            rec_voucherLedgerType: "",
            rec_voucherAmount: 0,
            rec_voucherRowList: [],
            rec_voucherReceivedAmount: 0,
          },
          function () {
            this.updateVoucherBankDetails(json[0].id);
          }
        );
      });
  };

  updateVoucherBankDetails = (id) => {
    fetch(`${authApi}bankfilter/?companydetails=${id}`, {
      method: "GET",
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
    })
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          selectedCompanyBanks: json,
        });
      });
  };

  getProjectDetails = (rec_voucherClientId, rec_voucherCompanyId) => {
    fetch(
      `${authApi}projectfilter/?clientid=${rec_voucherClientId}&companyid=${rec_voucherCompanyId}`,
      {
        method: "GET",
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
      }
    )
      .then((res) => res.json())
      .then((json) => {
        this.setState({
          project_details: json,
        });
      });
  };

  handleVoucherLedgerType = (event) => {
    if (
      this.state.rec_voucherLedgerType === "" ||
      (this.state.rec_voucherLedgerType !== event.target.value &&
        this.state.rec_voucherRowList.length === 0)
    ) {
      this.setState(
        {
          rec_voucherLedgerType: event.target.value,
          rec_voucherRowList: [],
          rec_voucherAmount: 0,
        },
        function callback() {
          this.getReceiptDetails(event.target.value);
        }
      );
    } else if (
      this.state.rec_voucherLedgerType !== event.target.value &&
      this.state.rec_voucherRowList.length !== 0
    ) {
      this.checkVoucherLedgerType(event.target.value);
    }
  };

  handleVoucherReceivedAmount = (event) => {
    this.setState({
      rec_voucherReceivedAmount: event.target.value,
    });
  };

  getReceiptDetails = (e) => {
    if (this.state.rec_voucherClientId !== "") {
      if (
        e === "SalesInvoice" ||
        e === "SalesAdvance" ||
        e === "Invoice" ||
        e === "Projects"
      ) {
        if (e === "SalesAdvance")
          fetch(
            `${authApi}salesfilter/?supplier=${this.state.rec_voucherClientId}&status=Issued&company=${this.state.rec_voucherCompanyId}`,
            {
              method: "GET",
              headers: {
                "content-type": "application/json",
                Authorization: `token ${this.props.token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((json) => {
              const salesOrders = json;
              this.setState({
                salesOrderDetails: salesOrders,
              });
            });
        else if (e === "Invoice")
          fetch(
            `${authApi}summaryfilter/?invoicesummaryclient=${this.state.rec_voucherClientId}&invoice_company=${this.state.rec_voucherCompanyId}`,
            {
              method: "GET",
              headers: {
                "content-type": "application/json",
                Authorization: `token ${this.props.token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((json) => {
              const dispatchBills = json.filter(function (db) {
                return (
                  db.invoicestatus !== "Paid" &&
                  db.invoicestatus !== "Cancelled" //&&
                );
              });
              this.setState({
                salesDispatchDetails: dispatchBills,
              });
              console.log(dispatchBills);
            });
        else if (e === "Projects") {
          this.getProjectDetails(
            this.state.rec_voucherClientId,
            this.state.rec_voucherCompanyId
          );
        } else if (e === "SalesInvoice")
          fetch(
            `${authApi}summaryfilter/?invoicesummaryclient=${this.state.rec_voucherClientId}&invoice_company=${this.state.rec_voucherCompanyId}`,
            {
              method: "GET",
              headers: {
                "content-type": "application/json",
                Authorization: `token ${this.props.token}`,
              },
            }
          )
            .then((res) => res.json())
            .then((json) => {
              const dispatchBills = json.filter(function (db) {
                return (
                  db.invoicestatus !== "Paid" &&
                  db.invoicestatus !== "Cancelled" &&
                  db.salesorder !== null
                );
              });
              this.setState({
                salesDispatchDetails: dispatchBills,
              });
              console.log(dispatchBills);
            });
      }
    }
  };

  handleVoucherPaymentMode = (event) => {
    this.setState({
      rec_voucherPaymentMode: event.target.value,
    });
  };

  handleProjectDetails = (event) => {
    const invoice_details = this.state.project_details.filter(
      (p_d) => p_d.id === parseInt(event.target.value)
    );
    const filter_projects = invoice_details
      .map((i_d) => i_d.project)
      .filter((p_d) => p_d.invoicestatus !== "Paid");
    this.setState({
      rec_projectdetails: event.target.value,
      salesDispatchDetails: filter_projects[0],
    });
  };

  handleRecVoucherAddRow = (e) => {
    e.preventDefault();
    const recVocList = {
      id: this.state.rec_voucherId + 1,
      sales_Id: "",
      recsubType: "",
      sales_Total: "",
      recamtReceived: "",
      recsubReferType: "",
      sales_Balance: "",
    };
    this.setState({
      rec_voucherId: recVocList.id,
      rec_voucherRowList: [...this.state.rec_voucherRowList, recVocList],
    });
  };

  handleRecVoucherRemoveRow = (i) => {
    const DelData = this.state.rec_voucherRowList.filter(
      (item) => item.id !== i.id
    );
    this.setState({
      rec_voucherRowList: [...DelData],
    });
  };

  updationRecVoucherlist = (x) => {
    this.setState(
      (prevState) => {
        const updatedRvData = prevState.rec_voucherRowList.map((rv) => {
          if (rv.id === x.id) {
            rv.id = x.id;
            rv.sales_Id = x.rec_salesId;
            rv.recsubType = x.rec_subType;
            rv.sales_Total = x.rec_salesTotal;
            rv.recamtReceived = x.rec_amtReceived;
            rv.recsubReferType = x.rec_subReferType;
            rv.sales_Balance = x.rec_salesBalance;
          }
          return rv;
        });
        return {
          rec_voucherRowList: updatedRvData,
        };
      },
      function callback() {
        const totalReceived = this.state.rec_voucherRowList
          .filter((item) => item.recamtReceived !== "")
          .reduce((i, i_rec) => i + parseFloat(i_rec.recamtReceived, 0), 0);
        this.setState({
          rec_voucherAmount: totalReceived,
        });
      }
    );
  };

  handleEnter = (event) => {
    if (!event.shiftKey && event.key.toLowerCase() === "enter") {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index + 1] !== undefined) {
        form.elements[index + 1].focus();
      }
      event.preventDefault();
    } else if (event.key.toLowerCase() === "enter" && event.shiftKey) {
      const form = event.target.form;
      const index = [...form].indexOf(event.target);
      if (form.elements[index - 1] !== undefined) {
        form.elements[index - 1].focus();
      }
      event.preventDefault();
    }
  };

  checkVoucherLedgerType = (ev) => {
    const getwarningAlert = () => (
      <ReactBSAlert
        warning
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        confirmBtnText="Yes"
        confirmBtnBsStyle="success"
        title="Delete receipt details ?"
        cancelBtnText="No"
        cancelBtnBsStyle="warning"
        onConfirm={() => this.changedLedgerType(ev)}
        onCancel={() => this.setState({ alert: false })}
      >
        Are you sure you want to delete all your receipt detail changes. This
        will erase all your entries
      </ReactBSAlert>
    );

    this.setState({
      alert: getwarningAlert(),
    });
  };

  backtoReceiptVoucherPage = () => {
    const getwarningAlert = () => (
      <ReactBSAlert
        warning
        showCancel
        style={{ display: "block", marginTop: "-100px" }}
        confirmBtnText="Yes"
        confirmBtnBsStyle="success"
        title="Delete receipt details ?"
        cancelBtnText="No"
        cancelBtnBsStyle="warning"
        onConfirm={() => this.goBackPage()}
        onCancel={() => this.setState({ alert: false })}
      >
        Are you sure you want to delete all your receipt detail changes. This
        will erase all your entries
      </ReactBSAlert>
    );

    this.setState({
      alert: getwarningAlert(),
    });
  };

  changedLedgerType(ev) {
    this.setState({
      alert: false,
      rec_voucherLedgerType: ev,
      rec_voucherRowList: [],
      rec_voucherAmount: 0,
    });
    this.getReceiptDetails(ev);
  }

  goBackPage() {
    this.setState({
      alert: false,
    });
    this.props.history.push("/admin/voucher/receiptvoucher");
  }

  onSubmitVoucher = async (e) => {
    e.preventDefault();
    if (
      this.state.rec_voucherClientName !== "" &&
      this.state.rec_voucherClientId !== "" &&
      this.state.rec_voucherCompanyName !== "" &&
      this.state.rec_voucherPaymentMode !== "" &&
      this.state.rec_voucherLedgerType !== ""
    ) {
      if (this.state.multi_invoice === false) {
        if (this.state.rec_voucherRowList.length !== 0) {
          if (
            parseInt(this.state.rec_voucherAmount) ===
            parseInt(this.state.rec_voucherReceivedAmount)
          ) {
            if (
              this.state.rec_voucherLedgerType === "SalesAdvance" ||
              this.state.rec_voucherLedgerType === "SalesInvoice" ||
              this.state.rec_voucherLedgerType === "Invoice" ||
              this.state.rec_voucherLedgerType === "Projects"
            ) {
              const voucherRows = this.state.rec_voucherRowList.filter(
                (item) => item.sales_Id !== "" && item.recamtReceived !== ""
              );
              if (parseInt(voucherRows.length) !== 0) {
                if (this.state.rec_voucherLedgerType === "SalesAdvance") {
                  const unique = new Set();
                  const showError = voucherRows.some(
                    (element) =>
                      unique.size === unique.add(element.sales_Id).size
                  );
                  if (!showError) {
                    await this.makeVoucherStringData(voucherRows);
                    await this.AddReceiptVoucher(rec_voucherStringData);
                  } else {
                    alert("Please remove duplicate entries");
                  }
                } else {
                  const unique = new Set();
                  const showError = voucherRows.some(
                    (element) =>
                      unique.size === unique.add(element.sales_Id).size
                  );
                  if (!showError) {
                    await this.makeVoucherStringData(voucherRows);
                    await this.AddReceiptVoucher(rec_voucherStringData);
                  } else {
                    alert("Please remove duplicate entries");
                  }
                }
              } else {
                alert("Please enter valid voucher record");
              }
            } else {
              const voucherRows = this.state.rec_voucherRowList.filter(
                (item) =>
                  item.recsubType !== "" &&
                  item.recsubReferType !== "" &&
                  item.recamtReceived !== ""
              );
              if (parseInt(voucherRows.length) !== 0) {
                await this.makeVoucherStringData(voucherRows);
                await this.AddReceiptVoucher(rec_voucherStringData);
              } else {
                alert("Please enter valid voucher record");
              }
            }
          } else {
            alert("Please enter received amount and total amount equally");
          }
        } else {
          alert("Please add atleast 1 voucher record");
        }
      } else {
        await this.AddReceiptVoucher(rec_voucherStringData);
      }
    } else {
      alert("Please enter valid details");
    }
  };

  makeVoucherStringData = (voucData) => {
    rec_voucherStringData = "";
    if (
      this.state.rec_voucherLedgerType === "SalesAdvance" ||
      this.state.rec_voucherLedgerType === "SalesInvoice" ||
      this.state.rec_voucherLedgerType === "Invoice" ||
      this.state.rec_voucherLedgerType === "Projects"
    ) {
      voucData.forEach((voucher) => {
        let newvoucher = {
          vouchertype: voucher.sales_Id,
          voucherreferencetype: this.state.rec_voucherLedgerType,
          amount: voucher.recamtReceived,
        };
        rec_voucherStringData = [...rec_voucherStringData, newvoucher];
      });
    } else {
      voucData.forEach((voucher) => {
        let newvoucher = {
          vouchertype: voucher.recsubType,
          voucherreferencetype: voucher.recsubReferType,
          amount: voucher.recamtReceived,
        };
        rec_voucherStringData = [...rec_voucherStringData, newvoucher];
      });
    }
  };

  AddReceiptVoucher = async (stringData) => {
    if (this.state.rec_voucherReceivedAmount > 0) {
      let datetime_field = getDateTimeField();
      axios({
        method: "post",
        url: `${authApi}vouchermanagement/`,
        headers: {
          "content-type": "application/json",
          Authorization: `token ${this.props.token}`,
        },
        data: {
          date: this.state.rec_voucherDate,
          time: moment().format("hh:mm a"),
          username: this.state.user_name,
          clientname: this.state.rec_voucherClientName,
          vouchertype: this.state.rec_voucherType,
          vouchersubtype:
            this.state.multi_invoice === false
              ? this.state.rec_voucherLedgerType
              : this.state.rec_voucherLedgerType + " Payment ",
          vouchersubreferencetype: "",
          amount:
            this.state.multi_invoice === false
              ? this.state.rec_voucherAmount
              : this.state.rec_voucherReceivedAmount,
          paymentmode: this.state.rec_voucherPaymentMode,
          companyname: this.state.rec_voucherCompanyName,
          client: this.state.rec_voucherClientId,
          createdby: this.props.username,
          createdat: datetime_field,
          modifiedby: this.props.username,
          modifiedat: datetime_field,
        },
      })
        .then((res) => {
          if (res.status === 201) {
            if (this.state.multi_invoice === false) {
              this.storeVoucherDetails(res.data.id, stringData);
            } else {
              this.StoreMultiVoucherDeatails(res.data.id);
            }
          }
        })
        .catch((err) => {
          this.setState({ error: err.message });
        });
    } else {
      alert("Please Check your Received Amount. Received Amount Can't be Zero");
    }
  };

  multiVoucherPayment = (id) => {
    axios({
      method: "post",
      url: `${authApi}sp_multipayvouchermanagement/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        vouchermanagement_id: id,
        date: this.state.rec_voucherDate,
        vouchertype: this.state.rec_voucherType,
        detailtype: this.state.rec_voucherLedgerType,
        client_id: this.state.rec_voucherClientId,
        user_id: this.state.currentUserId,
        company_id: this.state.rec_voucherCompanyId,
        paymentmode: this.state.rec_voucherPaymentMode,
        amount: this.state.rec_voucherReceivedAmount,
      },
    })
      .then((res) => {
        console.log(res.status);
        if (res.status === 200) {
          this.restoreDefaultData();
        }
      })
      .catch((err) => {
        alert("voucher detais store error", err.response.request.response);
      });
  };

  storeVoucherDetails = async (id, data) => {
    axios({
      method: "post",
      url: `${authApi}sp_vouchermanagement/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        vouchermanagement_id: id,
        date: this.state.rec_voucherDate,
        vouchertype: this.state.rec_voucherType,
        detailtype: this.state.rec_voucherLedgerType,
        client_id: this.state.rec_voucherClientId,
        user_id: this.state.currentUserId,
        company_id: this.state.rec_voucherCompanyId,
        paymentmode: this.state.rec_voucherPaymentMode,
        requesteddata: data,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          this.restoreDefaultData();
        }
      })
      .catch((err) => {
        alert("voucher detais store error", err.response.request.response);
      });
  };

  restoreDefaultData = async () => {
    this.setState({
      rec_voucherId: 1,
      rec_voucherType: "Receipt",
      rec_voucherDate: this.state.rec_voucherDate,
      rec_voucherLedgerType:
        this.state.multi_invoice === false ? "" : "Invoice",
      rec_projectdetails: "",
      rec_voucherAmount: 0,
      rec_voucherRowList: [],
      rec_voucherReceivedAmount: 0,
    });
  };

  handleMultiInvoice = () => {
    this.setState({
      multi_invoice: !this.state.multi_invoice,
      rec_voucherLedgerType: "Invoice",
    });
    if (this.state.multi_invoice !== false) {
      this.setState({
        rec_voucherLedgerType: "",
        rec_projectdetails: "",
      });
    }
  };

  StoreMultiVoucherDeatails = (id) => {
    let datetime_field = getDateTimeField();
    let newamount = parseInt(this.state.rec_voucherReceivedAmount) || 0;
    let date = moment(new Date()).format("YYYY-MM-DD");
    let currentTime = moment(new Date())
      .utcOffset("+05:30")
      .format("hh:mm:ss A");
    axios({
      method: "post",
      url: `${authApi}transactions/`,
      headers: {
        "content-type": "application/json",
        Authorization: `token ${this.props.token}`,
      },
      data: {
        transactiontaskid: "",
        date: date,
        time: currentTime,
        clientidnum: this.state.rec_voucherClientId,
        transaction_clientid: this.state.rec_voucherClientId,
        userid_id: this.state.currentUserId,
        amount: newamount,
        deliverynoteid: "",
        originaldeliverynoteid: 0,
        originalcompanyid: 0,
        description: "",
        transactiontype: "received",
        companyid: this.state.rec_voucherCompanyId,
        companyidnum: this.state.rec_voucherCompanyId,
        pmtmode: this.state.rec_voucherPaymentMode,
        voucherid: id,
        createdby: this.props.username,
        createdat: datetime_field,
        modifiedby: this.props.username,
        modifiedat: datetime_field,
      },
    })
      .then((res) => {
        if (res.status === 201) {
          this.multiVoucherPayment(id);
        }
      })
      .catch((err) => {
        alert("voucher detais store error", err.response.request.response);
      });
  };
  render() {
    const recVoucherRowList = [...this.state.rec_voucherRowList].map((rv) => (
      <RecVoucherRow
        key={rv.id}
        id={rv.id}
        sales_Id={rv.sales_Id}
        recsubType={rv.recsubType}
        sales_Total={rv.sales_Total}
        recamtReceived={rv.recamtReceived}
        recsubReferType={rv.recsubReferType}
        sales_Balance={rv.sales_Balance}
        voucherLedgerType={this.state.rec_voucherLedgerType}
        salesOrderData={this.state.salesOrderDetails}
        salesDispatchData={this.state.salesDispatchDetails}
        clientCompanyid={this.state.rec_voucherCompanyId}
        clientId={this.state.rec_voucherClientId}
        deleteRow={this.handleRecVoucherRemoveRow}
        updateAddRow={this.updationRecVoucherlist}
      />
    ));

    return (
      <>
        <Header />
        {this.state.alert}
        <Container className="mt--7" fluid>
          <br />
          <Row>
            <div
              className="addRow"
              style={{ position: "absolute", right: "65px", marginTop: 20 }}
            >
              <button
                className="btn btn-primary btn-sm"
                style={{ width: "135%", height: 40 }}
                onClick={this.backtoReceiptVoucherPage}
              >
                Back
              </button>
            </div>
          </Row>
          <br />
          <br />
          <br />
          <Row style={{ marginTop: 10 }}>
            <form style={{ width: "100%" }}>
              <Col xl="12">
                <Card className="bg-secondary shadow">
                  <CardBody>
                    <Row style={{ marginTop: -20 }}>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">Company</label>
                          <Input
                            type="select"
                            className="form-control-alternative"
                            style={{ height: "100%" }}
                            defaultValue={this.state.rec_voucherCompanyName}
                            value={this.state.rec_voucherCompanyName}
                            onChange={this.handleVoucherCompany}
                            onKeyDown={this.handleEnter}
                            autoFocus="autofocus"
                          >
                            <option value="">Select Company</option>
                            {this.state.companyDetails.map((item, key) => (
                              <option key={key} value={item.companyname}>
                                {item.companyname}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">
                            ClientName
                          </label>
                          <Input
                            type="option"
                            list="nickname-list"
                            className="form-control-alternative"
                            style={{ height: "100%" }}
                            defaultValue={this.state.rec_voucherClientName}
                            value={this.state.rec_voucherClientName}
                            onChange={this.handleVoucherClientname}
                            placeholder="Select Clientname"
                            onKeyDown={this.handleEnter}
                          />
                          <datalist id="nickname-list">
                            {this.state.clientDetails.map((item, name) => (
                              <option key={name} value={item.company_name} />
                            ))}
                          </datalist>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">Date</label>
                          <Input
                            type="date"
                            className="form-control-alternative"
                            style={{ height: 40 }}
                            defaultValue={this.state.rec_voucherDate}
                            value={this.state.rec_voucherDate}
                            onChange={this.handleVoucherDate}
                            onKeyDown={this.handleEnter}
                          />
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">
                            Multi Invoice
                          </label>
                          <Input
                            type="checkbox"
                            className="form-control-alternative"
                            style={{
                              height: 25,
                              width: 25,
                              top: 35,
                              left: 35,
                            }}
                            defaultValue={this.state.multi_invoice}
                            value={this.state.multi_invoice}
                            disabled={
                              this.state.rec_voucherCompanyName === "" ||
                              this.state.rec_voucherClientName === ""
                            }
                            onChange={this.handleMultiInvoice}
                            onKeyDown={this.handleEnter}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: -20 }}>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">
                            PaymentThrough
                          </label>
                          <Input
                            className="form-control-alternative"
                            style={{ height: "100%" }}
                            placeholder="paymentmode"
                            type="select"
                            defaultValue={this.state.rec_voucherPaymentMode}
                            value={this.state.rec_voucherPaymentMode}
                            onChange={this.handleVoucherPaymentMode}
                            onKeyDown={this.handleEnter}
                          >
                            <option value="">Select Mode</option>
                            {this.state.selectedCompanyBanks.map(
                              (item, key) => (
                                <option key={key} value={item.bankname}>
                                  {item.bankname}
                                </option>
                              )
                            )}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">
                            LedgerType
                          </label>
                          <Input
                            type="select"
                            className="form-control-alternative"
                            style={{ height: "100%" }}
                            defaultValue={this.state.rec_voucherLedgerType}
                            value={this.state.rec_voucherLedgerType}
                            disabled={this.state.multi_invoice === true}
                            onChange={this.handleVoucherLedgerType}
                            onKeyDown={this.handleEnter}
                          >
                            <option value="">Select Ledgertype</option>
                            {this.state.ledgerTypeDetails
                              .sort(
                                (a, b) => a.ledgertypename - b.ledgertypename
                              )
                              .map((item, key) => (
                                <option key={key} value={item.ledgertypename}>
                                  {item.ledgertypename}
                                </option>
                              ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">Projects</label>
                          <Input
                            type="select"
                            style={{ height: "100%" }}
                            defaultValue={this.state.rec_projectdetails}
                            value={this.state.rec_projectdetails}
                            disabled={
                              this.state.multi_invoice === true ||
                              this.state.rec_voucherLedgerType !== "Projects"
                            }
                            onChange={this.handleProjectDetails}
                            onKeyDown={this.handleEnter}
                          >
                            <option value="">Select Projects</option>
                            {this.state.project_details.map((item, key) => (
                              <option key={key} value={item.id}>
                                {item.projectname}
                              </option>
                            ))}
                          </Input>
                        </FormGroup>
                      </Col>
                      <Col lg="3">
                        <FormGroup>
                          <label className="form-control-label">
                            Received Amount
                          </label>
                          <Input
                            type="text"
                            className="form-control-alternative"
                            style={{ height: "100%" }}
                            defaultValue={this.state.rec_voucherReceivedAmount}
                            value={this.state.rec_voucherReceivedAmount}
                            onChange={this.handleVoucherReceivedAmount}
                            onKeyDown={this.handleEnter}
                          ></Input>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: -5, marginBottom: 5 }}>
                      <Col md="12">
                        <Table className="table-flush table-hover" responsive>
                          <thead className="thead-light">
                            {this.state.multi_invoice === false ? (
                              <tr>
                                {this.state.rec_voucherLedgerType ===
                                  "SalesAdvance" ||
                                this.state.rec_voucherLedgerType ===
                                  "SalesInvoice" ||
                                this.state.rec_voucherLedgerType ===
                                  "Invoice" ||
                                this.state.rec_voucherLedgerType ===
                                  "Projects" ? (
                                  <>
                                    <th className="text-center">Reference</th>
                                    <th className="text-center">Total</th>
                                    <th className="text-center">Balance</th>
                                    <th className="text-center">Amount</th>
                                    <th className="text-center">Action</th>
                                  </>
                                ) : (
                                  <>
                                    <th className="text-center">
                                      Receipt Type
                                    </th>
                                    <th className="text-center">Reference</th>
                                    <th className="text-center">Amount</th>
                                    <th className="text-center">Action</th>
                                  </>
                                )}
                              </tr>
                            ) : null}
                          </thead>
                          <tbody>{recVoucherRowList}</tbody>
                        </Table>
                        <div>
                          {this.state.multi_invoice === false ? (
                            <button
                              className="btn btn-primary btn-sm"
                              style={{ width: 35, height: 30 }}
                              onClick={this.handleRecVoucherAddRow}
                            >
                              <i className="fas fa-plus-circle fa-1x"></i>
                            </button>
                          ) : null}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg="12">
                        <hr style={{ backgroundColor: "gray" }}></hr>
                      </Col>
                    </Row>
                    <Row style={{ marginTop: -10 }}>
                      <Col lg="12"></Col>
                      <Col md="3">
                        {this.state.multi_invoice === false ? (
                          <ul class="list-group list-group-flush">
                            <li
                              class="list-group-item"
                              style={{
                                backgroundColor: "transparent",
                                marginLeft: 70,
                              }}
                            >
                              <h3>Voucher Balance</h3>
                            </li>
                          </ul>
                        ) : null}
                      </Col>
                      <Col md="3" style={{ backgroundColor: "transparent" }}>
                        {this.state.multi_invoice === false ? (
                          <ul class="list-group list-group-flush align-center">
                            <li
                              class="list-group-item"
                              style={{
                                backgroundColor: "transparent",
                                marginLeft: 130,
                              }}
                            >
                              <h3>
                                {this.state.rec_voucherReceivedAmount -
                                  this.state.rec_voucherAmount}
                              </h3>
                            </li>
                          </ul>
                        ) : null}
                      </Col>
                      <Col md="3">
                        {this.state.multi_invoice === false ? (
                          <ul class="list-group list-group-flush">
                            <li
                              class="list-group-item"
                              style={{
                                backgroundColor: "transparent",
                                marginLeft: 70,
                              }}
                            >
                              <h3>Amount</h3>
                            </li>
                          </ul>
                        ) : null}
                      </Col>
                      <Col md="3" style={{ backgroundColor: "transparent" }}>
                        {this.state.multi_invoice === false ? (
                          <ul class="list-group list-group-flush align-center">
                            <li
                              class="list-group-item"
                              style={{
                                backgroundColor: "transparent",
                                marginLeft: 80,
                              }}
                            >
                              <h3>{this.state.rec_voucherAmount}</h3>
                            </li>
                          </ul>
                        ) : null}
                      </Col>
                    </Row>
                    <button
                      className="btn btn-dark btn-sm"
                      style={{
                        width: 150,
                        height: 40,
                        float: "right",
                        right: "-5px",
                        marginTop: 5,
                      }}
                      onClick={this.onSubmitVoucher}
                    >
                      Create Voucher
                    </button>
                  </CardBody>
                </Card>
              </Col>
            </form>
          </Row>
        </Container>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.token,
    clientdetails: state.clientdetails,
    username: state.username,
    allusers: state.allusers,
  };
};

export default connect(mapStateToProps, null)(AddRecVouchers);
