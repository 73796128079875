import React from 'react';
import { Page, Document, StyleSheet, Image, View, Text } from "@react-pdf/renderer";
import PurchaseorderTableFooter from "./PurchaseorderTableFooter"
import PurchaseorderThankYouMsg from "./PurchaseorderThankYouMsg";
import PurchaseorderTable from './PurchaseorderTable';
import PurchaseHsnTable from '../SamplePurchaseOrderView/PurchaseHsnTable';
import PurchaseorderWatermark from './PurchaseorderWatermark';
import logo from "assets/img/brand/pgcadd.png";

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    fontSize: 11,
    paddingTop: 30,
    paddingLeft: 60,
    paddingRight: 60,
    lineHeight: 1.5,
    flexDirection: "column"
  },
  logo: {
    marginTop: -125,
    width: 75,
    height: 100,
    marginLeft: "auto",
    marginBottom: 5,
  },

  titleContainer: {
    width: 300,
    height: 66,
    marginRight: "auto",
    marginLeft: -50,
    marginTop: 15,
  },
  reportTitle: {
    color: "#11cdef",
    letterSpacing: 1,
    fontSize: 20,
    marginLeft: 23,
    textAlign: "left",
    textTransform: "uppercase"
  },
  titlelocation: {
    width: 140,
    height: 66,
    marginRight: "auto",
    marginLeft: -25,
    marginTop: -33,
  },

  invoiceNoContainer: {
    marginTop: -70,
    width: 200,
    height: 66,
    marginLeft: "auto",
    marginRight: -90,
  },

  invoiceClientContainer: {
    width: 200,
    height: 66,
    marginRight: "auto",
    marginLeft: -25,
    marginTop: 15,
  },

  invoiceDate: {
    fontSize: 12,
    fontStyle: "bold"
  },
});

const Purchaseorder = ({ purchaseOrderData, purchaseHsnData }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PurchaseorderWatermark data={purchaseOrderData} />

        <View style={styles.titleContainer}>
          <Text style={styles.reportTitle}>{purchaseOrderData.companyid}</Text>
        </View>
        <View>
          <Text style={styles.titlelocation}>
            334, opp. Indusind bank,
            Mettur road, Erode - 638001.
          </Text>
        </View>
        <View>
          <Image style={styles.logo} src={logo} />
        </View>

        <View>
          <View style={styles.invoiceClientContainer}>
            <Text style={styles.clientname}>{purchaseOrderData.supplier}</Text>
          </View>
        </View>

        <View style={styles.invoiceNoContainer}>
          <Text>PurchaseorderId : {purchaseOrderData.id}</Text>
          <Text>Date : {purchaseOrderData.date}</Text>
        </View>

        <PurchaseorderTable manageData={purchaseOrderData} />

        <PurchaseHsnTable hsndata={purchaseHsnData} />

        <PurchaseorderTableFooter itemsTotal={purchaseOrderData} />

        <PurchaseorderThankYouMsg />

      </Page>
    </Document>
  )
};

export default Purchaseorder;