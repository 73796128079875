import React from "react";
import { CustomInput } from "reactstrap";
import {
  FilteredData,
  ClientTransactData,
  MyTaskFilters,
  TeamTaskFilters,
  ProjectFilter,
} from "../../../components/loginRedux/loginReducers";
import store from "../../../components/loginRedux/Store";
let PreData = "";

export const Filter = ({ column: { preFilteredRows }, column }) => {
  if (column.Header === "Client") {
    store.dispatch({ type: ClientTransactData, data: column.filterValue });
  }
  if (
    column.Header === "ClientName" &&
    (column.filterValue !== undefined ||
      column.filterValue !== "" ||
      column.filterValue !== null)
  ) {
    if (column.ReferencePage === "MyTask")
      store.dispatch({
        type: MyTaskFilters,
        data: { clientname: column.filterValue },
      });
    else if (column.ReferencePage === "TeamTask")
      store.dispatch({
        type: TeamTaskFilters,
        data: { clientname: column.filterValue },
      });
    else if (column.ReferencePage === "Projects")
      store.dispatch({
        type: ProjectFilter,
        data: { clientname: column.filterValue },
      });
  }
  if (
    column.Header === "CompanyName" &&
    (column.filterValue !== undefined ||
      column.filterValue !== "" ||
      column.filterValue !== null)
  ) {
    if (column.ReferencePage === "TeamTask")
      store.dispatch({
        type: TeamTaskFilters,
        data: { companyname: column.filterValue },
      });
    else if (column.ReferencePage === "Projects")
      store.dispatch({
        type: ProjectFilter,
        data: { companyname: column.filterValue },
      });
  }
  if (
    column.Header === "AssignTo" &&
    (column.filterValue !== undefined ||
      column.filterValue !== "" ||
      column.filterValue !== null)
  ) {
    if (column.ReferencePage === "MyTask")
      store.dispatch({
        type: MyTaskFilters,
        data: { assignto: column.filterValue },
      });
    else if (column.ReferencePage === "TeamTask")
      store.dispatch({
        type: TeamTaskFilters,
        data: { assignto: column.filterValue },
      });
  }
  if (
    column.Header === "PlanType" &&
    (column.filterValue !== undefined ||
      column.filterValue !== "" ||
      column.filterValue !== null)
  ) {
    if (column.ReferencePage === "MyTask")
      store.dispatch({
        type: MyTaskFilters,
        data: { plantype: column.filterValue },
      });
    else if (column.ReferencePage === "TeamTask")
      store.dispatch({
        type: TeamTaskFilters,
        data: { plantype: column.filterValue },
      });
  }
  if (
    column.Header === "Status" &&
    (column.filterValue !== undefined ||
      column.filterValue !== "" ||
      column.filterValue !== null)
  ) {
    if (column.ReferencePage === "MyTask")
      store.dispatch({
        type: MyTaskFilters,
        data: { status: column.filterValue },
      });
    else if (column.ReferencePage === "TeamTask")
      store.dispatch({
        type: TeamTaskFilters,
        data: { status: column.filterValue },
      });
    else if (column.ReferencePage === "Projects")
      store.dispatch({
        type: ProjectFilter,
        data: { status: column.filterValue },
      });
  }
  if (
    column.Header === "Priority" &&
    (column.filterValue !== undefined ||
      column.filterValue !== "" ||
      column.filterValue !== null)
  ) {
    store.dispatch({
      type: MyTaskFilters,
      data: { priority: column.filterValue },
    });
  }
  if (
    column.Header === "ProjectName" &&
    (column.filterValue !== undefined ||
      column.filterValue !== "" ||
      column.filterValue !== null)
  ) {
    store.dispatch({
      type: TeamTaskFilters,
      data: { projectname: column.filterValue },
    });
  }
  if (
    column.Header === "ProjectLead" &&
    (column.filterValue !== undefined ||
      column.filterValue !== "" ||
      column.filterValue !== null)
  ) {
    if (column.ReferencePage === "Projects")
      store.dispatch({
        type: ProjectFilter,
        data: { projectlead: column.filterValue },
      });
  }
  PreData = preFilteredRows;
  store.dispatch({ type: FilteredData, datas: PreData });
  return (
    <div style={{ marginTop: 5 }}>
      {column.canFilter && column.render("Filter")}
    </div>
  );
};

export const DefaultColumnFilter = ({
  column: {
    preFilteredRows: { length },
  },
}) => {
  return <React.Fragment></React.Fragment>;
};

export const SelectColumnFilter = ({
  column: { filterValue, setFilter, preFilteredRows, id },
}) => {
  const options = React.useMemo(() => {
    const options = new Set();
    preFilteredRows.forEach((row) => {
      options.add(row.values[id]);
    });
    return [...options.values()];
  }, [id, preFilteredRows]);
  const Options1 = options.sort((a, b) => {
    a = a || "";
    b = b || "";
    return (a?.lastname ?? "").localeCompare(b?.lastname ?? "");
  });

  return (
    <CustomInput
      id="custom-select"
      type="select"
      style={{ width: 130 }}
      value={filterValue !== "all" ? filterValue : undefined}
      onChange={(e) => {
        if (e.target.value === "all") {
          setFilter(undefined);
          store.dispatch({ type: MyTaskFilters, data: { mytask_id: id } });
          store.dispatch({ type: TeamTaskFilters, data: { teamtask_id: id } });
          store.dispatch({ type: ProjectFilter, data: { project_id: id } });
        } else {
          setFilter(e.target.value);
        }
      }}
    >
      <option value="all">All</option>
      {Options1.map((option) => (
        <option key={option} value={option}>
          {option}
        </option>
      ))}
    </CustomInput>
  );
};
