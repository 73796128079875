import React, { useEffect, useState } from 'react';
import { Row, Card, Table, Container, Col, CardHeader } from 'reactstrap';
import { connect } from 'react-redux';
import config from "react-global-configuration";
import '../../../../config';
import axios from "axios";
const authApi = config.get('apiDomain');
function SalesRequestedData(props) {
  const [RequestedData, getSalesRequestedData] = useState([]);
  useEffect(() => {
    const getsalesRequestdetails = async () => {
      const response = await fetch(`${authApi}salesrequestfilter/?status__in=Requested%2CReturned`, {
        method: 'GET',
        headers: {
          'content-type': "application/json",
          'Authorization': `token ${props.token}`,
        }
      })
      let body = await response.json();

      updateInventoryisThere(body);
    }
    getsalesRequestdetails();
  },
    // eslint-disable-next-line
    [],
  )
  const BacktoAddSales = () => {
    props.backfunction();
  }

  const updateInventoryisThere = async (Requestedetails) => {
    let totalavailabe = await axios({
      method: "post",
      url: `${authApi}getavailableqtytemplate/`,
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${this.props.token}`,
      },
    })
      .then(res => {
        this.setState({
          Inventorydata: res.data,
        });
      })
      .catch(err => {
        console.log(err)
      })
    let availabledata = await totalavailabe.data;
    Requestedetails.forEach(stock => {
      let isthere = false;
      availabledata.forEach(available => {
        if (stock.stockname === available.StockName) {
          isthere = true;
        }
      })
      if (isthere === true) {
        stock.Salesid = "#c6f7d9";
      }
      else {
        stock.Salesid = "#f7dbc3";
      }
    })
    getSalesRequestedData(Requestedetails);
  }

  const updateRequststockintoSales = async (requestdata) => {
    await axios({
      method: 'post',
      url: `${authApi}sp_addsalestockwithsalesrequest/`,
      headers: {
        'content-type': 'application/json',
        'Authorization': `token ${props.token}`,
      },
      data: {
        sales_id: props.salesid,
        salesrequest_id: requestdata.id
      }
    })
      .then(res => {
        if (res.status === 200) {
          props.updateadddetails(requestdata);
          pagereferesh();
        }
      })
      .catch(err => {
        console.log(err);
      })
  }

  const pagereferesh = async () => {
    const response = await fetch(`${authApi}salesrequestfilter/?status__in=Requested%2CReturned`, {
      method: 'GET',
      headers: {
        'content-type': "application/json",
        'Authorization': `token ${props.token}`,
      }
    })
    let body = await response.json();
    updateInventoryisThere(body);
  }
  return (
    <React.Fragment>
      <Container className="mt--7" fluid><br />
        <Row>
          <Col md="11">
            <CardHeader style={{ backgroundColor: 'transparent', padding: 10, marginTop: 20 }}>
              <h3 className="mb-0"> ClientName <span style={{ marginRight: 5 }}></span>: {props.suppliername} </h3>
            </CardHeader>
          </Col>
          <Col md="1" style={{ marginTop: 22, float: 'right', right: -15 }}>
            <button className="btn btn-primary btn-sm" style={{ width: 45, height: 40 }}
              data-tip data-for="completed-Tip" onClick={BacktoAddSales}>
              <i className="fa fa-arrow-left fa-1x"></i>
            </button>
          </Col>
        </Row>
        <Row style={{ marginTop: 0 }}>
          <div className=" col"><br />
            <Card className="shadow">
              <Table className="align-items-center table-flush" responsive-sm>
                <thead className="thead-light">
                  <tr>
                    <th className="text-center">ACTIONS</th>
                    <th className="text-center">DATE</th>
                    <th className="text-center">STOCKNAME</th>
                    <th className="text-center">PRODUCTCODE</th>
                    <th className="text-center">QUANTITY</th>
                  
                    <th className="text-center">STATUS</th>
                  </tr>
                </thead>
                <tbody>{RequestedData.map((salesdata) => (
                  <tr style={{ backgroundColor: salesdata.Salesid }}>
                    <td className="text-center">
                      <button className="btn btn-primary btn-sm" style={{ marginRight: '5px' }} onClick={() => updateRequststockintoSales(salesdata)}>
                        AddSale
                      </button>
                    </td>
                    <td className="text-center">{salesdata.date}</td>
                    <td className="text-center">{salesdata.stockname}</td>
                    <td className="text-center">{salesdata.productcode}</td>
                    <td className="text-center">{salesdata.quantity}</td>
                   
                    <td className="text-center">{salesdata.status}</td>
                  </tr>
                ))}
                </tbody>
              </Table>
            </Card>
          </div>
        </Row>
      </Container>
    </React.Fragment>
  );
}
const mapStateToProps = state => {
  return {
    token: state.token,
    name: state.name,
  }
}
export default connect(mapStateToProps, null)(SalesRequestedData);