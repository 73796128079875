import React from "react";
import { View, StyleSheet, Text } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  descriptions: {
    width: "42%",
    textAlign: "center",
    paddingLeft: 8,
    paddingTop: 4,
  },
  rows: {
    flexDirection: "row",
    alignItems: "center",
    fontStyle: "bold"
  },
  amounts: {
    width: "19.33%",
    textAlign: "center",
    paddingRight: 20,
    paddingTop: 4,
  },
  discount: {
    width: "20.33%",
    textAlign: "center",
    paddingRight: 16,
    paddingTop: 4,
  
  },
  invoice_amount: {
    width: "20.33%",
    textAlign: "right",
    paddingRight: 20,
    paddingTop: 4,
  },
});

const InvoiceDrafting = ({ draftChar,discount }) => 
{
  const invoiceTot = parseInt(draftChar) + parseInt(discount); 

  return( 
    <React.Fragment>
      { draftChar === 0 ? null :
        <View style={styles.rows}>
          <Text style={styles.descriptions}>Drafting Charges</Text>
          <Text style={styles.amounts}>{draftChar}</Text>
          <Text style={styles.discount}>{parseFloat(discount).toFixed(2)}</Text>
          <Text style={styles.invoice_amount}>{parseFloat(invoiceTot).toFixed(2)}</Text>
        </View>
      }
    </React.Fragment>
  )
};

export default InvoiceDrafting;
